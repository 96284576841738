
var API_URL = 'http://uat-serv-app.ezi2survey.com/api/';
var IAM_URL = 'http://uat-serv-iam.ezi2survey.com/api/';
const RPT_URL = 'http://uss-uat-rpt-api.ezi2survey.com/api/';

const configURL = {
    loginURL: `${API_URL}v1/user-management/login`,
    login_iam_url: `${API_URL}login-with-credentials`,
    demographicURL: `${API_URL}v1/company-management/getallidentifiers`,
    addEmployeeURL: `${API_URL}v1/user-directory-management/addEmployee`,
    addSinglePeople: `${API_URL}v1/company-management/addsingle`,
    OtherContactDemographicURL: `${API_URL}v1/user-directory-management/getAllOtherDemographics`,
    AddOtherContactDemographicURL: `${API_URL}v1/user-directory-management/addOther`,
    employeeFilter: `${API_URL}v1/user-management/filter/employee`,
    customerFilter: `${API_URL}v1/user-management/filter/customer`,
    otherContactFilter: `${API_URL}v1/user-management/filter/other`,
    excelHeaderURL: `${API_URL}v1/user-directory-management/excel`,
    excelValidation: `${API_URL}v1/user-directory-management/excelentry`,
    excelAllentry: `${API_URL}v1/user-directory-management/allentry`,
    excelValidEntry: `${API_URL}v1/user-directory-management/validentry`,
    excelInvalidEntry: `${API_URL}v1/user-directory-management/invalidentry`,
    excelUpdateRow: `${API_URL}v1/user-directory-management/updatedataifstagging/`,
    processData: `${API_URL}v1/user-directory-management/processdata/`,
    customerDemographicURL: `${API_URL}v1/company-management/getallidentifiers`,
    excelDownload: `${API_URL}v1/user-directory-management/invalidentrywriter/employee`,
    addCustomer: `${API_URL}v1/user-directory-management/addCustomer`,
    themeListing: `${API_URL}v1/theme/themesviewer`,
    subthemeListing: `${API_URL}v1/theme/subthemesviewer`,
    categoryDs: `${API_URL}v1/theme/categoryviewer`,
    bulkUpdate: `${API_URL}v1/online-bulk-update/bulkupdate`,
    onlineUpdate: `${API_URL}v1/online-bulk-update/onlineedit`,
    addTheme: `${API_URL}v1/theme/addThemes`,
    updateThemeRow: `${API_URL}v1/theme/themeEditor/theme`,
    updateSubthemeRow: `${API_URL}v1/theme/themeEditor/subtheme`,
    questionBank: `${API_URL}v1/questionbank`,
    themeDropdown: `${API_URL}v1/themedropdown`,
    deleteRow: `${API_URL}v1/theme/delete-master`,
    saveSurvey: `${API_URL}v1/survey/savesurvey`,
    categoryListing: `${API_URL}v1/theme/categoryview`,
    categoryViewData: `${API_URL}v1/theme/categorydataviewer`,
    categorySave: `${API_URL}v1/theme/savecategory`,
    peopleByType: `${API_URL}v1/survey/filterlisting`,
    dynamicPeopleSourceLink: `${API_URL}v1/survey/participant-template-download`,
    getpeopleByRandom: `${API_URL}v1/survey/getpeopleByRandom`,
    addParticipantsToSurvey: `${API_URL}v1/survey/addParticipants`,
    saveSurveyQustions: `${API_URL}v1/survey/savesurveyquestions`,
    getSurveyQustions: `${API_URL}v1/survey/getsurveyquestions`,
    surveythemeDs: `${API_URL}v1/survey/thememapping`,
    surveySubThemeDs: `${API_URL}v1/survey/subthememapping`,
    createSurvey: `${API_URL}v1/survey/surveycreator`,
    getSurveyTypes: `${API_URL}v1/survey/get-types`,
    subThemesData: `${API_URL}v1/survey/subthemedataonload`,
    themeQuestionListing: `${API_URL}v1/survey/theme/questionlisting`,
    surveyDemographicfilter: `${API_URL}v1/survey/demographicsfilter`,
    participantFilters: `${API_URL}v1/survey/participants-filter`,
    participantFiltersOptions: `${API_URL}v1/survey/participants-filter-options`,
    surveyDemographicfilterSearch: `${API_URL}v1/survey/demographicsfiltersearch`,
    surveyQuestionMapping: `${API_URL}v1/survey/surveyquestionmapping`,
    surveyLogoDelete: `${API_URL}v1/survey/delete-logo`,
    publishingSettingListing: `${API_URL}v1/survey/channeltemplates`,
    saveTemplate: `${API_URL}v1/survey/save-templates`,
    channelUpdate: `${API_URL}v1/survey/channelupdate`,
    storeSurveySetting: `${API_URL}v1/survey/surveysettingstore`,
    getSurveySetting: `${API_URL}v1/survey/get-survey-settings`,
    surveyLaunch: `${API_URL}v1/survey/launchsurvey`,
    getSurveydata: `${API_URL}v1/survey/survey-link/get-survey-details`,
    surveyFinishLater: `${API_URL}v1/survey/finish-later`,
    surveySavePageResponse: `${API_URL}v1/survey/page-wise-response`,
    verifySurveyResponse: `${API_URL}v1/survey/check-identifier`,
    getCompanydata: `${API_URL}v1/survey/survey-link/get-company-details`,
    addtemplates: `${API_URL}v1/survey/addtemplates`,
    companyLogo: `${API_URL}v1/survey/companaylogourl`,
    createIdentifier: `${API_URL}v1/user-directory-management/addidentifier`,
    updateIdentifier: `${API_URL}v1/user-directory-management/identifierupdate`,
    deleteIdentifier: `${API_URL}v1/user-directory-management/identifierdelete`,
    addIdentifierOption: `${API_URL}v1/user-directory-management/addoption`,
    send_reminder_url: `${API_URL}v1/survey/companaylogourl`,
    save_survey_response_url: `${API_URL}v1/survey/survey-response`,
    templatedownload: `${API_URL}v1/user-directory-management/templatedownload`,
    invalidEntriesDownload: `${API_URL}v1/user-directory-management/invalidentrywriter/`,
    deleteStaggingData: `${API_URL}v1/user-directory-management/deleteStaggingData/`,
    bulkExcelDownload: `${API_URL}v1/online-bulk-update/validexceldownload`,
    template_dashboard_initials: `${API_URL}v1/survey-template/initials-data`,
    dashboard_recent_template_url: `${API_URL}v1/user-directory-management/identifierdelete`,
    save_template_url: `${API_URL}v1/survey-template/saving`,
    get_template_details_url: `${API_URL}v1/survey-template/view-templates`,
    category_templates_url: `${API_URL}v1/survey-template/category-wise-templates`,
    category_templates_metadata_url: `${API_URL}v1/survey-template/category-wise-counts`,
    get_template_builder_initals: `${API_URL}v1/survey-template/template-questions-initials`,
    save_template_builder_setting: `${API_URL}v1/survey-template/save-template-builder`,
    save_template_question: `${API_URL}v1/survey-template/save-template-question`,
    survey_dashboard_details: `${API_URL}v1/survey/dashboard-details`,
    get_survey_name: `${API_URL}v1/survey/get-survey-name`,
    category_wise_surveys: `${API_URL}v1/survey/category-wise-surveys`,
    survey_status_update: `${API_URL}v1/survey/status-update`,
    survey_name_update: `${API_URL}v1/survey/name-update`,
    selected_participants: `${API_URL}v1/survey/selected-participants`,
    download_response_sheet: `${API_URL}v1/survey/download-participant-sheet`,
    template_question_survey_name: `${API_URL}v1/survey-template/template-question-name`,
    rollback_survey: `${API_URL}v1/survey/rollback`,
    deleteSelectedParticipants: `${API_URL}v1/survey/delete-selected-participants`,
    deleteCommunication: `${API_URL}v1/survey/delete-communication-history`,
    resetParticipantResponse: `${API_URL}v1/survey/reset-response`,
    saveChannelTemplates: `${API_URL}v1/survey/save-communication-templates`,
    delete_template: `${API_URL}v1/survey-template/deleting`,
    deletePeople: `${API_URL}v1/company-management/delete-single`,
    get_dashboard_count_graphs: `${API_URL}v1/survey/survey-count-graphs`,
    get_dashboard_survey_highlights: `${API_URL}v1/survey/recent-survey-highlights`,
    get_company_users: `${API_URL}v1/iam/get-users`,
    get_user_detail: `${API_URL}v1/iam/user-details`,
    save_user: `${API_URL}v1/iam/user`,
    save_role: `${API_URL}v1/iam/role`,
    get_company_roles: `${API_URL}v1/iam/get-roles`,
    get_company_profiles: `${API_URL}v1/iam/get-profiles`,
    delete_person: `${API_URL}v1/iam/user-delete`,
    role_delete: `${API_URL}v1/iam/role-delete`,
    profile_delete: `${API_URL}v1/iam/profile-delete`,
    profile_detail: `${API_URL}v1/iam/profile-details`,
    save_profile: `${API_URL}v1/iam/profile`,
    get_role_detail: `${API_URL}v1/iam/role-details`,
    save_communicate_api: `${API_URL}v1/survey/communicate`,
    save_sample_api: `${API_URL}v1/survey/save-sampling`,
    dynamicParticipantIdentifier: `${API_URL}v1/survey/get-dynamic-particiapant-data`,
    savedynamicParticipant: `${API_URL}v1/survey/save-dynamic-participant`,
    communication_history: `${API_URL}v1/survey/communication-history`,
    create_survey_cat_by_template: `${API_URL}v1/survey-template/create-survey-category`,
    update_survey_date: `${API_URL}v1/survey/change-duration`,
    copy_survey: `${API_URL}v1/survey/copy-survey`,
    get_survey_detail: `${API_URL}v1/survey/survey-details`,
    iam_save_partner: `${IAM_URL}partner`,
    iam_partners_list: `${IAM_URL}partner`,
    iam_compnay_add: `${IAM_URL}partner/company`,
    compnay_delete: `${API_URL}v1/partner-template/delete-company`,
    get_compnay_details: `${API_URL}v1/partner-template/get-company-details`,
    save_compnay_details: `${API_URL}v1/partner-template/company-create-update`,
    company_listing: `${API_URL}v1/partner-template/company-listing`,
    partner_categoryDs: `${API_URL}v1/partner-admin/category-list`,
    partner_themeListing: `${API_URL}v1/partner-admin/theme-list`,
    partner_subthemeListing: `${API_URL}v1/partner-admin/sub-theme-list`,
    partner_addTheme: `${API_URL}v1/partner-admin/theme-save`,
    partner_addSubTheme: `${API_URL}v1/partner-admin/sub-theme-save`,
    partner_template_dashboard_initials: `${API_URL}v1/partner-template/initials-data`,
    partner_categoryListing: `${API_URL}v1/partner-admin/categoryview`,
    partner_categorySave: `${API_URL}v1/partner-admin/savecategory`,
    partner_save_template_url: `${API_URL}v1/partner-template/saving`,
    partner_surveythemeDs: `${API_URL}v1/partner-admin/theme/thememapping`,
    partner_surveySubThemeDs: `${API_URL}v1/partner-admin/theme/subthememapping`,
    partner_get_template_builder_initals: `${API_URL}v1/partner-template/template-questions-initials`,
    partner_save_template_builder_setting: `${API_URL}v1/partner-template/save-template-builder`,
    partner_save_template_question: `${API_URL}v1/partner-template/save-template-question`,
    partner_template_category: `${API_URL}v1/partner-admin/categoryviewer`,
    partner_get_template_details_url: `${API_URL}v1/partner-template/view-templates`,
    partner_category_templates_url: `${API_URL}v1/partner-template/category-wise-templates`,
    partner_company_templates: `${API_URL}v1/partner-template/company-wise-templates`,
    partner_category_templates_metadata_url: `${API_URL}v1/partner-template/category-wise-counts`,
    survey_filters: `${API_URL}v1/survey/get-survey-filter-listing`,
    surveyCheckList: `${API_URL}v1/survey/overall-survey-data`,
    verification_user: `${API_URL}v1/captcha`,
    recent_activities: `${API_URL}v1/recent-activities`,
    clear_activities: `${API_URL}v1/clear-activities`,
    partner_allocate_template: `${API_URL}v1/partner-admin/allocateTemplate`,
    partner_themeQuestionListing: `${API_URL}v1/partner-admin/theme/questionlisting`,
    partner_subThemesData: `${API_URL}v1/partner-admin/theme/subthemedataonload`,
    partner_surveyQuestionMapping: `${API_URL}v1/partner-admin/theme/surveyquestionmapping`,
    partner_dashboard_companies: `${IAM_URL}partner/dashbaord-counts`,
    partner_dashboard_template: `${API_URL}v1/partner-admin/dashboard-counts`,
    partner_delete_template: `${API_URL}v1/partner-template/deleting`,
    partner_add_company_data: `${API_URL}v1/company/add-company-data`,
    update_company_user: `${API_URL}v1/iam/update-user-profile`,
    partner_updateThemeRow: `${API_URL}v1/partner-admin/theme/themeEditor/theme`,
    partner_updateSubthemeRow: `${API_URL}v1/partner-admin/theme/themeEditor/subtheme`,
    partner_deleteRow: `${API_URL}v1/partner-admin/theme/delete-master`,
    getReportTypes: `${API_URL}v1/survey/get-report-types`,
    ReportSurveyQuestion: `${RPT_URL}get-survey-question`,
    ReportGetScore: `${RPT_URL}get-score`,
    ReportInsightInfocus: `${RPT_URL}get-infocus`,
    ReportInsightScoreBreakup: `${RPT_URL}get-score-breakup`,
    ReportInsightCommentCount: `${RPT_URL}get-comments-counts`,
    ReportInsightDistribution: `${RPT_URL}get-distribution`,
    ReportInsightComment: `${RPT_URL}get-comments-list`,
    ReportKeyPhrase: `${RPT_URL}get-comments-sentiment`,
    ReportAnalysisBarChart: `${RPT_URL}question-ananlysis/get-multi-choice`,
    ReportAnalysisCommentCount: `${RPT_URL}question-ananlysis/get-comments-counts`,
    ReportAnalysisComment: `${RPT_URL}question-ananlysis/get-comments-list`,
    ReportAnalysisSentiment: `${RPT_URL}question-ananlysis/get-comments-sentiment`,
    ReportAnalysisDistribution: `${RPT_URL}question-ananlysis/get-distribution`,
    ReportDemographicFilter: `${RPT_URL}get-demographics`,
    
}
export default configURL;
