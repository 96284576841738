import CompanyRouter from "router/CompanyRouter"
import AdminRouter from "router/AdminRouter"
import PartnerRouter from "router/PartnerRouter"

const RootRouter = [
    ...AdminRouter,
    ...CompanyRouter,
    ...PartnerRouter
]

export default RootRouter