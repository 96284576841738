import {useState,useEffect} from 'react';

const useColumnMapping = () => {
    var data;
    const [mappingData , setMappingData] = useState();
     const  handleChange = selectedOption => {
        setMappingData(selectedOption);
      };

      
 useEffect( () =>  {
     data=localStorage.getItem("val").split(",");
     setMappingData(data);
 },[]);

    return {
        mappingData,
        handleChange
    };
}
export default useColumnMapping;
