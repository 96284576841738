import React , { useState , useEffect , useRef ,useContext } from 'react';
import { Doughnut , Bar , Line as LineChart , HorizontalBar ,Chart} from 'react-chartjs-2';
import { Tab , Nav } from 'react-bootstrap';
import CommentComponent from './CommentComponent';
import { useParams } from "react-router-dom";
import Axios from "utility/Axios";
import configURL from 'config/config';
import { toast } from "react-toastify";
import ReactSpeedometer from 'react-d3-speedometer';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ReactWordcloud from 'react-wordcloud';
import ReactApexChart from 'react-apexcharts'
import AppContext from 'store/AppContext';

// ChartDataLabels by default display false globally 
Chart.defaults.global.plugins.datalabels.display = false;

// If the bar value 0 then hide value globally
// Chart.defaults.global.plugins.datalabels.display = function(ctx) {
//     return ctx.dataset.data[ctx.dataIndex] !== 0;
// }

// No data is present showing blink message 
Chart.plugins.register({
	afterDraw: function(chart) {
		// console.log(chart.data.datasets[0].data.length,  chart.canvas.id, chart.data.datasets[0].data);
		if (chart.data.datasets[0].data.length === 0) {
			// No data is present
			var ctx = chart.chart.ctx;
			var width = chart.chart.width;
			var height = chart.chart.height;
			chart.clear();
			ctx.save();
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.font = "16px normal 'NunitoSans'";
			ctx.fillText('No data to display for selected time period', width / 2, height / 2);
			ctx.restore();
		}
    }
});

const InsightComponent = (props) => {
    const urlParams = useParams();   
    const [ surveyId , setSurveyId ] = useState(urlParams.survey_id)
    const [ key, setKey] = useState('break_up');
    const [ insightData , setInsightData ] = useState(props.insightData);
    const [ scoreData , setScoreData ] = useState({});
    const [ infocusData , setInfocusData ] = useState({});
    const [ scoreBreakup , setScoreBreakup ] = useState([]);
    const [ scoreBreakupCount , setScoreBreakupCount ] = useState([]);
    const [ commentCountData , setCommentCountData ] = useState([]);
    const [ commentLable , setCommentLable ] = useState([]);    
    const [ hiData , setHiData ] = useState([]);
    const [ keyPhrase , setKeyPhrase ] = useState([]);
    const [ keyPhraseLabel , setKeyPhraseLabel ] = useState([]);
    const [ distributionData , setDistributionData ] = useState([]);
    let distributionTime = useRef();
    const [ wordCount , setWordCount ] = useState(20);
    const [ filterData, setFilterData] = useState(props.filterData);
    const [ dateFilter, setDateFilter] = useState(props.dateFilter);
    const { EziLoader } = useContext(AppContext)

    const wordOptions = {
        colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
        enableTooltip: true,
        deterministic: false,
        fontFamily: 'NunitoSansSemiBold',
        fontSizes: [5,50],
        rotations:0,
 
    };

    const  generateData = (count, yrange) => {
        var i = 0,
        series = [];
        while (i < count) {
        var x = 'w' + (i + 1).toString(),
        y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;    
          series.push({ x: x, y: y });
          i++;
        }
        return series;
    }

    const heatmapData = {      
        series: [
            { name: "May", data: [29,30,31] },
            { name: "May", data: [22,23,24,25,26,27,28] },
            { name: "May", data: [15,16,17,18,19,20,21] },
            { name: "May", data: [8,9,10,11,12,13,14] },
            { name: "May", data: [1,2,3,4,5,6,7] },
            // { name: 'Jan', data: generateData(7, { min: 0, max: 90 }) },
        ],
        options: {
          chart: { height: 300, type: 'heatmap', selection: { enabled: false} },
          stroke: { width: 0 },
          chart: { toolbar: { show: false } },
          plotOptions: {
            heatmap: {
              radius: 0,
              enableShades: false,
              colorScale: {
                ranges: [
                  { from: 1, to: 7,  name: 'Loving', color: '#8BD858' },
                  { from: 8, to: 14,   name: 'Happy',color: '#5C9721' },
                  { from: 15, to: 21,  name: 'Angry', color: '#D72F14' },
                  { from: 22, to: 28,   name: 'Sad',color: '#F8BE5B' },
                  { from: 29, to: 31,   name: 'Boring',color: '#FBAE2B' },
                ],
              },      
            }
          },
          dataLabels: { enabled: true, style: { colors: ['#fff'] } },
          grid: { show: false},
          xaxis: { type: 'category',labels: { show: false}, tooltip: { enabled: false },  axisBorder: { show: false },},
          yaxis: { type: 'category',labels: { show: false}},
        //   title: { text: 'Rounded (Range without Shades)' },
        },
    }
      

    const setFilter = (filter) => {
        distributionListing();
    }

    const questionTypeListing = () => {
        EziLoader.show();
        let formData = new FormData();
        formData.append("survey_id", surveyId);
        formData.append("question_type", insightData.question_type);
        formData.append("question_id", insightData.id);
        formData.append("filters",JSON.stringify(props.filterData));
        formData.append("date",JSON.stringify(props.dateFilter));
        // formData.append("survey_id","8addb246-34b2-41d2-aea6-aedbfbb983fb");
        // formData.append("question_id", "unq320f397ica8a12");
        // formData.append("question_type","HI");

        Axios.post(`${configURL.ReportGetScore}/${insightData.question_type.toLowerCase()}`, formData).then(response => {
            if (response.data.success !== undefined && response.data.success) {
                EziLoader.hide();
                setScoreData(response.data.result);   
                setHiData(response.data.result.hiscore);
                       
            } else {
                setScoreData(0);   
                setHiData(0)
                toast.warn(response.data.message);
            }
        })
    }

    const inFocusListing = () => {
        EziLoader.show();
        let formData = new FormData();
         formData.append("survey_id", surveyId);
         formData.append("question_type", insightData.question_type);
         formData.append("filter", "daily");
         formData.append("question_id", insightData.id);
        //  formData.append("survey_id", "8addb246-34b2-41d2-aea6-aedbfbb983fb");
        //  formData.append("question_id", "unq320f397ica8a12");
        formData.append("filters",JSON.stringify(props.filterData));
        formData.append("date",JSON.stringify(props.dateFilter));
        Axios.post(`${configURL.ReportInsightInfocus}/${insightData.question_type.toLowerCase()}`, formData).then(response => {
            EziLoader.hide();
            if (response.data.success !== undefined && response.data.success) {
                setInfocusData(response.data.result.infocus);               
            } else {
                toast.warn(response.data.message);
            }
        })
    }

    const scoreBreakUpListing = () => {
        EziLoader.show();
        let formData = new FormData();
        const scoreBreakUpValue = [];
        formData.append("survey_id", surveyId);
        formData.append("question_type",insightData.question_type);
        formData.append("filter", "daily");
        formData.append("question_id", insightData.id);
        formData.append("filters",JSON.stringify(props.filterData));
        formData.append("date",JSON.stringify(props.dateFilter));
        // formData.append("survey_id", "8addb246-34b2-41d2-aea6-aedbfbb983fb");
        //formData.append("question_id", "unq320f397ica8a12");
        Axios.post(configURL.ReportInsightScoreBreakup+'/'+insightData.question_type.toLowerCase(), formData).then(response => {
            EziLoader.hide();
            if (response.data.success !== undefined && response.data.success) {
                // console.log("scoreBreakUpListing :",response.data)
                // const scoreBreakUpKey = [];
                if(response.data.result.lables !== undefined && response.data.result.lables){
                     setScoreBreakup(response.data.result.lables);
                }else{
                    setScoreBreakup(0);
                }
                var count=response.data.result.count;
                if(response.data.result.count !== undefined && response.data.result.count){

                    for (var index in count) {                   
                        if (count.hasOwnProperty(index))  {                          
                            scoreBreakUpValue.push(count[index]);
                            setScoreBreakupCount(scoreBreakUpValue);
                        }
                    } 
                 }else{
                    setScoreBreakupCount(scoreBreakUpValue);
                 }
                // response.data.result.forEach((key, value) => {
                //     scoreBreakUpKey.push(key.scales);
                //     scoreBreakUpValue.push(key.counts);
                //     setScoreBreakup(scoreBreakUpKey);
                //     setScoreBreakupCount(scoreBreakUpValue);
                // });
            } 
            else {
                toast.warn(response.data.message);
            }
        })
    }
    
    const commentCount = () => {
        EziLoader.show();
        let formData = new FormData();
        formData.append("survey_id", surveyId);
        formData.append("question_type", insightData.question_type);
        formData.append("question_id", insightData.id);
        formData.append("filters",JSON.stringify(props.filterData));
        formData.append("date",JSON.stringify(props.dateFilter));
        // formData.append("survey_id","8addb246-34b2-41d2-aea6-aedbfbb983fb");
        // formData.append("question_id", "unq320f397ica8a12");
        Axios.post(`${configURL.ReportInsightCommentCount}`, formData).then(response => {
            EziLoader.hide();
            if (response.data.success !== undefined && response.data.success) {                
                const commnetValue = [];
                setCommentLable(response.data.result.lables);
                 var count=response.data.result.commentcount;
                for (var index in count) {                   
                        if (count.hasOwnProperty(index)) 
                        {
                              commnetValue.push(count[index]);                          
                        }
                } 
                setCommentCountData(commnetValue);
                                  
            } else {
                toast.warn(response.data.message);
            }
        })
    }
    const distributionListing = (filter) => {
        EziLoader.show();
        let formData = new FormData();
        let distributionFilter = (distributionTime.current?distributionTime.current.value:"hourly");
        formData.append("filter",distributionFilter);
        formData.append("survey_id", surveyId);
        formData.append("question_type", insightData.question_type);
        formData.append("question_id", insightData.id);
        formData.append("filters",JSON.stringify(props.filterData));
        formData.append("date",JSON.stringify(props.dateFilter));
        //  formData.append("survey_id", "8addb246-34b2-41d2-aea6-aedbfbb983fb");
        //  formData.append("question_id", "unq320f397ica8a12");
        //  formData.append("question_type","HI");
        Axios.post(`${configURL.ReportInsightDistribution}/${insightData.question_type.toLowerCase()}`, formData).then(response => {
            EziLoader.hide();
            if (response.data.success !== undefined && response.data.success) { 
                setDistributionData(response.data.result);              
            } else {
                // toast.warn(response.data.message);
            }
        })
    }
    const keyPhraseListing = () => {
        EziLoader.show();
        let formData = new FormData();
        formData.append("survey_id", surveyId);        
        formData.append("question_type", insightData.question_type);
        formData.append("question_id", insightData.id);
        formData.append("filters",JSON.stringify(props.filterData));
        formData.append("date",JSON.stringify(props.dateFilter));
        Axios.post(`${configURL.ReportKeyPhrase}`, formData).then(response => {          
            EziLoader.hide();
            if (response.data.success !== undefined && response.data.success) {  
                setKeyPhrase(response.data.result);                                  
            } else {
                toast.warn(response.data.message);
            }
        })
    }
 
   

    const barChartData = {
        labels: scoreBreakup ,
        datasets: [
            {
                label: "Count",
                backgroundColor: [ '#3d7e12','#7fcd31','#d72f14','#d1880c','#f7e521' ],
                borderColor: [ '#3d7e12','#7fcd31','#d72f14','#d1880c','#f7e521' ],
                data: scoreBreakupCount,
                offsetGridLines : false,
            }
        ]
    };

    
    const barChartOptions = {
        responsive: true,   
        maintainAspectRatio: false,
        animation: {
            duration: 0,
            onComplete: function () {
                // render the value of the chart above the bar
                var ctx = this.chart.ctx;
                ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, 'bold', Chart.defaults.global.defaultFontFamily);
                ctx.fillStyle = "#5A4E63";
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
                this.data.datasets.forEach(function (dataset) {
                    for (var i = 0; i < dataset.data.length; i++) {
                        var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                        ctx.fillText(dataset.data[i], model.x, model.y - 5);
                    }
                });
            }},
        layout: { padding: { top: 20, } },
        legend: {
            display: false,
            align:'start',
            position:'bottom',
            labels: {
                fontColor: '#5A4E63',
                boxWidth: 6,
                fontSize:12,
                fontFamily :"NunitoSansSemiBold",
                padding	:20,
                usePointStyle:true,
            }
        },
        scales: {
            xAxes: [{
                barThickness: 24,
                // maxBarThickness: 8,
                ticks: { fontColor: "#373737",fontFamily: "NunitoSans", fontSize: 12,stepSize: 1,  beginAtZero: true,},
                gridLines: { color: "transparent",zeroLineColor: 'transparent',}, 
            }],

            yAxes: [{
                ticks: { fontColor: "#5B5B5B",fontFamily: "NunitoSans", fontSize: 12,stepSize: 1, beginAtZero: true , padding: 15},
                 gridLines: { color: '#EDEDED',  zeroLineColor: '#EDEDED',borderColor:"transparent",   drawBorder: false, } }]
             }
        
    };



    const sentimentDoughnutData = {

        labels:commentLable,
        datasets: [{
            data: commentCountData,
            backgroundColor: [ '#009245' , '#CE2E6C', '#F1B343' ],
        }]


    } 

    const sentimentDoughnutOptions = {
        segmentShowStroke: false,
        responsive: true, 
        maintainAspectRatio: false,  
        legend: {
            display: true,
            align:'center',
            position:'bottom',
            labels: { fontColor: '#5A4E63', boxWidth: 6, fontSize:12, fontFamily :"NunitoSansSemiBold", padding	:20, usePointStyle:true, }
        },
        cutoutPercentage: 72,
        elements: { arc: { borderWidth: 0 } },
        tooltips: {
            displayColors: false,
            backgroundColor: "#5A4E63",
            enabled: true,
            mode: "single",
            bodyFontSize: 13,
            bodyFontFamily: "NunitoSansSemiBold",
            bodyFontColor: "#FFFFFF",
            yPadding: 8,
            xPadding: 15,
            cornerRadius: 5,
          },
    }

 
    const commentBarData = {
        labels: keyPhraseLabel,
        datasets: [
          {
            label: 'Count',
            backgroundColor: '#CE2E6C',
            borderColor: '#CE2E6C',
            hoverBackgroundColor: '#CE2E6C',
            hoverBorderColor: '#CE2E6C',
            borderWidth: 1,
            data: keyPhrase,
          }
        ]
      };

      const commentBarOptions = {
        responsive: true,   
        maintainAspectRatio: false,
        legend: { display: false, },
        layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
        scales: {
            xAxes: [{
                ticks: { display:false},
                gridLines: {   color: "rgba(0, 0, 0, 0)",zeroLineColor: '#EBEBEB',}, 
            }],
            yAxes: [{
                barPercentage: 1.8,
                categoryPercentage: 0.3,
                ticks: { fontColor: "#373737",fontFamily: "NunitoSansSemiBold", fontSize: 12, stepSize: 10, padding: 15},
                 gridLines: {  color: "transparent",  zeroLineColor: 'transparent', } }]
             }        
    };



    const distributionMixedData = {
        datasets: [{
            
            datalabels: { display: 'auto',color: '#5A4E63', font:{size:'13',family:'NunitoSansBold'} , anchor: 'end', align: 'top', formatter: Math.round, font: { weight: 'bold' } },
            label: 'HI',
            type:'line',
            data: distributionData.hiscore,
            fill: false,
            borderColor: '#7467F0',
            backgroundColor: '#7467F0',
            pointBorderColor: '#7467F0',
            pointBackgroundColor: '#7467F0',
            pointHoverBackgroundColor: '#7467F0',
            pointHoverBorderColor: '#7467F0',
            yAxisID: 'y-axis-2',
            borderWidth: 2,
        },
        {
            datalabels: {display: function(ctx) { return ctx.dataset.data[ctx.dataIndex] !== 0; }, color: '#fff'},
            type: 'bar',
            label: 'Happy + Loving',
            data: distributionData.loving_happy_count,
            fill: false,
            backgroundColor: '#8BD858',
            borderColor: '#8BD858',
            hoverBackgroundColor: '#8BD858',
            hoverBorderColor: '#8BD858',
            yAxisID: 'y-axis-1'
        },
        {
            datalabels: {display: function(ctx) { return ctx.dataset.data[ctx.dataIndex] !== 0; }, color: '#fff' },
            type: 'bar',
            label: 'Boring',
            data: distributionData.boring_count,
            fill: false,
            backgroundColor: '#F8BE5B',
            borderColor: '#F8BE5B',
            hoverBackgroundColor: '#F8BE5B',
            hoverBorderColor: '#F8BE5B',
            yAxisID: 'y-axis-1'
        },
        {
            datalabels: {display: function(ctx) { return ctx.dataset.data[ctx.dataIndex] !== 0; }, color: '#fff' },
            type: 'bar',
            label: 'Angry + Sad',
            data: distributionData.angry_sad_count,
            fill: false,
            backgroundColor: '#CE2E6C',
            borderColor: '#CE2E6C',
            hoverBackgroundColor: '#CE2E6C',
            hoverBorderColor: '#CE2E6C',
            yAxisID: 'y-axis-1'
        }
    ]};
        
    const distributionMixedOptions = {   
        showTooltips: true,  
        responsive: true,
        maintainAspectRatio: false,
        labels: distributionData.hilables,
        legend: {
            display: true,
            align:'center',
            position:'bottom',
            labels: { fontColor: '#5A4E63', boxWidth: 6, fontSize:12, fontFamily :"NunitoSansSemiBold", padding	:24, usePointStyle:true, }
        },
        layout: {
            padding: {top: 35}
        },
        elements: { line: { fill: false } },
        scales: {        
            xAxes: [{
                display: true,
                stacked: true,
                gridLines: { display: false },        
                labels: distributionData.hilables,
                barThickness: 28,
                // scaleLabel: {
                //     display: true,
                //     labelString: 'Duration',
                //     fontColor: "#5B5B5B",fontFamily: "NunitoSansSemiBold", fontSize: 13,
                //     padding:{top:10,bottom:0} 
                // },
                ticks: { fontColor: "#686868",fontFamily: "NunitoSansSemiBold", fontSize: 12, padding: 5,},
            }],
            yAxes: [{
                type: 'linear',
                stacked: true,
                display: true,
                position: 'left',
                id: 'y-axis-1',
                gridLines: { display: false },
                labels: { show: true },
                scaleLabel: {
                    display: true,
                    labelString: "Responses",
                    fontColor: "#5B5B5B",fontFamily: "NunitoSansSemiBold", fontSize: 13, 
                    padding:{bottom:15} 
                  },
                ticks: { fontColor: "#686868",fontFamily: "NunitoSansSemiBold", fontSize: 12, padding: 5, precision: 0,beginAtZero: true,},
            },
            {
                type: 'linear',
                display: true,
                position: 'right',
                id: 'y-axis-2',
                scaleLabel: {
                    display: true,
                    labelString: "HI",
                    fontColor: "#5B5B5B",fontFamily: "NunitoSansSemiBold", fontSize: 13, 
                    padding:{bottom:15} 
                  },
                labels: { show: true },
                   ticks: { fontColor: "#686868",fontFamily: "NunitoSansSemiBold", fontSize: 12,  padding: 5, precision: 0,beginAtZero: true},
                   gridLines: { color: '#EBEBEB',  zeroLineColor: '#EDEDED',borderColor:"transparent",   drawBorder: false,  }
            }
        ]}
    };

    const plugins = [{
        afterDraw: (chartInstance, easing) => {
        const ctx = chartInstance.chart.ctx;
        ctx.fillText("", 100, 100);
        }
    }];
    
   
    useEffect(() => {
        setFilterData(props.filterData);
        setDateFilter(props.dateFilter);
        inFocusListing();
        scoreBreakUpListing();
        questionTypeListing();
        commentCount();
        distributionListing("hourly");
        keyPhraseListing();

        
    }, [props.filterData,props.dateFilter]);
 
    return(           
        <React.Fragment>
                <section className="CX-QuestionComponent CE-insight-section" >
                    <div className="insight-header-text-wrap">
                        <span className="ce-title">Insights</span>
                        <span className="ce-subtitle">{insightData && insightData.title}</span>
                    </div>
                    <div className="ce-chart-content multiple-half-donut-wrap">
                        <div className="ce-chart-box-wrap checkbox-type-box multiple-half-donut">
                            <div className="ce-chart-box">
                                <div className="ce-chart-box-header">
                                    <span className="left-text">Happiness Index</span>
                                    <span className="right-text">{scoreData.total} Responses</span>
                                </div>
                                <div className="ce-chart-box-body speedometer-body">
                                            <div className="speedometer-wrapper">
                                                <ReactSpeedometer
                                                    width={300}
                                                    height={180}
                                                    forceRender={true}
                                                    needleHeightRatio={0.7}
                                                    maxSegmentLabels={1}
                                                    customSegmentStops={hiData}
                                                    segmentColors={["#CE2E6C", "#EEEEEE"]}
                                                    minValue={0}
                                                    maxValue={100}
                                                    value={scoreData.today_hiscore}
                                                    ringWidth={25}
                                                    currentValueText={"Latest Score : ${value}"}
                                                    needleTransitionDuration={3333}
                                                    needleTransition="easeElastic"
                                                    needleColor={"#504658"}
                                                    textColor={"#504658"}
                                                    labelFontSize="12"                                                
                                                    valueTextFontSize="13"
                                                />  
                                                <span className="speedometer-hi-score">HI : {hiData[1]}</span>   
                                            </div>
                                     
                                    <div className="half-donut-info">
                                        <div className="half-donut-rating-text">
                                            <div className="half-donut-rating-col half-donut-excellent">
                                                <span className="ce-title">{scoreData && scoreData.loving_happy || 0}</span>
                                                <span className="ce-subtitle">Happy + loving</span>
                                            </div>
                                            <div className="half-donut-rating-col half-donut-average">
                                                <span className="ce-title">{scoreData && scoreData.boring || 0}</span>
                                                <span className="ce-subtitle">Boring</span>
                                            </div>
                                            <div className="half-donut-rating-col half-donut-poor">
                                                <span className="ce-title">{scoreData && scoreData.sad_angry || 0}</span>
                                                <span className="ce-subtitle">Angry + Sad</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ce-chart-box-wrap tab-chart-box checkbox-tab-type-box insight-right-box">
                            <div className="tablist_ezi">
                            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                                <div className="tab-header-wrap">
                                    <div className="tab-left-header">
                                        <Nav variant="pills" >
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="in_focus">In Focus</Nav.Link>
                                            </Nav.Item> */}
                                                <Nav.Item>
                                                <Nav.Link eventKey="break_up">Score Break-Up</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="trend">Trend</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="distribution">Distribution</Nav.Link>
                                            </Nav.Item>
                                                <Nav.Item>
                                                <Nav.Link eventKey="sentiments">Sentiments</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                    
                                </div>
                                <Tab.Content>
                                    {/* <Tab.Pane eventKey="in_focus" mountOnEnter unmountOnExit>
                                        <div className="tab-chart-body focus-list-body">
                                            <span className="focus-title">Good Job</span>
                                            <span className="focus-subtitle">Your average Happiness Index improved over last week.</span>
                                            <span></span>
                                                <ul className="in_focus_list">
                                                    <li className="in_focus_item good_item">HI is up by<span>{infocusData && infocusData.up || "00"}</span>points over last week</li>
                                                    <li className="in_focus_item good_item">People are generally happy on <span>{infocusData && infocusData.day} </span>in last week</li>
                                                    <li className="in_focus_item good_item">Good mood hour is <span>{infocusData && infocusData.max_time || "00"} hrs</span></li>
                                                    <li className="in_focus_item bad_item">Bad mood hour is <span>{infocusData && infocusData.min_time || "00"} hrs</span></li>
                                                </ul>
                                        </div>
                                    </Tab.Pane>                              */}
                                    <Tab.Pane eventKey="break_up" mountOnEnter unmountOnExit>
                                        <div className="tab-chart-body rating-scale-body break_up_body">
                                            <div>                                                
                                                <Bar data={barChartData} options={barChartOptions} height={280}/>
                                            </div>
                                        </div>                                
                                    </Tab.Pane>                          
                                    <Tab.Pane eventKey="trend" mountOnEnter unmountOnExit>
                                        <div className="tab-chart-body rating-scale-body distribution-horizontalbar">
                                            <select className="distribution-select"  onChange={(e) => setFilter(e.target.value)} ref={distributionTime}>
                                                <option value="hourly" selected>Hourly</option>
                                                <option value="daily" >Daily</option>
                                                <option value="weekly">Weekly</option>
                                                <option value="monthly">Monthly</option>
                                            </select>
                                            <div>  
                                                <Bar data={distributionMixedData} options={distributionMixedOptions} plugins={plugins} height={300} />                                              
                                                {/* <HorizontalBar data={distributionBarData} options={distributionBarOptions} height={250} /> */}
                                            </div>
                                        </div> 
                                    </Tab.Pane>       
                                    <Tab.Pane eventKey="distribution" mountOnEnter unmountOnExit>
                                        
                                        <div className="distribution-heatmap-wrapper">
                                            <select className="distribution-select">
                                                <option value="monthly" selected>Monthly</option>
                                                <option value="hourly" >Hourly</option>
                                            </select>
                                            <div className="distribution-heatmap-chart">
                                                <ReactApexChart options={heatmapData.options} series={heatmapData.series} type="heatmap" height={300} />
                                            </div>
                                        </div>
                                    </Tab.Pane>                   
                                    <Tab.Pane eventKey="sentiments" mountOnEnter unmountOnExit>
                                         <div className="tab-chart-body rating-scale-body sentiment-rating-bar">

                                            <div className="word-count-select">
                                                <span className="word-count-label">Word Count :</span>
                                                <select className="distribution-select" onChange={(e) => setWordCount(e.target.value)} >
                                                    <option value="10">10</option>
                                                    <option value="20" selected>20</option>
                                                    <option value="30">30</option>
                                                    <option value="50">40</option>
                                                    <option value="50">50</option>
                                                </select>
                                            </div>
                                            <div className="sentiment-trending-wrapper">
                                                <div className="sentiment-inner"> 
                                                    <div className="sentiment-body">
                                                        <Doughnut data={sentimentDoughnutData} options={sentimentDoughnutOptions} height={220} width={220}/>
                                                    </div>                                                 
                                                </div>
                                                <div className="word-cloud-wrapper">
                                                    <ReactWordcloud words={keyPhrase} options={wordOptions} maxWords={wordCount}/>
                                                </div>
                                                {/* <div className="trending-keyword">
                                                    <div className="sentiment-trending-header">Trending Keywords</div> 
                                                    <div className="trending-body">
                                                        <HorizontalBar data={trendingBarData} options={trendingBarOptions} height={220} />
                                                    </div> 
                                                </div> */}
                                            </div>
                                        </div>                               
                                    </Tab.Pane>                          
                                </Tab.Content>
                            </Tab.Container>
                            </div>
                        </div>
                    </div>

                    { (key === "sentiments") && 
                        <React.Fragment>

                             {/* <div className="comment-hortalzontal-line-box">
                                <div className="comment-hortalzontal-line-header">
                                    <span>Trending Keywords</span>
                                    <div className="word-count-select">
                                        <span className="word-count-label">Word Count :</span>
                                        <select className="distribution-select" onChange={(e) => setWordCount(e.target.value)} >
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="75">75</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div> 
                                <div className="comment-hortalzontal-line-box-body">
                                    <HorizontalBar data={commentBarData} options={commentBarOptions} height={250} />
                                    <ReactWordcloud words={wordsData} options={wordOptions} maxWords={wordCount}/>
                                </div>
                            </div>   */}
                            <CommentComponent insightData={insightData} filterData={filterData} dateFilter={dateFilter} />

                        </React.Fragment>
                     }

                </section>

        </React.Fragment>
    )}

export default InsightComponent;