import React, { useContext, useState, useEffect } from "react";
import './Users.scss';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Breadcrumb, Spinner } from 'react-bootstrap';
import useForm from 'react-hook-form';
import AppContext from 'store/AppContext';
import AsyncSelect from 'react-select/async';
import { outlineRemove } from '../../../../utility/helper';
import Axios from "utility/Axios";
import configURL from "config/config";
import { toast } from "react-toastify";

const SaveUser = (props) => {
    const { languageObj = {}, EziLoader, accesFeature = {}, handleUnAuthWarn, appState } = useContext(AppContext)
    const { user_id = null } = props.match.params;
    const { register, handleSubmit, errors, getValues } = useForm()
    const [isSaving, setIsSaving] = useState(false)
    const [userData, setUserData] = useState({})
    const [rolesData, setRolesData] = useState([])
    var searchTimer = null;

    const saveUserData = (data = {}) => {
        setIsSaving(true)
        delete data.password_match;
        data.roles = userData.roles ? userData.roles.reduce((arr, cur) => arr.concat(cur.value), []) : [];
        let formData = new FormData();

        formData.append("first_name", data.first_name)
        formData.append("last_name", data.last_name)
        formData.append("email", data.email)
        formData.append("cell_number", data.cell_number)
        formData.append("active", data.active)
        formData.append("roles", JSON.stringify(data.roles))
        if (!user_id) {
            formData.append("password", data.password)
        }
        if (user_id && user_id !== "") {
            formData.append("person_id", user_id);
        }
        Axios.post(configURL.save_user, formData).then(res => {
            setIsSaving(false)
            if (res.data.success === true) {
                toast.success("User Saved.")
                props.history.replace("/users")
            } else {
                toast.warn(res.data.message)
            }
        })

    }

    useEffect(() => {
        if (user_id) {
            EziLoader.show();
            let formData = new FormData();
            formData.append("person_id", user_id);
            Axios.post(configURL.get_user_detail, formData).then(res => {
                EziLoader.hide();
                if (res.data.success === true) {
                    setUserData(res.data.result);
                }
            })
        }
    }, [])

    const loadOptions = (inputValue, callback) => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            let formData = new FormData()
            formData.append("search", inputValue);
            formData.append("page", 1);
            Axios.post(configURL.get_company_roles, formData).then(res => {
                if (res.data.success === true) {
                    let roles = [];
                    setRolesData(res.data.results.data)
                    res.data.results.data.forEach(item => {
                        roles.push({ value: item.id, label: item.name })
                    })
                    callback(roles);
                }
            })
        }, 800)

    }

    const handleRoleChange = (data, actionData) => {
        const selected = userData.roles ? [...userData.roles] : [];
        let newSelected = [];
        switch (actionData.action) {
            case 'select-option':
                let indx = rolesData.findIndex(el => el.id === actionData.option.value);
                if (indx > -1 && rolesData[indx].profile && rolesData[indx].profile.length > 0) {
                    selected.push(actionData.option);
                    newSelected = selected
                } else {
                    alert("Profiles not associated with option please add some profiles in this role.")
                    return;
                }

                break;
            case 'remove-value':
                newSelected = selected.filter(item => item.value !== actionData.removedValue.value);
                break;
        }
        setUserData({ ...userData, roles: newSelected })
    }

    const handleInputChange = ({ target }) => {
        let { name, value } = target
        setUserData({
            ...userData,
            [name]: value
        })
    }

    const SaveButton = props => {
        let accessData = (user_id && user_id !== "") ? accesFeature.edit_user : accesFeature.add_user;
        if (accessData && accessData === true) {
            return (<button type="submit" className="ezi-btn btn-ripple user-info-action-save" disabled={isSaving}>
                {props.children} {isSaving && <Spinner animation="grow" color="white" size="sm" />}
            </button>)
        } else {
            return (
                <button type="button" className={`ezi-btn btn-ripple user-info-action-save access_lock`} onClick={handleUnAuthWarn}>
                    {props.children}
                </button>)
        }
    }

    return (
        <React.Fragment>
            <section className="Page-UsersEdit" >
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item>{languageObj.translate('Settings.1')}</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => props.history.push("/users")}>{languageObj.translate('Users.1')}</Breadcrumb.Item>
                        <Breadcrumb.Item>{user_id ? 'Update User Details' : 'Add New User'}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <fieldset disabled={(appState.user && appState.user.id === user_id)}>
                    <form onSubmit={handleSubmit(saveUserData)}>
                        <div className="user-edit-form-wrap">
                            <h3 className="user-edit-form-header">User login & Role</h3>

                            <div className="user-edit-form-body">
                                <div className="edit-form-inner-header">
                                    <span className="form-heading">Primary Information</span>
                                    <div className="user-info-action">
                                        <SaveButton>Save</SaveButton>
                                        <button type="button" className="user-info-action-delete" onClick={() => props.history.goBack()}>
                                            Back
                                    </button>
                                    </div>
                                </div>
                                <div className="form-view-data">
                                    <label className="form-view-field-wrap">
                                        <span className="form-view-label">First Name</span>
                                        <div className="form-error-value-wrap">
                                            <input type="text" className="form-view-input-value" name="first_name" ref={register({ required: true })} defaultValue={userData.first_name || ""} onChange={handleInputChange} />
                                            {errors.first_name && errors.first_name.type === 'required' && <span className={`error-message`}>Please enter first name.</span>}
                                        </div>
                                    </label>
                                    <label className="form-view-field-wrap">
                                        <span className="form-view-label">Last Name</span>
                                        <div className="form-error-value-wrap">
                                            <input type="text" className="form-view-input-value" name="last_name" ref={register({ required: true })} defaultValue={userData.last_name || ""} onChange={handleInputChange} />
                                            {errors.last_name && errors.last_name.type === 'required' && <span className={`error-message`}>Please enter last name.</span>}
                                        </div>

                                    </label>
                                    <label className="form-view-field-wrap">
                                        <span className="form-view-label">Primary Email</span>
                                        <div className="form-error-value-wrap">
                                            <input type="text" className="form-view-input-value" name="email" ref={register({ required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })} defaultValue={userData.email || ""} onChange={handleInputChange} />
                                            {errors.email && errors.email.type === 'required' && <span className={`error-message`}>Please enter primary email.</span>}
                                            {errors.email && errors.email.type === 'pattern' && <span className={`error-message`}>Please enter valid email.</span>}
                                        </div>

                                    </label>
                                    <label className="form-view-field-wrap">
                                        <span className="form-view-label">Mobile Number</span>
                                        <div className="form-error-value-wrap">
                                            <input type="text" className="form-view-input-value" name="cell_number" ref={register({ required: true, pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/ })} defaultValue={userData.cell_number || ""} onChange={handleInputChange} />
                                            {errors.cell_number && errors.cell_number.type === 'required' && <span className={`error-message`}>Please enter contact number.</span>}
                                            {errors.cell_number && errors.cell_number.type === 'pattern' && <span className={`error-message`}>Please enter valid contact
                                        number</span>}
                                        </div>

                                    </label>
                                    <label className="form-view-field-wrap">
                                        <span className="form-view-label">Status</span>
                                        <div className="form-error-value-wrap">
                                            <select name="active" ref={register} className="form-view-select-value" value={userData.active} onChange={handleInputChange}>
                                                <option value={false}>Inactive</option>
                                                <option value={true}>Active</option>
                                            </select>
                                        </div>
                                    </label>
                                    <label className="form-view-field-wrap">
                                        <span className="form-view-label">Roles</span>
                                        <div className="form-error-value-wrap">
                                            <AsyncSelect
                                                className="ezi-select-plugin"
                                                styles={outlineRemove}
                                                value={userData.roles || []}
                                                loadOptions={loadOptions}
                                                defaultOptions
                                                onInputChange={val => val.replace(/\W/g, '')}
                                                isMulti
                                                isSearchable
                                                onChange={handleRoleChange}
                                            />
                                        </div>
                                    </label>
                                    {!user_id && <><label className="form-view-field-wrap">
                                        <span className="form-view-label">Password</span>
                                        <div className="form-error-value-wrap">
                                            <input type="password" className="form-view-input-value" name="password" ref={register({ required: true, minLength: 8 })} onChange={handleInputChange} />
                                            {errors.password && errors.password.type === 'required' && <span className={`error-message`}>You must specify a password</span>}
                                            {errors.password && errors.password.type === 'minLength' && <span className={`error-message`}>Password should be atleast 8 character long</span>}
                                        </div>
                                    </label>
                                        <label className="form-view-field-wrap">
                                            <span className="form-view-label">Confirm Password</span>
                                            <div className="form-error-value-wrap">
                                                <input type="text" className="form-view-input-value" name="password_match" ref={register({
                                                    required: true,
                                                    validate: value => (value === getValues()["password"])
                                                })} onChange={handleInputChange} />
                                                {errors.password_match && errors.password_match.type === 'required' && <span className={`error-message`}>You must specify a password</span>}
                                                {errors.password_match && errors.password_match.type === 'validate' && <span className={`error-message`}>The passwords do not match</span>}
                                            </div>
                                        </label></>}
                                </div>
                            </div>
                        </div>
                        {/* <div className="user-edit-form-wrap">
                    <h3 className="user-edit-form-header">Calendar</h3>
                    <div className="user-edit-form-body">
                        <div className="edit-form-inner-header">Calendar and time information</div>
                        <div className="form-view-data">
                            {
                                CALENDER_DATA.map((item, index) =>
                                    <label key={item.id} className="form-view-field-wrap">
                                        <span className="form-view-label">{item.label}</span>
                                        <input type="text" className="form-view-input-value" value={item.value} />
                                        
                                    </label>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className="user-edit-form-wrap">
                    <h3 className="user-edit-form-header">Currency</h3>
                    <div className="user-edit-form-body">
                        <div className="edit-form-inner-header">Currency and number field information</div>
                        <div className="form-view-data">
                            {
                                CURRENCY_DATA.map((item, index) =>
                                    <label key={item.id} className="form-view-field-wrap">
                                        <span className="form-view-label">{item.label}</span>
                                        <input type="text" className="form-view-input-value" value={item.value} />
                                        
                                    </label>
                                )
                            }
                        </div>
                    </div>
                </div> */}
                    </form>
                </fieldset>
            </section>
        </React.Fragment>
    )
}

export default SaveUser;