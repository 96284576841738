
import React , { useState } from 'react';
import { Bar , Doughnut } from 'react-chartjs-2';
import Rating from 'react-rating';

const YesNoMaybeMatrix = () => {

    const barChartData = {
        labels: [ 'Q1', 'Q2', 'Q3' ,'Q4' ],
        datasets: [
            { 
                label: "Yes",
                backgroundColor: '#B5EE5F',
                borderColor: '#B5EE5F',
                data: [40, 25, 15 ,35],
                offsetGridLines : false,
            },
            {
                label: "NO",
                backgroundColor: '#FF4E4E',
                borderColor: '#FF4E4E',
                data: [40, 25, 15,35 ],
                offsetGridLines : false,
            },
            {
                label: "Maybe",
                backgroundColor: '#5A4E63',
                borderColor: '#5A4E63',
                data: [40, 25, 15 ,35],
                offsetGridLines : false,
            }
          
         
        ],
    };

    
    const barChartOptions = {
        responsive: true,   
        maintainAspectRatio: false,
        legend: {
            display: false,
            align:'start',
            position:'top',
            labels: {
                fontColor: '#5A4E63',
                boxWidth: 6,
                fontSize:12,
                fontFamily :"NunitoSansSemiBold",
                padding	:20,
                usePointStyle:true,
            }
        },
        scales: {
            xAxes: [{
                stacked: true, 
                barPercentage: 0.4,
                categoryPercentage: 0.4,
                ticks: { fontColor: "#373737",fontFamily: "NunitoSans", fontSize: 12, stepSize: 6, beginAtZero: true,},
                gridLines: { color: "transparent",zeroLineColor: 'transparent',}, 
            }],

            yAxes: [{
                stacked: true, 
                ticks: { fontColor: "#5B5B5B",fontFamily: "NunitoSans", fontSize: 12, stepSize: 30, beginAtZero: true , padding: 15},
                 gridLines: { color: '#EDEDED',  zeroLineColor: '#EDEDED',borderColor:"transparent",   drawBorder: false, } }]
             }
        
    };

    const doughnutData = {
        labels: [ 'No', 'Maybe', 'Yes' ],
        datasets: [{
            data: [40 ,30,30],
            backgroundColor: [ '#FF4E4E' ,'#5A4E63' , '#B5EE5F'],
        }],
        text: 'Cleanliness'
    } 

    const doughnutOptions = {
        segmentShowStroke: false,
        responsive: true, 
        maintainAspectRatio: false,  
        rotation: 1 * Math.PI,
        circumference: 1 * Math.PI,
        legend: {
            display: false,
        },
        cutoutPercentage: 75,
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        tooltips: {
            displayColors: false,
            backgroundColor: "#5A4E63",
            enabled: true,
            mode: "single",
            bodyFontSize: 13,
            bodyFontFamily: "NunitoSansSemiBold",
            bodyFontColor: "#FFFFFF",
            yPadding: 8,
            xPadding: 15,
            cornerRadius: 5,
          },
    }

    const responseDoughnutData = {
        labels: [  'Yes' ,'No', 'Maybe'],
        datasets: [{
            data: [25,20,25],
            backgroundColor: [  '#B5EE5F','#FF4E4E' ,'#5A4E63'],
        }],
    } 

    const responseDoughnutOptions = {
        segmentShowStroke: false,
        responsive: false, 
        maintainAspectRatio: false,  
        legend: {
            display: false,
        },
        cutoutPercentage: 80,
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        tooltips: {
            displayColors: false,
            backgroundColor: "#5A4E63",
            enabled: true,
            mode: "single",
            bodyFontSize: 13,
            bodyFontFamily: "NunitoSansSemiBold",
            bodyFontColor: "#FFFFFF",
            yPadding: 8,
            xPadding: 15,
            cornerRadius: 5,
          },
    }


    const resSmallDoughnutData = {
        labels: ["1 Star", "2 Star"],
        datasets: [{
            data: [70 ,30],
            backgroundColor: [ '#32A6CB', '#5A4E63'],
        }],
        text: 'Cleanliness'
    } 

    const resSmallDoughnutOptions = {
        segmentShowStroke: false,
        responsive: false, 
        maintainAspectRatio: false,  
        legend: {
            display: false,
        },
        cutoutPercentage: 45,
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        tooltips: {
            displayColors: false,
            backgroundColor: "#5A4E63",
            enabled: true,
            mode: "single",
            bodyFontSize: 13,
            bodyFontFamily: "NunitoSansSemiBold",
            bodyFontColor: "#FFFFFF",
            yPadding: 8,
            xPadding: 15,
            cornerRadius: 5,
          },
    }


    const themeChartData = {
        labels: [ 'Yes', 'No', 'Maybe' ],
        datasets: [
            {
                backgroundColor: [ '#B5EE5F',  '#FF4E4E' ,'#5A4E63'],
                borderColor:  [ '#B5EE5F',  '#FF4E4E' ,'#5A4E63'],
                data: [40, 25, 15],
                offsetGridLines : false,
            }
        ]
    };

    
    const themeChartOptions = {
        responsive: true,   
        maintainAspectRatio: false,
        legend: {
            display: false,
            align:'start',
            position:'top',
            labels: {
                fontColor: '#5A4E63',
                boxWidth: 6,
                fontSize:12,
                fontFamily :"NunitoSansSemiBold",
                padding	:20,
                usePointStyle:true,
            }
        },
        scales: {
            xAxes: [{
                barPercentage: 0.8,
                categoryPercentage: 0.4,
                ticks: { fontColor: "#373737",fontFamily: "NunitoSans", fontSize: 12, stepSize: 6, beginAtZero: true,},
                gridLines: { color: "transparent",zeroLineColor: 'transparent',}, 
            }],

            yAxes: [{
                ticks: { fontColor: "#5B5B5B",fontFamily: "NunitoSans", fontSize: 12, stepSize: 10, beginAtZero: true , padding: 15},
                 gridLines: { color: '#EDEDED',  zeroLineColor: '#EDEDED',borderColor:"transparent",   drawBorder: false, } }]
             }
        
    };

    return(           
        <React.Fragment>
            <section className="Common-QuestionComponent Page-YesNoMaybeMatrix">
                <div className="question-type-wrap">
                    <label className="question"><span className="question-no">Q</span><span>[16]</span>Do you plan to visit our store again in the next 2 weeks?</label>
                    <div className="question-type yes-no-maybe">Yes No Maybe Matrix</div>
                </div>

                <div className="checkbox-multi-card-wrap">

                  
                    <div className="majority-card">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Majority selected </span>
                            <span className="subtitle">Maybe</span>
                        </div>
                    </div>

                    <div className="least-card">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Least selected</span>
                            <span className="subtitle">NO</span>
                        </div>
                        <span className="card--danger-icon no-danger"></span>
                    </div>

                    <div className="total-responses">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Total Responses</span>
                            <span className="subtitle">189</span>
                        </div>
                        <span className="checkbox-multi-card-icon"></span>
                    </div>

                    <div className="total-comments">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Total Comments</span>
                            <span className="subtitle">98</span>
                        </div>
                        <span className="checkbox-multi-card-icon"></span>
                    </div>

                </div>


                <div className="star-rating-matrix-box">


                    <div className="checkbox-multi-chart-wrap star-rating-matrix-left-box">  


                        <div className="checkbox-multi-chart-donut-wrap">
                            <div className="response-statistics-header category_statistics">
                                <p>Cleanliness</p>
                                <button type="button" className="header-download-graph"></button>
                            </div> 
                        <div className="checkbox-multi-chart-donut">
                            <Doughnut data={doughnutData} options={doughnutOptions} height={250} width={250}/>                           
                        </div>
                        <div className="res-top-text">
                            <span className="res-negative-txt">Most Negative</span>
                            <span className="res-positive-txt">Most Positive</span>
                        </div>
                        <div className="response-percent-text-wrap">
                            <div className="negative-percent-wrap yes-percent">
                                <span className="title">66.45%</span>
                                <span className="subtitle">Yes</span>
                            </div>
                            <div className="negative-percent-wrap no-percent">
                                <span className="title">24.55%</span>
                                <span className="subtitle">No</span>
                            </div>
                            <div className="positive-percent-wrap maybe-percent">
                                <span className="title">10.00%</span>
                                <span className="subtitle">Maybe</span>
                            </div>
                        </div>
                        </div>

                        <div className="response-statistics-chart">
                            <div className="response-statistics-header">
                            <div className="postive-negative-text-wrap">
                                    <span className="yes-text">Yes</span>
                                    <span className="no-text">No</span>
                                    <span className="maybe-text">Maybe</span>
                            </div>
                                <div className="select-dowload-wrap">
                                    <button type="button" className="header-download-graph"></button>
                                </div>
                            </div>
                            <div className="response-statistics-body">
                                <Bar data={barChartData} options={barChartOptions} height={250}/>
                            </div>
                        </div> 

                    </div>

                    <div className="star-rating-matrix-right-box">
                        <button type="button" className="header-download-graph"></button>
                        <div className="star-matrix-full-donut-wrap">
                                <div className="star-matrix-full-donut">
                                    <Doughnut data={responseDoughnutData} options={responseDoughnutOptions} height={150} width={150}/>
                                    <span className="title">Cleanliness</span>
                                    <span className="subtitle">329 response</span>
                                </div>
                                <div className="star-matrix-small-donut">                                      
                                    <Doughnut data={resSmallDoughnutData} options={resSmallDoughnutOptions} height={60} width={60}/>
                                    <span className="res-small-title">Q1</span>
                                    <Doughnut data={resSmallDoughnutData} options={resSmallDoughnutOptions} height={60} width={60}/>
                                    <span className="res-small-title">Q2</span>
                                    <Doughnut data={resSmallDoughnutData} options={resSmallDoughnutOptions} height={60} width={60}/>
                                    <span className="res-small-title">Q3</span>
                                </div>
                        </div>

                        <div className="star-matrix-theme-chart">
                            <Bar data={themeChartData} options={themeChartOptions} height={250}/>
                        </div>

                    </div>

                </div>

               

            </section>
        </React.Fragment>   
    )


}

export default YesNoMaybeMatrix;