import React from 'react';
import { Line as LineChart } from 'react-chartjs-2';


const Trend = () =>{

    
    const lineChartData = {
        labels: ["3 Nov", "4 Nov", "5 Nov", "6 Nov", "7 Nov", "8 Nov", "9 Nov"],
        datasets: [
            {
                label: "My First dataset",
                data: [18,58,4,52,8,52,5],
                borderColor: '#FBCD49' ,
                backgroundColor: 'transparent',
                borderWidth: 1,
            },
            {
                label: "My Second dataset",
                data: [5,50,15,40,44,15,35],
                borderColor: '#7467F0' ,
                backgroundColor: 'rgba(22,88,136,.053)',
                borderWidth: 1
            }
        ]
    };

    const lineChartOptions = {
        responsive: true,   
        maintainAspectRatio: false,
        elements: { point: { radius: 0 } },
        legend: { display: false },
        scales: {            
            xAxes: [{
                ticks: { fontColor: "#C3CBD4",fontFamily: "NunitoSans", fontSize: 12, stepSize: 7, beginAtZero: true, padding: 15, },
                gridLines: { color: "transparent",zeroLineColor: 'transparent',}, 
            }],

            yAxes: [{
                ticks: { fontColor: "#C3CBD4",fontFamily: "NunitoSans", fontSize: 12, stepSize: 15, beginAtZero: true , padding: 15},
                 gridLines: { color: '#EDEDED',  zeroLineColor: '#EDEDED',borderColor:"transparent",   drawBorder: false, } }]
             }
    }


    return(

        <div className="report-trend-card">

            <div className="report-trend-card-header">
                <span className="header-tit">Trend</span>
            </div>

            <div className="report-trend-card-body">    
            <LineChart data = {lineChartData} options={lineChartOptions} />
            </div>
            
        </div>
        

    )


}

export default Trend;