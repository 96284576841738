import React, { useState, useEffect } from 'react';
import configURL from 'config/config';
import Axios from 'utility/Axios';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

const RecentActivity = props => {
    const { type = null } = props;
    const [activities, setActivities] = useState([]);

    const clearActivity = () => {
        confirmAlert({
            title: 'Clear Activity',
            message: 'Are you want to remove your recent activity logs ?',
            buttons: [{
                label: 'Confirm',
                onClick: () => {
                    let formData = new FormData()
                    if (type) {
                        formData.append("type", type)
                    }
                    Axios.post(configURL.clear_activities, formData).then(response => {
                        if (response.data.success !== undefined && response.data.success == true) {
                            getRecentActivities();
                            toast.success(response.data.message || "Activity Cleared!")
                        } else {
                            toast.warn(response.data.message || "Something went wrong")
                        }
                    })
                }
            }, { label: 'Cancel' }]
        });
    }

    const getRecentActivities = () => {
        let formData = new FormData()
        if (type) {
            formData.append("type", type)
        }
        Axios.post(configURL.recent_activities, formData).then(response => {
            if (response.data.success !== undefined && response.data.success == true) {
                setActivities(response.data.data)
            }
        })
    }
    useEffect(() => {
        getRecentActivities()
    }, [])
    return (
        <div className="highlight-card">
            <div className="highlight-card-header">
                <label className="highlight-header-heading">Recent Activities</label>
                <button type="button" className="highlight-clear-btn" onClick={clearActivity}>Clear All</button>
            </div>
            <div className="highlight-card-body">
                <ul>
                    {
                        activities.length > 0 && activities.map((item, index) =>
                            <li key={`highlight${index}`}>
                                <span className={`highlight-card-msg-icon hightlight-${item.type}`} ></span>
                                <div className="highlight-des-wrap">
                                    <span className="highlight-card-des">{item.description || 'NA'}</span>
                                    <span className="highlight-card-time">{item.time || 'NA'}</span>
                                </div>
                            </li>
                        )
                    }

                </ul>
                {activities && !activities.length && <span className="no-activity">No Recent Activities Available</span>}
            </div>
        </div>
    )
}

export default RecentActivity;