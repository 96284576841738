import React , {useState} from "react";
import './SurveyReport.scss';
import { Dropdown } from 'react-bootstrap';
import FilterModal from './FilterModal'; 
import Statistics from './Statistics';
import ChannelEffectiveness from './ChannelEffectiveness';
import AverageLateness from './AverageLateness';
import QuestionStatistics from './QuestionStatistics';
import TrendingThemes from './TrendingThemes';
import Alerts from './Alerts';
import Trend from './Trend';

const SurveyReport =()  =>{

    const [ filterModal , setFilterModal ] = useState(false);
    const [ trend, setTrend ] = useState(false);    

    const handleFilter = () =>{
        setFilterModal(true);
    }

    const trendChart = () =>{
        setTrend(!trend)
    }

return (


  <React.Fragment>
      <section className="Page-SurveyReport">
            <header>

                <div className="report-header-text-wrap">
                    <span className="title">Awareness Survey</span>
                    <span className="subtitle">Survey Report Dashboard</span>
                </div>

                <div className="report-header-right">
                    <button type="button" className="ezi-filter-btn" onClick={handleFilter}>Filter</button>

                    <div className="menu-dropdown common-dropdown">
                        <Dropdown>
                            <Dropdown.Toggle>                           
                                <span>Menu 1</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Basic Analysis</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Advance Analysis</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <button type="button" className="btn-ripple download-report">Download Report</button>
                </div>


            </header>


            <main>

                <div className="report-top-card-wrapper">
                    <Statistics showTrend={trendChart} label={trend ? "Back" : "View Trend"} />

                    {      
                    trend ?
                        <React.Fragment>               
                            <Trend />
                        </React.Fragment>
                    :
                        <React.Fragment>
                            <ChannelEffectiveness /> 
                            <AverageLateness />
                        </React.Fragment>
            
                    }
               
                   
                </div>

                <div className="report-bottom-card-wrapper">
                    <QuestionStatistics />
                    <TrendingThemes />
                    <Alerts />
                </div>

            </main>

            <FilterModal show={filterModal} onHide={() => setFilterModal(false)}   />
      </section>
  </React.Fragment>
)

}

export default SurveyReport; 