import React, { useContext } from 'react';
import AppContext from 'store/AppContext';
import { getFirstWord } from '../../../utility/helper';


const GreetUser = () => {
    const { languageObj = {} } = useContext(AppContext)

    const curHour = new Date().getHours()
    let greetText = "";
    let local = JSON.parse(localStorage.getItem('appState'));
    let userName = "", firstName = "";
    if (local !== null) {
        userName = local["user"]["name"];
        firstName = getFirstWord(userName);
    }
    // if (curHour < 12) {
    //     greetText = languageObj.translate('GoodMorning.1')+`${firstName}`;
    // } else if (curHour < 18) {
    //     greetText = languageObj.translate('GoodAfternoon.1')+`${firstName}`;
    // } else if (curHour >= 18 && curHour < 20) {
    //     greetText =languageObj.translate('GoodEvening.1')+ `${firstName}`;
    // } 
    // else {
    //     greetText = {firstName};
    // }
    // console.log("greetText", greetText, curHour);

    return (
        <div className="greet-text-section">
            <h5 className="page-heading">Welcome {firstName}</h5>
        </div>
    )
}

export default GreetUser;