import * as ActionType from "./actionTypes"

export const setTemplateInitials = (payload = {}) => {
    return {
        type: ActionType.SET_TEMPLATE_INITIAL,
        payload: payload
    }
}

export const launchableTemplate = (payload = {}) => {
    return {
        type: ActionType.SET_TEMPLATE_LAUNCHABLE,
        payload: payload
    }
}

export const resetTemplateState = () => {
    return {
        type: ActionType.RESET_TEMPLATE_STATE
    }
}

export const setTemplateLocales = (payload = {}) => {
    return {
        type: ActionType.SET_TEMPLATE_LOCALES,
        payload: payload
    }
}
export const setTemplateLocale = (locale) => {
    return {
        type: ActionType.SET_TEMPLATE_LOCALE,
        payload: locale
    }
}