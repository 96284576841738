import React from 'react';
import { ProgressBar } from 'react-bootstrap';


const AverageLateness = () =>{

    return(

        <div className="average-lateness-card">

            <div className="average-lateness-card-header">
                <span className="header-tit">Average Lateness</span>
            </div>

            <div className="report-card-body">
                <label className="progress-label">Completed on time</label>
                <div className="progress-wrap">
                    <ProgressBar animated now={90} className="average-progess-bar average-pink" />
                    <span className="progres-bar-count">90%</span>
                </div>
                <label className="progress-label">Complete with lateness</label>
                <div className="progress-wrap">
                    <ProgressBar animated now={65} className="average-progess-bar average-blue"/>
                    <span className="progres-bar-count">65%</span>
                </div>
                <label className="progress-label">Doesn't complete</label>
                <div className="progress-wrap">
                    <ProgressBar animated now={30} className="average-progess-bar average-red"/>
                    <span className="progres-bar-count">30%</span>
                </div>
            </div>
            
        </div>
        

    )


}

export default AverageLateness;