import React, { useState, useContext } from 'react';
import { Tab, Nav, Spinner } from 'react-bootstrap';
import ParticipantSetting from './ParticipantSetting/ParticipantSetting';
import SurveySetting from './SurveySetting/SurveySetting';
import { useHistory, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import SurveyPreview from './SurveyPreview/SurveyPreview';
import Axios from 'utility/Axios';
import configURL from 'config/config';
import { confirmAlert } from 'react-confirm-alert';
import AppContext from 'store/AppContext';
import EditableContent from 'components/EditableContent';
import { connect } from "react-redux";
import * as AppActions from "store/actions";

const SurveyCreatorTab = ({ dispatchShowChecklist, dispatchSurveyName, surveyState, previewSurvey, dispatchResetSurveyState, changeLocale }) => {
    let { surveyName, categoryName, status, canLaunch } = surveyState

    let surveyStatus = status
    const { languageObj = {} } = useContext(AppContext)
    const [key, setKey] = useState('survey-builder');
    const [loadingBtn] = useState(false);
    let nameChangeTimeOut = null;
    let history = useHistory();
    let urlParams = useParams();

    const handleBackSurvey = () => {
        confirmAlert({
            title: 'Back To Dashboard',
            message: "Are you sure you want to go back ?",
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        dispatchResetSurveyState()
                        history.push("/survey-dashboard");
                    }
                },
                {
                    label: 'Cancel'
                }
            ]
        });
    }

    const handleTabSwitch = tab => {
        if (!canLaunch) {
            toast.info("Please add some questions before proceeding further!");
        } else {
            setKey(tab);
        }
    }
    const handleSurveyNameChange = (event) => {
        let inputVal = event.target.value;
        clearTimeout(nameChangeTimeOut)
        nameChangeTimeOut = setTimeout(() => {
            let formData = new FormData();
            formData.append("name", inputVal);
            formData.append("survey_id", urlParams.survey_id);
            Axios.post(configURL.survey_name_update, formData).then(response => {
                if (response.data.success !== undefined && response.data.success === true) {
                    toast.success(response.data.message);
                    dispatchSurveyName({ surveyName: inputVal })
                } else {
                    toast.warn(response.data.message);
                }

            })
        }, 500);
    }


    /**
     * Change status
     * 
     * @param {number} id data id
     */
    const handleCloseSurvey = () => {
        confirmAlert({
            title: 'Close Survey',
            message: "Are you sure want to close this survey",
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        let formData = new FormData();
                        formData.append("survey_id", urlParams.survey_id);
                        formData.append("status", 'close');
                        Axios.post(configURL.survey_status_update, formData).then(response => {
                            if (response.data.success !== undefined && response.data.success === true) {
                                toast.success(response.data.message);
                                history.push("/survey-dashboard")
                            } else {
                                toast.warn(response.data.message)
                            }
                        })
                    }
                },
                {
                    label: 'Cancel'
                }
            ]
        });
    }

    const SurveyButton = () => {
        if (surveyStatus && surveyStatus === 'draft') {
            return (
                <button
                    disabled={!canLaunch}
                    title={canLaunch ? 'Launch Survey' : 'Add Some questions into survey'}
                    type="button"
                    className="test btn-ripple survey-preview-btn"
                    onClick={dispatchShowChecklist}>Launch</button>
            )
        } else if (surveyStatus && surveyStatus === 'inactive') {
            return (
                <button
                    disabled
                    title="Survey Is Launched"
                    type="button"
                    className="test btn-ripple survey-preview-btn"
                >Launched</button>
            )
        }
        else if (surveyStatus && surveyStatus === 'active') {
            return (
                <button
                    type="button"
                    className={`test btn-ripple survey-preview-btn`}
                    onClick={handleCloseSurvey}
                    title="Close Survey">Close Survey
                </button>
            )
        } else if (surveyStatus && surveyStatus === 'close') {
            return (
                <button
                    disabled type="button"
                    className={`test btn-ripple survey-preview-btn`}
                    title={`Survey is ${surveyStatus}`}>Survey Closed
                </button>
            )
        }
    }

    return (
        <React.Fragment>
            {/* survey heaxder  */}
            <div className="survey-creator-header">
                <div className="survey-creator-header-left">
                    <label className="survey-theme-name">{categoryName}</label>
                    <div className="survey-template-wrap">
                        <div className="template-name-wrap">
                            <EditableContent
                                html={surveyName}
                                onChange={handleSurveyNameChange}
                                styles={`survey-template-name ${(surveyStatus && surveyStatus && (surveyStatus === 'inactive' || surveyStatus === 'draft')) ? 'editable' : ''}`}
                                editable={(surveyStatus && surveyStatus && (surveyStatus === 'inactive' || surveyStatus === 'draft'))}
                            />
                        </div>
                    </div>
                </div>
                <div className="status-wrap">
                    <button type="button" className="survey_rollback" onClick={handleBackSurvey}> {languageObj.translate('BacktoDashboard.1')} {loadingBtn && <Spinner animation="border" size="sm" />}</button>
                    <span className={`survey-status ${surveyStatus && surveyStatus}`}>{surveyStatus && surveyStatus}</span>
                </div>
            </div>

            <div className="tablist_ezi survey-tab-container">
                <Tab.Container activeKey={key} onSelect={k => handleTabSwitch(k)}>
                    <div className="survey-tab-header-wrap">
                        <div className="tab-left-header">
                            <Nav variant="pills" >
                                <Nav.Item >
                                    <Nav.Link eventKey="survey-builder">{languageObj.translate('SurveyBuilder.1')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="survey-setting">{languageObj.translate('SurveySettings.1')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="participant-setting">{languageObj.translate('ParticipantSettings.1')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="preview-survey" onClick={previewSurvey}>{languageObj.translate('Preview.1')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="survey-preview-btn-wrap">
                                    <Nav.Link>
                                        <SurveyButton />
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </div>

                    <Tab.Content className="survey-tab-content">
                        <Tab.Pane eventKey="survey-builder" mountOnEnter>
                            <div id="surveyCreatorContainer" />
                        </Tab.Pane>
                        <Tab.Pane eventKey="participant-setting" mountOnEnter>
                            <ParticipantSetting surveyStatus={surveyStatus} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="survey-setting">
                            <SurveySetting surveyStatus={surveyStatus} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="preview-survey" >
                            <SurveyPreview changeLocale={changeLocale} />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = state => {
    return {
        surveyState: state.survey
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowChecklist: () => dispatch(AppActions.viewChecklist({ view: true })),
        dispatchSurveyName: (data) => dispatch(AppActions.setSurveyName(data)),
        dispatchResetSurveyState: () => dispatch(AppActions.resetSurveyState())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SurveyCreatorTab);