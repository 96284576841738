import React, { useState, useEffect, useContext, useRef } from 'react';
import './Companies.scss';
import { Breadcrumb, OverlayTrigger, Tooltip } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { toast } from 'react-toastify';
import Axios from "utility/Axios";
import configURL from 'config/config';
import SweetSearch from "components/SweetSearch";
import { confirmAlert } from 'react-confirm-alert';
import AppContext from 'store/AppContext';

const Companies = ({ history }) => {
    const { EziLoader, languageObj = {} } = useContext(AppContext)
    const [accounts, setAccounts] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [tableMeta, setTableMeta] = useState({
        pagination: {},
        columns: [{}],
        uniqueKey: "id"
    });
    const per_page = 10
    let searchTimer = null
    let inputSearch = useRef(null)

    /**
     * Redirect To template Allocation
     * @param {Object} rowData 
     */
    const allocateTemplate = (rowData) => {
        history.push(`/partner/companies-template-dashboard/${rowData.account_id}`, {
            name: rowData.name,
            category_id: "",
            category_name: "All Categories"
        });
    }

    /**
     * Delete Company Data
     * @param {Object} rowData 
     */
    const deleteCompany = (rowData, page) => {
        confirmAlert({
            title: 'Delete Company',
            message: 'Are you sure you want to delete ?',
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        let formData = new FormData();
                        formData.append("account_id", rowData.account_id);
                        Axios.post(configURL.compnay_delete, formData).then(response => {
                            if (response.data.success && response.data.success === true) {
                                toast.success(response.data.message.toString() || "Company Deleted")
                                getCompanies(page)
                            } else {
                                toast.warn(response.data.message.toString() || "Something went wrong")
                            }
                        })
                    }
                },
                {
                    label: 'Cancel'
                }
            ]
        });

    }

    /**
     * Get Dynamic Table
     * @param {*} param
     */
    const RemotePagination = ({ data, page = 1, sizePerPage, onTableChange, totalSize }) => {
        return <BootstrapTable
            remote
            keyField={tableMeta.uniqueKey || "id"}
            noDataIndication="No Data Available"
            data={data}
            columns={tableMeta.columns}
            pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true, })}
            onTableChange={onTableChange}
        />
    }

    /**
     * Handele Table Change on events like pagination and cell edit
     * @param {*} type 
     * @param {*} param1 
     */
    const handleTableChange = (type, { page }) => {
        if (type === 'pagination') {
            EziLoader.show()
            getCompanies(page);
        }
    }

    /**
     * Search Table Handler
     */
    const handleFilterSearch = () => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setSearchLoading(true);
            getCompanies();
        }, 1000);
    }

    const actionHeader = (cell, row) => {
        return (
            <span className="datatable-action-text">Actions</span>
        );
    }

    const actionFormatter = (cell, row, page) => {
        return (
            <div className="template-action-wrap">
                <OverlayTrigger overlay={<Tooltip>Allocate Template</Tooltip>}>
                    <button type="button" className="allocate_template" onClick={() => { allocateTemplate(row); }}> </button>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Edit Company Details</Tooltip>}>
                    <button type="button" className="edit_template" onClick={() => {
                        history.push(`/partner/update-company/${row.account_id}`)
                    }} > </button>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Delete Company</Tooltip>}>
                    <button type="button" className="delete_template" onClick={() => deleteCompany(row, page)}> </button>
                </OverlayTrigger>
            </div>
        );
    }

    /**
     * Get Compnies Data
     */
    const getCompanies = (page = 1) => {
        let formData = new FormData()
        let search = inputSearch.current.value;
        formData.append("page", page);
        formData.append("search", search);
        formData.append("language", languageObj.curLang);
        formData.append("per_page", per_page);

        Axios.post(configURL.company_listing, formData).then(res => {
            EziLoader.hide()
            setSearchLoading(false);
            if (res.data.success !== undefined && res.data.success) {
                setAccounts(res.data.result);
                let columnData = res.data.columns
                columnData.push({ dataField: 'actions', text: '', headerFormatter: actionHeader, formatter: (cell, row) => actionFormatter(cell, row, page) })
                setTableMeta({
                    pagination: res.data.pagination,
                    columns: columnData,
                    uniqueKey: res.data.unique_key || "id"
                })
            } else {
                toast.warn(res.data.message || "Something went wrong")
                setAccounts([]);
            }
        }).catch(err => {
            EziLoader.hide()
            console.log(err.toString())
            setSearchLoading(false);
            toast.error("Something went wrong")
        })
    }

    useEffect(() => {
        EziLoader.show()
        getCompanies()
    }, [])

    return (
        <React.Fragment>
            <section className="Page-Companies" >
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => history.push("/partner-dashboard")}>Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => history.push("/partner/companies")}>Companies</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="column-header">
                        <h1 className="page-heading">Companies</h1>
                        <div className="column-header-btn">
                            <button type="button" className="btn-ripple add-new " onClick={() => history.push('/partner/add-company')}>Add New Companies</button>
                        </div>
                    </div>
                </div>
                <div className="filter-search-wrap">
                    <SweetSearch loading={searchLoading} change={handleFilterSearch} ref={inputSearch} />
                </div>
                <div className="users-table-content">
                    <RemotePagination
                        data={accounts}
                        page={tableMeta.pagination.current_page}
                        sizePerPage={per_page}
                        totalSize={tableMeta.pagination.total}
                        onTableChange={handleTableChange}
                    />
                </div>
            </section>
        </React.Fragment>
    )
}

export default Companies;