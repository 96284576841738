import React, { useState, useContext } from "react";
import Axios from "utility/Axios";
import configURL from 'config/config';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import 'utility/i18next.js';
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import AppContext from 'store/AppContext';

const AddCategory = (props) => {
    const { languageObj = {} } = useContext(AppContext)

    const [translate] = useTranslation();
    const [sendData, setsendData] = useState({
        'category_name': '',
        'description': ''
    });
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, errors } = useForm();

    const handleFormSubmit = data => {
        let formData = new FormData();
        data.type = "template"
        let sendData = { "data": data }
        formData.append("insertcategory", JSON.stringify(sendData));
        setLoading(true);
        Axios.post(configURL.partner_categorySave, formData).then(res => {
            setLoading(false);
            if (res.data.success !== undefined && res.data.success) {
                props.history.push("/partner-template-dashboard/template-category", {
                    action: "create-new"
                });
            } else {
                toast.warn(res.data.message)
            }
        })
    };

    const inputChangeHandler = ({ target }) => {
        const value = target.value;
        const name = target.name;
        setsendData({ ...sendData, [name]: value });
    }

    return (
        <React.Fragment>
            <section className="Page-AddCategory">
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="survey-category-wrap">
                        <h1 className="category_label">{languageObj.translate('CreateNewCategory.1')}</h1>
                        <div className="category-field-control">
                            <input type="text" name="category_name" ref={register({ required: true })} placeholder={languageObj.translate('Typecategoryname.1')} className="create-category-input" onChange={(e) => inputChangeHandler(e)} />
                            {errors.category_name && <span className="error-message">{languageObj.translate('categoryNameErr.1')}</span>}
                        </div>
                        <div className="category-field-control">
                            <textarea rows="4" name="description" ref={register({ required: true })} className="create-category-input" placeholder={languageObj.translate('Entercategorydescription.1')} onChange={(e) => inputChangeHandler(e)}></textarea>
                            {errors.description && <span className="error-message">{languageObj.translate('categoryDescErr.1')}</span>}
                        </div>
                        <div className="category_add_btn_wrap">
                            <button type="submit" className="btn-ripple category_add_btn" disabled={loading}>
                                {languageObj.translate('CreateNewCategory.1')}
                                {loading && <Spinner animation="grow" variant="success" />}
                            </button>
                        </div>
                    </div>
                </form>
            </section>
        </React.Fragment>
    )
}

export default AddCategory;