import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Doughnut, Line as LineChart } from 'react-chartjs-2';
import AppContext from 'store/AppContext';
import configURL from 'config/config';
import Axios from 'utility/Axios';
import RecentActivity from './RecentActivity';

const DashboardHead = props => {
    const { headerData } = props;
    const history = useHistory();
    const { languageObj = {}, handleUnAuthWarn = {}, accesFeature = {} } = useContext(AppContext)

    const doughnutData = {
        labels: ['Email', 'Website', 'SMS'],
        datasets: [{
            data: [280, 100, 50],
            backgroundColor: ['#7467F0', '#FFA550', '#EA6061'],
        }]
    };

    const doughnutOptions = {
        segmentShowStroke: false,
        responsive: true,
        maintainAspectRatio: true,
        legend: { display: false, },
        cutoutPercentage: 70,
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        tooltips: {
            displayColors: false,
            backgroundColor: "#5A4E63",
            enabled: true,
            mode: "single",
            bodyFontSize: 13,
            bodyFontFamily: "NunitoSansSemiBold",
            bodyFontColor: "#FFFFFF",
            yPadding: 8,
            xPadding: 15,
            cornerRadius: 5,
        },
    }

    const lineChartData = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July"],

        datasets: [
            {
                label: 'My First dataset',
                data: [9, 20, 4, 25, 8, 20, 5],
                fill: false,
                borderColor: '#FBCD49',
                backgroundColor: '#FBCD49',
            },
            {
                label: 'My Second dataset',
                data: [5, 15, 10, 20, 25, 8, 15],
                fill: false,
                borderColor: '#7467F0',
                backgroundColor: '#7467F0',
            },

        ]
    };

    const lineChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        elements: { point: { radius: 0 } },
        legend: {
            display: true,
            align: 'start',
            position: 'top',
            labels: {
                fontColor: '#5A4E63',
                boxWidth: 6,
                fontSize: 12,
                fontFamily: "NunitoSansSemiBold",
                padding: 20,
                usePointStyle: true,
            }
        },
        scaleLineColor: 'transparent',
        tooltips: {
            displayColors: false,
            backgroundColor: "black",
            enabled: true,
            mode: "single",
            bodyFontSize: 15,
            bodyFontFamily: "Gamja Flower",
            bodyFontColor: "white",
            yPadding: 5,
            xPadding: 15,
            cornerRadius: 4,
            bodyFontStyle: "bold",
            callbacks: {
                title: () => {
                    return "";
                },
                label: (tooltipItems, data) => {
                    return tooltipItems.yLabel;
                }
            }
        },
        scales: {

            xAxes: [{
                ticks: { fontColor: "#C3CBD4", fontFamily: "NunitoSans", fontSize: 12, stepSize: 5, beginAtZero: true, padding: 15, },
                gridLines: { color: "transparent", zeroLineColor: 'transparent', },
            }],

            yAxes: [{
                ticks: { fontColor: "#C3CBD4", fontFamily: "NunitoSans", fontSize: 12, stepSize: 10, beginAtZero: true, padding: 15 },
                gridLines: { color: '#EDEDED', zeroLineColor: '#EDEDED', borderColor: "transparent", drawBorder: false, }
            }]
        }

    };

    return (
        <div className="graph-card-main-wrap">
            <section className="graph-card-left-section">
                <div className="survey-info-card-wrap">
                    <div className="survey-info-card--common active-surveys" onClick={() => {
                                history.push('survey-dashboard/category-survey-dashboard', {
                                    category_id: "",
                                    category_name: "All Categories",
                                    survey_status : "active",
                                });
                            }}>
                        <div className="surveys-icon-wrap--common">
                            <span className="surveys-icon--common active-surveys_ic"></span>
                        </div>
                        <div className="survey-info-text-wrap">
                            <span className="title_c">{languageObj.translate('ActiveSurveys.1')}</span>
                            <span className="subtitle_c">{headerData && headerData.counts.active || 0}</span>
                        </div>
                    </div>

                    <div className={`survey-info-card--common create-surveys ${accesFeature.create_survey || "access_lock"}`} onClick={() => {
                        if (accesFeature.create_survey) {
                            history.push("/survey-dashboard/categories", { action: "add-new" })
                        } else {
                            handleUnAuthWarn();
                        }
                    }
                    }>
                        <div className="surveys-icon-wrap--common">
                            <span className="surveys-icon--common create-surveys_ic"></span>
                        </div>
                        <div className="survey-info-text-wrap" >
                            <span className="title_c">{languageObj.translate('Create.1')}</span>
                            <span className="subtitle_c">{languageObj.translate('NewSurvey.1')}</span>
                        </div>
                    </div>
                </div>
                <div className="donut-card-wrap">
                    <div className="donut-card-header">
                        <label className="title">{languageObj.translate('LifetimeResponses.1')}</label>
                        <span className="subtitle">27k</span>
                    </div>
                    <div className="donut-card-body">
                        <div className="donut-card-body-left">
                            <span className="title">{languageObj.translate('Responsebasedon.1')}</span>
                            <span className="subtitle">{languageObj.translate('Channels.1')}</span>
                            <div className="donut-data-wrap">
                                <label><span className="data-label email">{languageObj.translate('Email.1')}</span><span className="data-count up">2%</span></label>
                                <label><span className="data-label sms">{languageObj.translate('SMS.1')}</span><span className="data-count up">8%</span></label>
                                <label><span className="data-label website">{languageObj.translate('Website.1')}</span><span className="data-count down">6%</span></label>
                            </div>
                        </div>
                        <div className="donut-card-body-right">
                            <Doughnut data={doughnutData} options={doughnutOptions} />
                        </div>
                    </div>
                </div>
                <RecentActivity />
            </section>
        </div>
    )
}

export default DashboardHead;