import * as ActionType from "./actionTypes"

export const loginUser = (payload = {}) => {
    return {
        type: ActionType.LOGIN,
        payload: payload
    }
}

export const logOutUser = (payload = {}) => {
    return {
        type: ActionType.LOGOUT,
        payload: payload
    }
}

export const updateUser = (payload = {}) => {
    return {
        type: ActionType.UPDATE_USER,
        payload: payload
    }
}