import axios from "axios";
import { toast } from 'react-toastify';
import { APP_FIELD_NAME, APP_ID } from "constants/constants";

let notificationTimer = null;
const Axios = axios.create();
/**
 * Here Modify request data if needed like Login token, company ids
 */
Axios.interceptors.request.use(request => {
    const appStore = JSON.parse(localStorage.getItem("appState"));
    if (appStore && appStore.user && appStore.user !== null) {
        let { auth_token = "", company_id = "", id = "", partner_id = "" } = appStore.user;
        if (request.data instanceof FormData) {
            request.data.append("company_id", company_id);
            request.data.append("partner_id", partner_id);
            request.data.append("user_id", id);
            request.data.append(APP_FIELD_NAME, APP_ID);
        } else {
            request.data = new FormData();
            request.data.append("company_id", company_id)
            request.data.append("user_id", id);
            request.data.append("partner_id", partner_id);
            request.data.append(APP_FIELD_NAME, APP_ID);
        }
        request.headers["Authorization"] = `Bearer ${auth_token}`;
    } else {
        clearTimeout(notificationTimer);
        notificationTimer = setTimeout(() => {
            toast.warn("Login Expired redirecting to login page.");
            window.location = "/"
        }, 100);
    }
    return request;
});

/**
 * Intercept Responses and check if response status os OK.
 */
Axios.interceptors.response.use(response => {
    /**
     * Added temperory solution to check expired data.
     */
    return response;
}, error => {
    clearTimeout(notificationTimer);
    if (!error.response) {
        notificationTimer = setTimeout(() => {
            console.log(error)
            toast.warn("Server is not responding please try after some time.")
        }, 300);
    }

    if (error.response && error.response.status) {
        const { data = {}, status = 500 } = error.response
        switch (status) {
            case 401:
                notificationTimer = setTimeout(() => {
                    toast.info(data.message || "Something is not right here.", {
                        onClose: () => {
                            window.location = "/"
                        }
                    });
                }, 300);
                break;
            case 500:
                notificationTimer = setTimeout(() => {
                    toast.warn(data.message || "Something is not right here.");
                }, 300);
                break;
        }
    }
    return Promise.reject(error);
});

export default Axios;
