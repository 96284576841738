import React, { useContext } from 'react';
import '../assets/scss/sidebar.scss';
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import AppContext from 'store/AppContext';

const Sidebar = ({ sidebarPara, appState }) => {

    const { languageObj = {} } = useContext(AppContext);
    const appAccesses = appState.access_matrix || {};
    const rootPages = Object.keys(appAccesses);
    const collection = ["templates", "themes", "peoples", "question_bank"];
    const settings = ["company_profile", "account_management"];

    return (
        <React.Fragment>
            <nav id="sidebar" className={`sidebar ${sidebarPara.sidebar ? "active" : ""}`}>
                <div className="sidebar-navigation">
                    <button type="button" className="humburger-button" onClick={sidebarPara.overlaySidebar}>
                        <img alt="" src={require(`../assets/images/sidebar/humburger.svg`)} className="humburger-logo" />
                    </button>
                    <div className="navigation-text">{languageObj.translate('Navigation.1')}</div>
                </div>
                <ul className="sidebar-list">
                    {rootPages.includes("dashboard") && appAccesses["dashboard"] !== undefined && appAccesses["dashboard"].includes("READ") &&
                        <li className="sidebar-item">
                            <NavLink activeClassName='link-active' to="/dashboard" onClick={sidebarPara.overlaySidebar} className="side-icon dashboard">
                                {languageObj.translate('Dashboard.1')}
                            </NavLink>
                        </li>}

                    {rootPages.includes("admin") && appAccesses["admin"] !== undefined && appAccesses["admin"].includes("READ") &&
                        <li className="sidebar-item">
                            <NavLink activeClassName='link-active' to="/admin-dashboard" onClick={sidebarPara.overlaySidebar} className="side-icon dashboard">
                                {languageObj.translate('Dashboard.1')}
                            </NavLink>
                        </li>}

                    {rootPages.includes("partner") && appAccesses["partner"] !== undefined && appAccesses["partner"].includes("READ") &&
                        <li className="sidebar-item">
                            <NavLink activeClassName='link-active' to="/partner-dashboard" onClick={sidebarPara.overlaySidebar} className="side-icon dashboard">
                                {languageObj.translate('Dashboard.1')}
                            </NavLink>
                        </li>}

                    {rootPages.includes("survey") && appAccesses["survey"] !== undefined && appAccesses["survey"].includes("READ") &&
                        <li className="sidebar-item">
                            <NavLink activeClassName='link-active' to="/survey-dashboard" onClick={sidebarPara.overlaySidebar} className="side-icon surveys">
                                {languageObj.translate('Surveys.1')}
                            </NavLink>
                        </li>}
                    {rootPages.includes("report_dashboard") && appAccesses["report_dashboard"] !== undefined && appAccesses["report_dashboard"].includes("READ") &&
                        <li className={`sidebar-item`}>
                            <NavLink activeClassName='link-active' to="/customer-experience" onClick={sidebarPara.overlaySidebar} className="side-icon reports">
                                {languageObj.translate('Reports.1')}
                            </NavLink>
                        </li>}

                    {/* <div className={`sidebar-submenu-item ${sidebarPara.reportFlag ? "report-active" : ""}`}>
                                <li className="sidebar-item">
                                    <NavLink activeClassName='link-active' to="/customer-experience" onClick={sidebarPara.overlaySidebar} className="side-icon customer-exp">
                                        Customer Experience
                                    </NavLink>
                                </li>
                                <li className="sidebar-item">
                                    <NavLink activeClassName='link-active' to="/employee-experience" onClick={sidebarPara.overlaySidebar} className="side-icon emplyee-exp">
                                        Employee Experience
                                    </NavLink>
                                </li>                             
                            </div> */}

                    {rootPages.includes("partner") && appAccesses["partner"] !== undefined && appAccesses["partner"].includes("READ") && <li className="sidebar-item">
                        <NavLink activeClassName='link-active' to="/partner/companies" onClick={sidebarPara.overlaySidebar} className="side-icon themes">
                            companies
                                     </NavLink>
                    </li>}
                    {rootPages.includes("partner_templates") && appAccesses["partner_templates"] !== undefined && appAccesses["partner_templates"].includes("READ") && <li className="sidebar-item">
                        <NavLink activeClassName='link-active' to="/partner-template-dashboard" onClick={sidebarPara.overlaySidebar} className="side-icon templates">
                            {languageObj.translate('Templates.1')}
                        </NavLink>
                    </li>}
                    {rootPages.includes("partner_themes") && appAccesses["partner_themes"] !== undefined && appAccesses["partner_themes"].includes("READ") && <li className="sidebar-item">
                        <NavLink activeClassName='link-active' to="/partner-themes" onClick={sidebarPara.overlaySidebar} className="side-icon themes">
                            {languageObj.translate('Themes.1')}
                        </NavLink>
                    </li>}
                    {collection.some(item => rootPages.includes(item)) &&
                        <><li className={`sidebar-item submenu ${sidebarPara.collectionFlag ? "active" : ""}`}>
                            <a onClick={sidebarPara.collectionMenu} className="side-icon collection">
                                {languageObj.translate('Collection.1')}
                            </a>
                        </li>
                            <div className={`sidebar-submenu-item ${sidebarPara.collectionFlag ? "collection-active" : ""}`}>
                                {rootPages.includes("peoples") && appAccesses["peoples"] !== undefined && appAccesses["peoples"].includes("READ") && <li className="sidebar-item">
                                    <NavLink activeClassName='link-active' to="/people-directory" onClick={sidebarPara.overlaySidebar} className="side-icon people">
                                        {languageObj.translate('People.1')}
                                    </NavLink>
                                </li>}
                                {rootPages.includes("templates") && appAccesses["templates"] !== undefined && appAccesses["templates"].includes("READ") && <li className="sidebar-item">
                                    <NavLink activeClassName='link-active' to="/template-dashboard" onClick={sidebarPara.overlaySidebar} className="side-icon templates">
                                        {languageObj.translate('Templates.1')}
                                    </NavLink>
                                </li>}

                                {rootPages.includes("themes") && appAccesses["themes"] !== undefined && appAccesses["themes"].includes("READ") && <li className="sidebar-item">
                                    <NavLink activeClassName='link-active' to="/themes" onClick={sidebarPara.overlaySidebar} className="side-icon themes">
                                        {languageObj.translate('Themes.1')}
                                    </NavLink>
                                </li>}

                                {/* {rootPages.includes("question_banks") && appAccesses["question_banks"] !== undefined && appAccesses["question_banks"].includes("READ") && <li className="sidebar-item">
                                    <NavLink activeClassName='link-active' to="/questions" onClick={sidebarPara.overlaySidebar} className="side-icon questions">
                                        {languageObj.translate('Questions.1')}
                                    </NavLink>
                                </li>} */}
                            </div></>}
                    {settings.some(item => rootPages.includes(item)) &&
                        <><li className={`sidebar-item submenu ${sidebarPara.settingFlag ? "active" : ""}`}>
                            <a onClick={sidebarPara.settingMenu} className="side-icon settings">
                                {languageObj.translate('Settings.1')}
                            </a>
                        </li>
                            <div className={`sidebar-submenu-item ${sidebarPara.settingFlag ? "setting-active" : ""}`}>
                                {rootPages.includes("company_profile") && appAccesses["company_profile"] !== undefined && appAccesses["company_profile"].includes("READ") && <li className="sidebar-item">
                                    <NavLink activeClassName='link-active' to="/company-profile" onClick={sidebarPara.overlaySidebar} className="side-icon company-profile">
                                        {languageObj.translate('CompanyProfile.1')}
                                    </NavLink>
                                </li>}
                                {rootPages.includes("account_management") && appAccesses["account_management"] !== undefined && appAccesses["account_management"].includes("READ") && <li className="sidebar-item">
                                    <NavLink activeClassName='link-active' to="/users-roles" onClick={sidebarPara.overlaySidebar} className="side-icon user-roles">
                                        User Management
                                    </NavLink>
                                </li>}
                            </div></>}
                    <li className="sidebar-item">
                        <NavLink activeClassName='link-active' to="/support" onClick={sidebarPara.overlaySidebar} className="side-icon support">
                            {languageObj.translate('Support.1')}
                        </NavLink>
                    </li>
                </ul>
                <div className="copyright-text">
                    © {new Date().getFullYear()} Great Place IT Services ™. <br />All Rights Reserved.
                </div>
            </nav>
        </React.Fragment>

    )
}

const mapStateToProps = state => {
    return {
        appState: state.app.appState,
    }
}

export default connect(mapStateToProps)(Sidebar);

