import * as Survey from "survey-knockout";
import { uniqueGenerator } from "utility/helper";
import './Widget.scss';

var widget = {
	name: "yesno",
	title: "Thumbs Up & Down",
	iconName: "",
	widgetIsLoaded: function () { return true; },
	isFit: function (question) {
		return question.getType() === 'yesno';
	},
	activatedByChanged: function () {
		Survey.JsonObject.metaData.addClass("yesno", [], null, "radiogroup");
	},
	isDefaultRender: false,
	htmlTemplate: "<div class='thumb-wrapper'> <fieldset> <div class='thumb-inner thumb-up-wrap'> <input class='thumb-radio' type='radio' name='thumbradio' value='thumbup' /> <span class='thumb-icon thumb-up'></span> </div> <div class='thumb-inner thumb-down-wrap'> <input class='thumb-radio' type='radio' name='thumbradio' value='thumbdown' /><span class='thumb-icon thumb-down'></span> </div> <fieldset/></div>",
	afterRender: function (question, el) {
		var elements = el.getElementsByTagName("input");
		var text = el.getElementsByTagName("fieldset")[0];
		text.inputType = question.inputType;
		question.value = "";
		var matches = text.querySelectorAll("input.thumb-radio");
		var elName = "thumbupdown_" + uniqueGenerator();
		for (let index = 0; index < matches.length; index++) {
			matches[index].name = elName
		}
		text.onchange = function () {
			for (var i = 0, l = elements.length; i < l; i++) {
				if (elements[i].checked) {
					question.value = elements[i].value;
				}
			}
		}
		var onValueChangedCallback = function () {
			for (var i = 0, l = elements.length; i < l; i++) {
				if (elements[i].checked) {
					question.value = elements[i].value;
				}
			}
		}
		var onReadOnlyChangedCallback = function () {
			if (question.isReadOnly) {
				text.setAttribute('disabled', 'disabled');
			} else {
				text.removeAttribute("disabled");
			}
		};
		question.readOnlyChangedCallback = onReadOnlyChangedCallback;
		question.valueChangedCallback = onValueChangedCallback;
		onValueChangedCallback();
		onReadOnlyChangedCallback();
	},
	willUnmount: function (question, el) {
	}
}
Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");
export default widget;
