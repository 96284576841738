import React, { useState, useEffect, Fragment, useRef, useContext } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import useOutsideClick from '../hooks/useOutsideClick';
import Axios from 'utility/Axios';
// import { demographicFilter } from 'constants/dummy-data';
import AppContext from 'store/AppContext';

const DemographicFilter = (props) => {
    const { languageObj = {} } = useContext(AppContext)
    const [filterActiveTab, setFilterActiveTab] = useState("Location")
    const [filterData, setFilterData] = useState([])
    const [filterHolderData, setFilterHolderData] = useState([])
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [selectAll, setSelectAll] = useState([]);
    let inputSearchTimer = null;
    let filterRef = useRef();
    const apiUrlFilters = props.dataSource.filters
    const apiUrlFilterSearch = props.dataSource.filterOptions

    useOutsideClick(filterRef, () => {
        props.show && props.hide();
    });

    useEffect(() => {
        let responseData, initialFilters = [];
        let formData = new FormData();
        if (props.additional && Object.keys(props.additional).length > 0) {
            Object.keys(props.additional).forEach(item => {
                formData.append(item, props.additional[item])
            })
        }
        formData.append("language", languageObj.curLang);
        formData.append("surveyFilter", props.surveyFilter ? true : false);
        Axios.post(apiUrlFilters, formData).then(response => {
            if (response.status === 200 && response.data.success && response.data.data[languageObj.curLang] && response.data.data[languageObj.curLang].length > 0) {
                responseData = response.data.data[languageObj.curLang];
                setFilterData(responseData)
                if (responseData.length > 1) {
                    setFilterActiveTab(responseData[0]['label'])
                }
                setFilterHolderData(responseData)
                responseData.forEach(element => {
                    initialFilters.push({
                        name: element.name,
                        options: [],
                        active: false
                    })
                });
                setSelectedFilter(initialFilters);
            }
        })
    }, [props.dataSource.filters]);

    const hanleCheckFilterAll = ({ target }, demography) => {
        let isAllChecked = target.checked;
        let allFilters = JSON.parse(JSON.stringify(filterData));
        let selectedFilters = JSON.parse(JSON.stringify(selectedFilter));
        let searchIndex = allFilters.findIndex(item => item.name === demography);
        let checkOpts = [...selectAll];
        if (isAllChecked) {
            let allOpts = allFilters[searchIndex].options;
            selectedFilters[searchIndex].options = allOpts;
            selectedFilters[searchIndex].active = true;
            checkOpts.push(demography);

        } else {
            selectedFilters[searchIndex].options = [];
            selectedFilters[searchIndex].active = false;
            checkOpts.splice(checkOpts.indexOf(demography), 1);
        }
        setSelectAll(checkOpts);
        setSelectedFilter(selectedFilters);
    }

    const handleCheckOption = ({ target }, demograpgy) => {
        let checkedValue = target.value;
        let iSChecked = target.checked;
        let filters = [...selectedFilter];
        let searchIndex = filters.findIndex(item => item.name === demograpgy);
        if (iSChecked && checkedValue != "") {
            filters[searchIndex].active = true;
            filters[searchIndex].options.push(checkedValue);
        }
        if (!iSChecked && checkedValue != "") {
            let valueIndex = filters[searchIndex].options.indexOf(checkedValue);
            filters[searchIndex].options.splice(valueIndex, 1);
            filters[searchIndex].active = filters[searchIndex].options.length > 0;
        }
        setSelectedFilter(filters);
    }
    const handleApplyFilter = () => {
        let filterData = [...selectedFilter];
        let tableFilters = [];
        filterData.forEach(item => {
            if (item.active === true) {
                tableFilters.push({ label: item.name, options: item.options })
            }
        });
        if (filterData.length == 0) {
            return;
        }
        props.applyFilter(tableFilters);
    }

    const handleSearchOptions = ({ target }, demograpgy) => {
        clearTimeout(inputSearchTimer);
        inputSearchTimer = setTimeout(() => {
            let inputValue = target.value;
            let allFiltersData = JSON.parse(JSON.stringify(filterData));
            let allFilterHolderData = JSON.parse(JSON.stringify(filterHolderData));
            let dataIndex = allFiltersData.findIndex(item => item.name === demograpgy);
            if (inputValue.length > 2) {
                setSearchLoading(true);
                if (dataIndex < 0) {
                    return;
                }
                let oldOptions = allFilterHolderData[dataIndex].options;

                let formData = new FormData();
                if (props.additional && Object.keys(props.additional).length > 0) {
                    Object.keys(props.additional).forEach(item => {
                        formData.append(item, props.additional[item])
                    })
                }
                formData.append("language", languageObj.curLang);
                formData.append("search", JSON.stringify({ label: demograpgy, search: inputValue }));
                formData.append("surveyFilter", props.surveyFilter ? true : false);

                Axios.post(apiUrlFilterSearch, formData).then(res => {
                    if (res.data.success !== undefined && res.data.success === true && res.data.data.options !== undefined) {
                        let newOptions = res.data.data.options.filter(x => x !== null);
                        let updatedOpt = [...new Set(oldOptions.concat(newOptions))];
                        allFiltersData[dataIndex].options = newOptions;
                        allFilterHolderData[dataIndex].options = updatedOpt;
                        setFilterHolderData(allFilterHolderData)
                        setFilterData(allFiltersData)
                    }
                    setSearchLoading(false);
                }).catch(err => {
                    setSearchLoading(false);
                })
            }
            if (inputValue.length === 0 && dataIndex >= 0) {
                setFilterData(filterHolderData)
            }
        }, 1000);
    }

    const isValueChecked = (filterDemographic, value) => {
        let index = selectedFilter.findIndex(item => (item.name === filterDemographic && item.active === true));
        if (index >= 0) {
            let status = selectedFilter[index].options.includes(value);
            return status;
        } else {
            return false;
        }
    }

    const handleClearFilter = () => {
        const initialFilters = [];
        filterData.forEach(element => {
            initialFilters.push({
                name: element.name,
                options: [],
                active: false
            })
        });
        setSelectedFilter(initialFilters);
        setSelectAll([]);
        props.clearFilter();
    }
    const getSelectedFilters = (node = "") => {
        let index = selectedFilter.findIndex(item => (item.name === node && item.active === true));
        if (index >= 0) {
            return selectedFilter[index].options.length;
        } else {
            return 0;
        }
    }

    return (
        <Fragment>
            {filterData.length > 0 ?
                <div ref={filterRef} className={`custom-demography-select-dropdown ${props.position} ${props.show ? "active" : ""}`}>
                    <div className="demograpgy-filter-wrap">
                        <div className="demograpgy-filter-header">
                            <p className="demograpgy-filter-heading">Filters</p>
                            <div className="filter-btn-wraps">
                                <button type="button" className="btn-ripple ezi-pink-btn filter-apply-btn" onClick={handleApplyFilter}>Apply</button>
                                <button type="button" className="btn-ripple ezi-pink-btn filter-apply-btn" onClick={handleClearFilter}>Clear</button>
                                <button type="button" className="filter-close-btn" onClick={props.hide}>X</button>
                            </div>
                        </div>
                        <div className="demography-filter-inner">
                            <Tab.Container activeKey={filterActiveTab} onSelect={k => setFilterActiveTab(k)}>
                                <div className="filter-tab-header">
                                    <Nav variant="pills" >
                                        {
                                            filterData.map((item, index) =>
                                                <Nav.Item key={index}>
                                                    <Nav.Link eventKey={item.label}>
                                                        {item.label}
                                                        {selectedFilter.length > 0 && getSelectedFilters(item.name) > 0 && <span className="checkbox-count">{getSelectedFilters(item.name)}</span>}
                                                    </Nav.Link>

                                                </Nav.Item>
                                            )
                                        }
                                    </Nav>
                                </div>
                                <Tab.Content className="demography-filter-tab-content">
                                    {
                                        filterData.map((item, i) =>
                                            <Tab.Pane eventKey={item.label} key={i}>
                                                <div className="demography-filter-search-wrap">
                                                    <div className="check_all_wrapper">
                                                        <input className="check_all_checkbox" type="checkbox" onChange={(e) => hanleCheckFilterAll(e, item.name)} />
                                                        <span className={`check_all_txt ${selectAll.includes(item.name) ? "checkbox-active" : ""}`}>All</span>
                                                    </div>
                                                    {props.searchable && <input type="text" className="demography-filter-search" placeholder="search...." onChange={(e) => handleSearchOptions(e, item.name)} />}
                                                </div>
                                                <ul>
                                                    {searchLoading &&
                                                        <div className="serach-loader-wrapper_c">
                                                            <div className="search-loader-inner_c"></div>
                                                        </div>
                                                    }
                                                    {
                                                        (item.options && item.options.length > 0) ? item.options.map((el, index) =>
                                                            <li key={index}>
                                                                <div className="demography-checkbox-wrap">
                                                                    <label className="ezi-checkbox">
                                                                        <input type="checkbox" value={el} onChange={(e) => handleCheckOption(e, item.name)} checked={isValueChecked(item.name, el)} />
                                                                        <span className="ezi-checkbox-mark"></span>
                                                                    </label>
                                                                    <span>{el}</span>
                                                                </div>
                                                            </li>
                                                        ) : <span className="demographic_not_found">Demographics not found</span>
                                                    }
                                                </ul>
                                            </Tab.Pane>
                                        )
                                    }
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div> : <div ref={filterRef} className={`custom-demography-select-dropdown ${props.position} ${props.show ? "active" : ""}`}> <p>Filters not found</p></div>
            }
        </Fragment>
    )

}

export default React.memo(DemographicFilter);