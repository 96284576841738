import React, { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap';
import Axios from "utility/Axios";
import configURL from 'config/config';
import { toast } from "react-toastify";

const EditThemeModel = (props) => {
    const [editData, setEditData] = useState({});

    const changeModalFormData = (e) => {
        let tempEditData = { ...editData };
        tempEditData[e.target.name] = e.target.value;
        setEditData({ ...tempEditData });
    }
    const sendUpdatedData = () => {
        let updateData = { ...editData };
        updateData.theme_id = updateData.id;
        updateData.theme_name = updateData.name;
        delete updateData['id'];
        updateData.status = (updateData.status === 'active');
        let sendData = {
            'update_data': updateData,
        };
        let formData = new FormData();
        formData.append("type", props.tab);
        formData.append("data", JSON.stringify(sendData));
        Axios.post(configURL.partner_updateThemeRow, formData).then(res => {
            if (res.data.success !== undefined && res.data.success) {
                props.updatethemelisting();
            } else {
                toast.warn(res.data.message || 'Something went wrong.')
            }
        })
    }
    useEffect(() => {
        setEditData(props.editdata)
    }, [props.show])

    return (
        <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered className="theme-modal-wrapper">
            <Modal.Header className="ezi-modal-header">
                <Modal.Title id="contained-modal-title-vcenter" className="theme-modal-title ezi-modal-header-title">
                    <span className="theme-modal-title-text"> Edit {props.name}</span>
                    <span className="ezi-modal-close" onClick={props.onHide}></span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="add-theme-form">
                    <div className="theme-field-wrapper">
                        <div className="theme-field-50">
                            <div className="theme-field">
                                <label>{props.name}</label>
                                <input type="text" name="name" value={editData.name} className="theme-field-control" placeholdeer={`Enter ${props.name} Name`} onChange={(e) => changeModalFormData(e)} />
                            </div>
                            <div className="theme-field">
                                <label>Status</label>
                                <select name="status" className="theme-field-control" value={editData.status} onChange={(e) => changeModalFormData(e)}>
                                    <option value="active">Active</option>
                                    <option value="offline">Offline</option>
                                </select>
                            </div>
                        </div>
                        <div className="theme-field">
                            <label>Description</label>
                            <textarea rows="4" cols="50" name="description" value={editData.description} className="theme-field-control" onChange={(e) => changeModalFormData(e)}></textarea>
                        </div>
                    </div>
                    <div className="theme-modal-footer">
                        <button type="button" className="close-theme-btn" onClick={props.toggleeditmodal}>Close</button>
                        <button type="button" className="btn-ripple ezi-pink-btn add-theme-btn" onClick={() => sendUpdatedData()}>Update</button>
                    </div>
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button variant="light" onClick={props.toggleeditmodal}>Close</Button>
                <Button variant="success" onClick={() => sendUpdatedData()}>Update</Button>
            </Modal.Footer> */}
        </Modal>
    );
}


export default EditThemeModel;