import React, { useState } from "react";

function ColumnTodoForm({ onAddColumn , closeDemography}) {
  const [value, setValue] = useState("");

  const handleSubmit = e => {
    e.preventDefault();
    if (!value) return;
    onAddColumn(value);
    setValue("");
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="add-input-wrap">
        <input
          type="text"
          className="add-input"
          value={value}
          placeholder="Enter New Demography"
          onChange={e => setValue(e.target.value)}
        />
        <button type="submit" className="add-column-btn">Add</button>
        <button type="submit" className="demography-close-btn" onClick={closeDemography}>Cancel</button>
      </div>


    </form>
  );
}

export default ColumnTodoForm;