import * as ActionType from "./actionTypes"

export const setSurveyName = (payload = {}) => {
    return {
        type: ActionType.SET_SURVEY_NAME,
        payload: payload
    }
}
export const setSurveyLogo = (payload = {}) => {
    return {
        type: ActionType.SET_SURVEY_LOGO,
        payload: payload
    }
}

export const setSurveyCategory = (payload = {}) => {
    return {
        type: ActionType.SET_SURVEY_CATEGORY,
        payload: payload
    }
}

export const setSurveyMeta = (payload = {}) => {
    return {
        type: ActionType.SET_SURVEY_META,
        payload: payload
    }
}
export const launchableSurvey = (payload = {}) => {
    return {
        type: ActionType.SET_LAUNCHABLE,
        payload: payload
    }
}
export const viewChecklist = (payload = {}) => {
    return {
        type: ActionType.VIEW_SURVEY_CHECKLIST,
        payload: payload
    }
}
export const viewThemeMap = (payload = {}) => {
    return {
        type: ActionType.VIEW_THEME_MAP,
        payload: payload
    }
}
export const resetSurveyState = () => {
    return {
        type: ActionType.RESET_STATE
    }
}

export const setSurveyLocales = (payload = {}) => {
    return {
        type: ActionType.SET_SELECTED_LOCALES,
        payload: payload
    }
}
export const setSelectedLocale = (locale) => {
    return {
        type: ActionType.SET_SELECTED_LOCALE,
        payload: locale
    }
}