import React, { useState, useEffect, useRef, useContext } from "react";
import Axios from "utility/Axios";
import './PeopleDirectory.scss';
import { Tab, Breadcrumb, Nav, Dropdown } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import configURL from 'config/config';
import { toast } from 'react-toastify';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { confirmAlert } from 'react-confirm-alert';
import SweetSearch from "../../../components/SweetSearch";
import AppContext from 'store/AppContext';
import DemographicFilter from "components/DemographicFilter";
import { getTablePagesSizes } from 'utility/helper';

const PeopleDirectory = (props) => {
    const { languageObj = {}, handleUnAuthWarn = {}, accesFeature = {}, EziLoader } = useContext(AppContext)
    const [rowData, setRowData] = useState([]);
    const [paginationData, setPaginationData] = useState({});
    const [uniqueKey, setUniqueKey] = useState("id");
    const [activeTab, setActiveTab] = useState(
        (props.location.state && props.location.state.page_key) ? props.location.state.page_key : "employee"
    );
    const [columns, setColumns] = useState([{}]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [listingFilter, setListingFilter] = useState([]);
    const [selectedUniqueId, setSelectedUniqueId] = useState([]);
    const inputSearchEmployee = useRef(null);
    const inputSearchCustomer = useRef(null);
    const inputSearchOther = useRef(null);
    var searchTimer = null;
    let pageRef = useRef(25)

    /**
     * Add Action buttons into table
     * @param {*} cell 
     * @param {*} row 
     * @param {*} rowIndex 
     * @param {*} delete_data_access 
     * @param {*} tab 
     */
    const buttonFormatter = (cell, row, rowIndex, delete_data_access, tab) => {
        return (
            <button type="button" className={`table_delete_ic ${delete_data_access || "access_lock"}`} onClick={() => {
                if (delete_data_access) {
                    confirmDelete(row.id, tab)
                } else {
                    handleUnAuthWarn()
                }
            }}>Delete</button>
        );
    }

    /**
     * Delete Confirmation Modal
     * @param {*} deleteid 
     * @param {*} tab 
     */
    const confirmDelete = (deleteid = null, tab) => {
        confirmAlert({
            title: 'Delete People',
            message: 'Are you sure you want to delete ?',
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        if (deleteid !== null) {
                            let formData = new FormData();
                            formData.append("type", (tab === "other_contact") ? "other" : tab);
                            formData.append("id", deleteid);
                            Axios.post(configURL.deletePeople, formData).then(res => {
                                if (res.data.success !== undefined && res.data.success) {
                                    toast.info(res.data.message);
                                    switch (tab) {
                                        case 'employee':
                                            getPeopleListing(1, "employee");
                                            break;
                                        case 'customer':

                                            getPeopleListing(1, "customer");
                                            break;
                                        case 'other_contact':
                                            getPeopleListing(1, "other_contact");
                                            break;
                                    }
                                }
                            })
                        }
                    }
                },
                {
                    label: 'Cancel'
                }
            ]
        });
    }

    /**
     * Create access feature object
     */
    const handleCalculateAccess = (tab = 'employee') => {
        const access = {
            create: true,
            edit: true,
            delete: true
        }
        switch (tab) {
            case 'employee':
                access.delete = accesFeature.delete_employee
                access.edit = accesFeature.edit_employee
                access.create = accesFeature.create_employee

                break;
            case 'customer':
                access.delete = accesFeature.delete_customer
                access.edit = accesFeature.edit_customer
                access.create = accesFeature.create_customer
                break;
            case 'other':
                access.delete = accesFeature.delete_others
                access.edit = accesFeature.edit_others
                access.create = accesFeature.create_others
                break;
        }
        return access;
    }

    /**
     * List People Data
     * @param {*} pageno 
     * @param {*} tab 
     * @param {*} search 
     * @param {*} filters 
     */
    const getPeopleListing = (pageno = 1, tab = "employee", search = '', filters = listingFilter) => {
        const accessData = handleCalculateAccess(tab)
        let formData = new FormData();
        formData.append("page", pageno);
        formData.append("search", search);
        formData.append("type", tab);
        formData.append("language", languageObj.curLang);
        formData.append("filters", JSON.stringify(filters));
        formData.append("per_page", pageRef.current);
        Axios.post(configURL.peopleByType, formData).then(res => {
            EziLoader.hide()
            setSearchLoading(false);
            if (res.data.success !== undefined && res.data.success) {
                setRowData(res.data.data);
                let columns = res.data.column || [];
                const newCols = columns.map(item => {
                    if (item.dataField === "id") {
                        return {
                            ...item,
                            hidden: true,
                        }
                    }
                    else {
                        return item;
                    }
                });
                newCols.push({
                    dataField: 'action',
                    text: 'Action',
                    editable: false,
                    formatter: (cell, row, rowIndex) => buttonFormatter(cell, row, rowIndex, accessData.delete, tab),
                });
                setColumns(newCols);
                setPaginationData(res.data.pagination);
                setUniqueKey(res.data.unique_key || "id");
            } else {
                toast.warn(res.data.message || "Something is wrong here")
                setRowData([]);
                setColumns([{}]);
                setUniqueKey("id");
                setPaginationData({});
            }
        }).catch(err => {
            EziLoader.hide()
        })
    }

    /**
     * Update Row Data Locally
     * @param {*} oldValue 
     * @param {*} newValue 
     * @param {*} row 
     * @param {*} column 
     */
    const updateRowData = (oldValue, newValue, row, column) => {
        if (oldValue !== newValue) {
            row[column.dataField] = newValue;
            let objIndex = rowData.map((o) => o[uniqueKey]).indexOf(row[uniqueKey]);
            rowData[objIndex] = row;
            setRowData(rowData);
        }
    }

    /**
     * Get Dynamic Table Based on Tab
     * @param {*} param
     */
    const RemotePagination = ({ data, page = 1, sizePerPage, onTableChange, totalSize }) => {
        let tab = activeTab === 'other_contact' ? 'other' : activeTab
        const accessData = handleCalculateAccess(tab)
        return <BootstrapTable
            remote
            keyField={uniqueKey || "id"}
            noDataIndication="Table is Empty"
            cellEdit={accessData.edit ? cellEditFactory({
                mode: 'click',
                blurToSave: true,
                beforeSaveCell: (oldValue, newValue, row, column, done) => { updateRowData(oldValue, newValue, row, column); done(false); },
            }) : false}
            data={data}
            columns={columns}
            pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList: getTablePagesSizes(totalSize) })}
            onTableChange={onTableChange}
            selectRow={{ mode: 'checkbox', onSelect: handleSelect, selected: selectedUniqueId, hideSelectColumn: !accessData.edit, hideSelectAll: !accessData.edit, onSelectAll: handleOnSelectAll }}
        />
    }

    /**
     * Handle Table All Checkbox selection 
     * @param {*} isSelected 
     * @param {*} rows 
     */
    const handleOnSelectAll = (isSelected, rows) => {
        if (isSelected) {
            let selectedData = rows.map(item => item[uniqueKey])
            let newValues = [...selectedUniqueId, ...selectedData];
            setSelectedUniqueId([...new Set(newValues)])
        }
        if (!isSelected) {
            let unSelectedData = rows.map(item => item[uniqueKey])
            let oldValues = [...selectedUniqueId];
            let newValues = oldValues.filter(item => !unSelectedData.includes(item));
            setSelectedUniqueId(newValues)
        }
    }

    /**
     * Handle Table Single Checkbox selection
     * @param {*} row 
     * @param {*} isSelect 
     */
    const handleSelect = (row, isSelect) => {
        if (isSelect) {
            setSelectedUniqueId([...selectedUniqueId, row[uniqueKey]])
        }
        if (!isSelect) {
            let oldValues = [...selectedUniqueId];
            let newValues = oldValues.filter(item => item !== row[uniqueKey]);
            setSelectedUniqueId(newValues)
        }
    }

    /**
     * Handele Table Change on events like pagination and cell edit
     * @param {*} type 
     * @param {*} param1 
     */
    const handleTableChange = (type, { page, sizePerPage }) => {
        if (type === 'pagination') {
            pageRef.current = sizePerPage
            EziLoader.show()
            switch (activeTab) {
                case 'employee':
                    getPeopleListing(page, "employee");
                    break;
                case 'customer':
                    getPeopleListing(page, "customer");
                    break;
                case 'other_contact':
                    getPeopleListing(page, "other");
                    break;
            }
        }
    }

    /**
     * Set Tab Initial data
     * @param {*} activeTab 
     */
    const handleTabChange = (activeTab) => {
        let tab = activeTab === 'other_contact' ? 'other' : activeTab
        EziLoader.show()
        pageRef.current = 25
        setActiveTab(activeTab);
        setSelectedUniqueId([])
        setShowFilter(false)
        setListingFilter([])
        getPeopleListing(1, tab, "", []);
    }

    /**
     * Run After Component Render initially
     */
    useEffect(() => {
        handleTabChange(activeTab)
        if (props.location.state && props.location.state.page_key) {
            props.history.replace("/people-directory")
        }
        return () => toast.dismiss()
    }, []);

    /**
     * Search Table Handler
     */
    const searchFilter = () => {
        let search = "";
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setSearchLoading(true);
            switch (activeTab) {
                case 'employee':
                    search = inputSearchEmployee.current.value;
                    getPeopleListing(1, "employee", (search.length > 2) ? search : '');
                    break;
                case 'customer':
                    search = inputSearchCustomer.current.value;
                    getPeopleListing(1, "customer", (search.length > 2) ? search : '');
                    break;
                case 'other_contact':
                    search = inputSearchOther.current.value;
                    getPeopleListing(1, "other", (search.length > 2) ? search : '');
                    break;
            }
        }, 1000);
    }

    /**
     * Apply Filter Handler
     * @param {*} filters 
     */
    const handleApplyFilter = (filters) => {
        setShowFilter(false)
        EziLoader.show()
        setListingFilter(filters)
        let tab = activeTab === 'other_contact' ? 'other' : activeTab
        getPeopleListing(1, tab, "", filters);
    }

    /**
     * Filter Clear Handler
     */
    const handleClearFilter = () => {
        let tab = activeTab === 'other_contact' ? 'other' : activeTab
        setShowFilter(false)
        setListingFilter([])
        EziLoader.show()
        getPeopleListing(1, tab, "", []);
    }

    /**
     * Bulk Upload Handler
     */
    const handleBulkUpdate = () => {
        let tab = activeTab === 'other_contact' ? 'other' : activeTab
        const accessData = handleCalculateAccess(tab)
        if (!accessData.edit) {
            handleUnAuthWarn();
            return;
        }
        if (selectedUniqueId.length <= 0) {
            toast.warn("No Peoples selected.")
            return;
        }
        let selectedRows = rowData.filter(item => selectedUniqueId.includes(item[uniqueKey]))
        let selectedCols = Object.keys(selectedRows[0]);
        let selectedColNames = columns.map(item => item['text'].trim());
        let selectedUuIds = selectedRows.map(item => item['id']);

        selectedCols.splice(selectedCols.indexOf('id'), 1)
        selectedColNames.splice(selectedColNames.indexOf('Action'), 1)
        localStorage.removeItem("bulkUpdateData");
        localStorage.setItem("bulkUpdateData", JSON.stringify({
            selected_people: selectedUuIds,
            selected_columns: selectedCols,
            selected_column_name: selectedColNames
        }))
        props.history.push(`/bulk-update/${tab}`);
    }

    /**
     * Table Data update Handler
     */
    const handleUpdateRows = () => {
        let tab = activeTab === 'other_contact' ? 'other' : activeTab
        const accessData = handleCalculateAccess(tab)
        if (!accessData.edit) {
            handleUnAuthWarn();
            return;
        }
        if (selectedUniqueId.length <= 0) {
            toast.warn("No Peoples selected.")
            return;
        }
        let updatedRows = rowData.filter(item => selectedUniqueId.includes(item[uniqueKey]))
        let formData = new FormData();
        formData.append("language", languageObj.curLang);
        let data = {
            'data': updatedRows
        };
        formData.append("update", JSON.stringify(data));
        Axios.post(`${configURL.onlineUpdate}/${tab}`, formData).then(res => {
            if (res.data.success !== undefined && res.data.success) {
                res.data.sucess_message.map((msg) => {
                    toast.success(msg);
                })
                res.data.error_message.map((msg) => {
                    toast.error(msg);
                })
            } else {
                toast.error(res.data.message);
            }
        })
    }

    return (
        <React.Fragment>
            <div className="content-page Page-PeopleDirectory">
                <div className="breadcrumb_ezi people-directory-breadcrumb">
                    {accesFeature.view_peoples && <Breadcrumb>
                        <Breadcrumb.Item>{languageObj.translate('PeopleDirectory.1')}</Breadcrumb.Item>
                        {(() => {
                            switch (activeTab) {
                                case 'employee':
                                    return <Breadcrumb.Item>{'Employee'}</Breadcrumb.Item>
                                case 'customer':
                                    return <Breadcrumb.Item>{'Customer'}</Breadcrumb.Item>
                                case 'other_contact':
                                    return <Breadcrumb.Item>{'Other Contact'}</Breadcrumb.Item>
                            }
                        })()}
                    </Breadcrumb>}
                    <div className="column-header">
                        <h1 className="page-heading">{languageObj.translate('Directory.1')}</h1>
                    </div>
                </div>
                <div className="tablist_ezi">
                    <Tab.Container defaultActiveKey={activeTab}>
                        <div className="tab-header-wrap">
                            <div className="tab-left-header">
                                <Nav variant="pills" >
                                    {accesFeature.view_employee && <Nav.Item>
                                        <Nav.Link eventKey="employee" onClick={() => handleTabChange('employee')}>{languageObj.translate('Employees.1')}</Nav.Link>
                                    </Nav.Item>}
                                    {accesFeature.view_customer && <Nav.Item>
                                        <Nav.Link eventKey="customer" onClick={() => handleTabChange('customer')}>{languageObj.translate('Customers.1')}</Nav.Link>
                                    </Nav.Item>}
                                    {accesFeature.view_others && <Nav.Item>
                                        <Nav.Link eventKey="other_contact" onClick={() => handleTabChange('other_contact')}>{languageObj.translate('OtherContacts.1')}</Nav.Link>
                                    </Nav.Item>}
                                </Nav>
                            </div>
                            <div className="tab-right-header">
                                <div className="updateDropdown CommonDropdown">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <span>{languageObj.translate('Update.1')}</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <li className="item_route_link" onClick={handleUpdateRows}>
                                                {languageObj.translate('OnlineUpdate.1')}
                                            </li>
                                            <li className="item_route_link" onClick={handleBulkUpdate}>
                                                {languageObj.translate('BulkUpdate.1')}
                                            </li>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="addNewDropdown CommonDropdown">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <span>{languageObj.translate('Addnew.1')}</span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <li className="item_route_link" onClick={() => {
                                                let tab = activeTab === 'other_contact' ? 'other-contacts' : activeTab
                                                props.history.push(`/${tab}-add`)
                                            }}>
                                                {languageObj.translate('AddSingle.1')}
                                            </li>
                                            <li className="item_route_link" onClick={() => {
                                                let tab = activeTab === 'other_contact' ? 'other' : activeTab
                                                props.history.push(`/bulk-upload/${tab}`)
                                            }}>
                                                {languageObj.translate('BulkUpload.1')}
                                            </li>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                                <div className="identifierDropdown CommonDropdown">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <li className="identifier" onClick={() => {
                                                let tab = activeTab === 'other_contact' ? 'other' : activeTab
                                                props.history.push(`/identifier/${tab}`)
                                            }}>
                                                <span>{languageObj.translate('Identifiers.1')}</span>
                                            </li>
                                        </Dropdown.Toggle>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <Tab.Content>
                            {accesFeature.view_employee && <Tab.Pane eventKey="employee" mountOnEnter unmountOnExit>
                                <React.Fragment>
                                    <div className="filter-search-wrap">
                                        <div className="participant-filter-wrap">
                                            <button type="button" className="ezi-filter-btn" onClick={() => setShowFilter(!showFilter)} >Filter</button>
                                            <DemographicFilter
                                                applyFilter={handleApplyFilter}
                                                clearFilter={handleClearFilter}
                                                searchable={true}
                                                show={showFilter}
                                                dataSource={{
                                                    filters: `${configURL.surveyDemographicfilter}/employee`,
                                                    filterOptions: `${configURL.surveyDemographicfilterSearch}/employee`
                                                }}
                                                hide={() => setShowFilter(false)}
                                                position="right"
                                            />
                                        </div>
                                        <SweetSearch loading={searchLoading} change={searchFilter} ref={inputSearchEmployee} />
                                    </div>
                                    <RemotePagination data={rowData} page={paginationData.current_page} sizePerPage={pageRef.current} totalSize={paginationData.total} onTableChange={handleTableChange} />
                                </React.Fragment>
                            </Tab.Pane>}
                            {accesFeature.view_customer && <Tab.Pane eventKey="customer" mountOnEnter unmountOnExit>
                                <React.Fragment>
                                    <div className="filter-search-wrap">
                                        <div className="participant-filter-wrap">
                                            <button type="button" className="ezi-filter-btn" onClick={() => setShowFilter(!showFilter)} >Filter</button>
                                            <DemographicFilter
                                                applyFilter={handleApplyFilter}
                                                clearFilter={handleClearFilter}
                                                searchable={true}
                                                show={showFilter}
                                                dataSource={{
                                                    filters: `${configURL.surveyDemographicfilter}/customer`,
                                                    filterOptions: `${configURL.surveyDemographicfilterSearch}/customer`
                                                }}
                                                hide={() => setShowFilter(false)}
                                                position="right"
                                            />
                                        </div>
                                        <SweetSearch loading={searchLoading} change={searchFilter} ref={inputSearchCustomer} />
                                    </div>
                                    <RemotePagination data={rowData} page={paginationData.current_page} sizePerPage={pageRef.current} totalSize={paginationData.total} onTableChange={handleTableChange} />

                                </React.Fragment>
                            </Tab.Pane>}
                            {accesFeature.view_others && <Tab.Pane eventKey="other_contact" mountOnEnter unmountOnExit>
                                <React.Fragment>
                                    <div className="filter-search-wrap">
                                        <div className="participant-filter-wrap">
                                            <button type="button" className="ezi-filter-btn" onClick={() => setShowFilter(!showFilter)} >Filter</button>
                                            <DemographicFilter
                                                applyFilter={handleApplyFilter}
                                                clearFilter={handleClearFilter}
                                                searchable={true}
                                                show={showFilter}
                                                dataSource={{
                                                    filters: `${configURL.surveyDemographicfilter}/other`,
                                                    filterOptions: `${configURL.surveyDemographicfilterSearch}/other`
                                                }}
                                                hide={() => setShowFilter(false)}
                                                position="right"
                                            />
                                        </div>
                                        <SweetSearch loading={searchLoading} change={searchFilter} ref={inputSearchOther} />
                                    </div>
                                    <RemotePagination data={rowData} page={paginationData.current_page} sizePerPage={pageRef.current} totalSize={paginationData.total} onTableChange={handleTableChange} />
                                </React.Fragment>
                            </Tab.Pane>}
                        </Tab.Content>
                    </Tab.Container>
                </div> {/* Tabs End */}
            </div>
        </React.Fragment>
    )
}

export default PeopleDirectory;