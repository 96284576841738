import React, { useState, useRef, Fragment, useContext, useEffect } from "react";
import { Nav, Tab, } from 'react-bootstrap';
import useOutsideClick from "hooks/useOutsideClick";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import configURL from 'config/config';
import Axios from 'utility/Axios';
import { surveyFilter } from 'constants/dummy-data';
import AppContext from 'store/AppContext';
import { dateConvert } from 'utility/helper';

const SurveyFilter = (props) => {
    const [filterStaticData, setFilterStaticData] = useState({
        response_rate: {
            min: 0,
            max: 0,
        },
        date_range: [Date.now() - 7 * 24 * 60 * 60 * 1000, new Date()],
        date_selected: false
    })
    const { languageObj = {} } = useContext(AppContext)
    const [filterActiveTab, setFilterActiveTab] = useState("response_rate")
    const [filterData, setFilterData] = useState(surveyFilter)
    const [filterHolderData, setFilterHolderData] = useState(surveyFilter)
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [selectAll, setSelectAll] = useState([]);
    let inputSearchTimer = null;
    let filterRef = useRef();

    useOutsideClick(filterRef, () => {
        props.show && props.hide();
    });
    useEffect(() => {
        let responseData, initialFilters = [];
        let formData = new FormData();
        formData.append("language", languageObj.curLang);
        Axios.post(configURL.survey_filters, formData).then(response => {
            if (response.status === 200 && response.data.success) {
                //responseData = response.data.data[languageObj.curLang];
                responseData = response.data.data;
                setFilterData(responseData)
                setFilterHolderData(responseData)
                responseData.forEach(element => {
                    initialFilters.push({
                        name: element.name,
                        options: [],
                        active: false
                    })
                });
                setSelectedFilter(initialFilters);
            } else {
                filterData.forEach(element => {
                    initialFilters.push({
                        name: element.name,
                        options: [],
                        active: false
                    })
                });
                setSelectedFilter(initialFilters);
            }
        })
    }, []);

    const hanleCheckFilterAll = ({ target }, node) => {
        let isAllChecked = target.checked;
        let allFilters = JSON.parse(JSON.stringify(filterData));
        let selectedFilters = JSON.parse(JSON.stringify(selectedFilter));
        let searchIndex = allFilters.findIndex(item => item.name === node);
        let checkOpts = [...selectAll];
        if (isAllChecked) {
            let allOpts = allFilters[searchIndex].options || [];
            let allOptsIds = allOpts.map(el => el.id)
            selectedFilters[searchIndex].options = allOptsIds;
            selectedFilters[searchIndex].active = true;
            checkOpts.push(node);

        } else {
            selectedFilters[searchIndex].options = [];
            selectedFilters[searchIndex].active = false;
            checkOpts.splice(checkOpts.indexOf(node), 1);
        }
        setSelectAll(checkOpts);
        setSelectedFilter(selectedFilters);
    }

    const handleCheckOption = ({ target }, node) => {
        let checkedValue = target.value;
        let iSChecked = target.checked;
        let filters = [...selectedFilter];
        let searchIndex = filters.findIndex(item => item.name === node);
        if (iSChecked && checkedValue != "") {
            filters[searchIndex].active = true;
            filters[searchIndex].options.push(checkedValue);
        }
        if (!iSChecked && checkedValue != "") {
            let valueIndex = filters[searchIndex].options.indexOf(checkedValue);
            filters[searchIndex].options.splice(valueIndex, 1);
            filters[searchIndex].active = filters[searchIndex].options.length > 0;
        }
        setSelectedFilter(filters);
    }
    const handleApplyFilter = () => {
        let filterData = [...selectedFilter];
        let tableFilters = {};
        filterData.forEach(item => {
            if (item.active === true) {
                tableFilters[item.name] = item.options
            }
        });
        if (filterData.length == 0) {
            return;
        }
        if (filterStaticData.response_rate.max > 0) {
            tableFilters["response_rate"] = [filterStaticData.response_rate.min, filterStaticData.response_rate.max]
        }
        if (filterStaticData.date_selected) {
            tableFilters["date_range"] = [dateConvert(filterStaticData.date_range[0]), dateConvert(filterStaticData.date_range[1])]
        }
        props.applyFilter(tableFilters);
    }

    const handleSearchOptions = ({ target }, node) => {
        clearTimeout(inputSearchTimer);
        inputSearchTimer = setTimeout(() => {
            let inputValue = target.value;
            let allFiltersData = JSON.parse(JSON.stringify(filterData));
            let allFilterHolderData = JSON.parse(JSON.stringify(filterHolderData));
            let dataIndex = allFiltersData.findIndex(item => item.name === node);
            if (inputValue.length > 2) {
                setSearchLoading(true);
                if (dataIndex < 0) {
                    return;
                }
                let oldOptions = allFilterHolderData[dataIndex].options;
                let formData = new FormData();
                formData.append("language", languageObj.curLang);
                formData.append("search_option", node);
                formData.append("search_value", inputValue);
                Axios.post(configURL.survey_filters, formData).then(res => {
                    if (res.data.success !== undefined && res.data.success === true && res.data.data.options !== undefined) {
                        let newOptions = res.data.data.options.filter(x => x !== null);
                        let updatedOpt = [...oldOptions, ...newOptions];
                        let updatedOptUnique = [...updatedOpt.reduce((map, val) => {
                            if (!map.has(val.id)) {
                                map.set(val.id, val);
                            }
                            return map;
                        }, new Map()).values()]

                        allFiltersData[dataIndex].options = newOptions;
                        allFilterHolderData[dataIndex].options = updatedOptUnique;
                        setFilterHolderData(allFilterHolderData)
                        setFilterData(allFiltersData)
                    }
                    setSearchLoading(false);
                }).catch(err => {
                    setSearchLoading(false);
                })
            }
            if (inputValue.length === 0 && dataIndex >= 0) {
                setFilterData(filterHolderData)
            }
        }, 1000);
    }

    const isValueChecked = (node, value) => {
        let index = selectedFilter.findIndex(item => (item.name === node && item.active === true));
        if (index >= 0) {
            let status = selectedFilter[index].options.includes(value);
            return status;
        } else {
            return false;
        }
    }

    const handleClearFilter = () => {
        const initialFilters = [];
        filterData.forEach(element => {
            initialFilters.push({
                name: element.name,
                options: [],
                active: false
            })
        });
        setSelectedFilter(initialFilters);
        setSelectAll([]);
        setFilterStaticData({ date_range: [Date.now() - 7 * 24 * 60 * 60 * 1000, new Date()], response_rate: { min: 0, max: 0 } })
        props.clearFilter();
    }
    const getSelectedFilters = (node = "") => {
        let index = selectedFilter.findIndex(item => (item.name === node && item.active === true));
        if (index >= 0) {
            return selectedFilter[index].options.length;
        } else {
            return 0;
        }
    }

    return (
        filterData.length > 0 && <Fragment>
            <div ref={filterRef} className={`custom-demography-select-dropdown ${props.position} ${props.show ? "active" : ""}`}>
                <div className="demograpgy-filter-wrap">
                    <div className="demograpgy-filter-header">
                        <p className="demograpgy-filter-heading">Survey Filter</p>
                        <div className="filter-btn-wraps">
                            <button type="button" className="btn-ripple ezi-pink-btn filter-apply-btn" onClick={handleApplyFilter}>Apply</button>
                            <button type="button" className="btn-ripple ezi-pink-btn filter-apply-btn" onClick={handleClearFilter}>Clear</button>
                            <button type="button" className="filter-close-btn" onClick={props.hide}>X</button>
                        </div>
                    </div>
                    <div className="demography-filter-inner">
                        <Tab.Container activeKey={filterActiveTab} onSelect={k => setFilterActiveTab(k)}>
                            <div className="filter-tab-header">
                                <Nav variant="pills" >
                                    <Nav.Item>
                                        <Nav.Link eventKey="response_rate">Response Rate</Nav.Link>
                                    </Nav.Item>
                                    {
                                        filterData.map((item, index) =>
                                            <Nav.Item key={index}>
                                                <Nav.Link eventKey={item.label}>
                                                    {item.label}
                                                    {selectedFilter.length > 0 && getSelectedFilters(item.name) > 0 && <span className="checkbox-count">{getSelectedFilters(item.name)}</span>}
                                                </Nav.Link>

                                            </Nav.Item>
                                        )
                                    }
                                    <Nav.Item>
                                        <Nav.Link eventKey="date_range">Date Range</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <Tab.Content className="demography-filter-tab-content">
                                {
                                    filterData.map((item, i) =>
                                        <Tab.Pane eventKey={item.label} key={i}>
                                            <div className="demography-filter-search-wrap">
                                                <div className="check_all_wrapper">
                                                    <input className="check_all_checkbox" type="checkbox" onChange={(e) => hanleCheckFilterAll(e, item.name)} />
                                                    <span className={`check_all_txt ${selectAll.includes(item.name) ? "checkbox-active" : ""}`}>All</span>
                                                </div>
                                                {/* {props.searchable && <input type="text" className="demography-filter-search" placeholder="search...." onChange={(e) => handleSearchOptions(e, item.name)} />} */}
                                                <input type="text" className="demography-filter-search" placeholder="search...." onChange={(e) => handleSearchOptions(e, item.name)} />
                                            </div>
                                            <ul>
                                                {searchLoading && <div className="filter-loader-wrapper">
                                                    <div className="demography-filter-loader"></div></div>}
                                                {
                                                    (item.options && item.options.length > 0) ? item.options.map((el, index) =>
                                                        <li key={index}>
                                                            <div className="demography-checkbox-wrap">
                                                                <label className="ezi-checkbox">
                                                                    <input type="checkbox" value={el.id} onChange={(e) => handleCheckOption(e, item.name)} checked={isValueChecked(item.name, el.id)} />
                                                                    <span className="ezi-checkbox-mark"></span>
                                                                </label>
                                                                <span>{el.name || 'NA'}</span>
                                                            </div>
                                                        </li>
                                                    ) : <span className="option_not_found">Options Not Found</span>
                                                }
                                            </ul>
                                        </Tab.Pane>
                                    )
                                }
                                <Tab.Pane eventKey="date_range">
                                    <div className="survey-date-range-wrapper">
                                        <span className="select-date">Select date range</span>
                                        <div className="survey-date-range-picker">
                                            <DateRangePicker className="sweet-date-picker" onChange={(data) => setFilterStaticData({ ...filterStaticData, date_range: data, date_selected: true })} value={filterStaticData.date_range} clearIcon={null} calendarIcon={null} format="d/MM/y" />
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="response_rate">
                                    <div className="input-range-wrap">
                                        <span className="response_range">Set Response Rate</span>
                                        <InputRange
                                            maxValue={100}
                                            minValue={0}
                                            //step={5}
                                            formatLabel={value => `${value}%`}
                                            value={filterStaticData.response_rate}
                                            onChange={value => setFilterStaticData({ ...filterStaticData, response_rate: value })}
                                        />
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default SurveyFilter;