import React, { useState, useEffect, useContext } from "react";
import { Modal } from 'react-bootstrap';
import useForm from 'react-hook-form';
import Axios from "utility/Axios";
import configURL from 'config/config';
import { toast } from 'react-toastify';

function AddPartnerModal(props) {
    const { register, handleSubmit, errors } = useForm();

    const submitFormData = data => {
        //data[props.commonidname] = props.commonid;
        console.log(data);
        const sendData = new FormData();
        let addData = {
            'data': data,
        }

        sendData.append("insertdata", JSON.stringify(addData));
        Axios.post(configURL.iam_save_partner, sendData).then(res => {

            if (res.data.success !== undefined && res.data.success) {
                toast.success(res.data.message);
                props.onHide();
                props.updatePartnerlisting();
            } else {
                toast.error(res.data.message);

            }
        })
    };
    return (

        <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered className="ezi-common-modal-wrapper" >

            <Modal.Body>
                <div className="application-admin-modal-header">
                    <span className="ezi-common-modal-body-close" onClick={props.onHide}></span>
                    {/*<div className="partner-admin-logo-wrap">
                    <img alt="" src={require(`src/assets/images/partner-default-logo.svg`)} className="partner-default-logo" />

                    { <div class="partner-logo-upload-position" >
                        <div class="partner-logo-upload-wrapper" >
                            <span class="partner-logo-upload-btn"></span>
                            <input type="file" name="myfile" className="partner-logo-upload-input" />
                        </div>
                    </div> 
                    </div>*/}
                    <div className="admin-modal-header-text">
                        <span className="title">Add New Partner</span>
                        <span className="subtitle">Industry type</span>
                    </div>
                </div>
                <form onSubmit={handleSubmit(submitFormData)} className="application-admin-form">

                    <div className="ezi-material-group">
                        <input type="text" name="name" className="ezi-material-input" ref={register({ required: true })} required /><span class="ezi-material-bar"></span>
                        <label className="ezi-material-label">Partner name</label>
                    </div>
                    <div className="ezi-material-group">
                        <input type="text" name="email" className="ezi-material-input" ref={register({ required: true })} required /><span class="ezi-material-bar"></span>
                        <label className="ezi-material-label">Email</label>
                    </div>
                    <div className="ezi-material-group">
                        <input type="text" name="phone" className="ezi-material-input" ref={register({ required: true })} required /><span class="ezi-material-bar"></span>
                        <label className="ezi-material-label">Phone number with country code</label>
                    </div>
                    <div className="ezi-material-group">
                        <input type="password" name="pwd" className="ezi-material-input" ref={register({ required: true })} required /><span class="ezi-material-bar"></span>
                        <label className="ezi-material-label">Password</label>
                    </div>

                    <div className="application-admin-form-footer">
                        <input type="submit" value="Save" className="btn-ripple ezi-pink-btn application-admin-save-btn" />
                        <button type="button" className="application-admin-close-btn" onClick={props.onHide}>Close</button>
                    </div>

                </form>

            </Modal.Body>
        </Modal>


    );
}


export default AddPartnerModal;