import React from "react";
import { Modal } from 'react-bootstrap';
import '../assets/scss/EziAlert.scss';

const EziAlert = (props) => {
	const { showcancel, showBtnTwo, showClose } = props;
	return (
		<Modal centered show={props.show} onHide={props.onhide} aria-labelledby="contained-modal-title-vcenter" size="md" className="ezi-alert-modal" backdropClassName="ezi-alert-backdrop" >
			<Modal.Body className="ezi-alert-body">
				<div className="ezi-alert-inner">
					{showClose && <button type="button" className="close-alert-icon" onClick={props.onhide}>{(props.closetext) ? props.closetext : "Close"}</button>}
					<span className="ezi-alert-text">{props.alerttext}</span>
					<div className="ezi-alert-footer">
						<button type="button" className="confirm-alert-btn" onClick={props.confirm}>{(props.confirmtext) ? props.confirmtext : "Confirm"}</button>&nbsp;
						{showBtnTwo && <button type="button" className="confirm-alert-btn" onClick={props.onConfirmTwo}>{(props.confirmTwotext) ? props.confirmTwotext : "Confirm Two"}</button>}
						{showcancel && <button type="button" className="close-alert-btn" onClick={props.onhide}>{(props.canceltext) ? props.canceltext : "Cancel"}</button>}
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default EziAlert;