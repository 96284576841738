import React, { useContext, useState } from 'react';
import AppContext from 'store/AppContext';
import { useHistory } from 'react-router-dom';

const RecentCards = ({ recentCards }) => {
    const { languageObj = {}, handleUnAuthWarn = {}, accesFeature = {} } = useContext(AppContext)
    const history = useHistory()
    const handleSurveyAnalyze = (surveyId) => {
        history.push(`/customer-experience/${surveyId}`, {
            survey_id: surveyId,
        });
    }
    return (
        <section className="recent-highlight-main-wrap">
            <div className="recent-surveys-card">
                <div className="recent-surveys-card-header">{languageObj.translate('RecentSurveys.1')}</div>
                <div className="recent-surveys-card-body">
                    <ul>
                        {
                            recentCards && recentCards.recent_surveys.map((item, index) =>
                                <li key={`recent-surveys${index}`} className={item.status && item.status.toLowerCase()}>
                                    <span className="recent-surveys-index">{index + 1}</span>
                                    <span className="recent-surveys-name">{item.name}</span>
                                    <span className={`recent-surveys-status ${item.status && item.status.toLowerCase()}`}>
                                        {item.status && item.status.toLowerCase()}
                                    </span>
                                    <button type="button" className={`recent-surveys-report ${accesFeature.analyze_survey || " access_lock"}`} onClick={() => {
                                        if (accesFeature.analyze_survey) {
                                            handleSurveyAnalyze(item.id)
                                        } else {
                                            handleUnAuthWarn()
                                        }
                                    }}>Analyse</button>
                                </li>
                            )
                        }
                    </ul>
                    {recentCards && !recentCards.recent_surveys.length && <span className="no-survey">No Recent Surveys Available</span>}
                </div>
            </div>
            <section className="highlight-card-main-wrap">
                <div className="common-vertical-card-wrap">
                    <div className="common-vertical-card tips-making" onClick={() => history.push('/feature-developed')}>
                        <div className="tips-making-wrap">
                            <div className="common-vertical-card-icon-wrap">
                                <span className="common-vertical-card-icon tips-making_ic"></span>
                            </div>
                            <span className="tips-making-text">{languageObj.translate('Tipsformakingsurveys.1')}</span>
                        </div>
                    </div>
                    <div className={`common-vertical-card usersroles ${accesFeature.user_manage || " access_lock"}`} onClick={() => {
                        if (accesFeature.user_manage) {
                            history.push("/users-roles")
                        } else {
                            handleUnAuthWarn();
                        }
                    }
                    }>
                        <div className="common-vertical-card-icon-wrap">
                            <span className="common-vertical-card-icon usersroles_ic"></span>
                        </div>
                        <span className="users-roles-text">{languageObj.translate('UsersRoles.1')}</span>
                    </div>
                    <div className="common-vertical-card need-help" onClick={() => history.push('/feature-developed')}>
                        <div className="common-vertical-card-icon-wrap">
                            <span className="common-vertical-card-icon need-help_ic"></span>
                        </div>
                        <span className="need-help-text">{languageObj.translate('NeedHelp?.1')}</span>
                    </div>
                </div>
            </section>
        </section>
    )
}

export default RecentCards;