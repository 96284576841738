import React, { useState, useEffect } from 'react';
import './Dashboard.scss';
import { useTranslation } from 'react-i18next';
import 'utility/i18next.js';
import { toast } from 'react-toastify';
import BootstrapTable from "react-bootstrap-table-next";
import AddPartnerModal from './components/AddPartnerModal';
import EditPartnerModal from './components/EditPartnerModal';
import EziAlert from 'components/Alert';
import Axios from "utility/Axios";
import configURL from 'config/config';
import EziLoader from 'components/EziLoader';

const Dashboard = () => {
  const [translate, setTranslate] = useTranslation();
  const [addPartnerModal, setAddPartnerModal] = useState(false);
  const [editPartnerModal, setEditPartnerModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);


  const deleteCompany = () => {
    //wroite confirm butoon functionality here
  }

  const actionFormatter = (cell, row) => {
    return (
      <div className="datatable-editDelete-wrap">
        <button type="button" className="datatable-edit_c" onClick={() => setEditPartnerModal(true)}>Edit</button>
        <button type="button" className="datatable-delete_c" onClick={() => setConfirmation(true)} >Delete</button>
      </div>
    );
  }

  const actionHeader = (cell, row) => {
    return (
      <span className="datatable-action-text">Action</span>
    );
  }

  const data = [
    { id: 1, name: "samir", role: "Organization ", description: "Lorem ipsum dollar", status: "active" },
    { id: 2, name: "akshay", role: "Ceo ", description: "Lorem ipsum dollar", status: "active" },
    { id: 3, name: "pranay", role: "Vice President ", description: "Lorem ipsum dollar", status: "offline" },
    { id: 4, name: "ritesh", role: "Sales Manager", description: "Lorem ipsum dollar", status: "active" }
  ];



  let formData = new FormData();
  // formData.append("category_id", categoryObj.id);
  // formData.append("page", pageno);



  const columns = [
    // { dataField: "id", text:"ID" }, 
    { dataField: "name", text: "Company Name" },
    { dataField: "email", text: "Email" },
    { dataField: "phone", text: "Phone no" },
    { dataField: "status", text: "Status" },
    // { dataField: "action", text: "Action",headerFormatter: actionHeader , formatter: actionFormatter },
  ];

  const setPartnersListing = () => {
    setLoading(true)
    //setEditModalVisible(false);
    // let formData = new FormData();
    // formData.append("category_id", categoryObj.id);
    // formData.append("page", pageno);
    Axios.get(configURL.iam_partners_list, {}).then(res => {
      setLoading(false)
      //setPartners(res.data.data);
      console.log("ressss", res);
      if (res.data.success !== undefined && res.data.success) {
        //setPagination(res.data.pagination);          
        setPartners(res.data.result);
      } else {
        setPartners([]);
      }
    })
  };

  useEffect(() => {
    let successStatus = sessionStorage.getItem('isShow');
    if (successStatus !== null && successStatus === 'true') {
      toast.success(translate('LoggedIn.1'), {
      });
    }
    sessionStorage.removeItem('isShow');
    setPartnersListing();


  }, [])

  return (
    <React.Fragment>
      <section className="Page-ApplicationAdminDashboard">

        <div className="admin-dashboard-header">
          <div className="user-welcome-wrap">
            <span className="user-welcome-text">Application Admin Dashboard </span>
            {/* <span className="admin-dashboard-beadcrumb">Application Admin Dashboard</span> */}
          </div>
          <button type="button" className="btn-ripple ezi-pink-btn add-new-partner" onClick={() => setAddPartnerModal(true)}>Add new Partner</button>
        </div>

        <div className="application-admin-table-content">
          <BootstrapTable
            keyField="id"
            data={partners}
            columns={columns}
            rowClasses="datatable-action-row"
          />
        </div>

        <AddPartnerModal show={addPartnerModal} onHide={() => setAddPartnerModal(false)} updatePartnerlisting={() => setPartnersListing()} />
        <EditPartnerModal show={editPartnerModal} onHide={() => setEditPartnerModal(false)} />
        <EziAlert show={confirmation} alerttext="Are you sure you want to delete this ID ?" confirmtext="Confirm" showcancel confirm={deleteCompany} onhide={() => setConfirmation(false)} />
        {loading && <EziLoader />}
      </section>
    </React.Fragment>
  )
}
export default Dashboard; 