import React from "react";
import { Modal } from 'react-bootstrap';

function EditPartnerModal(props) {

    return (

        <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered className="ezi-common-modal-wrapper" >

            <Modal.Body>
                <div className="application-admin-modal-header">
                    <span className="ezi-common-modal-body-close" onClick={props.onHide}></span>
                    <div className="partner-admin-logo-wrap">
                        <img alt="" src={require(`../../../assets/images/partner-default-logo.svg`)} className="partner-default-logo" />

                        <div class="partner-logo-upload-position">
                            <div class="partner-logo-upload-wrapper">
                                <span class="partner-logo-upload-btn"></span>
                                <input type="file" name="myfile" className="partner-logo-upload-input" />
                            </div>
                        </div>
                    </div>
                    <div className="admin-modal-header-text">
                        <span className="title">Add New Partner</span>
                        <span className="subtitle">Industry type</span>
                    </div>
                </div>
                <form className="application-admin-form">

                    <div class="ezi-material-group">
                        <input type="text" className="ezi-material-input" required /><span class="ezi-material-bar"></span>
                        <label className="ezi-material-label">Partner name</label>
                    </div>
                    <div class="ezi-material-group">
                        <input type="text" className="ezi-material-input" required /><span class="ezi-material-bar"></span>
                        <label className="ezi-material-label">Username/Email</label>
                    </div>
                    <div class="ezi-material-group">
                        <input type="text" className="ezi-material-input" required /><span class="ezi-material-bar"></span>
                        <label className="ezi-material-label">Phone number with country code</label>
                    </div>

                    <div className="application-admin-form-footer">
                        <input type="submit" value="Save" className="btn-ripple ezi-pink-btn application-admin-save-btn" />
                        <button type="button" className="application-admin-close-btn" onClick={props.onHide}>Close</button>
                    </div>

                </form>

            </Modal.Body>
        </Modal>


    );
}


export default EditPartnerModal;