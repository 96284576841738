import * as actionsTypes from "store/actions/actionTypes";

const appStateData = localStorage.getItem("appState") ? JSON.parse(localStorage.getItem("appState")) : {}

const initalState = {
    appState: appStateData,
}

const appReducer = (state = initalState, action) => {
    switch (action.type) {
        case actionsTypes.LOGIN:
            return {
                ...state,
                appState: localStorage.getItem("appState") ? JSON.parse(localStorage.getItem("appState")) : {}
            }
        case actionsTypes.LOGOUT:
            return {
                ...state,
                appState: {}
            }
        case actionsTypes.UPDATE_USER:
            return {
                ...state,
                appState: localStorage.getItem("appState") ? JSON.parse(localStorage.getItem("appState")) : {}
            }
        default:
            return state
    }
}

export default appReducer
