import React from 'react';

const FeatureDeveloped = (props) => {
    return (
        <div className="page-not-found-component">
            <div className="no-result-svg-wrap">
                <img src = {require("../assets/images/no-result-found.svg")}/>
                <span className="unauthorised-result-text">Feature yet to be Developed</span>
            </div>
        </div>
    )
}
export default FeatureDeveloped;