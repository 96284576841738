import React, { useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import './AddSingle.scss';
import { Tab, Breadcrumb, Nav } from 'react-bootstrap';
import AddSingleFields from './AddSingleFields';
import { toast } from 'react-toastify';
import useForm from 'react-hook-form';
import configURL from 'config/config';
import Axios from "utility/Axios";
import AppContext from 'store/AppContext';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const AddSingleEmployee = () => {

    const { languageObj = {} } = useContext(AppContext)
    const [key, setKey] = useState('personal');
    const [demographicData, setDemographicData] = useState();
    const { register, handleSubmit, errors, setError, clearError } = useForm();
    const [formData, setFormData] = useState({});
    const [phoneNumber, setPhoneNumber] = useState("");
    const history = useHistory();

    const makeEmptyFormObject = (fieldJson) => {
        let tempFormData = {};
        for (var field in fieldJson) {
            if (fieldJson.hasOwnProperty(field)) {
                let tempFormFields = {};
                let formFields = fieldJson[field];
                formFields.forEach((formField, index) => {
                    tempFormFields[formField.name] = '';
                });
                tempFormData[field] = tempFormFields;
            }
        }

        let personalObj = {
            'employee_id': '',
            'fname': '',
            'lname': '',
            'email': '',
            'contact_number': ''
        }

        tempFormData['Personal'] = personalObj;
        setFormData(tempFormData);
    };

    const handleChange = (e, tab) => {
        const { value, type } = e.target;
        const name = e.target.getAttribute('name');
        if (name === 'email') {
            clearError("both")
        }
        if (type === 'radio' || type === 'select-one' || type === 'text') {
            setFormData({
                ...formData,
                [tab]: {
                    ...formData[tab],
                    [name]: value
                }
            });
        }
        if (type === 'checkbox') {
            // no checkbox in the form
        }
    }
    useEffect(() => {
        clearError(["both", "contact_number"])
    }, [phoneNumber])
    const submitFormData = (data) => {
        var sendData = new FormData();
        sendData.append("type", 'employee');
        sendData.append("employee_id", data.employee_id || "");
        sendData.append("fname", data.fname || "");
        sendData.append("lname", data.lname || "");
        sendData.append("email", data.email || "");
        sendData.append("contact_number", phoneNumber);
        delete formData.Personal;
        sendData.append("demographics", JSON.stringify(formData));
        Axios.post(configURL.addSinglePeople, sendData)
            .then(res => {
                if (res.data.success) {
                    toast.success(res.data.message);
                    history.push("/people-directory", {
                        page_key: "employee"
                    })
                } else {
                    toast.warn(res.data.message);
                    if (Object.keys(res.data.errors).length > 0) {
                        Object.keys(res.data.errors).forEach(item => {
                            setError(item, "backend", res.data.errors[item] || res.data.errors[item].message || "Something wrong")
                        })
                    }
                    setKey('personal')
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleDateChange = (date, activeTab) => {
        setFormData({ ...formData, [activeTab]: { ...formData[activeTab], ["dob"]: date } });
    }

    useEffect(() => {
        var formData = new FormData();
        formData.append("type", 'employee');
        Axios.post(configURL.demographicURL, formData).then(res => {
            if (res.data.success !== undefined && res.data.success === true) {
                setDemographicData(res.data.data[languageObj.curLang]);
                makeEmptyFormObject(res.data.data[languageObj.curLang]);
            } else {
                toast.warn(res.data.message)
            }
        }).catch(err => {
            console.log(err)
        })
    }, []);
    console.log(errors);

    return (
        <React.Fragment>

            <section className="Page-AddSingle">
                {/* Breadcrumb Start  */}
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => history.push("/people-directory")}>
                            {languageObj.translate('PeopleDirectory.1')}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => history.push("/people-directory", {
                            page_key: "employee"
                        })}>{languageObj.translate('Employees.1')}</Breadcrumb.Item>
                        <Breadcrumb.Item>{languageObj.translate('NewUser.1')}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="add-single-card">
                    <form onSubmit={handleSubmit(submitFormData)} className="personal-form">
                        <div className="single-card-header">
                            <div className="card-user-info">
                                <h4 className="name">{languageObj.translate('UserName.1')}</h4>
                                <p className="role">{languageObj.translate('Role.1')}</p>
                            </div>
                            <div className="single-card-btn">
                                <button type="reset" className="single-clear">{languageObj.translate('ClearAll.1')}</button>
                                <button type="submit" className="btn-ripple single-save" onClickCapture={() => setKey('personal')}>{languageObj.translate('Save.1')}</button>
                            </div>
                        </div>

                        <div className="tablist_ezi">
                            <Tab.Container activeKey={key} onSelect={k => setKey(k)}>
                                <div className="tab-header-wrap">
                                    <div className="tab-left-header">
                                        <Nav variant="pills" >
                                            <Nav.Item>
                                                <Nav.Link eventKey="personal">{languageObj.translate('Personal.1')}</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="demographic">{languageObj.translate('Identifiers.1')}</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                                <Tab.Content>
                                    {/* Personal Tab Content Start */}
                                    <Tab.Pane eventKey="personal">
                                        <label>
                                            Employee Id
                            <input type="text" placeholder="Enter Id" name="employee_id" ref={register({ required: true })} onChange={(e) => handleChange(e, 'Personal')} />
                                            <span className="error_cu">{errors.employee_id && errors.employee_id.type === "required" && '* Employee Id required.'}</span>
                                            <span className="error_cu">{errors.employee_id && errors.employee_id.type === "backend" && errors.employee_id.message}</span>
                                        </label>
                                        <label>
                                            Employee first name
                            <input type="text" placeholder="Enter Employee First Name" name="fname" ref={register({ minLength: 0, maxLength: 256 })} onChange={(e) => handleChange(e, 'Personal')} />
                                            <span className="error_cu"> {errors.fname && errors.fname.type === 'maxLength' && <span>Max length exceeded</span>}</span>
                                        </label>

                                        <label>
                                            Employee last name
                            <input type="text" placeholder="Enter Employee Last Name" name="lname" ref={register({ minLength: 0, maxLength: 256 })} onChange={(e) => handleChange(e, 'Personal')} />
                                            <span className="error_cu"> {errors.lname && errors.lname.type === 'maxLength' && <span>Max length exceeded</span>}</span>
                                        </label>

                                        <label>
                                            Email
                            <input type="text" placeholder="Enter Employee Email" name="email" ref={register({ pattern: /^[a-zA-Z0-9.a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]+$/i })} onChange={(e) => handleChange(e, 'Personal')} />
                                            <span className="error_cu">{errors.email && errors.email.type === 'pattern' && <span>Please Enter Valid Email id</span>}</span>
                                            {errors.email && errors.email.type === "backend" && <span className="error_cu">{errors.email.message}</span>}
                                            {errors.both && errors.both.type === "backend" && <span className="error_cu">{errors.both.message}</span>}
                                        </label>

                                        <div className="phone-input-wrap">
                                            <span className="phone-input-label">Phone number with country code</span>
                                            <PhoneInput
                                                placeholder="Enter phone number"
                                                country={'in'}
                                                value={phoneNumber}
                                                onChange={setPhoneNumber} />
                                            {errors.contact_number && errors.contact_number.type === "backend" && <span className="error_cu">{errors.contact_number.message}</span>}
                                        </div>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="demographic">
                                        <div className="demographic_data">
                                            <AddSingleFields demographicData={demographicData} activeTab='identifier' selectChangeHandler={handleChange} register={register} dateChange={handleDateChange} />
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </form>
                </div>
                {/* { loading && <EziLoader /> } */}
            </section>

        </React.Fragment>
    )
}

export default AddSingleEmployee;