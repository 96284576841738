import * as Survey from "survey-knockout";
import './Widget.scss';
import { uniqueGenerator } from "utility/helper";

var widget = {
    name: "rating_csat",
    title: "CSAT",
    iconName: "",
    widgetIsLoaded: function () {
        return true;
    },
    isFit: function (question) {
        return question.getType() === 'rating_csat';
    },
    activatedByChanged: function () {
        Survey.JsonObject.metaData.addClass("rating_csat", [], null, "radiogroup");
    },
    isDefaultRender: false,
    htmlTemplate: "<div class='csat-widget-wrapper'> <fieldset> <div class='csat-widget-inner'> <span class='csat-label-left'>Not Satisfied</span> <div class='csat-radio-wrapper'> <input class='csat-radio' type='radio' name='csatradio' value='1' /> <span class='csat-number'>1</span> </div> <div class='csat-radio-wrapper'> <input class='csat-radio' type='radio' name='csatradio' value='2' /> <span class='csat-number'>2</span> </div> <div class='csat-radio-wrapper'> <input class='csat-radio' type='radio' name='csatradio' value='3' /> <span class='csat-number'>3</span> </div> <div class='csat-radio-wrapper'> <input class='csat-radio' type='radio' name='csatradio' value='4' /> <span class='csat-number'>4</span> </div> <div class='csat-radio-wrapper'> <input class='csat-radio' type='radio' name='csatradio' value='5' /> <span class='csat-number'>5</span> </div> <span class='csat-label-right'>Completely Satisfied</span> </div> <fieldset/> </div>",
    afterRender: function (question, el) {
        var elements = el.getElementsByTagName("input");
        var text = el.getElementsByTagName("fieldset")[0];
        text.inputType = question.inputType;
        question.value = "";

        var matches = text.querySelectorAll("input.csat-radio");
        var elName = "csatradio_" + uniqueGenerator();
        for (let index = 0; index < matches.length; index++) {
            matches[index].name = elName
        }

        text.onchange = function () {
            for (var i = 0, l = elements.length; i < l; i++) {
                if (elements[i].checked) {
                    question.value = elements[i].value;
                }
            }
        }
        var onValueChangedCallback = function () {
            for (var i = 0, l = elements.length; i < l; i++) {
                if (elements[i].checked) {
                    question.value = elements[i].value;
                }
            }
        }
        var onReadOnlyChangedCallback = function () {
            if (question.isReadOnly) {
                text.setAttribute('disabled', 'disabled');
            } else {
                text.removeAttribute("disabled");
            }
        };
        question.readOnlyChangedCallback = onReadOnlyChangedCallback;
        question.valueChangedCallback = onValueChangedCallback;
        onValueChangedCallback();
        onReadOnlyChangedCallback();

    },
    willUnmount: function (question, el) {
    }
}
Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");
export default widget;
