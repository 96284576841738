import React, { useState, useEffect, useContext } from 'react';
import '../TemplateDashboard/Template.scss';
import { Breadcrumb } from 'react-bootstrap';
import Axios from 'utility/Axios';
import configURL from 'config/config';
import Skeleton from 'components/Skeleton';
import Pagination from "react-js-pagination";
import NoResult from 'components/NoResult';
import AppContext from 'store/AppContext';

const TemplateCategory = (props) => {
    const { languageObj = {} } = useContext(AppContext)
    const [catCards, setCatCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState(false);
    const [perPage] = useState((props.location.state.action === "edit-one") ? 8 : 7);

    const categoryListing = (page = 1) => {
        setLoading(true);
        let formData = new FormData();
        formData.append("page", page);
        formData.append("per_page", perPage);
        formData.append("type", "template");
        Axios.post(configURL.partner_categoryListing, formData).then(res => {
            if (res.data.success !== undefined && res.data.success) {
                setCatCards(res.data.data);
                setPagination(res.data.pagination)
                setLoading(false);
            }
        })
    }

    const handlePaginate = (page = 1) => {
        categoryListing(page)
    }

    useEffect(() => {
        categoryListing();
    }, []);

    return (
        <React.Fragment>
            <section className="Page-SurveyCategory">
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => props.history.push("/partner-template-dashboard")}>
                            {languageObj.translate('Template.1')}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{languageObj.translate('TemplateCategories.1')}</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="column-header">
                        <h1 className="page-heading">{languageObj.translate('Categories.1')}</h1>
                    </div>
                </div>
                <div className="survey_card_c-wrap">
                    {
                        (props.location.state.action === "create-new") && <div className="survey_card_c" onClick={() => {
                            props.history.push('/partner-template-dashboard/add-category');
                        }}>
                            <span className="survey_card_add_ic"></span>
                            <label className="survey_card_add-label">{languageObj.translate('CreateNew.1')}</label>
                            <p className="survey_card_c-des">{languageObj.translate('Categories.1')}</p>
                        </div>
                    }
                    {loading
                        ?
                        <Skeleton value={perPage} />
                        :
                        catCards.map((item, index) => (
                            <div className="survey_card_c" key={item.id} onClick={() => {
                                if (props.location.state.action === "edit-one") {
                                    props.history.push('/partner-template-dashboard/category-templates', {
                                        category_id: item.id,
                                        category_name: item.category_name
                                    });
                                }
                                else {
                                    props.history.push(`/partner-template-dashboard/template-add/${item.id}`, {
                                        action: "create-template",
                                        category_name: item.category_name
                                    });
                                }
                            }}>
                                <label className="survey_card_c-label">{item.category_name}</label>
                                <p className="survey_card_c-des">{item.description}</p>
                            </div>
                        ))
                    }
                </div>
                {(catCards.length === 0 && loading === false) && <NoResult />}
                {(pagination && pagination.total > perPage) && <div className="pagination-plugin-wrap">
                    <Pagination
                        activePage={pagination.current_page}
                        itemsCountPerPage={perPage}
                        totalItemsCount={pagination.total}
                        onChange={handlePaginate}
                        hideDisabled={true}
                    />
                </div>}
            </section>
        </React.Fragment>
    )
}

export default TemplateCategory;