import React, { useCallback, useEffect, useState, useContext } from 'react';
import './BulkUpload.scss';
import Dropzone from "hooks/Dropzone";
import configURL from 'config/config';
import Axios from "utility/Axios";
import ColumnMapping from '../ColumnMapping/ColumnMapping';
import { toast } from 'react-toastify';
import EziLoader from 'components/EziLoader';
import AppContext from 'store/AppContext';
import { Breadcrumb } from 'react-bootstrap';


var file;
var exceldata;

const BulkUpload = (props) => {
    const { languageObj = {} } = useContext(AppContext)
    const [header, setHeader] = useState();
    const [redirect, setRedirect] = useState(false);
    const [upload, setUpload] = useState(false);
    const [message, SetMessage] = useState();
    const [fileData, setFileData] = useState();
    const [loading, setLoading] = useState(false);
    const onDrop = useCallback(acceptedFiles => { file = acceptedFiles[0]; }, []);
    const [templateUrl, setTemplateUrl] = useState('');
    const urlPath = props.match.path.split('/')[2];

    const getTemplateDownloadLink = () => {
        let formData = new FormData();
        formData.append("template", 'excel-template');
        formData.append("type", urlPath);
        let templatePromise = Axios.post(configURL.templatedownload, formData);
        Promise.resolve(templatePromise).then(res => {
            if (res.data && res.data.success) {
                setTemplateUrl(res.data.url);
            } else {
                setTemplateUrl('');
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const downloadTemplate = () => {
        if (templateUrl !== '') {
            window.open(templateUrl, '_blank').focus();
        } else {
            toast.error(`Invalid Download Link !`);
        }
    }

    useEffect(() => {
        if (upload) {
            if (typeof (file) === "undefined") {
                toast.warn("Please attach file in drop area before upload.");
            }
            else if (file.size >= 50000) {
                toast.warn("Please do not upload file size greater than 50MB");
            }
            else if (file.name.split('.').pop() !== "xlsx" && "xls") {
                toast.warn("Please upload file in xlsx or xls format");
            }
            else {
                setLoading(true);
                const fileupload = new FormData();
                fileupload.append("file", file);
                fileupload.append("type", urlPath);
                Axios.post(configURL.excelHeaderURL, fileupload).then(response => {
                    setLoading(false);
                    if (response.data.success !== undefined && response.data.success) {
                        setHeader(response.data.header);
                        setRedirect(true);
                        exceldata = (response.data.excel_preview);
                        localStorage.setItem('val', response.data.header);
                        setFileData(response.data);
                    } else {
                        SetMessage(response.data.validation);
                        toast.error(response.data.validation.toString(), {
                        });
                    }
                })
            }

        }
        setUpload(false);
    }, [upload]);

    useEffect(() => {
        getTemplateDownloadLink();
    }, []);

    if (redirect) {
        return (<div><ColumnMapping recentpath={urlPath} data={fileData} fileobject={file} /></div>);
    }
    else {
        return (
            <React.Fragment>
                <section className="Page-BulkUpload">
                    <div className="breadcrumb_ezi people-directory-breadcrumb">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => props.history.push("/people-directory")}>
                                {languageObj.translate('PeopleDirectory.1')}
                            </Breadcrumb.Item>
                            {(() => {
                                switch (urlPath) {
                                    case 'employee':
                                        return <Breadcrumb.Item onClick={() => props.history.push("/people-directory", {
                                            page_key: "employee"
                                        })}>{'Employee'}</Breadcrumb.Item>
                                    case 'customer':
                                        return <Breadcrumb.Item onClick={() => props.history.push("/people-directory", {
                                            page_key: "customer"
                                        })}>{'Customer'}</Breadcrumb.Item>
                                    case 'other':
                                        return <Breadcrumb.Item onClick={() => props.history.push("/people-directory", {
                                            page_key: "other_contact"
                                        })}>{'Other Contact'}</Breadcrumb.Item>
                                }
                            })()}
                            <Breadcrumb.Item>Bulk Upload</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="column-header">
                            <h1 className="page-heading">{languageObj.translate('BulkUpload.1')}</h1>
                            <button className="btn-ripple download-template" onClick={downloadTemplate}>
                                {languageObj.translate('DownloadTemplate.1')}
                            </button>
                        </div>
                    </div>
                    {/* <div className="bulkUpload-header">
                        <div className="bulk-heading">
                            <h1>{languageObj.translate('BulkUpload.1')}</h1>
                            <p>{languageObj.translate('BulkUploaddetailsofusersfromexcelfiles.1')}</p>
                        </div>
                        <button className="btn-ripple download-template" onClick={downloadTemplate}>
                            {languageObj.translate('DownloadTemplate.1')}
                        </button>
                    </div> */}
                    <span>{message}</span>
                    <div className="dropzone-wrap">
                        <Dropzone onDrop={onDrop} accept={"application/vnd.ms-excel/*"} />
                    </div>
                    <div className="dropzone-btn">
                        <input type="button" className="btn-ripple upload-btn" onClick={() => { setUpload(true) }} value="Upload Selected File" />
                    </div>
                    <span className="dropzone-supported-file">{languageObj.translate('Filessupported.1')}, Max File Size is 50MB</span>
                    {loading && <EziLoader />}
                </section>
            </React.Fragment>
        )
    }
}
export default BulkUpload;