import React, { useState, useContext, useEffect } from "react";
import './Roles.scss';
import { Breadcrumb, Spinner } from 'react-bootstrap';
import AppContext from 'store/AppContext';
import configURL from "config/config";
import { toast } from "react-toastify";
import Axios from "utility/Axios";
import AsyncSelect from 'react-select/async';
import { outlineRemove } from 'utility/helper';
import useForm from 'react-hook-form';

const SaveRole = (props) => {
    let searchTimer = null
    const { languageObj = {}, EziLoader, accesFeature = {}, handleUnAuthWarn } = useContext(AppContext)
    const [isSaving, setIsSaving] = useState(false)
    const { register, handleSubmit, errors } = useForm()
    const { role_id = null } = props.match.params;
    const [roleData, setRoleData] = useState([])

    const loadOptions = (inputValue, callback) => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            let formData = new FormData()
            formData.append("search", inputValue);
            formData.append("page", 1);
            Axios.post(configURL.get_company_profiles, formData).then(res => {
                if (res.data.success === true) {
                    let profiles = [];
                    res.data.results.data.length > 0 && res.data.results.data.forEach(el => {
                        profiles.push({ value: el.id, label: el.name })
                    })
                    callback(profiles);
                }
            })
        }, 800)
    }

    const handleProfileChange = (data, actionData) => {
        const selected = roleData.profile ? [...roleData.profile] : [];
        let newSelected = [];
        switch (actionData.action) {
            case 'select-option':
                selected.push(actionData.option);
                newSelected = selected
                break;
            case 'remove-value':
                newSelected = selected.filter(item => item.value !== actionData.removedValue.value);
                break;
        }
        setRoleData({ ...roleData, profile: newSelected })
    }

    const handleInputChange = ({ target }) => {
        let { name, value } = target
        setRoleData({
            ...roleData,
            [name]: value
        })
    }
    const saveRoleData = (data) => {
        data.profiles = roleData.profile
        setIsSaving(true)
        data.profile = roleData.profile ? roleData.profile.reduce((arr, cur) => arr.concat(cur.value), []) : [];
        data.status = data.status === "true" ? true : false;

        let formData = new FormData();
        formData.append("name", data.name)
        formData.append("status", data.status)
        formData.append("profiles", JSON.stringify(data.profile))
        if (role_id && role_id !== "") {
            formData.append("role_id", role_id);
        }
        Axios.post(configURL.save_role, formData).then(res => {
            setIsSaving(false)
            if (res.data.success === true) {
                toast.success("Role Saved")
                props.history.replace("/roles")
            } else {
                toast.warn(res.data.message)
            }
        })

    }
    useEffect(() => {
        EziLoader.show()
        let formData = new FormData();
        formData.append("role_id", role_id)
        Axios.post(configURL.get_role_detail, formData).then(res => {
            EziLoader.hide()
            if (res.data.success === true) {
                setRoleData(res.data.result)
            }
        })
        return () => toast.dismiss()
    }, [])
    useEffect(() => {
        if (roleData && roleData.is_default === true) {
            toast.warn("Default Role operations are not allowed.")
        }
    }, [roleData])

    const SaveButton = () => {
        let accessData = (role_id && role_id !== "") ? accesFeature.edit_role : accesFeature.add_role;
        if (accessData && accessData === true) {
            return (
                <button type="submit" className={`ezi-btn btn-ripple role-edit active`} disabled={(roleData && roleData.is_default === true)} title={(roleData && roleData.is_default === true) ? 'Default Role Could not be changed' : ''}>
                    Save {isSaving && <Spinner animation="grow" color="white" size="sm" />}
                </button>
            )
        } else {
            return (
                <button type="button" className={`ezi-btn btn-ripple permission-edit access_lock`} onClick={handleUnAuthWarn}>
                    Save
                </button>)
        }
    }

    return (
        <React.Fragment>
            <section className="Page-RolesEdit" >
                <form onSubmit={handleSubmit(saveRoleData)}>
                    <div className="breadcrumb_ezi">
                        <Breadcrumb>
                            <Breadcrumb.Item>{languageObj.translate('Settings.1')}</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => props.history.push("/roles")}>Roles</Breadcrumb.Item>
                            <Breadcrumb.Item>{role_id ? "Update Role" : "Add new Role"}</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="column-header">
                            <h1 className="page-heading">Role</h1>
                            <div className="column-header-btn">
                                <SaveButton />
                                <button type="button" className="ezi-btn btn-ripple role-cancel" onClick={() => props.history.goBack()}>
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="roles-edit-card">
                        <div className="role-edit-form-field">
                            <label className="role-edit-form-label">Name</label>
                            <div className="role-edit-right-field">
                                <input type="text" className={`role-edit-form-input active`} name="name" ref={register({ required: true })} defaultValue={roleData.name || ""} onChange={handleInputChange} />
                                {errors.name && errors.name.type === 'required' && <span className={`error-message`}>Please enter Role name.</span>}
                            </div>
                        </div>
                        <div className="role-edit-form-field">
                            <label className="role-edit-form-label">Status</label>
                            <div className="role-edit-right-field">
                                <select type="text" name="status" ref={register} className={`role-edit-form-select active`} value={roleData.status} onChange={handleInputChange}>
                                    <option value={true}>Active</option>
                                    <option value={false}>Inactive</option>
                                </select>

                            </div>
                        </div>
                        <div className="role-edit-form-field">
                            <label className="role-edit-form-label">Assigned Privilages</label>
                            <div className="role-edit-right-field">
                                <AsyncSelect
                                    value={roleData.profile || []}
                                    loadOptions={loadOptions}
                                    defaultOptions
                                    onInputChange={val => val.replace(/\W/g, '')}
                                    isMulti
                                    isSearchable
                                    onChange={handleProfileChange}
                                    className="ezi-select-plugin"
                                    styles={outlineRemove}
                                />
                            </div>
                        </div>

                    </div>
                </form>
            </section>
        </React.Fragment >
    )
}

export default SaveRole;