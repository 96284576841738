export const reportFilter = [
    {
        name: "mood",
        label: "Mood",
        active: true,
        options: [
            "Loving",
            "Happy",
            "Angry",
            "Sad",
            "Boring"
        ]
    },
    {
        name: "sentiments",
        label: "Sentiment",
        active: true,
        options: [
            "Positive",
            "Neutral",
            "Negative"
        ]
    }
];