import React, { useContext } from "react";
import './UsersRoles.scss';
import AppContext from 'store/AppContext';

const UsersRoles = (props) => {
    const { languageObj = {}, handleUnAuthWarn = {}, accesFeature = {} } = useContext(AppContext)

    return (
        <React.Fragment>
            <section className="Page-UsersRoles" >
                <div className="users-roles-heading">
                    <div className="role-heading">User Management</div>
                </div>
                <div className="users-roles-content">
                    <div className="user-roles-card">
                        <div className={`role-card ${accesFeature.users || "access_lock"}`} onClick={() => {
                            if (accesFeature.users) {
                                props.history.push("/users")
                            } else {
                                handleUnAuthWarn()
                            }
                        }}>
                            <div className="role-card-icon">
                                <span className={`role_card_ic users_ic`}></span>
                            </div>
                            <label className="role-card-label">{languageObj.translate("Users.1")}</label>
                        </div>
                        <div className={`role-card ${accesFeature.roles || "access_lock"}`} onClick={() => {
                            if (accesFeature.roles) {
                                props.history.push("/roles")
                            } else {
                                handleUnAuthWarn()
                            }
                        }}>
                            <div className="role-card-icon">
                                <span className={`role_card_ic roles_ic`}></span>
                            </div>
                            <label className="role-card-label">{languageObj.translate("Roles.1")}</label>
                        </div>
                        <div className={`role-card ${accesFeature.profiles || "access_lock"}`} onClick={() => {
                            if (accesFeature.profiles) {
                                props.history.push("/profiles")
                            } else {
                                handleUnAuthWarn()
                            }
                        }}>
                            <div className="role-card-icon">
                                <span className={`role_card_ic profiles_ic`}></span>
                            </div>
                            <label className="role-card-label">{languageObj.translate("Profiles.1")}</label>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default UsersRoles;