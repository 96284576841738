import PeopleDirectory from 'modules/company/PeopleDirectory/PeopleDirectory';
import BulkUpload from 'modules/company/BulkUpload/BulkUpload';
import EmployeeAdd from 'modules/company/AddSingle/EmployeeAdd';
import Identifier from 'modules/company/Identifier/Identifier';
import ValidateData from 'modules/company/ValidateData/ValidateData';
import Dashboard from 'modules/company/Dashboard/Dashboard';
import UserProfile from 'modules/shared/Profile/UserProfile';
import Themes from 'modules/company/Themes/Themes';
import Subthemes from 'modules/company/Themes/Subthemes';
import UsersRoles from 'modules/company/UsersRoles/UsersRoles';
import Users from 'modules/company/UsersRoles/Users/Users';
import UsersView from 'modules/company/UsersRoles/Users/UsersView';
import SaveUser from 'modules/company/UsersRoles/Users/SaveUser';
import Profiles from 'modules/company/UsersRoles/Profiles/Profiles';
import Roles from 'modules/company/UsersRoles/Roles/Roles';
import CustomerAdd from 'modules/company/AddSingle/CustomerAdd';
import OtherContactAdd from 'modules/company/AddSingle/OtherContactAdd';
import OnlineUpdate from 'modules/company/OnlineUpdate/OnlineUpdate';
import BulkUpdate from 'modules/company/BulkUpdate/BulkUpdate';
import SurveyDashboard from 'modules/company/Survey/SurveyDashboard';
import SurveyCategory from 'modules/company/Survey/SurveyCategory';
import CategoryAdd from 'modules/company/Survey/CategoryAdd';
import SurveyAdd from 'modules/company/Survey/SurveyAdd';
import SurveyCreator from 'modules/company/Survey/SurveyCreator';
import SurveyPreview from 'modules/company/Survey/SurveyPreview/SurveyPreview';
import TemplateDashboard from 'modules/company/Template/Dashboard/TemplateDashboard';
import TemplateCategory from 'modules/company/Template/Category/TemplateCategory';
import AddCategory from 'modules/company/Template/Category/AddCategory';
import TemplateAdd from 'modules/company/Template/Dashboard/TemplateAdd';
import CategoryTemplateDashboard from 'modules/company/Template/Category/CategoryTemplates';
import CategorySurveyDashboard from 'modules/company/Survey/CategorySurveyDashboard/CategorySurveyDashboard';
import TemplateBuilder from 'modules/company/Template/Builder/TemplateBuilder';
import SurveyReport from 'modules/company/Dashboard/SurveyReport/SurveyReport';
import PageNotFound from 'components/PageNotFound';
import ProfileDetails from 'modules/company/UsersRoles/Profiles/ProfileDetails';
import SaveRole from 'modules/company/UsersRoles/Roles/SaveRole';
import RoleView from 'modules/company/UsersRoles/Roles/RoleView';
import CustomerExperience from 'modules/company/Report/CustomerExperience/CustomerExperience';
import EmployeeExperience from 'modules/company/Report/EmployeeExperience/EmployeeExperience';
import FeatureDeveloped from 'components/FeatureDeveloped';




const CompanyRouter = [
    { path: '/people-directory', component: PeopleDirectory, access_page: "peoples", skip_access: false, min_permission: "READ" },
    { path: '/bulk-upload/employee', component: BulkUpload, access_page: "employee", skip_access: false, min_permission: "CREATE" },
    { path: '/validated-data/employee', component: ValidateData, access_page: "employee", skip_access: false, min_permission: "CREATE" },
    { path: '/bulk-upload/customer', component: BulkUpload, access_page: "customer", skip_access: false, min_permission: "CREATE" },
    { path: '/validated-data/customer', component: ValidateData, access_page: "customer", skip_access: false, min_permission: "CREATE" },
    { path: '/bulk-upload/other', component: BulkUpload, access_page: "others", skip_access: false, min_permission: "CREATE" },
    { path: '/validated-data/other', component: ValidateData, access_page: "others", skip_access: false, min_permission: "CREATE" },
    { path: '/online-update/employee', component: OnlineUpdate, access_page: "employee", skip_access: false, min_permission: "UPDATE" },
    { path: '/online-update/customer', component: OnlineUpdate, access_page: "customer", skip_access: false, min_permission: "UPDATE" },
    { path: '/online-update/other', component: OnlineUpdate, access_page: "others", skip_access: false, min_permission: "UPDATE" },
    { path: '/bulk-update/employee', component: BulkUpdate, access_page: "employee", skip_access: false, min_permission: "UPDATE" },
    { path: '/bulk-update/customer', component: BulkUpdate, access_page: "customer", skip_access: false, min_permission: "UPDATE" },
    { path: '/bulk-update/other', component: BulkUpdate, access_page: "others", skip_access: false, min_permission: "UPDATE" },
    { path: '/identifier/employee', component: Identifier, access_page: "employee", skip_access: true, min_permission: "READ" },
    { path: '/identifier/customer', component: Identifier, access_page: "customer", skip_access: true, min_permission: "READ" },
    { path: '/identifier/other', component: Identifier, access_page: "others", skip_access: true, min_permission: "READ" },
    { path: '/employee-add', component: EmployeeAdd, access_page: "employee", skip_access: false, min_permission: "CREATE" },
    { path: '/dashboard', component: Dashboard, skip_access: true },
    { path: '/themes', component: Themes, access_page: "themes", skip_access: false, min_permission: "READ" },
    { path: '/subthemes/:category_id/:theme_id', component: Subthemes, access_page: "themes", skip_access: false, min_permission: "READ" },
    { path: '/users-roles', component: UsersRoles, access_page: "account_management", skip_access: false, min_permission: "READ" },
    { path: '/users', component: Users, access_page: "users", skip_access: false, min_permission: "READ", exact: true },
    { path: '/user/view/:user_id', component: UsersView, access_page: "users", skip_access: false, min_permission: "READ", exact: true },
    { path: '/user/edit/:user_id', component: SaveUser, access_page: "users", skip_access: false, min_permission: "UPDATE", exact: true },
    { path: '/user/create', component: SaveUser, access_page: "users", skip_access: false, min_permission: "CREATE", exact: true },
    { path: '/profiles', component: Profiles, access_page: "profiles", skip_access: false, min_permission: "READ", exact: true },
    { path: '/profile/edit/:profile_id', component: ProfileDetails, access_page: "profiles", skip_access: false, min_permission: "READ", exact: true },
    { path: '/profile/create', component: ProfileDetails, access_page: "profiles", skip_access: false, min_permission: "CREATE", exact: true },
    { path: '/roles', component: Roles, access_page: "roles", skip_access: false, min_permission: "READ", exact: true },
    { path: '/role/view/:role_id', component: RoleView, access_page: "roles", skip_access: false, min_permission: "READ", exact: true },
    { path: '/role/edit/:role_id', component: SaveRole, access_page: "roles", skip_access: false, min_permission: "UPDATE", exact: true },
    { path: '/role/create-new', component: SaveRole, access_page: "roles", skip_access: false, min_permission: "CREATE", exact: true },
    { path: '/customer-add', component: CustomerAdd, access_page: "customer", skip_access: false, min_permission: "CREATE" },
    { path: '/other-contacts-add', component: OtherContactAdd, access_page: "others", skip_access: false, min_permission: "CREATE" },

    { path: '/survey-dashboard', component: SurveyDashboard, exact: true, access_page: "survey", skip_access: false, min_permission: "READ" },
    { path: '/survey-dashboard/add-category', component: SurveyCategory, exact: true, access_page: "survey", skip_access: false, min_permission: "CREATE" },
    { path: '/survey-dashboard/categories', component: SurveyCategory, exact: true, access_page: "survey", skip_access: false, min_permission: "READ" },
    { path: '/survey-dashboard/add-new-category', component: CategoryAdd, exact: true, access_page: "survey", skip_access: false, min_permission: "CREATE" },
    { path: '/survey-dashboard/add-survey/:category_id', component: SurveyAdd, access_page: "survey", skip_access: false, min_permission: "CREATE" },
    { path: '/survey-dashboard/edit-survey/:category_id/:survey_id', component: SurveyCreator, access_page: "survey", skip_access: false, min_permission: "UPDATE" },
    { path: '/survey-dashboard/category-survey-dashboard', component: CategorySurveyDashboard, exact: true, access_page: "survey", skip_access: false, min_permission: "READ" },
    { path: '/survey-dashboard/survey-builder/:category_id/:survey_id', component: SurveyCreator, exact: true, access_page: "survey", skip_access: false, min_permission: "CREATE" },
    { path: '/survey-preview', component: SurveyPreview, access_page: "survey", skip_access: false, min_permission: "CREATE" },
    { path: '/template-dashboard', component: TemplateDashboard, exact: true, access_page: "templates", skip_access: false, min_permission: "READ" },
    { path: '/template-dashboard/template-category', component: TemplateCategory, exact: true, access_page: "templates", skip_access: false, min_permission: "READ" },
    { path: '/template-dashboard/add-category', component: AddCategory, exact: true, access_page: "templates", skip_access: false, min_permission: "CREATE" },
    { path: '/template-dashboard/template-add/:category_id', component: TemplateAdd, exact: true, access_page: "templates", skip_access: false, min_permission: "CREATE" },
    { path: '/template-dashboard/category-templates', component: CategoryTemplateDashboard, exact: true, access_page: "templates", skip_access: false, min_permission: "READ" },
    { path: '/template-dashboard/edit-template/:category_id/:template_id', component: TemplateAdd, exact: true, access_page: "templates", skip_access: false, min_permission: "UPDATE" },
    { path: '/template-dashboard/template-questions/:category_id', component: TemplateBuilder, exact: true, access_page: "templates", skip_access: false, min_permission: "CREATE" },
    { path: '/template-dashboard/template-questions/:category_id/:template_id', component: TemplateBuilder, exact: true, access_page: "templates", skip_access: false, min_permission: "UPDATE" },
    { path: '/customer-experience/:survey_id', component: CustomerExperience,  exact: true, skip_access: true },
    { path: '/employee-experience', component: EmployeeExperience, exact: true, skip_access: true },
    { path: '/survey-report', component: SurveyReport, exact: true, access_page: "survey", skip_access: false, min_permission: "READ" },
    { path: '/user-profile', component: UserProfile, exact: true, skip_access: true },
    { path: '/support', component: FeatureDeveloped, exact: true, skip_access: true },
    { path: '/company-profile', component: FeatureDeveloped, exact: true, access_page: "company_profile", skip_access: false, min_permission: "READ" },
    { path: '/feature-developed', component: FeatureDeveloped, exact: true, skip_access: true, }
];

export default CompanyRouter;