import React , { useState } from 'react';
import { Bubble , Doughnut } from 'react-chartjs-2';
import { ProgressBar } from 'react-bootstrap';

const DateType = () => {

  const data = {
    labels: ['13th Nov','14th Nov','15th Nov','16th Nov','17th Nov','18th Nov'],
    datasets: [
      {
        label: 'My First dataset',
        fill: false,
        backgroundColor: [ '#CE2E6C', '#5A4E63', '#32CBBB' , '#32A6CB' , '#FFBD24' ],
        borderColor: [ '#CE2E6C', '#5A4E63', '#32CBBB' , '#32A6CB' , '#FFBD24' ],
        borderJoinStyle: 'miter',
        pointBorderColor: '#CE2E6C',
        pointBackgroundColor: '#fff',
        pointHitRadius: 10,       
        data: [{x:30,y:30,r:5},{x:50,y:50,r:5},{x:20,y:20,r:5},{x:10,y:40,r:5},{x:40,y:40,r:5},{x:60,y:60,r:5},]
      },
    
    ]
  };

    const doughnutData = {

        labels: ['13th Nov','14th Nov','15th Nov','16th Nov'],
        datasets: [{
            data: [30, 30, 20 , 20 ,20],
            backgroundColor: [ '#CE2E6C', '#5A4E63', '#32CBBB' , '#32A6CB' , '#FFBD24' ],
        }]


    } 

    const doughnutOptions = {
        segmentShowStroke: false,
        responsive: true, 
        maintainAspectRatio: false,  
        legend: {
            display: true,
            align:'start',
            position:'bottom',
            labels: {
                fontColor: '#5A4E63',
                boxWidth: 6,
                fontSize:12,
                fontFamily :"NunitoSansSemiBold",
                padding	:20,
                usePointStyle:true,
            }
        },
        cutoutPercentage: 75,
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        tooltips: {
            displayColors: false,
            backgroundColor: "#5A4E63",
            enabled: true,
            mode: "single",
            bodyFontSize: 13,
            bodyFontFamily: "NunitoSansSemiBold",
            bodyFontColor: "#FFFFFF",
            yPadding: 8,
            xPadding: 15,
            cornerRadius: 5,
          },
    }

    const bubbleOptions = {
      responsive: true,   
      maintainAspectRatio: false,
      legend: {
          display: false,
      },
      scales: {
          xAxes: [{
              ticks: { fontColor: "#373737",fontFamily: "NunitoSans", fontSize: 12, stepSize: 10, beginAtZero: true,},
              gridLines: { color: "#EBEBEB",zeroLineColor: 'transparent',borderColor:"transparent",}, 
          }],

          yAxes: [{
              ticks: { fontColor: "#5B5B5B",fontFamily: "NunitoSans", fontSize: 12, stepSize: 10, beginAtZero: true , padding: 15},
               gridLines: { color: '#EDEDED',  zeroLineColor: 'transparent',borderColor:"transparent", } 
            }]
           }
      
    }


    return(           
        <React.Fragment>
            <section className="Common-QuestionComponent Page-DateType">
                <div className="question-type-wrap">
                    <label className="question"><span className="question-no">Q</span><span>[9]</span>What time can you make the appointment?</label>
                    <div className="question-type date-type">Date</div>
                </div>

                <div className="checkbox-multi-card-wrap">

                   
                    <div className="majority-card">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Majority selected </span>
                            <span className="subtitle">Location</span>
                        </div>
                        <span className="card--info-icon calender-info"></span>
                    </div>

                    <div className="least-card">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Least selected</span>
                            <span className="subtitle">Service</span>
                        </div>
                        <span className="card--danger-icon calender-danger"></span>
                    </div>

                    <div className="total-responses">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Total Responses</span>
                            <span className="subtitle">189</span>
                        </div>
                        <span className="checkbox-multi-card-icon"></span>
                    </div>

                    <div className="total-comments">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Total Comments</span>
                            <span className="subtitle">98</span>
                        </div>
                        <span className="checkbox-multi-card-icon"></span>
                    </div>

                </div>

                <div className="checkbox-multi-chart-wrap">

                    <div className="response-statistics-chart">
                        <div className="response-statistics-header">
                            <p>Response count</p>
                            <div className="select-dowload-wrap">
                                <select><option>weekly</option></select>
                                <button type="button" className="header-download-graph"></button>
                            </div>
                        </div>
                        <div className="response-statistics-body">
                            <Bubble data={data} options={bubbleOptions} height={250} />
                        </div>
                    </div>

                    <div className="checkbox-multi-chart-donut">
                    <button type="button" className="header-download-graph"></button>
                         <Doughnut data={doughnutData} options={doughnutOptions} height={150} width={150}/>
                    </div>

                </div>

            </section>
        </React.Fragment>   
    )


}

export default DateType;