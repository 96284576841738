import React, { useState, useEffect, useRef, useContext } from "react";
import './Themes.scss';
import { Breadcrumb } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import { useParams, useLocation } from "react-router-dom";
import useOutsideClick from "hooks/useOutsideClick";
import paginationFactory from 'react-bootstrap-table2-paginator';
import Axios from "utility/Axios";
import AddThemeModal from './AddThemeModal';
import EditSubthemeModel from './EditSubthemeModel';
import configURL from 'config/config';
import DeleteModal from '../Common/DeleteModal';
import AppContext from 'store/AppContext';
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';

const Subthemes = props => {
    const { languageObj = {}, handleUnAuthWarn = {}, accesFeature = {}, EziLoader } = useContext(AppContext)
    const [themeModal, setThemeModal] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editModelData, setEditModelData] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const ref = useRef();
    const hookLocation = useLocation();
    let urlParams = useParams();
    const [dropdownFlag, setDropdownFlag] = useState(false);
    const [theme, setTheme] = useState({
        id: urlParams.theme_id,
        name: hookLocation.state.name,
        description: hookLocation.state.description
    });
    const [themes, setThemes] = useState([]);
    const [pagination, setPagination] = useState({});
    const [subThemes, setSubthemes] = useState([{}]);
    const dropdown = () => {
        setDropdownFlag(!dropdownFlag);
    }

    const statusFormatter = (cell, row) => {
        return (
            <span className={`themes-status_c ${row.status === "active" ? "active" : "offline"}`}>{cell}</span>
        );
    }

    const subThemeNameFormatter = (cell, row) => {
        return (
            <span className="theme-name_c">{cell}</span>
        );
    }

    const AddNewFormatter = (column, colIndex) => {
        return (
            <div className="add-theme-btn-wrap">
                <button type="button" className={`btn-ripple ezi-pink-btn add-theme-btn ${accesFeature.create_theme || "access_lock"}`} onClick={() => {
                    if (accesFeature.create_theme) {
                        setThemeModal(true)
                    } else {
                        handleUnAuthWarn()
                    }
                }}>{column.text} <span className="add_ic"></span></button>
            </div>
        );
    }

    const actionFormatter = (cell, row) => {
        return (
            <div className="theme-editDelete-wrap">
                <button type="button" className={`theme-edit ${accesFeature.update_theme || "access_lock"}`} onClick={() => {
                    if (accesFeature.update_theme) {
                        setEditRowData(row)
                    } else {
                        handleUnAuthWarn()
                    }
                }}>Edit</button>
                <button type="button" className={`theme-delete ${accesFeature.delete_theme || "access_lock"}`} onClick={() => {
                    if (accesFeature.delete_theme) {
                        deleteEntry(row.id)
                    } else {
                        handleUnAuthWarn()
                    }
                }}>Delete</button>
            </div>
        );
    }

    const columns = [
        { dataField: "name", text: "sub themes", formatter: subThemeNameFormatter },
        { dataField: "description", text: "Description" },
        { dataField: "status", text: "Status", formatter: statusFormatter },
        { dataField: 'theme', text: 'Add new sub-theme', headerFormatter: AddNewFormatter, formatter: actionFormatter },
    ];

    const changeTheme = (themeObj) => {
        setDropdownFlag(!dropdownFlag);
        setTheme(themeObj);
        setSubthemeListing(themeObj);
    }

    const setSubthemeListing = (themeObj, pageno = 1) => {
        EziLoader.show();
        setEditModalVisible(false);
        let formData = new FormData();
        formData.append("theme_id", themeObj.id);
        formData.append("page", pageno);
        Axios.post(configURL.subthemeListing, formData).then(res => {
            EziLoader.hide();
            if (res.data.success) {
                setPagination(res.data.pagination);
                setSubthemes(res.data.results);
            } else {
                toast.warn(res.data.message || "Something went wrong!")
            }
        }).catch(err => {
            EziLoader.hide();
        })
    };

    const toggleEditThemeModal = () => {
        setEditModalVisible(!editModalVisible);
    }

    const setEditRowData = (rowData) => {
        toggleEditThemeModal();
        setEditModelData(rowData);
    }

    const searchCategory = (e) => {
        setSearchTerm(e.target.value);
    }

    useOutsideClick(ref, () => {
        dropdownFlag && setDropdownFlag(false);
    });

    const RemotePagination = ({ data, page = 1, sizePerPage, onTableChange, totalSize }) => (
        <BootstrapTable
            remote
            keyField="id"
            data={subThemes}
            columns={columns}
            noDataIndication="Table is Empty"
            pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true, })}
            onTableChange={onTableChange}
            rowClasses="theme-datatable-row"
        />
    );

    const handleAllEntriesChange = (type, { page, sizePerPage }) => {
        if (type === 'pagination') {
            setSubthemeListing(theme, page);
        }
    }

    const deleteEntry = (rowId) => {
        confirmAlert({
            title: 'Delete Sub-Theme',
            message: `Are you sure you want  to delete this entry ?`,
            buttons: [
                {
                    label: 'Okay',
                    onClick: () => {
                        if (rowId !== null) {
                            let formData = new FormData();
                            formData.append("id", rowId);
                            formData.append("model", 'se_sub_themes');
                            Axios.post(configURL.deleteRow, formData).then(resposnse => {
                                if (resposnse !== undefined && resposnse.status) {
                                    setSubthemeListing(theme);
                                } else {
                                    toast.warn(resposnse.data.message || "Something went wrong!")
                                }
                            })
                        }
                    }
                },
                { label: 'Cancel' }
            ]
        });
    }

    useEffect(() => {
        let formData = new FormData();
        formData.append("theme_id", theme.id);
        Axios.post(configURL.subthemeListing, formData).then(res => {
            if (res.data.success) {
                setPagination(res.data.pagination);
                setSubthemes(res.data.results);
            }
        })
        let themeData = new FormData();
        themeData.append("category_id", urlParams.category_id);
        themeData.append("type", hookLocation.state.type || 'survey');
        Axios.post(configURL.themeListing, themeData).then(res => {
            if (res.data.success) {
                setThemes(res.data.results);
            }
        })
    }, []);

    useEffect(() => {
        const results = themes.filter(ele =>
            ele.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchResults(results);
    }, [searchTerm]);

    let themeList = (searchTerm !== '') ? searchResults : themes;

    return (
        <React.Fragment>
            <section className="Page-Themes" >
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => props.history.goBack()}> Theme </Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => props.history.goBack()}> {hookLocation.state.type || 'survey'} </Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => props.history.goBack()}> {hookLocation.state.category}</Breadcrumb.Item>
                        <Breadcrumb.Item> {theme.name || ''} </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="themes-dropdown" >
                        <button type="button" onClick={dropdown} className={`themes-dropdown-btn selected`}> {(theme.name !== undefined && theme.name !== '') ? theme.name : 'Select Theme'} </button>
                        <div className={`themes-inner-dropdown ${dropdownFlag ? 'active' : ""}`} ref={ref}>
                            <div className="themes-dropdown-header">
                                <input type="text" className="themes-dropdown-search" value={searchTerm} onChange={(e) => searchCategory(e)} placeholder={languageObj.translate('Search.1')} />
                                <label>Themes</label>
                            </div>
                            <ul>
                                {
                                    themeList.map(themedata =>
                                        <li onClick={() => changeTheme(themedata)} key={themedata.id}>
                                            {themedata.name}
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    <span className="themes-subtitle"> {theme.description || ''} </span>
                </div>
                <AddThemeModal commonid={theme.id} name="sub-theme" fieldname="Sub Theme" commonidname="theme_id" show={themeModal} onHide={() => setThemeModal(false)} updatethemelisting={() => setSubthemeListing(theme)} />
                <EditSubthemeModel show={editModalVisible} onHide={() => toggleEditThemeModal()} toggleeditmodal={() => toggleEditThemeModal()} name="Sub Theme" editdata={editModelData} updatethemelisting={() => setSubthemeListing(theme)} />
                <div className="themes-content">
                    <RemotePagination
                        data={subThemes}
                        page={pagination.current_page}
                        sizePerPage={pagination.per_page}
                        totalSize={pagination.total}
                        onTableChange={handleAllEntriesChange}
                    />
                </div>
            </section>
        </React.Fragment>
    )
}

export default Subthemes;