import React, { useState, useEffect, useContext } from "react";
import Axios from "utility/Axios";
import './ValidateData.scss';
import { Tab, Breadcrumb, Nav, Dropdown } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import configURL from 'config/config';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from "react-router-dom";
import AppContext from 'store/AppContext';
import EziLoader from 'components/EziLoader';

const ValidateData = (props) => {
    const { languageObj = {} } = useContext(AppContext)
    const [loading, setLoading] = useState(false);
    let currentPath = props.match.path.split('/')[2];
    let history = useHistory();
    const mappingData = useLocation();
    const [columnName] = useState(mappingData.state.value);
    let defaultValidMeta = {
        total: mappingData.state.value.valid
    }
    let defaultInvalidMeta = {
        total: mappingData.state.value.invalid
    }
    const [pushCol, setPushCol] = useState([{}]);
    const [isDataLoad, setIsDataLoad] = useState(false);
    const [allEntries, setAllEntries] = useState(mappingData.state.value.data);
    const [validEntries, setValidEntries] = useState([]);
    const [invalidEntries, setInvalidEntries] = useState([]);
    const [allmeta, setAllMeta] = useState(mappingData.state.value.paginationdata);
    const [validmeta, setValidMeta] = useState(defaultValidMeta);
    const [invalidmeta, setInvalidMeta] = useState(defaultInvalidMeta);
    const [downloadExcel, setDownloadExcel] = useState(false);
    const [excelDownloadLink, setExcelDownloadLink] = useState('');
    const [stateActiveTab, setActiveTab] = useState('excelAllentry');

    const buttonFormatter = (cell, row, rowIndex) => {
        return (
            <button type="button" className="table_delete_ic" onClick={() => deleteStagingEntry(row.id)}>Delete</button>
        );
    }

    const deleteStagingEntry = (id) => {
        let formData = new FormData();
        formData.append("id", id);
        Axios.post(configURL.deleteStaggingData + currentPath, formData).then(res => {
            if (res.data && res.data.success) {
                axiosApiCallPage(stateActiveTab);
                toast.success(`${res.data.message} !`);
            } else {
                toast.error(`${res.data.message} !`);
            }
        })
            .catch(err => {
                toast.error(err.toString());
            })
    }

    const axiosApiCallPage = (activeTab = stateActiveTab, pageno = 1, search = '') => {
        let formData = new FormData();
        formData.append("page", pageno);
        formData.append("search", search);
        Axios.post(configURL[activeTab] + '/' + currentPath, formData).then(res => {
            if (res.sucess !== undefined && res.sucess) {
                // success spelling change
            }
            switch (activeTab) {
                case 'excelAllentry':
                    setAllEntries(res.data.data);
                    setAllMeta(res.data.paginationdata);
                    break;
                case 'excelValidEntry':
                    setValidEntries(res.data.data);
                    setValidMeta(res.data.paginationdata);
                    break;
                case 'excelInvalidEntry':
                    setInvalidEntries(res.data.data);
                    setInvalidMeta(res.data.paginationdata);
                    break;
                // default:
            }
        })
            .catch(err => {
                toast.error(err.toString());
            })
    }

    const loadTabData = (activeTab) => {
        setActiveTab('activeTab');
        axiosApiCallPage(activeTab);
        setDownloadExcel(activeTab === "excelInvalidEntry")
    }

    const updateRowData = (oldValue, newValue, row, column) => {
        if (oldValue !== newValue) {
            let formData = new FormData();
            let data = {};
            row[column.dataField] = newValue;
            let dataString = JSON.stringify(row);
            formData.append("data", dataString);
            Axios.post(configURL.excelUpdateRow + currentPath, formData).then(res => {
                if (res.data.success !== undefined && res.data.success) {
                    axiosApiCallPage(stateActiveTab);
                    toast.success(`${res.data.message} !`);
                } else {
                    toast.error(`${res.data.message} !`);
                }
            })
        }
    }

    const RemotePagination = ({ data, page = 1, sizePerPage, onTableChange, totalSize }) => (
        <div>
            <BootstrapTable
                remote
                keyField="id"
                data={data}
                noDataIndication="Table is Empty"
                columns={pushCol}
                pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true, })}
                onTableChange={onTableChange}
                cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    beforeSaveCell: (oldValue, newValue, row, column) => { updateRowData(oldValue, newValue, row, column); },
                })}
            />
        </div>
    );

    const handleAllEntriesChange = (type, { page, sizePerPage, cellEdit }) => {
        if (type === 'pagination') {
            axiosApiCallPage('excelAllentry', page);
        }
    }

    const handleValidEntriesChange = (type, { page, sizePerPage, cellEdit }) => {
        if (type === 'pagination') {
            axiosApiCallPage('excelValidEntry', page);
        }
    }

    const handleInalidEntriesChange = (type, { page, sizePerPage, cellEdit }) => {
        if (type === 'pagination') {
            axiosApiCallPage('excelInvalidEntry', page);
        }
    }

    const downloadInvalidExcel = () => {
        if (excelDownloadLink !== '') {
            window.open(excelDownloadLink, '_blank').focus();
        } else {
            toast.error(`Invalid Download Link !`);
        }
    }

    const getExcelDownloadLink = () => {
        Axios.post(configURL.invalidEntriesDownload + currentPath, {}).then(res => {
            if (res.data.success !== undefined && res.data.success) {
                setExcelDownloadLink(res.data.excel_link);
            } else {
                toast.error(res.data.message);
            }
        })
            .catch(err => {

            })
    }

    const sendProcessData = () => {
        setLoading(true);
        let formData = new FormData();
        formData.append("language", 'En');
        Axios.post(configURL.processData + currentPath, formData).then(res => {
            setLoading(false);
            if (res.data.success !== undefined && res.data.success) {
                sessionStorage.setItem('isSuccess', true);
                sessionStorage.setItem('msg', languageObj.translate('DataImportedSucessfully .1'));
                history.push("/people-directory");
            }
            else {
                toast.error(res.data.message);
            }
        })
            .catch(err => {
                setLoading(false);
                toast.error(err.toString(), {
                });
            })
    }

    useEffect(() => {
        let columns = [];
        columnName.columns && columnName.columns.map((item) => {
            let columnField = {
                dataField: item,
                text: item.split("_").join(" "),
                editable: !(item == "status" || item == "error_message")
            }
            columns.push(columnField);
        })
        columns.push({
            formatter: buttonFormatter,
            editable: false
        });
        setPushCol(columns);
        setIsDataLoad(true);
        getExcelDownloadLink();
    }, []);

    return (
        <React.Fragment>

            <section className="Page-ValidateData">
                {/* Breadcrumb Start */}
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item href="#">{languageObj.translate('PeopleDirectory.1')}</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">{languageObj.translate('Employees.1')}</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">{languageObj.translate('BulkUpload.1')}</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">{languageObj.translate('ColumnMapping.1')}</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">{languageObj.translate('ValidatedData.1')}</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="column-header">
                        <h1 className="page-heading">{languageObj.translate('ValidatedData.1')}</h1>
                        <div className="column-header-btn">
                            {/* <button type="button" className="btn-ripple back">{translate('Back.1')}</button> */}
                            <button type="button" className="btn-ripple next" onClick={sendProcessData}>{languageObj.translate('Process.1')}<span className="next_ic"></span></button>
                        </div>
                    </div>
                </div>
                {/* Breadcrumb End  */}
                <div className="ezi-datatable-tab">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="allEntries">
                        <div className="ezi-datatable-wrap">
                            <div className="ezi-datatable-left-header">
                                <Nav variant="pills" >
                                    <Nav.Item>
                                        <Nav.Link className="entry-dropdown">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success">
                                                    <p className="show-entry"><span>{languageObj.translate('Show.1')}</span>6 {languageObj.translate('Entries.1')}</p>
                                                </Dropdown.Toggle>

                                                {/* <Dropdown.Menu>
                                                    <Dropdown.Item href="#/action-1">5</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-2">10</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-3">{languageObj.translate('All.1')}</Dropdown.Item>
                                                </Dropdown.Menu> */}
                                            </Dropdown>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="allEntries" onClick={() => loadTabData('excelAllentry')}><p className="all_entry"><span className="all_count">{(allmeta.total !== undefined) ? allmeta.total : 0}</span>{languageObj.translate('AllEntries.1')}</p></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="validEntries" onClick={() => loadTabData('excelValidEntry')}><p className="valid_entry"><span className="valid_count">{(validmeta.total !== undefined) ? validmeta.total : 0}</span>{languageObj.translate('ValidEntries.1')}</p></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="invalidEntries" onClick={() => loadTabData('excelInvalidEntry')}><p className="invalid_entry"><span className="invalid_count">{(invalidmeta.total !== undefined) ? invalidmeta.total : 0}</span>{languageObj.translate('InvalidEntries.1')}</p></Nav.Link>
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                        {downloadExcel && (invalidEntries.length > 0) &&
                            <div className="download-excel-btn-wrap">
                                <button type="button" className="download-excel-btn" onClick={downloadInvalidExcel}>Download</button>
                            </div>
                        }
                        <Tab.Content className="ezi-datatable-content">
                            <Tab.Pane eventKey="allEntries">
                                {
                                    (isDataLoad) &&
                                    <RemotePagination
                                        data={allEntries}
                                        page={allmeta.current_page}
                                        sizePerPage={allmeta.per_page}
                                        totalSize={allmeta.total}
                                        onTableChange={handleAllEntriesChange}
                                    />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="validEntries">
                                <RemotePagination
                                    data={validEntries}
                                    page={validmeta.current_page}
                                    sizePerPage={validmeta.per_page}
                                    totalSize={validmeta.total}
                                    onTableChange={handleValidEntriesChange}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="invalidEntries">
                                <RemotePagination
                                    data={invalidEntries}
                                    page={invalidmeta.current_page}
                                    sizePerPage={invalidmeta.per_page}
                                    totalSize={invalidmeta.total}
                                    onTableChange={handleInalidEntriesChange}
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </section>
            {loading && <EziLoader />}
        </React.Fragment>
    )
}

export default ValidateData;