import React, { useState, useEffect, useRef, useContext } from "react";
import { Tab, Nav } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import configURL from 'config/config';
import { useParams } from "react-router-dom";
import Axios from "utility/Axios";
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import AppContext from 'store/AppContext';
import { connect } from 'react-redux';
import * as AppAction from "store/actions";

const SurveySetting = ({ surveyStatus = "draft", dispatchSurveyLogo, dispatchParticipantSource }) => {

    const { languageObj = {}, EziLoader } = useContext(AppContext)
    const [key, setKey] = useState('general');
    const [surveyType, setSurveyType] = useState("oneTimeSurvey");
    const [isLoading, setIsLoading] = useState(false);
    const [imageSource, setImageSource] = useState({ 'partner_logo': null, 'company_logo': null });
    const [imageFiles, setImageFiles] = useState({ 'partner_logo': '', 'company_logo': '' });
    const urlParams = useParams()

    const partnerLogoRef = useRef(null)
    const companyLogoRef = useRef(null)

    const [surveySetting, setSurveySetting] = useState({
        survey_type: "",
        start_date: new Date(),
        end_date: new Date()
    });

    const handleImageUpload = (e) => {
        var name = e.target.name;
        var file = e.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = () => {
            dispatchSurveyLogo({ ...imageSource, [name]: reader.result })
            setImageSource({ ...imageSource, [name]: reader.result });
        }
        setImageFiles({ ...imageFiles, [name]: file });
    }

    /**
     * Send Survey Setting data to server.
     */
    const handleStoreSurveySetting = () => {
        if (surveySetting.start_date.getTime() > surveySetting.end_date.getTime()) {
            confirmAlert({
                title: 'Date Error!',
                message: 'Start date could not be greater than End Date..',
                buttons: [{ label: 'I Understood' }]
            });
            return;
        }
        EziLoader.show()
        let formData = new FormData();
        formData.append("survey_id", urlParams.survey_id);
        if (imageFiles.partner_logo && imageFiles.partner_logo !== '') {
            formData.append("partner_logo", imageFiles['partner_logo']);
        }
        if (imageFiles.company_logo && imageFiles.company_logo !== '') {
            formData.append("company_logo", imageFiles['company_logo']);
        }
        let setting_json = {
            surveyType: surveyType,
            trigger_profile: null,
            startDate: new Date(surveySetting.start_date.getTime() - (surveySetting.start_date.getTimezoneOffset() * 60000)).toJSON(),
            endDate: new Date(surveySetting.end_date.getTime() - (surveySetting.end_date.getTimezoneOffset() * 60000)).toJSON(),
            schedule: { "repeat type": null, "occurence": null, "start on": null },
            number_of_submissions: null,
            reporting: null,
            editable: null,
            SelectionProfile: { "questions": null, "population": null, "SameQuestion": null }
        };
        formData.append("setting_json", JSON.stringify(setting_json));
        Axios.post(configURL.storeSurveySetting, formData).then(res => {
            EziLoader.hide()
            if (res.data.success !== undefined && res.data.success) {
                toast.info(res.data.message || "Survey Setting added");
            } else {
                toast.warn(res.data.message || "Something went wrong");
            }
        }).catch(err => {
            EziLoader.hide()
        })
    }

    /**
     * Handle Survey Reset setting
     */
    const handleSurveyResetSetting = () => {
        setSurveyType("oneTimeSurvey");
        setSurveySetting({
            start_date: new Date(),
            end_date: new Date()
        });
        setImageSource({
            company_logo: null,
            partner_logo: null,
        })
    }

    const isDateDisabled = (date = new Date()) => {
        if (surveyStatus === 'active' || surveyStatus === 'close') {
            return true
        }
        if (surveyStatus === "inactive") {
            return (date !== null && new Date(new Date(date).toDateString()).getTime() < new Date(new Date().toDateString()).getTime()) ? true : false
        }
        if (surveyStatus === "draft") {
            return false
        }
    }
    useEffect(() => {
        EziLoader.show()
        let formData = new FormData()
        formData.append("survey_id", urlParams.survey_id);
        Axios.post(configURL.getSurveySetting, formData).then(res => {
            EziLoader.hide()
            if (res.data.success !== undefined && res.data.success) {
                let start_date = (res.data.result.start_date) ? new Date(res.data.result.start_date) : new Date()
                let end_date = (res.data.result.end_date) ? new Date(res.data.result.end_date) : new Date()
                setSurveySetting({
                    start_date: start_date,
                    end_date: end_date
                });
                setImageSource({
                    company_logo: res.data.result.company_logo_path || null,
                    partner_logo: res.data.result.partner_logo_path || null,
                })
                dispatchSurveyLogo({
                    company_logo: res.data.result.company_logo_path || null,
                    partner_logo: res.data.result.partner_logo_path || null,
                })
                if (res.data.result.survey_type) {
                    setSurveyType(res.data.result.survey_type)
                }
                if (res.data.result.participant_source) {
                    dispatchParticipantSource(res.data.result.participant_source)
                }
            } else {
                toast.warn(res.data.message || "Something went wrong!!");
            }
        }).catch(err => {
            EziLoader.hide()
        })
        return () => toast.dismiss()
    }, [])

    const handleDeleteLogo = type => {
        let formData = new FormData()
        formData.append("survey_id", urlParams.survey_id);
        formData.append("type", type);
        Axios.post(configURL.surveyLogoDelete, formData).then(response => {
            if (response.data.success !== undefined && response.data.success === true) {
                toast.success(response.data.message);
                let updatedData;
                if (type === 'partner') {
                    updatedData = { ...imageSource, partner_logo: null }
                    partnerLogoRef.current.value = null
                    setImageSource(updatedData)
                    setImageFiles({ ...imageFiles, partner_logo: null })
                    dispatchSurveyLogo(updatedData)
                }
                if (type === 'company') {
                    companyLogoRef.current.value = null
                    updatedData = { ...imageSource, company_logo: null }
                    setImageSource(updatedData)
                    setImageFiles({ ...imageFiles, company_logo: null })
                    dispatchSurveyLogo(updatedData)
                }
            } else {
                toast.warn(response.data.message);
            }
        })
    }

    return (
        <div className="tablist_ezi survey-inner-tab-container">
            <Tab.Container activeKey={key} onSelect={k => setKey(k)}>
                <div className="survey-inner-tab-header-wrap">
                    <div className="tab-left-header">
                        <Nav variant="pills">
                            <Nav.Item>
                                <Nav.Link eventKey="general">{languageObj.translate('General.1')}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="customize">{languageObj.translate('Customize.1')}</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="tab-right-header">
                        {isLoading && <div>loading....</div>}
                        <Nav variant="pills" >
                            <Nav.Item>
                                <Nav.Link>
                                    <button
                                        type="button"
                                        className="btn-ripple ezi-pink-btn inner_save_btn"
                                        onClick={handleStoreSurveySetting}
                                        disabled={(surveyStatus === 'active' || surveyStatus === 'close')}
                                        title={(surveyStatus === 'active' || surveyStatus === 'close') ? 'Survey is ' + surveyStatus : ''}
                                    >
                                        {languageObj.translate('Save.1')}
                                    </button>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link><button type="button" disabled={(surveyStatus === 'active' || surveyStatus === 'close')} className="btn-ripple inner_reset_btn" title={(surveyStatus === 'active' || surveyStatus === 'close') ? 'Survey is ' + surveyStatus : ''} onClick={handleSurveyResetSetting}>{languageObj.translate('Reset.1')}</button></Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                </div>
                <Tab.Content className="survey-inner-tab-content">
                    <Tab.Pane eventKey="general">
                        <div className="survey-type-wrap">
                            <h1 className="survey-type-heading">{languageObj.translate('SurveyType.1')}</h1>
                            <div className="survey-type-container">
                                <Tab.Container activeKey={surveyType} onSelect={k => setSurveyType(k)}>
                                    <div className="survey-button-tab-header">
                                        <div className="tab-left-header">
                                            <Nav variant="pills" >
                                                <Nav.Item>
                                                    <Nav.Link eventKey="oneTimeSurvey">{languageObj.translate('OneTime.1')}</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item >
                                                    <Nav.Link eventKey="continuous">{languageObj.translate('Continuous.1')}</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                    </div>
                                    <div className="startend-date-wrap">
                                        <div className="start-date-wrap">
                                            <label className="startend-label">{languageObj.translate('Starts.1')}</label>
                                            <DatePicker
                                                selected={surveySetting.start_date}
                                                value={surveySetting.start_date}
                                                disabled={isDateDisabled(surveySetting.start_date)}
                                                onChange={(date) => setSurveySetting({ ...surveySetting, start_date: date })}
                                            />
                                        </div>
                                        <span className="date-wrapper-icon"></span>
                                        <div className="end-date-wrap">
                                            <label className="startend-label">{languageObj.translate('Ends.1')}</label>
                                            <DatePicker
                                                selected={surveySetting.end_date}
                                                value={surveySetting.end_date}
                                                minDate={new Date()}
                                                onChange={(date) => setSurveySetting({ ...surveySetting, end_date: date })}
                                                disabled={isDateDisabled(surveySetting.end_date)}
                                            />
                                        </div>
                                    </div>
                                </Tab.Container>
                            </div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="customize">
                        <div className="company-logo-main-wrap">
                            <h1 className="company-logo-heading">{languageObj.translate('PartnerLogo.1')}</h1>
                            <div className="company-logo-container">
                                <div className="company-logo-content">
                                    <div className="default-registered-image">
                                        {imageSource.partner_logo &&
                                            <button type="button" onClick={() => handleDeleteLogo('partner')}>Delete</button>}
                                        <img src={imageSource.partner_logo || require(`../../../../assets/images/logo.png`)} className="default-registered-logo" />
                                    </div>
                                    <span className="company-logo-devider">{languageObj.translate('or.1')}</span>
                                    <div className="common-img-upload-wrapper">
                                        <span className="common-upload-btn-formatter">{languageObj.translate('Upload.1')}</span>
                                        <input type="file" className="common-upload-input" name="partner_logo" onChange={handleImageUpload} disabled={(surveyStatus === 'active' || surveyStatus === 'close')} ref={partnerLogoRef} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="company-logo-main-wrap">
                            <h1 className="company-logo-heading">{languageObj.translate('CompanyLogo.1')}</h1>
                            <div className="company-logo-container">
                                <div className="company-logo-content">
                                    <div className="default-registered-image">
                                        {imageSource.company_logo &&
                                            <button type="button" onClick={() => handleDeleteLogo('company')}>Delete</button>}
                                        <img alt="" src={imageSource.company_logo || require(`../../../../assets/images/logo.png`)} className="default-registered-logo" />
                                    </div>
                                    <span className="company-logo-devider">{languageObj.translate('or.1')}</span>
                                    <div className="common-img-upload-wrapper">
                                        <span className="common-upload-btn-formatter">{languageObj.translate('Upload.1')}</span>
                                        <input type="file" className="common-upload-input" name="company_logo" onChange={handleImageUpload} disabled={(surveyStatus === 'active' || surveyStatus === 'close')} ref={companyLogoRef} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        surveyStatus: state.survey.status
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatchParticipantSource: (source) => dispatch(AppAction.setParticipantSource({ source })),
        dispatchSurveyLogo: (surveyLogos = {}) => dispatch(AppAction.setSurveyLogo({ surveyLogos })),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SurveySetting);