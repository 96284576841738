import React, { useState } from 'react';
import { EditorState, Modifier } from 'draft-js';
import PropTypes from 'prop-types';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { CHANNEL_PLACEHOLDERS } from "constants/constants";

const DropdownPlaceholder = (props) => {
    const [isopen, setIsOpen] = useState(false)

    const addPlaceholder = (placeholder) => {
        const { editorState, onChange } = props;
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            placeholder,
            editorState.getCurrentInlineStyle(),
        );
        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    }

    return (
        <div onClick={() => setIsOpen(!isopen)} className="rdw-block-wrapper" aria-label="rdw-block-control">
            <div className="rdw-dropdown-wrapper rdw-block-dropdown" aria-label="rdw-dropdown">
                <div className="rdw-dropdown-selectedtext" title="Placeholders">
                    <span>Placeholder</span>
                    <div className={`rdw-dropdown-caretto${isopen ? "close" : "open"}`}></div>
                </div>
                <ul className={`rdw-dropdown-optionwrapper ${isopen ? "close-placeholder-ul" : "placeholder-ul"}`}>
                    {CHANNEL_PLACEHOLDERS.map(item => (
                        <li
                            onClick={() => addPlaceholder(item.value)}
                            key={item.id}
                            className="rdw-dropdownoption-default placeholder-li"
                        >{item.label}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
DropdownPlaceholder.propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
}
export default DropdownPlaceholder