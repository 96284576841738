import React from 'react';

const TrendingThemes = () =>{

    return(

        <div className="trending-themes-card">

            <div className="trending-themes-card-header">
                <span className="header-tit">Trending Themes</span>
            </div>

            <div className="trending-themes-card-body">
                    <div key="index" className="trending-themes-list">
                      <span className="themes-label">Cleanliness</span>
                      <span className="themes-count trend-up">+25%</span>
                    </div>
                {
                    [...Array(15)].map((item,index) =>                
                        <div key="index" className="trending-themes-list">
                            <span className="themes-label">Maintenance</span>
                            <span className="themes-count trend-down">-13%</span>
                        </div>
                    )
                }
            </div>
            
        </div>
        

    )


}

export default TrendingThemes;