import React, { Fragment } from 'react';
import { connect } from 'react-redux';

const ParticipantTabHeader = ({ surveyAction, surveyStatus, saveData, activeTab, communicate, showCommunicate }) => {
    return ((showCommunicate && surveyAction === "edit" && surveyStatus !== "draft") || activeTab === 'participants' ?
        <button type="button" className="ezi-pink-btn" onClick={communicate}>Communicate</button> :
        (<Fragment>
            <button type="button" onClick={saveData} className="btn-ripple ezi-pink-btn inner_save_btn">Save</button>
            <button type="button" className="btn-ripple inner_reset_btn">Reset</button>
        </Fragment>)
    )
}
const mapStateToProps = state => {
    return {
        surveyAction: state.survey.action,
        surveyStatus: state.survey.status
    }
}

export default connect(mapStateToProps)(ParticipantTabHeader);
