import PartnerDashboard from 'modules/partner/Dashboard';
import SaveCompany from 'modules/partner/Companies/SaveCompany'
import PartneEditCompany from 'modules/partner/Companies/EditCompany';
import PartneCompanies from 'modules/partner/Companies/Companies';
import AllocateTemplate from 'modules/partner/TemplateAllocation/AllocateTemplate';
import PartnerThemes from 'modules/partner/Themes/Themes';
import PartnerSubthemes from 'modules/partner/Themes/Subthemes';
import PartnerTemplateDashboard from 'modules/partner/TemplateDashboard/TemplateDashboard';
import PartnerTemplateCategory from 'modules/partner/TemplateCategory/TemplateCategory';
import PartnerAddCategory from 'modules/partner/TemplateCategory/AddCategory';
import PartnerTemplateAdd from 'modules/partner/TemplateDashboard/TemplateAdd';
import PartnerTemplateBuilder from 'modules/partner/TemplateBuilder/TemplateBuilder';
import PartnerCategoryTemplateDashboard from 'modules/partner/TemplateDashboard/CategoryTemplateDashboard';

const PartnerRouter = [
    { path: '/partner-dashboard', component: PartnerDashboard, exact: true, skip_access: true, access_page: "partner", skip_access: false, min_permission: "READ" },
    { path: '/partner/add-company', component: SaveCompany, exact: true, skip_access: true, access_page: "partner", skip_access: false, min_permission: "READ" },
    { path: '/partner/update-company/:account_id', component: SaveCompany, exact: true, skip_access: true, access_page: "partner", skip_access: false, min_permission: "READ" },
    { path: '/partner/companies', component: PartneCompanies, exact: true, skip_access: true, access_page: "partner", skip_access: false, min_permission: "READ" },
    { path: '/partner/edit-company', component: PartneEditCompany, exact: true, skip_access: true, access_page: "partner", skip_access: false, min_permission: "READ" },
    { path: '/partner/companies-template-dashboard/:account_id', component: AllocateTemplate, exact: true, skip_access: true, access_page: "partner", skip_access: false, min_permission: "READ" },
    { path: '/partner-themes', component: PartnerThemes, access_page: "partner_themes", skip_access: false, min_permission: "READ" },
    { path: '/partner-subthemes/:category_id/:theme_id', component: PartnerSubthemes, access_page: "partner_themes", skip_access: false, min_permission: "READ" },
    { path: '/partner-template-dashboard', component: PartnerTemplateDashboard, exact: true, access_page: "partner_templates", skip_access: false, min_permission: "READ" },
    { path: '/partner-template-dashboard/template-category', component: PartnerTemplateCategory, exact: true, access_page: "partner_templates", skip_access: false, min_permission: "READ" },
    { path: '/partner-template-dashboard/add-category', component: PartnerAddCategory, exact: true, access_page: "partner_templates", skip_access: false, min_permission: "CREATE" },
    { path: '/partner-template-dashboard/template-add/:category_id', component: PartnerTemplateAdd, exact: true, access_page: "partner_templates", skip_access: false, min_permission: "CREATE" },
    { path: '/partner-template-dashboard/category-templates', component: PartnerCategoryTemplateDashboard, exact: true, access_page: "partner_templates", skip_access: false, min_permission: "READ" },
    { path: '/partner-template-dashboard/edit-template/:category_id/:template_id', component: PartnerTemplateAdd, exact: true, access_page: "partner_templates", skip_access: false, min_permission: "UPDATE" },
    { path: '/partner-template-dashboard/template-questions/:category_id', component: PartnerTemplateBuilder, exact: true, access_page: "partner_templates", skip_access: false, min_permission: "CREATE" },
    { path: '/partner-template-dashboard/template-questions/:category_id/:template_id', component: PartnerTemplateBuilder, exact: true, access_page: "partner_templates", skip_access: false, min_permission: "UPDATE" },
]

export default PartnerRouter