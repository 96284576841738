
import React from "react";
import useColumnMapping from './useColumnMapping';

function ColumnTodo({ todo, index, onComplete, onUnfinished, onDeleteTodo, selectHandler, checkHandler }) {
    const mapData = useColumnMapping();
    let demographicName = todo.text;    
    return (
        <React.Fragment>
            <div className="excel-field-wrap" >
                <label className="ezi-checkbox">
                    <input type="checkbox" checked={(todo.isSelected != undefined) ? (todo.isSelected) : false} data-demographic={todo.text} onChange={(e) => checkHandler(e)}/>
                    <span className="ezi-checkbox-mark"></span>
                </label>                                
                <label className="field-label"  style={{ textDecoration: todo.isCompleted ? "line-through" : "" }}> {todo.label}</label> 
                <div className="field-control">
                    <select className="map-select" onChange={(e) => selectHandler(e)}>
                        <option key={'noselect'} value={''}>Please select</option>
                        {mapData.mappingData && mapData.mappingData.map((item, index) => (
                            <option key={index} data-demographic={todo.text} value={item}>{item}</option>
                        ))}
                    </select>  
                </div>
                {todo.isDeleteButtonShow  &&
                    <button onClick={() => onDeleteTodo(index, todo.text)} className="delete-column" >
                        <span>&#10005;</span> 
                    </button>
                }
            </div>
        </React.Fragment>
    );
}
 
export default ColumnTodo;