import { useState } from 'react';

const useSidebarHooks = () => {
    const [sidebar, setSidebar] = useState(false);
    const [mobMenu, setMobMenu] = useState(false);
    const [reportFlag, setReportMenu] = useState(false);
    const [collectionFlag, setCollectionMenu] = useState(false);
    const [settingFlag, setSettingMenu] = useState(false);
    const sidebarCollapse = () => {
        setSidebar(true);
    }
    const overlaySidebar = () => {
        setSidebar(false);
    }
    const mobileMenu = () => {
        mobMenu ? setMobMenu(false) : setMobMenu(true);
    }
    const ReportMenu = () => {
        reportFlag ? setReportMenu(false) : setReportMenu(true);
    }
    const collectionMenu = () => {
        collectionFlag ? setCollectionMenu(false) : setCollectionMenu(true);
    }
    const settingMenu = () => {
        settingFlag ? setSettingMenu(false) : setSettingMenu(true);
    }
    return {
        sidebarCollapse,
        overlaySidebar,
        mobileMenu,
        sidebar,
        mobMenu,
        collectionMenu,
        collectionFlag,
        settingMenu,
        settingFlag,
        reportFlag,
        ReportMenu
    };
}
export default useSidebarHooks;
