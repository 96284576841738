import React, { useState, useEffect } from "react";
import { Modal, Spinner } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';


const ActiveSurvey = ({ closeDate, onhide, survey_id, confirm, show }) => {

    const [dateValue, setDateValue] = useState(new Date())
    const [errData, setErrData] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (closeDate) {
            setDateValue(new Date(closeDate))
        }
        if (!show) {
            setErrData(null)
            setLoading(false)
        }
    }, [closeDate, show]);

    const handleSave = () => {
        if (dateValue.getTime() < new Date().getTime()) {
            setErrData("Survey date could not be less then current date")
            return;
        }
        confirm(dateValue, survey_id)
        setLoading(true)
    }

    return (
        <Modal centered show={show} onHide={onhide} aria-labelledby="contained-modal-title-vcenter" size="sm" className="ezi-alert-modal" backdropClassName="ezi-alert-backdrop" >

            <Modal.Body className="ezi-alert-body">
                <div className="ezi-alert-inner prompt-alert">
                    <button type="button" className="close-alert-icon" onClick={onhide}>Cancel</button>
                    <span className="ezi-alert-text">Survey date is finished please set survey end date</span>
                    <div className="active-survey-datepicker">
                        <DateTimePicker
                            onChange={(date) => {
                                setDateValue(date)
                                setErrData(null)
                            }}
                            className="ezi-datepicker-custom"
                            value={dateValue}
                            clearIcon={null}
                            calendarIcon={null}
                        />
                    </div>
                    {errData && <div className="survey_extend_date_err">
                        <span className="survey_extend_date_err">{errData}</span>
                    </div>}
                    <div className="ezi-alert-footer">
                        <button type="button" className="confirm-alert-btn" onClick={handleSave} disabled={loading}>
                            Continue &nbsp; {loading && <Spinner size="sm" animation="border" />}
                        </button>
                        <button type="button" className="close-alert-btn" onClick={onhide}>Cancel</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ActiveSurvey;