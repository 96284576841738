import React, { useState, useContext, useEffect } from "react";
import './Roles.scss';
import { Breadcrumb } from 'react-bootstrap';
import AppContext from 'store/AppContext';
import Axios from "utility/Axios";
import configURL from "config/config";
import Badge from 'react-bootstrap/Badge'
import { toast } from "react-toastify";

const RoleView = (props) => {

    const { languageObj = {}, accesFeature = {}, handleUnAuthWarn, EziLoader } = useContext(AppContext)
    const { role_id = null } = props.match.params;
    const [roleData, setRoleData] = useState({});

    useEffect(() => {
        EziLoader.show()
        let formData = new FormData();
        formData.append("role_id", role_id)
        Axios.post(configURL.get_role_detail, formData).then(res => {
            EziLoader.hide()
            if (res.data.success === true) {
                setRoleData(res.data.result)
            }
        })
    }, [])

    useEffect(() => {
        if (roleData && roleData.is_default === true) {
            toast.warn("Default Role operations are not allowed.")
        }
    }, [roleData])

    return (
        <React.Fragment>
            <section className="Page-RolesEdit" >

                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item>{languageObj.translate('Settings.1')}</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => props.history.push("/roles")}>Roles</Breadcrumb.Item>
                        <Breadcrumb.Item>{roleData.name || ""}</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="column-header">
                        <h1 className="page-heading">Role Details</h1>
                        <div className="column-header-btn">
                            <button type="button" className={`ezi-btn btn-ripple role-edit active ${accesFeature.edit_role || 'access_lock'}`} onClick={() => {
                                if (accesFeature.edit_role) {
                                    props.history.push("/role/edit/" + role_id)
                                } else {
                                    handleUnAuthWarn();
                                }
                            }} disabled={(roleData && roleData.is_default === true)} title={(roleData && roleData.is_default === true) ? 'Default Role Could not be changed' : ''}>Edit</button>
                            <button type="button" className="ezi-btn btn-ripple role-cancel" onClick={() => props.history.goBack()}>Back</button>
                        </div>
                    </div>
                </div>
                <div className="roles-edit-card">
                    <div className="role-edit-form-field">
                        <label className="role-edit-form-label">Name</label>
                        <div className="role-edit-right-field">
                            <span className="form-view-value">{roleData.name || ""}</span>
                        </div>
                    </div>
                    <div className="role-edit-form-field">
                        <label className="role-edit-form-label">Status</label>
                        <div className="role-edit-right-field">
                            <span className={`user-status_c ${roleData.status ? "active" : "offline"}`}>{roleData.status ? "Active" : "Offline"}</span>
                        </div>
                    </div>
                    <div className="role-edit-form-field">
                        <label className="role-edit-form-label">Privileges</label>
                        <div className="role-edit-right-field">
                            {roleData.profile && roleData.profile.map(el => <Badge key={el.value} pill variant="primary">{el.label}</Badge>)}
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default RoleView;