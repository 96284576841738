import React , { useState } from 'react';
import { Bar , Doughnut } from 'react-chartjs-2';
import Rating from 'react-rating';

const SmileyCustomRating = () => {

    const barChartData = {
        labels: [ 'Strongly Disagree', 'Disagree', 'Bit Disagree' , 'Somewhat Disagree' , 'Natural ', 'Lil Bit Natural ', 'Somewhat Agree ', 'Bit Agree ', 'Agree', 'Strongly Agree'],
        datasets: [
            {
                label: "Total Invitees",
                backgroundColor: '#CE2E6C',
                borderColor: '#CE2E6C',
                data: [40, 25, 35 ,30 ,25,40, 25, 35 ,30 ,25],
                offsetGridLines : false,
            }
        ]
    };

    
    const barChartOptions = {
        responsive: true,   
        maintainAspectRatio: false,
        legend: {
            display: false,
            align:'start',
            position:'top',
            labels: {
                fontColor: '#5A4E63',
                boxWidth: 6,
                fontSize:12,
                fontFamily :"NunitoSansSemiBold",
                padding	:20,
                usePointStyle:true,
            }
        },
        scales: {
            xAxes: [{
                barPercentage: 0.9,
                categoryPercentage: 0.4,
                ticks: { fontColor: "#373737",fontFamily: "NunitoSans", fontSize: 12, stepSize: 6, beginAtZero: true,},
                gridLines: { color: "transparent",zeroLineColor: 'transparent',}, 
            }],

            yAxes: [{
                ticks: { fontColor: "#5B5B5B",fontFamily: "NunitoSans", fontSize: 12, stepSize: 10, beginAtZero: true , padding: 15},
                 gridLines: { color: '#EDEDED',  zeroLineColor: '#EDEDED',borderColor:"transparent",   drawBorder: false, } }]
             }
        
    };

    const doughnutData = {

        labels: [ 'Price', 'Usability', 'Features' , 'Support' , 'Others'],
        datasets: [{
            data: [30, 30, 20 , 20 ,20],
            backgroundColor: [ '#CE2E6C', '#5A4E63', '#32CBBB' , '#32A6CB' , '#FFBD24' ],
        }]


    } 

    const doughnutOptions = {
        segmentShowStroke: false,
        responsive: true, 
        maintainAspectRatio: false,  
        legend: {
            display: true,
            align:'start',
            position:'bottom',
            labels: {
                fontColor: '#5A4E63',
                boxWidth: 6,
                fontSize:12,
                fontFamily :"NunitoSansSemiBold",
                padding	:20,
                usePointStyle:true,
            }
        },
        cutoutPercentage: 75,
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        tooltips: {
            displayColors: false,
            backgroundColor: "#5A4E63",
            enabled: true,
            mode: "single",
            bodyFontSize: 13,
            bodyFontFamily: "NunitoSansSemiBold",
            bodyFontColor: "#FFFFFF",
            yPadding: 8,
            xPadding: 15,
            cornerRadius: 5,
          },
    }



    return(           
        <React.Fragment>
            <section className="Common-QuestionComponent Page-SmileyCustomRating">
                <div className="question-type-wrap">
                    <label className="question"><span className="question-no">Q</span><span>[14]</span>How would you rate our services today?</label>
                    <div className="question-type smiley-rating">Smiley Custom Rating</div>
                </div>

                <div className="checkbox-multi-card-wrap">

                  
                    <div className="overall-smily-rating">
                        <span className="smily-rating-icon smily9"></span>
                        <div className="overall-smily-rating-text">                           
                            <span className="title">9/10</span>
                            <span className="subtitle">329 response</span>
                        </div>
                        <Rating
                          readonly
                        placeholderRating={9}
                        stop={10}
                        emptySymbol={[<img alt="" src={require(`../../../../assets/images/report/smilyten/smily1.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily2.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily3.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily4.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily5.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily6.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily7.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily8.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily9.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily10.svg`)}  className="smily-rating-sm-img" />] } 
                        placeholderSymbol={[<img alt="" src={require(`../../../../assets/images/report/smilyten/smily1-fill.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily2-fill.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily3-fill.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily4-fill.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily5-fill.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily6-fill.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily7-fill.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily8-fill.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily9-fill.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily10-fill.svg`)}  className="smily-rating-sm-img" />]}
                        fullSymbol={[<img alt="" src={require(`../../../../assets/images/report/smilyten/smily1-fill.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily2-fill.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily3-fill.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily4-fill.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily5-fill.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily6-fill.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily7-fill.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily8-fill.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily9-fill.svg`)}  className="smily-rating-sm-img" />,<img alt="" src={require(`../../../../assets/images/report/smilyten/smily10-fill.svg`)}  className="smily-rating-sm-img" />] }
                        />
                    </div>

                    <div className="total-responses">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Total Responses</span>
                            <span className="subtitle">189</span>
                        </div>
                        <span className="checkbox-multi-card-icon"></span>
                    </div>

                    <div className="total-comments">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Total Comments</span>
                            <span className="subtitle">98</span>
                        </div>
                        <span className="checkbox-multi-card-icon"></span>
                    </div>

                </div>


                <div className="checkbox-multi-chart-wrap three-four">

                    <div className="response-statistics-chart">
                        <div className="response-statistics-header">
                            <p>Response statistics</p>
                        </div>
                        <div className="response-statistics-body">
                             <Bar data={barChartData} options={barChartOptions} height={250}/>
                        </div>
                    </div>

                    <div className="checkbox-multi-chart-donut">
                        <button type="button" className="header-download-graph"></button>
                         <Doughnut data={doughnutData} options={doughnutOptions} height={150} width={150}/>
                    </div>

                </div>
               

            </section>
        </React.Fragment>   
    )


}

export default SmileyCustomRating;