import React, { useState, useEffect, useContext } from 'react';
import './Survey.scss';
import 'react-circular-progressbar/dist/styles.css';
import EziLoader from 'components/EziLoader';
import Axios from 'utility/Axios';
import configURL from 'config/config';
import { toast } from 'react-toastify';
import AppContext from 'store/AppContext';
import RecentActivity from 'modules/company/Dashboard/RecentActivity';
import { SURVEY_DASHBOARD_CARDS } from "constants/constants";

const SurveyDashboard = (props) => {

    const { languageObj = {}, handleUnAuthWarn = {}, accesFeature = {} } = useContext(AppContext)
    const [loading, setLoading] = useState(false);
    const [arrCount, setArrCount] = useState([])
    const [countData, setCountData] = useState({
        total_surveys: 0,
        active_surveys: 0,
        active_survey_percentage: 0,
        inactive_surveys: 0,
        closed_surveys: 0
    });

    const [recentSurvey, setRecentSurvey] = useState();

    const handleCardClick = (status) => {
        props.history.push('survey-dashboard/category-survey-dashboard', {
            category_id: "",
            category_name: "All Categories",
            survey_status: status,
        });
    }

    const getDashboardInitials = () => {

        setLoading(true);
        Axios.post(configURL.survey_dashboard_details, {}).then(response => {
            setLoading(false)
            if (response.data.success !== undefined && response.data.success) {
                setCountData(response.data.results);
                let newdata = Object.values(response.data.results);
                setArrCount(newdata);
            } else {
                toast.warn(response.data.message);
            }
        })

    }

    const getRecentSurvey = () => {
        setLoading(true);
        Axios.post(configURL.get_dashboard_survey_highlights, {}).then(response => {
            setLoading(false)
            if (response.data.success !== undefined && response.data.success) {
                setRecentSurvey(response.data.results);
            } else {
                toast.warn(response.data.message);
            }
        })


    }

    useEffect(() => {
        getDashboardInitials();
        getRecentSurvey();
    }, [])

    const handleSurveyAnalyze = (surveyId) => {
        props.history.push(`/customer-experience/${surveyId}`, {
            survey_id: surveyId,
        });
    }

    return (
        <React.Fragment>
            {loading && <EziLoader />}
            <section className="Page-SurveyDashboard">
                <div className="st_heading">{languageObj.translate('SurveyDashboard.1')}</div>
                <div className="st_card-wrap">
                    {
                        SURVEY_DASHBOARD_CARDS.map((item, index) =>
                            <div key={item.id} className="st_card" onClick={() => handleCardClick(item.status)}>
                                <div className="st_card-icon-wrap">
                                    <span className={`st_card-icon ${item.card_icon}`}></span>
                                </div>
                                <div className="st_card-text-wrap">
                                    <span className="st_card-text">{item.card_name}</span>
                                    <span className="st_count">{arrCount[index]}</span>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="action-hightlight-card-wrap">
                    <div className="st_create-exsting-wrap">
                        <div className="st_action-card-wrap">
                            <div className={`st_action-card create-card ${accesFeature.create_survey || "access_lock"}`} onClick={() => {
                                if (accesFeature.create_survey) {
                                    props.history.push('/survey-dashboard/categories', { action: "add-new" });
                                } else {
                                    handleUnAuthWarn()
                                }

                            }}>
                                <div className="st_action-icon-wrap">
                                    <span className="st_action-icon"></span>
                                </div>
                                <label>Create New Survey</label>
                            </div>
                            <div className="st_action-card existing-card" onClick={() => {
                                props.history.push('survey-dashboard/category-survey-dashboard', {
                                    category_id: "",
                                    category_name: "All Categories",
                                });
                            }}>
                                <div className="st_action-icon-wrap">
                                    <span className="st_action-icon"></span>
                                </div>
                                <label>View Existing Survey</label>
                            </div>
                        </div>
                        <div className="recent-st_card">
                            <div className="recent-st_card-header">{languageObj.translate('RecentSurveys.1')}</div>
                            <div className="recent-st_card-body">
                                <ul>
                                    {
                                        recentSurvey && recentSurvey.recent_surveys.map((item, index) =>
                                            <li key={`recent-survey${index}`} className={item.status.toLowerCase()}>
                                                <span className="recent-st_index">{index + 1}</span>
                                                <span className="recent-st_name">{item.name}</span>
                                                <span className={`recent-surveys-status_st ${item.status.toLowerCase()}`}>{item.status.toLowerCase()}</span>
                                                <button type="button" className={`recent-st_report ${accesFeature.analyze_survey || " access_lock"}`} onClick={() => {
                                                    if (accesFeature.analyze_survey) {
                                                        handleSurveyAnalyze(item.id)
                                                    } else {
                                                        handleUnAuthWarn()
                                                    }
                                                }} disabled={(item.status.toLowerCase() === 'active' || item.status.toLowerCase() === 'close') ? false : true} >Analyse</button>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <RecentActivity type="survey" />
                </div>
            </section>
        </React.Fragment>

    )
}

export default SurveyDashboard;