import React, { useState, useEffect, useRef, useContext } from "react";
import { Tab, Nav } from 'react-bootstrap';
import Axios from "utility/Axios"
import configURL from 'config/config';
import { dateConvert } from 'utility/helper';
import DateTimePicker from 'react-datetime-picker';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import DropdownPlaceholder from './DropdownPlaceholder';
/** Editor Imports ends */
import { EMAIL_TEMPLATES, CHANNEL_PLACEHOLDERS, PUBLISHING_CHANNELS_TXT } from "constants/constants";
import { connect } from 'react-redux';
import * as AppAction from "store/actions";
import AppContext from "store/AppContext";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import InputPrompt from "components/InputPrompt";

const CommunicationChannel = ({ surveyAction, dispatchSelectedCount, selectedCount, dispatchChannels, surveyStatus, channelTypes }) => {
    let channelDispatcher = null;
    const { EziLoader } = useContext(AppContext)
    const [lastFocusInput, setLastFocusInput] = useState('message');
    const [editMeta, setEditMeta] = useState(false);
    const [startDateEmail, setStartDateEmail] = useState(new Date());
    const [startDateSMS, setStartDateSMS] = useState(new Date());
    const [selectedChannel, setSelectedChannel] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({ 'webEmail': '', 'webSms': '', });
    const [activeChannel, setActiveChannel] = useState('');
    const [showPrompt, setShowPrompt] = useState(false);
    const inputSubjectRef = useRef(null);
    const inputMessageRef = useRef(null);
    const [channelTemplates, setChannelTemplates] = useState(EMAIL_TEMPLATES)
    const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(""))))
    const urlParams = useParams();
    const [toggleEmail, setToggleEmail] = useState(false);
    const [toggleSms, setToggleSms] = useState(false);

    /**
     * Change Editor Data
     * @param {Object} editorState 
     */
    const onEditorStateChange = (editorState) => {
        handleInputChange({
            target: {
                name: "message",
                value: draftToHtml(convertToRaw(editorState.getCurrentContent()))
            }
        }, 'webEmail')
        setEditorState(editorState)
    }

    /**
     * Handle Variable insert on input
     */
    const insertVariable = (e) => {
        let inputRef = (lastFocusInput === 'subject') ? inputSubjectRef : inputMessageRef;
        let textToInsert = e.target.value;
        let cursorPosition = inputRef.current.selectionStart;
        let textBeforeCursorPosition = inputRef.current.value.substring(0, cursorPosition);
        let textAfterCursorPosition = inputRef.current.value.substring(cursorPosition, inputRef.current.value.length);
        let finalText = textBeforeCursorPosition + textToInsert + textAfterCursorPosition;
        let customEvent = { ...inputRef };
        customEvent.target = { 'value': finalText, 'name': inputRef.current.name };
        handleInputChange(customEvent, inputRef.current.getAttribute("data-channel"));
    }

    /**
     * Updated template subscription
     */
    useEffect(() => {
        handleDispatchTemplates()
    }, [channelTemplates, selectedTemplate, selectedChannel, startDateEmail, startDateSMS, toggleEmail, toggleSms])

    /**
     * Updated template subscription
     */
    useEffect(() => {
        getChannelTemplates()
    }, [channelTypes])

    /**
     * Get All Channel Templates
     */
    const getChannelTemplates = () => {
        EziLoader.show()
        let formData = new FormData();
        formData.append("survey_id", urlParams.survey_id);
        formData.append("isedit", (surveyAction === "edit") ? true : false);
        Axios.post(configURL.publishingSettingListing, formData).then(res => {
            EziLoader.hide()
            if (res.data.success !== undefined && res.data.success) {
                setChannelTemplates(res.data.data)
                if (surveyStatus === 'draft') {
                    dispatchSelectedCount(res.data.selectedparticipantcount)
                }
            } else {
                toast.warn(res.data.message || "Something went wrong.");
            }
        }).catch(err => {
            EziLoader.hide()
            toast.warn("Something went wrong.");
            console.log(err);
        })
    }

    /**
     * handle selected channel list
     */
    const handleCheckedChannel = (e) => {
        if (e.target.checked) {
            setActiveChannel(e.target.value);
            setSelectedChannel([...selectedChannel, e.target.value]);
        } else {
            let selectedChannelSet = new Set(selectedChannel);
            selectedChannelSet.delete(e.target.value);
            setSelectedChannel([...selectedChannelSet]);
        }
    }

    /**
     * Handle Template change
     * @param {Synthetic Event} e Input event
     * @param {String} tab selected tab
     */
    const changeTemplate = (e, tab) => {
        setSelectedTemplate({ ...selectedTemplate, [tab]: e.target.value });
        if (tab === 'webEmail' && channelTemplates.webEmail[e.target.value]) {
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(channelTemplates.webEmail[e.target.value]['message']))))
        }
    }

    /**
     * Handle Template content change
     * @param {Event} e 
     * @param {String} tab 
     */
    const handleInputChange = (e, tab) => {
        setChannelTemplates({
            ...channelTemplates, [tab]: {
                ...channelTemplates[tab], [selectedTemplate[tab]]: {
                    ...channelTemplates[tab][selectedTemplate[tab]], [e.target.name]: e.target.value
                }
            }
        });
    }

    /**
     * Channel data dispatch
     */
    const handleDispatchTemplates = () => {
        let channelData = getTemplatesObj()
        if (Object.keys(channelData).length > 0) {
            clearTimeout(channelDispatcher)
            setTimeout(() => {
                let channelData = getTemplatesObj()
                dispatchChannels(channelData)
            }, 300);
        }
    }

    /**
     * Get Selected templates and changed data
     */
    const getTemplatesObj = () => {
        let tempSurveySetting = JSON.parse(JSON.stringify(channelTemplates));
        if (tempSurveySetting.webEmail && selectedChannel.includes("webEmail")) {
            let templateNameEmail = (tempSurveySetting.webEmail[selectedTemplate.webEmail] && tempSurveySetting.webEmail[selectedTemplate.webEmail].name) ? tempSurveySetting.webEmail[selectedTemplate.webEmail].name : "";
            tempSurveySetting.webEmail = {
                'templateId': selectedTemplate.webEmail,
                'schedule_datetime': (toggleEmail === false) ? 'now' : dateConvert(startDateEmail),
                'name': templateNameEmail,
                ...channelTemplates.webEmail[selectedTemplate.webEmail]
            }

        }
        if (tempSurveySetting.webSms && selectedChannel.includes("webSms")) {
            let templateNameSms = (tempSurveySetting.webSms[selectedTemplate.webSms] && tempSurveySetting.webSms[selectedTemplate.webSms].name) ? tempSurveySetting.webSms[selectedTemplate.webSms].name : "";
            tempSurveySetting.webSms = {
                'templateId': selectedTemplate.webSms,
                'schedule_datetime': (toggleSms === false) ? 'now' : dateConvert(startDateSMS),
                'name': templateNameSms,
                ...channelTemplates.webSms[selectedTemplate.webSms]
            }
        }
        if (tempSurveySetting.webEmbed && selectedChannel.includes("webEmbed")) {
            tempSurveySetting.webEmbed = "Survey Embeded Code Generated"
        }
        if (tempSurveySetting.qrCode && selectedChannel.includes("qrCode")) {
            tempSurveySetting.qrCode = "qrCode"
        }
        const seleted = new Set(selectedChannel);
        [...new Set([...Object.keys(tempSurveySetting)].filter(x => !seleted.has(x)))].forEach(el => delete tempSurveySetting[el])
        return tempSurveySetting;

    }

    /**
     * Handle Save Template As new
     */
    const handleSaveNewTemplateEmail = (templateName) => {
        console.log(templateName);
        if (selectedTemplate.webEmail && channelTemplates.webEmail[selectedTemplate.webEmail]) {
            let data = channelTemplates.webEmail[selectedTemplate.webEmail];
            let formData = new FormData();
            formData.append("survey_id", urlParams.survey_id);
            formData.append("name", templateName);
            formData.append("body", data.message);
            formData.append("language", localStorage.getItem("i18nextLng"));
            formData.append("type", 'webEmail');
            formData.append("subject", data.subject);
            formData.append("from", data.from);

            Axios.post(configURL.saveTemplate, formData).then(res => {
                if (res.data.success !== undefined && res.data.success) {
                    toast.success(res.data.message || "Communication template saved");
                    getChannelTemplates()
                } else {
                    toast.warn(res.data.message || "Something went wrong.");
                }
            }).catch(err => {
                toast.warn("Something went wrong.");
                console.log(err);
            })
        }
    }

    const handleTemplateNameInput = (val) => {
        if (activeChannel === 'webEmail') {
            handleSaveNewTemplateEmail(val)
            setShowPrompt(false)
        }
    }

    const handleToggle = () => {
        if (activeChannel === 'webEmail') {
            setToggleEmail(!toggleEmail);
        }
        if (activeChannel === 'webSms') {
            setToggleSms(!toggleSms);
        }
    }
    return (
        <div className="channel-main-wrap">
            <InputPrompt
                show={showPrompt}
                alerttext="Please Enter Template Name"
                confirm={handleTemplateNameInput}
                onhide={() => setShowPrompt(false)}
            />
            <div className="channel-heading">Channels</div>
            <div className="channel-content">
                {
                    Object.keys(channelTemplates).map((item, index) =>
                        <div className="channel-checkbox-wrap" key={index}>
                            <label className="ezi-checkbox">
                                <input type="checkbox" value={item} checked={selectedChannel.includes(item)} onChange={(e) => handleCheckedChannel(e)} />
                                <span className="ezi-checkbox-mark"></span>
                            </label>
                            <p className="channel-name">{PUBLISHING_CHANNELS_TXT[item]}</p>
                        </div>
                    )
                }
                <div className={`main-channel-tab-wrap ${selectedChannel.length > 0 ? "" : "border-none"}`}>
                    <Tab.Container activeKey={activeChannel}>
                        <div className="survey-channel-tab-header">
                            <div className="tab-left-header">
                                <Nav variant="pills">
                                    {
                                        selectedChannel.includes('webEmail') &&
                                        <Nav.Item onClick={() => setActiveChannel('webEmail')}>
                                            <Nav.Link eventKey="webEmail">Web email</Nav.Link>
                                        </Nav.Item>
                                    }
                                    {
                                        selectedChannel.includes('webSms') &&
                                        <Nav.Item onClick={() => setActiveChannel('webSms')}>
                                            <Nav.Link eventKey="webSms">Web SMS</Nav.Link>
                                        </Nav.Item>
                                    }
                                    {
                                        selectedChannel.includes('webEmbed') &&
                                        <Nav.Item onClick={() => setActiveChannel('webEmbed')}>
                                            <Nav.Link eventKey="webEmbed">Web Embed</Nav.Link>
                                        </Nav.Item>
                                    }
                                    {
                                        selectedChannel.includes('qrCode') &&
                                        <Nav.Item onClick={() => setActiveChannel('qrCode')}>
                                            <Nav.Link eventKey="qrCode">QR Code</Nav.Link>
                                        </Nav.Item>
                                    }
                                </Nav>
                            </div>
                        </div>
                        {
                            (selectedChannel.length > 0) &&
                            <Tab.Content className="survey-channel-tab-content channel-tab-content">
                                <Tab.Pane eventKey="webEmail">
                                    <div>
                                        <select className="channel-select-mail" onChange={(e) => changeTemplate(e, 'webEmail')} value={selectedTemplate.webEmail}>
                                            <option value="" key="0">Select Email Template </option>
                                            {
                                                channelTemplates.webEmail && Object.keys(channelTemplates.webEmail).map(key =>
                                                    <option value={key} key={key} data-name={channelTemplates.webEmail[key]['name']}>{channelTemplates.webEmail[key]['name']}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    {
                                        (selectedTemplate.webEmail !== '') &&
                                        <React.Fragment>
                                            <div className="email-preview-wrap">
                                                <div className="email-preview-header">
                                                    <span className="email-preview-heading">Email Preview</span>
                                                    <div className="email-preview-right-header">
                                                        <button type="button" className="email-preview-edit" onClick={() => setEditMeta('webEmail')}>Edit</button>
                                                        <button type="button" className="email-preview-save" onClick={() => setShowPrompt(true)}>Save</button>
                                                    </div>
                                                </div>
                                                <div className="email-preview-content">
                                                    {
                                                        editMeta &&
                                                        <div className="placeholder-btn-wrap">
                                                            {
                                                                CHANNEL_PLACEHOLDERS.map((item) =>
                                                                    <div key={item.id} className="placeholder-btn-inner">
                                                                        <button type="button" className="insert-placeholder-btn" value={item.value} onClick={insertVariable}>{item.label}</button>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    }
                                                    <div className="channel-form-wrap">
                                                        <div className="channel-form-field">
                                                            <label>From:</label>
                                                            <input type="text" className={`email-preview-input ${editMeta == "webEmail" ? 'email-preview-input-edit' : ''}`} name="from" value={channelTemplates.webEmail[selectedTemplate.webEmail]['from']} onChange={(e) => handleInputChange(e, 'webEmail')} />
                                                        </div>
                                                        <div className="channel-form-field">
                                                            <label>To:</label>
                                                            <input type="text" className={`email-preview-input ${editMeta == "webEmail" ? 'email-preview-input-edit' : ''}`} readOnly name="to" value={`selected(${selectedCount})`} />
                                                        </div>
                                                        <div className="channel-form-field">
                                                            <label>Subject:</label>
                                                            <input type="text" ref={inputSubjectRef} data-channel="webEmail" className={`email-preview-input ${editMeta == "webEmail" ? 'email-preview-input-edit' : ''}`} name="subject" value={channelTemplates.webEmail[selectedTemplate.webEmail]['subject']} onFocus={() => setLastFocusInput('subject')} onChange={(e) => handleInputChange(e, 'webEmail')} />
                                                        </div>
                                                        <div className="channel-form-field">
                                                            <label>Message:</label>
                                                            <Editor
                                                                wrapperClassName="ezi-editor-wrapper"
                                                                editorClassName="ezi-editor-inner"
                                                                toolbarClassName="ezi-editor-toolbar"
                                                                editorState={editorState}
                                                                onEditorStateChange={onEditorStateChange}
                                                                toolbarCustomButtons={[<DropdownPlaceholder />]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="schedule-main-wrap">
                                                <div className="schedule-toggle-date">
                                                    <label className="schedule-label">Email Schedule  :</label>
                                                    <div className="schedule-toggle-text">Now<div className={`ezi-switch-toggle ${toggleEmail ? "on" : "off"}`} onClick={handleToggle} />Later
                                                        {toggleEmail && <div className="schedule-date-wrap">
                                                            <DateTimePicker
                                                                onChange={(date) => setStartDateEmail(date)}
                                                                value={startDateEmail}
                                                                clearIcon={null}
                                                                calendarIcon={null}
                                                                minDate={new Date()}
                                                            />
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="webSms">
                                    <div>
                                        <select className="channel-select-mail" onChange={(e) => changeTemplate(e, 'webSms')} value={selectedTemplate.webSms}>
                                            <option value="" key="0">Select SMS Template </option>
                                            {
                                                channelTemplates.webSms && Object.keys(channelTemplates.webSms).map(key =>
                                                    <option value={key} key={key} data-name={channelTemplates.webSms[key]['name']}>{channelTemplates.webSms[key]['name']}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    {
                                        (selectedTemplate.webSms !== '') &&
                                        <React.Fragment>
                                            <div className="email-preview-wrap">
                                                <div className="email-preview-header">
                                                    <span className="email-preview-heading">SMS Preview</span>
                                                    <button type="button" className="email-preview-edit" onClick={() => setEditMeta('webSms')}>Edit</button>
                                                </div>
                                                <div className="email-preview-content">
                                                    <div className="channel-form-wrap">
                                                        <div className="channel-form-field">
                                                            <label>From:</label>
                                                            <input type="text" className="email-preview-input" name="from" value={channelTemplates.webSms[selectedTemplate.webSms]['from']} onChange={(e) => handleInputChange(e, 'webSms')} />
                                                        </div>
                                                        <div className="channel-form-field">
                                                            <label>Message:</label>
                                                            <textarea maxLength="1500" className={`email-preview-textarea  ${editMeta == "webSms" ? 'email-preview-input-edit' : ''}`} name="message" rows="4" cols="50" onChange={(e) => handleInputChange(e, 'webSms')} value={channelTemplates.webSms[selectedTemplate.webSms]['message']} />
                                                        </div>
                                                    </div>
                                                    <span className="email-preview-length">{channelTemplates.webSms[selectedTemplate.webSms]['message'].length}/1500</span>
                                                </div>
                                            </div>
                                            <div className="schedule-main-wrap">

                                                <div className="schedule-toggle-date">
                                                    <label className="schedule-label">SMS Schedule :</label>
                                                    <div className="schedule-toggle-text">Now<div className={`ezi-switch-toggle ${toggleSms ? "on" : "off"}`} onClick={handleToggle} />Later
                                                            {toggleSms && <div className="schedule-date-wrap">
                                                            <DateTimePicker
                                                                onChange={(date) => setStartDateSMS(date)}
                                                                value={startDateSMS}
                                                                clearIcon={null}
                                                                calendarIcon={null}
                                                                minDate={new Date()}
                                                            />
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="webEmbed">
                                    <div className="email-preview-wrap">
                                        <div className="email-preview-content">
                                            <div className="channel-form-wrap">
                                                <p>Please Keep copy of below highlighted code and paste it on target website to embed survey</p>
                                                <div className="channel-form-field">
                                                    <code>
                                                        {(channelTemplates.webEmbed && channelTemplates.webEmbed['genrated_link']) ? `<script type='text/javascript'>window.onload = function() {var iframe = document.createElement('iframe');iframe.style.display = "block";iframe.style.width = "500";
                                                        iframe.style.height = "400";iframe.src = "${channelTemplates.webEmbed['genrated_link']}";document.body.appendChild(iframe);}</script>` : 'Web Embed Code Not Exists'}
                                                    </code>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="qrCode">
                                    <div className="email-preview-wrap">
                                        <div className="email-preview-content">
                                            <div className="channel-form-wrap">
                                                <div className="channel-form-field">
                                                    <p>QR Code Will be sent to email.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        }
                    </Tab.Container>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        surveyAction: state.survey.action,
        surveyStatus: state.survey.status,
        selectedCount: state.participant.selectedCount,
        channelTypes: state.participant.channelTypes
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatchSelectedCount: (count = 0) => dispatch(AppAction.updateSelectedCount({ count })),
        dispatchChannels: (channels = {}) => dispatch(AppAction.updateChannelData({ channels })),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationChannel);