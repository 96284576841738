import React, { useContext, useEffect, useState, useRef } from "react";
import './Roles.scss';
import { Breadcrumb } from 'react-bootstrap';
import AppContext from 'store/AppContext';
import Axios from "utility/Axios";
import configURL from "config/config";
import { toast } from "react-toastify";
import DataTable from "components/DataTable";
import SweetSearch from "components/SweetSearch";
import { confirmAlert } from "react-confirm-alert";

const Roles = (props) => {

    const { languageObj = {}, accesFeature = {}, handleUnAuthWarn, EziLoader } = useContext(AppContext)
    const [pagination, setPagination] = useState({})
    const [searchLoading, setSearchLoading] = useState(false)
    const [rolesData, setRolesData] = useState([])
    const [dataColumn, setDataColumn] = useState([]);
    const per_page = 10;
    const inputSearch = useRef(null);
    var searchTimer = null;

    const statusFormatter = (cell, row) => {
        return (
            <span title='Click to change status' className={`user-status_c ${row.status ? "active" : "offline"}`} onClick={() => updateStatus(row)}>{row.status ? "active" : "offline"}</span>
        );
    }

    const updateStatus = (row = {}) => {
        let statusLabel = row.status ? "Offline" : "Active";
        let statusData = row.status ? false : true;
        console.log(row);
        confirmAlert({
            title: 'Change Role Status',
            message: `Are you sure you want to "${statusLabel}" this Role ? `,
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        let formData = new FormData();
                        formData.append("status", statusData)
                        formData.append("role_id", row.id);
                        Axios.post(configURL.save_role, formData).then(res => {
                            if (res.data.success === true) {
                                toast.success(res.data.message || "Status Saved")
                                getRolesList();
                            } else {
                                toast.warn(res.data.message)
                            }
                        })
                    }
                },
                {
                    label: 'Cancel',
                }
            ]
        });

    }

    const handleDeleteRole = (row = {}) => {
        let formData = new FormData();
        formData.append("role_id", row.id)
        confirmAlert({
            title: 'Delete Role',
            message: "Are you sure you want to delete this ? ",
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        Axios.post(configURL.role_delete, formData).then(response => {
                            if (response.data.success === true) {
                                getRolesList();
                                toast.success(response.data.message)
                            } else {
                                toast.success(response.data.message)
                            }
                        })

                    }
                },
                {
                    label: 'Cancel',

                }
            ]
        });
    }
    const handleTableChange = (type, props) => {
        switch (type) {
            case "pagination":
                getRolesList(props.page);
                break;
        }

    }

    const getRolesList = (page = 1) => {
        let roleSearch = inputSearch.current.value;
        let formData = new FormData()
        formData.append("page", page)
        formData.append("per_page", per_page)
        formData.append("search", roleSearch)
        Axios.post(configURL.get_company_roles, formData).then(response => {
            EziLoader.hide();
            if (response.data.success === true) {
                const columnData = response.data.results.columns.map(item => {
                    if (item.dataField === "status") {
                        return {
                            ...item,
                            formatter: statusFormatter,
                        }
                    } else if (item.dataField === "id") {
                        return {
                            ...item,
                            hidden: true,
                        }
                    }
                    else {
                        return item;
                    }
                });
                columnData.push({
                    dataField: 'action', text: 'Action', headerFormatter: headingFormatter, formatter: actionFormatter
                });
                setDataColumn(columnData)
                setRolesData(response.data.results.data)
                setPagination(response.data.pagination)
                setSearchLoading(false)
            } else {
                toast.warn(response.data.message);
            }
        })
    }

    const handleTableSearch = () => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setSearchLoading(true);
            getRolesList();
        }, 500);
    }

    const headingFormatter = (column, colIndex) => {
        return (
            <div className="action-heading-name">Action</div>
        );
    }


    const actionFormatter = (cell, row) => {
        return (
            <div className="dt-bootstrap-edit-delte-wrap">
                <button type="button" className="dt-bootstrap-edit_ic" onClick={() => props.history.push(`/role/view/${row.id}`)}>View</button>
                <button type="button" className={`dt-bootstrap-delete_ic ${accesFeature.delete_role || "access_lock"}`} onClick={() => {
                    if (accesFeature.delete_role) {
                        handleDeleteRole(row)
                    } else {
                        handleUnAuthWarn()
                    }
                }} disabled={(row && row.is_default === true)} title={(row && row.is_default === true) ? 'Default Role' : ''}>Delete</button>
            </div>
        );
    }

    useEffect(() => {
        EziLoader.show();
        getRolesList();
    }, [])

    return (
        <React.Fragment>
            <section className="Page-Users" >
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item>{languageObj.translate('Settings.1')}</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => props.history.push("/users-roles")}>User Management</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="column-header">
                        <h1 className="page-heading">{languageObj.translate('Roles.1')}</h1>
                        <div className="column-header-btn">
                            <button type="button" className={`btn-ripple add-new ${accesFeature.add_role || "access_lock"}`} onClick={() => {
                                if (accesFeature.add_role) {
                                    props.history.push("/role/create-new")
                                } else {
                                    handleUnAuthWarn()
                                }
                            }}>
                                {languageObj.translate('AddnewRole.1')}
                                <span className="add_ic"></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="users-table-content">
                    <SweetSearch ref={inputSearch} change={handleTableSearch} loading={searchLoading} />
                    {(rolesData.length > 0 && dataColumn.length > 0) &&
                        <div className="saved_participants_table">
                            <DataTable
                                data={rolesData}
                                columns={dataColumn}
                                hideSelectAll
                                page={pagination.current_page}
                                sizePerPage={per_page}
                                onTableChange={handleTableChange}
                                keyField="id"
                                totalSize={pagination.total}
                                hideSelect
                            />
                        </div>
                    }
                    {(rolesData.length === 0) &&
                        <div className="saved_participants_table">
                            <DataTable data={[]} columns={[{}]} keyField="id" hideSelect />
                        </div>
                    }
                </div>
            </section>
        </React.Fragment>
    )
}

export default Roles;