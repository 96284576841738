import React, { useState, useEffect, useRef, useContext } from "react";
import './OnlineUpdate.scss';
import { Breadcrumb } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import configURL from 'config/config';
import Axios from "utility/Axios"
import { toast } from 'react-toastify';
import AppContext from 'store/AppContext';
import SweetSearch from "components/SweetSearch";
import EziLoader from "components/EziLoader";
import DemographicFilter from "components/DemographicFilter";

const OnlineUpdate = (props) => {

    const { languageObj = {} } = useContext(AppContext)
    const [rowData, setRowData] = useState([]);
    const [columns, setColumns] = useState([{}]);
    const [meta, setMeta] = useState({});
    const [search] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [sendCheckData, setSendCheckData] = useState([]);
    const [seletedEmpId, setSeletedEmpId] = useState([]);
    const [uniqueKey, setUniqueKey] = useState('employee_id');
    const [loading, setLoading] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [pagination, setPagination] = useState({});
    const [listingFilter, setListingFilter] = useState([]);
    const inputRef = useRef(null);
    var searchTimer = null;
    const [activeTab] = useState(props.match.path.split('/')[2]);

    const getPeopleListing = (pageno = 1, search = '') => {
        let formData = new FormData();
        formData.append("page", pageno);
        formData.append("search", search);
        formData.append("type", activeTab);
        formData.append("language", languageObj.curLang);
        formData.append("filters", JSON.stringify(listingFilter));
        Axios.post(configURL.peopleByType, formData).then(res => {
            setLoading(false)
            setSearchLoading(false);
            if (res.data.success !== undefined && res.data.success === true) {
                setRowData(res.data.data);
                setColumns(res.data.column);
                setPagination(res.data.pagination)
                setUniqueKey(res.data.unique_key);
            } else {
                setRowData([]);
                setColumns([]);
                setPagination({})
                setUniqueKey("");
            }
        })
    }

    const RemotePagination = ({ data, page = 1, sizePerPage, onTableChange, totalSize }) => (
        <div>
            <BootstrapTable
                remote
                keyField={(uniqueKey !== '') ? uniqueKey : 'employee_id'}
                data={data}
                noDataIndication="Table is Empty"
                cellEdit={cellEditFactory({
                    mode: 'click',
                    beforeSaveCell: (oldValue, newValue, row, column, done) => { updateRowData(oldValue, newValue, row, column); done(false); },
                })}
                columns={columns}
                pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true, })}
                onTableChange={onTableChange}
                selectRow={{ mode: 'checkbox', onSelect: handleSelect, selected: seletedEmpId, hideSelectAll: true }}
            />
        </div>
    )

    const handleTableChange = (type, { page, cellEdit }) => {
        if (type === 'pagination') {
            setLoading(true)
            getPeopleListing(page)
        }
        if (type === 'cellEdit') {

        }
    }

    const updateRowData = (oldValue, newValue, row, column) => {
        if (oldValue !== newValue) {
            row[column.dataField] = newValue;
            let objIndex = rowData.map((o) => o[uniqueKey]).indexOf(row[uniqueKey]);
            rowData[objIndex] = row;
            setRowData(rowData);
        }
    }

    const UpdateRows = () => {
        let formData = new FormData();
        formData.append("language", languageObj.curLang);
        let data = {
            'data': sendCheckData
        };
        formData.append("update", JSON.stringify(data));
        Axios.post(`${configURL.onlineUpdate}/${activeTab}`, formData).then(res => {
            if (res.data.success !== undefined && res.data.success) {
                res.data.sucess_message.map((msg) => {
                    toast.success(msg);
                })
                res.data.error_message.map((msg) => {
                    toast.error(msg);
                })
            } else {
                toast.error(res.data.message);
            }
        })
    }

    const makeCheckDataArr = (rowData) => {
        setSendCheckData([...sendCheckData, rowData]);
    }

    const removeUncheckDataObject = (rowData) => {
        let tempSendCheckData = sendCheckData;
        let objIndex = tempSendCheckData.map((o) => o[uniqueKey]).indexOf(rowData[uniqueKey]);
        tempSendCheckData.splice(objIndex, 1)
        setSendCheckData(tempSendCheckData);
    }

    useEffect(() => {
        setLoading(true)
        getPeopleListing(1);
    }, [listingFilter]);

    const handleSelect = (row, isSelect) => {
        if (isSelect) {
            makeCheckDataArr(row);
            setSeletedEmpId([...seletedEmpId, row[uniqueKey]]);
        } else {
            removeUncheckDataObject(row);
            let tempSeletedEmpIds = seletedEmpId;
            tempSeletedEmpIds.indexOf(row[uniqueKey]) !== -1 && tempSeletedEmpIds.splice(tempSeletedEmpIds.indexOf(row[uniqueKey]), 1)
            setSeletedEmpId([...tempSeletedEmpIds]);
        }
    }

    const searchFilter = (e) => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setSearchLoading(true);
            let value = inputRef.current.value;
            getPeopleListing(1, (value.length > 2) ? value : '');
        }, 600);
    }
    const handleApplyFilter = (filters) => {
        setShowFilter(false)
        setListingFilter(filters)

    }
    const handleClearFilter = () => {
        setShowFilter(false)
        setListingFilter([])
    }

    return (
        <React.Fragment>
            <section className="Page-OnlineUpdate">
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => props.history.push("/people-directory")}>
                            {languageObj.translate('PeopleDirectory.1')}
                        </Breadcrumb.Item>
                        {(() => {
                            switch (activeTab) {
                                case 'employee':
                                    return <Breadcrumb.Item onClick={() => props.history.push("/people-directory", {
                                        page_key: "employee"
                                    })}>{'Employee'}</Breadcrumb.Item>
                                case 'customer':
                                    return <Breadcrumb.Item onClick={() => props.history.push("/people-directory", {
                                        page_key: "customer"
                                    })}>{'Customer'}</Breadcrumb.Item>
                                case 'other':
                                    return <Breadcrumb.Item onClick={() => props.history.push("/people-directory", {
                                        page_key: "other_contact"
                                    })}>{'Other Contact'}</Breadcrumb.Item>
                            }
                        })()}
                        <Breadcrumb.Item>Online Update</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="column-header">
                        <h1 className="page-heading">{languageObj.translate('OnlineUpdate.1')}</h1>
                        <button type="button" className="btn-ripple bulk-update-save" onClick={UpdateRows}>{languageObj.translate('OnlineUpdate.1')}</button>
                    </div>
                </div>
                <div className="filter-search-wrap">
                    <div className="participant-fiter-wrap">
                        <button type="button" className="ezi-filter-btn" onClick={() => setShowFilter(!showFilter)} >Filter</button>
                        <DemographicFilter
                            applyFilter={handleApplyFilter}
                            clearFilter={handleClearFilter}
                            searchable={true}
                            show={showFilter}
                            filterType={activeTab}
                            hide={() => setShowFilter(false)}
                        />
                    </div>
                    <SweetSearch loading={searchLoading} change={searchFilter} ref={inputRef} />
                </div>
                <RemotePagination data={rowData} page={pagination.current_page || 1} sizePerPage={pagination.per_page || 10} totalSize={pagination.total} onTableChange={handleTableChange} />
                {loading && <EziLoader />}
            </section>
        </React.Fragment>
    )
}

export default OnlineUpdate;