import React, { useContext, useEffect, useState } from "react";
import AppContext from 'store/AppContext';
import EziLoader from "components/EziLoader";
import Axios from "utility/Axios";
import configURL from "config/config";
import { toast } from "react-toastify";
import DataTable from "components/DataTable";
import { useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';

const CommunicationHistory = (props) => {

    const { languageObj = {}, accesFeature = {}, handleUnAuthWarn } = useContext(AppContext)
    const [loading, setLoading] = useState(false)
    const [communicationData, setCommunicationData] = useState([])
    const [dataColumn, setDataColumn] = useState([]);
    const [metaData, setMetaData] = useState({
        total: 0,
        page: 1,
        per_page: 10
    });
    const urlParams = useParams()

    const buttonFormatter = (cell, row, rowIndex) => (
        <button type="button" className="table_delete_ic" disabled={(row.status.toLowerCase() === "complete")} title={(row.status.toLowerCase() === "complete") ? 'Communication Already Completed' : 'Remove Communication'} onClick={() => handleDeleteCommunication(row)}>Delete</button>
    )

    const statusFormatter = (cell, row) => {
        return (
            <span className={`history_status ${cell}_c`}>{cell}</span>
        );
    }

    const handleDeleteCommunication = (row) => {
        console.log(row)
        confirmAlert({
            title: 'Delete Communication',
            message: 'Are you sure you want to delete ?',
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        let formData = new FormData();
                        formData.append("communication_id", row.id);
                        formData.append("survey_id", urlParams.survey_id);
                        Axios.post(configURL.deleteCommunication, formData).then(res => {
                            if (res.data && res.data.success) {
                                toast.success(res.data.message || 'Data Deleted');
                                getCommunicationData()
                            } else {
                                toast.warn(res.data.message || 'Something went wrong');
                            }
                        }).catch(err => {
                            toast.error(err.toString());
                        })
                    }
                },
                {
                    label: 'Cancel'
                }
            ]
        });
    }

    /**
     * Handle Table Changes
     * @param {String} type 
     * @param {Object} props 
     */
    const handleTableChange = (type, props) => {
        setLoading(true)
        switch (type) {
            case "pagination":
                getCommunicationData(props.page);
                break;
        }

    }

    /**
     * Get Communication History
     * @param {Number} page 
     */
    const getCommunicationData = (page = 1) => {
        let formData = new FormData()
        formData.append("page", page)
        formData.append("survey_id", urlParams.survey_id)
        formData.append("per_page", metaData.per_page)
        Axios.post(configURL.communication_history, formData).then(response => {
            if (response.data.success === true) {
                setMetaData({ ...metaData, total: response.data.results.page_count, page: page })
                const columnData = response.data.results.column.map(item => {
                    if (item.dataField === "id") {
                        return {
                            ...item,
                            hidden: true,
                        }
                    }
                    else if (item.dataField === "status") {
                        return {
                            ...item,
                            formatter: statusFormatter,
                        }
                    }
                    else {
                        return item;
                    }
                });
                columnData.push({
                    headerFormatter: () => <div><span>Action</span></div>,
                    formatter: buttonFormatter
                });
                setDataColumn(columnData)
                setCommunicationData(response.data.results.data)
                setLoading(false)
            } else {
                toast.warn(response.data.message);
            }
        })
    }

    useEffect(() => {
        setLoading(true)
        getCommunicationData();
    }, [])

    return (
        <React.Fragment>
            <div className="users-table-content">
                {(dataColumn.length > 0) &&
                    <div className="saved_participants_table">
                        <DataTable
                            data={communicationData}
                            columns={dataColumn}
                            hideSelectAll
                            page={metaData.page}
                            sizePerPage={metaData.per_page}
                            onTableChange={handleTableChange}
                            keyField="id"
                            totalSize={metaData.total}
                            hideSelect
                        />
                    </div>
                }
            </div>
            {loading && <EziLoader />}
        </React.Fragment>
    )
}

export default CommunicationHistory;