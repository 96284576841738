export const PUBLISHING_CHANNELS_TXT = {
    webEmail: "Email Invite",
    webSms: "SMS Invite",
    emailEmbed: "Email Embed",
    webEmbed: "Web Embed",
    qrCode: "QR Code"
};
export const APP_ID = "8fac2b08-3ec3-40d1-b944-1e10bf8efce1";
export const APP_FIELD_NAME = 'application_id';
//export const GOOGLE_CAPTCHA_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
export const GOOGLE_CAPTCHA_KEY = '6LdipOYUAAAAAClshAbp8AQJ3VeU1cbE8p_BeWCQ';

export const PERMISSION_DATA = {
    survey: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'Surveys' },
    templates: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'Templates' },
    question_banks: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'Question Banks' },
    themes: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'Themes' },
    users: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'Users' },
    account_management: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'Users & Roles' },
    roles: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'Roles' },
    peoples: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'Peoples' },
    employee: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'Employees' },
    customer: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'Customers' },
    others: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'Others' },
    setting: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'Settings' },
    profiles: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'Profiles' },
    report_dashboard: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'Report Dashboard' },
    company_profile: { permissions: ["READ", "CREATE", "UPDATE", "DELETE"], label: 'Company Profile' }
}

export const EMAIL_TEMPLATES = {
    'webEmail': {
        '1': {
            'name': 'template 1',
            'from': 'outreach@ezi2survey.com',
            'subject': 'Hey Batman! We need your help!',
            'message': 'Dear [name]'
        },
        '2': {
            'name': 'template 2',
            'from': 'outreach@ezi2survey.com',
            'subject': 'Hey Batman! We need your help!',
            'message': 'Suspendisse aliquam dignissim dictum. Sed ut nulla dui, nec auctor mi. Nam magna nisi, luctus id iaculis in, dapibus vitae ligula. Donec et lacus velit. Maecenas at porta purus. Donec iaculis sagittis ante in pretium. Curabitur ipsum arcu, molestie a adipiscing consequat, cursus in leo. '
        },
    },

    'webSms': {
        '1': {
            'name': 'template 1',
            'from': 'AD-Ezi2Survey',
            'message': 'Suspendisse aliquam dignissim dictum. Sed ut nulla dui, nec auctor mi. Nam magna nisi, luctus id iaculis in, dapibus vitae ligula. Donec et lacus velit. Maecenas at porta purus. Donec iaculis sagittis ante in pretium. Curabitur ipsum arcu, molestie a adipi'
        },
        '2': {
            'name': 'template 2',
            'from': 'AD-Ezi2Survey',
            'message': 'Suspendisse aliquam dignissim dictum. Sed ut nulla dui, nec auctor mi. Nam magna nisi, luctus id iaculis in, dapibus vitae ligula. Donec et lacus velit. Maecenas at porta purus. Donec iaculis sagittis ante in pretium. Curabitur ipsum arcu, molestie a adipi'
        },
    },
}
export const CHANNEL_PLACEHOLDERS = [
    { id: 1, value: '[first_name]', label: 'First Name' },
    { id: 2, value: '[last_name]', label: 'Last Name' },
    { id: 3, value: '[survey_name]', label: 'Survey Name' },
    { id: 4, value: '[survey_link]', label: 'Survey Link' }
]

export const PERSONAL_IDENTIFIERS = {
    'employee': [
        { 'name': "employeeId", 'label': "Employee Id", },
        { 'name': "employeeFname", 'label': "first name", },
        { 'name': "employeeLname", 'label': "last name", },
        { 'name': "employeeEmail", 'label': "Email", },
        { 'name': "mobileNumber", 'label': "Phone number", },
    ],

    'customer': [
        { 'name': "customerId", 'label': "Customer Id", },
        { 'name': "customerFname", 'label': "Customer first name", },
        { 'name': "customerLname", 'label': "Customer last name", },
        { 'name': "email", 'label': "Email", },
        { 'name': "mobileNumber", 'label': "Phone number", },
    ],

    'other': [
        { 'name': "otherContactId", 'label': "Id", },
        { 'name': "otherContactFname", 'label': "First name", },
        { 'name': "otherContactLname", 'label': "Last name", },
        { 'name': "otherContactemail", 'label': "Email", },
        { 'name': "otherContactMobileNumber", 'label': "Phone number", },
    ],
};
export const PERSONAL_INPUT_TYPES = [
    {
        id: 1,
        value: 'dropdown',
        label: 'Select box',
    },
    {
        id: 2,
        value: 'radio',
        label: 'Radio',
    }, ,
    {
        id: 3,
        value: 'text',
        label: 'Text',
    },
    {
        id: 4,
        value: 'datetime',
        label: 'Date',
    },
]

export const SURVEY_DASHBOARD_CARDS = [
    { id: 1, card_name: 'Total Survey', data_label: "total_template_count", card_icon: 'template_ic', status: 'total' },
    { id: 2, card_name: 'Active Survey', data_label: "published_card_count", card_icon: 'publish_ic', status: 'active' },
    { id: 3, card_name: 'Inactive Survey', data_label: "unpublished_card_count", card_icon: 'template_ic', status: 'inactive' },
    { id: 4, card_name: 'Closed Survey', data_label: "published_question_count", card_icon: 'closed_ic', status: 'close' }
]

export const TEMPLATE_DASHBOARD_CARDS = [
    { id: 1, card_name: 'Total Templates', data_label: "total_template_count", card_icon: 'template_ic', status: "all" },
    { id: 2, card_name: 'Active Templates', data_label: "active_template_count", card_icon: 'publish_ic', status: "active" },
    { id: 3, card_name: 'Published', data_label: "published_template_count", card_icon: 'template_ic', status: "published" },
    { id: 4, card_name: 'Unpublished', data_label: "unpublished_template_count", card_icon: 'publish_ic', status: "unpublished" }
]