import React from 'react';

const Alerts = () =>{

    const HIGHLIGHT_CLASSES = ['report-alert-success', 'report-alert-info', 'report-alert-warning', 'report-alert-error'];

    return(

           
            <div className="report-alert-card">
                    <div className="report-alert-card-header">
                        <label className="report-alert-header-heading">Alert</label>
                        <button type="button" className="report-alert-clear-btn">Clear all</button>
                    </div>
                    <div className="report-alert-card-body">
                        <ul>
                            {
                                [...Array(20)].map((item, index) =>
                                    <li key={`report-alert${index}`}>
                                        <span className={`report-alert-card-msg-icon ${HIGHLIGHT_CLASSES[index % 4]}`} >1</span>
                                        <div className="report-alert-des-wrap">
                                            <span className="report-alert-card-des">You have successfully changed your password! Please Re-Login.</span>
                                            <span className="report-alert-card-time">4 hours ago</span>
                                        </div>
                                    </li>
                                )
                            }

                        </ul>
                    </div>
                </div>
            
        

    )


}

export default Alerts;