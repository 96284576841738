import * as actionsTypes from "store/actions/actionTypes";

const initalState = {
    addedParticipant: {
        customer: [],
        employee: [],
        other: []
    },
    participantSource: "",
    selectedOnEdit: [],
    selectedCount: 0,
    channelTypes: false,
    dynamicSourceFile: null,
    dynamicParticipantSourceType: "",
    channelTemplate: {}
}

const participantReducer = (state = initalState, action) => {
    switch (action.type) {
        case actionsTypes.SET_ADDED_PARTICIPANTS:
            return {
                ...state,
                addedParticipant: action.payload.participants
            }
        case actionsTypes.SET_PARTICIPANT_SOURCE:
            return {
                ...state,
                participantSource: action.payload.source
            }
        case actionsTypes.DYNAMIC_PARTICIPANT_SOURCE_TYPE:
            return {
                ...state,
                dynamicParticipantSourceType: action.payload.type
            }
        case actionsTypes.SET_PARTICIPANTS_ONEDIT:
            return {
                ...state,
                selectedOnEdit: action.payload.participants
            }
        case actionsTypes.SET_PARTICIPANTS_COUNT:
            return {
                ...state,
                selectedCount: action.payload.count
            }
        case actionsTypes.SET_CHANNEL_DATA:
            return {
                ...state,
                channelTemplate: action.payload.channels
            }
        case actionsTypes.RELOAD_CHANNEL_DATA:
            return {
                ...state,
                channelTypes: action.payload.type
            }
        case actionsTypes.SET_DYNAMIC_SOURCE_DATA:
            return {
                ...state,
                dynamicSourceFile: action.payload.file
            }
        case actionsTypes.RESET_STATE:
            return initalState
        default:
            return state
    }
}

export default participantReducer
