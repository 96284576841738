import React , { useState , useEffect, useRef , useContext } from 'react';
import { useParams } from "react-router-dom";
import Axios from "utility/Axios";
import configURL from 'config/config';
import { toast } from "react-toastify";
// import CommentFilter from './CommentFilter'
import CommentFilter from './ReportCommentFilter'
import SweetSearch from "components/SweetSearch";
import AppContext from 'store/AppContext';

const CommentSection = (props) => {
    const urlParams = useParams();
    const [ showFilter, setShowFilter] = useState(false);
    const [ surveyId , setSurveyId ] = useState(urlParams.survey_id)
    const  CHECKBOX_VALUE = [ 'All','Passive','Promoter','Detractors' ]
    const SMILY_CLASSES = ['smile', 'sad', 'very-poor', 'poor'];
    const [ commentList , setCommentList ] = useState([]);
    const [ commentInsightData , setCommentInsightData ] = useState(props.insightData);
    const [checkedItems, setCheckedItems] = useState(new Map());
    let inputSearch = useRef(null)
    let searchTimer = null
    const [searchLoading, setSearchLoading] = useState(false);
    const { EziLoader } = useContext(AppContext)
    const [ filterData, setFilterData] = useState(props.filterData);
    const [ dateFilter, setDateFilter] = useState(props.dateFilter);
    const handleChange = (event) => {
        console.log(event)
        setCheckedItems(checkedItems => checkedItems.set(event.target.name, event.target.checked));
    }
    const handleFilterSearch = () => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            commentListing();
        }, 1000);
    }
    const commentListing = ( filters = []) => {
        EziLoader.show();
        let formData = new FormData();
        formData.append("survey_id", surveyId);
        let search = inputSearch.current.value;
        formData.append("search", search);
        formData.append("question_type", commentInsightData.question_type);
        formData.append("question_id", commentInsightData.id);
        formData.append("comment_filters",JSON.stringify(filters)); 
        formData.append("filters",JSON.stringify(props.filterData));
        formData.append("date",JSON.stringify(props.dateFilter));
        Axios.post(`${configURL.ReportInsightComment}`, formData).then(response => {
            EziLoader.hide();
             if (response.data.success !== undefined && response.data.success) {                
                setCommentList(response.data.result.data);
                                  
            } else {
                toast.warn(response.data.message);
            }
        })
    }
useEffect(() => {   
    commentListing();
}, [props.filterData,props.dateFilter]); 
    return(           
        <React.Fragment>
            <div className="ce-comment-section">

                <div className="ce-comment-section-header">
                    <label className="commnet-label">Comments</label>
                    <div className="comment-right-header">
                         
                        {/* <input type="text" className="comment-header-search" placeholder="Search by keyword" change={handleFilterSearch} ref={inputSearch} /> */}
                        {/* <div className="comment-checkbox-wrap">
                            {
                                checkboxValue.map((item,index) =>  
                                    <div key={index} className="comment-checkbox-inner">
                                        <label className="ezi-checkbox">
                                            <input type="checkbox" />
                                            <span className="ezi-checkbox-mark"></span>
                                        </label>
                                        <span className="commnet-check-name">{item}</span>
                                    </div>                                  
                                )                                
                            }
                        </div> */}
                        <div className="comment-filter-btn-wrap">
                            <button type="button" className="ezi-filter-btn" onClick={() => setShowFilter(!showFilter)}>Filter</button>
                            <CommentFilter
                                show={showFilter}
                                hide={() => setShowFilter(false)}
                                position="right"
                                applyFilter={(filters) => commentListing( filters)}
                               
                            />
                        </div>
                        {/* <button type="button" className="comment-download">Download</button> */}
                        <SweetSearch loading={searchLoading} change={handleFilterSearch} ref={inputSearch} />
                    </div>
                </div>
                <label className="ce-comment-title">Please share any other comments to serve you better</label>
                <div className="ce-comment-body">
                    {

                            commentList && commentList.map((item,index)=>
                            <div className={`comment-box-emoji-wrap ${item.sentiment_score.toLowerCase()}`}>
                                <div  className="ce-comment-box">
                                    <div className="comment-box-text-wrap">
                                        <span className="comment-heading">{item.comments} </span>
                                        <div className="comment-badge-wrap">
                                             {item.key_phrases && item.key_phrases.map((key)=>  
                                               <span className="comment-badge">{key.Text}</span>
                                             )}
                                                {/* <span className="comment-badge">Variety</span> */}
                                        </div>
                                    </div>
                                    <div className="comment-box-right-text">
                                        <span className="comment-date-title">{(item.respondent_fname)}</span>
                                        <span className="comment-date">{item.sentiment_created_at} </span>
                                    </div>
                                </div>
                            </div>
                        )

                    }    
                </div>
            </div>
        </React.Fragment>
    )}

export default CommentSection;