import React, { useState, useContext } from "react";
import { Tab, Nav } from 'react-bootstrap';
import Axios from "utility/Axios"
import { useParams } from "react-router-dom";
import ParticipantSourceTab from './ParticipantSourceTab';
import configURL from 'config/config';
import { toast } from 'react-toastify';
import SavedParticipants from "./SavedParticipants";
import CommunicationHistory from "./CommunicationHistory";
import AppContext from 'store/AppContext';
import CommunicationChannel from "./CommunicationChannel";
import { connect } from 'react-redux';
import ParticipantTabHeader from "./ParticipantTabHeader";
import * as AppAction from "store/actions";

const ParticipantSetting = (props) => {
    const {
        surveyAction,
        surveyStatus,
        selectedParticipant,
        participantSource,
        selectedOnEdit,
        channelTemplate,
        dispatchSelectedOnEdit,
        dispatchSelectedCount,
        dispatchReloadChannel,
        dynamicSourceFile,
        dynamicParticipantSourceType
    } = props
    const { languageObj = {}, EziLoader } = useContext(AppContext)
    const [tabKey, setTabKey] = useState('general');
    const urlParams = useParams();
    const [surveySetting] = useState({});
    const [showCommunicate, setShowCommunicate] = useState(false);

    /**
     * Save Communication channel data
     */
    const saveChannelData = () => {
        let formData = new FormData();
        formData.append("survey_id", urlParams.survey_id);
        formData.append("template_json", JSON.stringify(channelTemplate));
        formData.append("isedit", (surveyAction === "edit") ? true : false);
        Axios.post(configURL.saveChannelTemplates, formData).then(res => {
            if (res.data.success !== undefined && res.data.success) {
                toast.info(res.data.message || "Survey Setting added.");
            } else {
                toast.warn(res.data.message || "Something went wrong.");
            }
        }).catch(err => {
            console.log(err);
        })
    }

    /**
     * Save Participant Data.
     */
    const saveParticipentData = () => {
        if (!participantSource || participantSource === "") {
            toast.warn('Please choose participant source')
            return;
        }
        if (participantSource === 'dynamic_participant' && dynamicParticipantSourceType === 'upload' && !dynamicSourceFile) {
            toast.warn('Please upload dynamic source data.')
            return;
        }
        if (participantSource === 'dynamic_participant' && dynamicParticipantSourceType === '') {
            toast.warn('Please upload or add single dynamic participants data.')
            return;
        }
        EziLoader.show();
        let formData = new FormData();
        formData.append("survey_id", urlParams.survey_id);
        formData.append("people_ids", JSON.stringify(selectedParticipant));
        formData.append("survey_setting", JSON.stringify(surveySetting));
        formData.append("isedit", (surveyAction === "edit") ? true : false);
        formData.append("participant_source", participantSource);

        if (participantSource === 'dynamic_participant') {
            formData.append("dynamic_participant_source_type", dynamicParticipantSourceType);
        }
        if (participantSource === 'dynamic_participant' && dynamicParticipantSourceType === 'upload') {
            formData.append("dynamic_participant_data", dynamicSourceFile);
        }

        Axios.post(configURL.addParticipantsToSurvey, formData).then(res => {
            if (res.data.success !== undefined && res.data.success) {
                toast.info(res.data.message || "Setting Saved");
                dispatchReloadChannel(participantSource)
            } else {
                toast.warn(res.data.message || "Something went wrong.");
            }
            EziLoader.hide();
        }).catch(err => {
            EziLoader.hide()
            console.log(err);
        })
    }

    /**
     * Save Participant data on Edit survey
     */
    const handleCommunicate = () => {
        EziLoader.show()
        let formData = new FormData();
        formData.append("survey_id", urlParams.survey_id);
        formData.append("participants_id", JSON.stringify(selectedOnEdit));
        formData.append("channel_template", JSON.stringify(channelTemplate));
        formData.append("isedit", (surveyAction === "edit") ? true : false);

        Axios.post(configURL.save_communicate_api, formData).then(res => {
            EziLoader.hide()
            if (res.data.success !== undefined && res.data.success) {
                dispatchSelectedOnEdit([]);
                dispatchSelectedCount(0)
                toast.info(res.data.message || "Participant saved !!");
            } else {
                toast.warn(res.data.message);
            }
        }).catch(err => {
            EziLoader.hide()
        });
    }

    /**
     * Save Participant data
     */
    const handleSaveData = () => {
        if (surveyAction === "edit" && surveyStatus !== "draft" && tabKey === 'channels') {
            handleCommunicate()
        } else {
            switch (tabKey) {
                case 'channels':
                    saveChannelData();
                    break;
                case 'general':
                    saveParticipentData();
                    break;
            }
        }
    }

    return (
        <div className="tablist_ezi survey-inner-tab-container">
            <Tab.Container activeKey={tabKey} onSelect={k => setTabKey(k)}>
                <div className="survey-inner-tab-header-wrap">
                    <div className="tab-left-header">
                        <Nav variant="pills" >
                            <Nav.Item>
                                <Nav.Link eventKey="general">{languageObj.translate('General.1')}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="participants"> {languageObj.translate('Participants.1')}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="channels">{languageObj.translate('Channels.1')}</Nav.Link>
                            </Nav.Item>
                            {(participantSource !== 'nosource') && <Nav.Item>
                                <Nav.Link eventKey="communication">{languageObj.translate('CommunicationHistory.1')}</Nav.Link>
                            </Nav.Item>}
                        </Nav>
                    </div>
                    <div className="tab-right-header">
                        <ParticipantTabHeader
                            saveData={handleSaveData}
                            communicate={() => {
                                setTabKey('channels')
                                setShowCommunicate(false)
                            }}
                            activeTab={tabKey}
                            showCommunicate={showCommunicate}
                        />
                    </div>
                </div>
                <Tab.Content className="survey-inner-tab-content">
                    <Tab.Pane eventKey="general" mountOnEnter>
                        <ParticipantSourceTab />
                    </Tab.Pane>
                    <Tab.Pane eventKey="participants" mountOnEnter unmountOnExit>
                        <SavedParticipants />
                    </Tab.Pane>
                    <Tab.Pane eventKey="channels" mountOnEnter>
                        <CommunicationChannel />
                    </Tab.Pane>
                    <Tab.Pane eventKey="communication" mountOnEnter unmountOnExit>
                        <CommunicationHistory />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        surveyAction: state.survey.action,
        surveyStatus: state.survey.status,
        selectedParticipant: state.participant.addedParticipant,
        participantSource: state.participant.participantSource,
        selectedOnEdit: state.participant.selectedOnEdit,
        channelTemplate: state.participant.channelTemplate,
        dynamicSourceFile: state.participant.dynamicSourceFile,
        dynamicParticipantSourceType: state.participant.dynamicParticipantSourceType
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatchSelectedOnEdit: (data = []) => dispatch(AppAction.setSelectedOnEdit({ participants: data })),
        dispatchSelectedCount: (count = 0) => dispatch(AppAction.updateSelectedCount({ count })),
        dispatchReloadChannel: (type) => dispatch(AppAction.reloadChannels({ type })),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantSetting);