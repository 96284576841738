import React, { Component, Fragment } from 'react';
import './Dashboard.scss';
import Loader from 'components/EziLoader';
import RecentCards from "./RecentCard"
import DashboardHead from './DashboardHead';
import Axios from 'utility/Axios';
import configURL from 'config/config';
import GreetUser from './GreetUser';
import AppContext from 'store/AppContext';
import { toast } from 'react-toastify';


class Dashboard extends Component {
    state = {
        loading: false,
        headerData: null,
        recentCards: null,
    }
    static contextType = AppContext;

    componentDidMount() {
        this.setState({ loading: true })
        Axios.post(configURL.get_dashboard_count_graphs, {}).then(response => {
            if (response.data.success !== undefined && response.data.success == true) {
                this.setState({
                    headerData: response.data.results
                })
            }
        })
        Axios.post(configURL.get_dashboard_survey_highlights, {}).then(response => {
            this.setState({ loading: false })
            if (response.data.success !== undefined && response.data.success == true) {
                this.setState({
                    recentCards: response.data.results
                })
            }
        }).catch(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const { languageObj = {} } = this.context
        return (
            <Fragment>
                <section className="Page-Dashboard">
                    <div className="column-header">
                        <GreetUser />
                        <p className="dashboard-subtitle">{languageObj.translate('Dashboard.1')}</p>
                    </div>
                    <div className="dashboard-col-wrap">
                        <RecentCards recentCards={this.state.recentCards} />
                        <DashboardHead headerData={this.state.headerData} />
                    </div>
                </section>
                {this.state.loading && <Loader />}


            </Fragment>
        )
    }
}
export default Dashboard; 