import * as Survey from "survey-knockout";
import './Widget.scss';
import { uniqueGenerator } from "utility/helper";

var widget = {
    name: "smily",
    title: "Happiness Index",
    iconName: "",
    widgetIsLoaded: function () {
        return true;
    },
    isFit: function (question) {
        return question.getType() === 'smily';
    },
    activatedByChanged: function () {
        Survey.JsonObject.metaData.addClass("smily", [], null, "radiogroup");
    },
    isDefaultRender: false,
    htmlTemplate: "<div class='smily-wrapper'> <fieldset> <div class='smily-inner-wrapper'> <div class='smily-inner'> <div class='smily-icon-wrapper'> <input class='smily-radio' type='radio' name='smilyradio' value='loving' /> <span class='smily-icon very-poor'></span> <div class='smily-name'>Loving</div> </div> </div> <div class='smily-inner'> <div class='smily-icon-wrapper'> <input class='smily-radio' type='radio' name='smilyradio' value='happy' /> <span class='smily-icon poor'></span> <div class='smily-name'>Happy</div> </div> </div> <div class='smily-inner'> <div class='smily-icon-wrapper'> <input class='smily-radio' type='radio' name='smilyradio' value='angry' /> <span class='smily-icon average'></span> <div class='smily-name'>Angry</div> </div> </div> <div class='smily-inner'> <div class='smily-icon-wrapper'> <input class='smily-radio' type='radio' name='smilyradio' value='sad' /> <span class='smily-icon good'></span> <div class='smily-name'>Sad</div> </div> </div> <div class='smily-inner'> <div class='smily-icon-wrapper'> <input class='smily-radio' type='radio' name='smilyradio' value='boring' /> <span class='smily-icon excellent'></span> <div class='smily-name'>Boring</div> </div> </div> </div> <fieldset/> </div>",
    afterRender: function (question, el) {
        var elements = el.getElementsByTagName("input");
        var text = el.getElementsByTagName("fieldset")[0];
        text.inputType = question.inputType;
        question.value = "";

        var matches = text.querySelectorAll("input.smily-radio");
        var elName = "smilyradio_" + uniqueGenerator();
        for (let index = 0; index < matches.length; index++) {
            matches[index].name = elName
        }

        text.onchange = function () {
            for (var i = 0, l = elements.length; i < l; i++) {
                if (elements[i].checked) {
                    question.value = elements[i].value;
                }
            }
        }
        var onValueChangedCallback = function () {
            for (var i = 0, l = elements.length; i < l; i++) {
                if (elements[i].checked) {
                    question.value = elements[i].value;
                }
            }
        }
        var onReadOnlyChangedCallback = function () {
            if (question.isReadOnly) {
                text.setAttribute('disabled', 'disabled');
            } else {
                text.removeAttribute("disabled");
            }
        };
        question.readOnlyChangedCallback = onReadOnlyChangedCallback;
        question.valueChangedCallback = onValueChangedCallback;
        onValueChangedCallback();
        onReadOnlyChangedCallback();

    },
    willUnmount: function (question, el) {
    }
}
Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");
export default widget;
