import React from "react";
import {  Modal  } from 'react-bootstrap';

function FilterModal(props) {
  

    return (  
         

            <Modal {...props} animation={true} className="ezi-right-animated-modal" backdropClassName="ezi-right-modal-backdrop" >
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h1>Filter Modal</h1>
                </Modal.Body>
            </Modal>     
     
    );
  }

  
  export default FilterModal;