import React, { useState } from "react";
import Axios from "utility/Axios";
import configURL from 'config/config';
import useForm from 'react-hook-form';
import EziLoader from 'components/EziLoader';
import { useTranslation } from 'react-i18next';
import 'utility/i18next.js';
import { toast } from "react-toastify";

const CategoryAdd = (props) => {
    const [translate] = useTranslation();
    const [sendData, setsendData] = useState({
        category_name: '',
        description: '',
        type: "survey"
    });
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = data => {
        let formData = new FormData();
        data.type = "survey";
        let sendData = { "data": data }
        formData.append("insertcategory", JSON.stringify(sendData));
        setLoading(true);
        Axios.post(configURL.categorySave, formData).then(res => {
            setLoading(false);
            if (res.data.success !== undefined && res.data.success) {
                toast.success(res.data.message);
                props.history.push("/survey-dashboard/categories", {
                    action: "add-new"
                });
            } else {
                toast.warn(res.data.message);
            }
        })
    };

    const inputChangeHandler = (e) => {
        const value = e.target.value;
        const name = e.target.getAttribute('name');
        setsendData({ ...sendData, [name]: value });
    }

    return (
        <React.Fragment>
            <section className="Page-CategoryAdd">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="survey-category-wrap">
                        <h1 className="category_label">{translate('CreateNewCategory.1')}</h1>
                        <div className="category-field-control">
                            <input type="text" name="category_name" ref={register({ required: true })} placeholder={translate('Typecategoryname.1')} className="create-category-input" onChange={(e) => inputChangeHandler(e)} />
                            {errors.category_name && <span className="error-message">{translate('Categorynameisrequired.1')}</span>}
                        </div>
                        <div className="category-field-control">
                            <textarea rows="4" name="description" ref={register({ required: true })} className="create-category-input" placeholder={translate('Entercategorydescription.1')} onChange={(e) => inputChangeHandler(e)}></textarea>
                            {errors.description && <span className="error-message">{translate('Descriptionisrequired.1')}</span>}
                        </div>
                        <div className="category_add_btn_wrap">
                            <button type="submit" className="btn-ripple category_add_btn">{translate('Create.1')}</button>
                        </div>
                    </div>
                </form>
                {loading && <EziLoader />}
            </section>
        </React.Fragment>
    )
}

export default CategoryAdd;