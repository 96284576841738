import React, { PureComponent } from "react";
import * as SurveyJSCreator from "survey-creator";
import * as SurveyKo from "survey-knockout";
import "survey-creator/survey-creator.css";
import "./SurveyCreator.scss";
import "jquery-ui/themes/base/all.css";
import "select2/dist/css/select2.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import "jquery-bar-rating/dist/themes/css-stars.css";
import "jquery-bar-rating/dist/themes/fontawesome-stars.css";
import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker.js";
import "select2/dist/js/select2.js";
import "jquery-bar-rating";
import "pretty-checkbox/dist/pretty-checkbox.css";
import * as widgets from "surveyjs-widgets";
import Axios from "utility/Axios";
import thumbsupwidget from "lib/survey-widget/ThumbsWidget";
import smilywidget from "lib/survey-widget/SmilyWidget";
import CSAT from "lib/survey-widget/CSATWidget";
import PersonalInfoText from "lib/survey-widget/PersonalInfoText";
import { toast } from 'react-toastify';
import configURL from 'config/config';
import SurveyCreatorTab from './SurveyCreatorTab';
import * as CONFIG from "./Constants";
import { SURVEY_LANG_STRINGS } from "constants/SurveyConstants";
import { confirmAlert } from 'react-confirm-alert';
import ErrorBoundary from "components/ErrorBoundary";
import ThemeMappingModal from './ThemeMappingModal';
import AppContext from 'store/AppContext';
import ChecklistModal from "./ChecklistModal";
import { connect } from "react-redux";
import { uniqueGenerator, surveyQuestionModifier, canSaveBuilderQuestions, getIdentifierQuestion, getPersonalQuestion } from "utility/helper";
import * as AppActions from "store/actions";

/**Custom  widgets**/
widgets.prettycheckbox(SurveyKo);
widgets.select2(SurveyKo, $);
widgets.inputmask(SurveyKo);
widgets.jquerybarrating(SurveyKo, $);
widgets.jqueryuidatepicker(SurveyKo, $);
widgets.select2tagbox(SurveyKo, $);
widgets.sortablejs(SurveyKo);
widgets.ckeditor(SurveyKo);
widgets.autocomplete(SurveyKo, $);
widgets.bootstrapslider(SurveyKo);
/**Custom  widgets end**/
var surveyPreviewModel = null
class SurveyCreator extends PureComponent {
	surveyCreator;
	isTranTabInitialised = false
	state = {
		survey_json: {},
		canSaveQuestion: false,
		survey_locales: [],
		canEditQuestion: true
	}
	inputSearchTimer = null;
	static contextType = AppContext;

	initSurveyBuilder = () => {
		let surveyStatus = this.props.surveyState.status
		this.setLaunchable(this.state.survey_json)
		if (surveyStatus === 'active' || surveyStatus === 'close') {
			confirmAlert({
				title: 'Survey Alert',
				message: `Survey is ${surveyStatus} you will not be able to change questions and survey dates.`,
				buttons: [{ label: 'Okay' }, { label: 'Go Back', onClick: () => this.props.history.goBack() }]
			});
		}
		this.renderSurveyCreater()
	}

	getQuestionsData = async () => {
		let formData = new FormData();
		formData.append("created_survey_id", this.props.match.params.survey_id);
		const questionsData = await Axios.post(configURL.getSurveyQustions, formData);
		if (questionsData.data.success !== undefined && questionsData.data.success === true) {
			let questions = questionsData.data.survey_json;
			let locales = [...new Set(questionsData.data.survey_locales.filter(el => el !== "")), '']
			this.setState({
				survey_json: (questions !== null) ? questions : null,
				survey_locales: locales,
				canEditQuestion: (questionsData.data.is_question_editable !== undefined) ? questionsData.data.is_question_editable : true,
			}, this.initSurveyBuilder)
			this.props.dispatchSurveyName({ surveyName: questionsData.data.survey_name })
		}
	}

	componentDidMount() {
		this.context.EziLoader.show()
		this.props.dispatchResetSurveyState()
		let surveyStatus = (this.props.location.state && this.props.location.state.status) || "draft"
		let surveyAction = (this.props.location.state && this.props.location.state.action) || "create"
		this.props.dispatchMeta({ action: surveyAction, status: surveyStatus })
		this.fetchCategoryData();
		this.getQuestionsData();
	}

	renderSurveyCreater = () => {
		let surveyStatus = this.props.surveyState.status
		for (let key in CONFIG.TOOLBAR_NAMES) {
			SurveyJSCreator.editorLocalization.getLocale(SurveyJSCreator.editorLocalization.currentLocale).ed[key] = CONFIG.TOOLBAR_NAMES[key];
		}
		/**Change Template Text */
		SurveyJSCreator.editorLocalization.getLocale(SurveyJSCreator.editorLocalization.currentLocale).ed.designer = "Survey Designer";
		SurveyJSCreator.editorLocalization.getLocale(SurveyJSCreator.editorLocalization.currentLocale).ed.logic = "Survey Logic";
		SurveyJSCreator.editorLocalization.getLocale(SurveyJSCreator.editorLocalization.currentLocale).ed.testSurvey = "Preview Survey";
		/** Change Template Text End */
		this.surveyCreator = new SurveyJSCreator.SurveyCreator("surveyCreatorContainer", CONFIG.BUILDER_OPTIONS);
		let toolBoxItems = this.surveyCreator.toolbox.items;
		this.surveyCreator.toolbox.orderedQuestions = CONFIG.TOOLBOX_ORDERS;
		for (let key in this.surveyCreator.toolbox.items) {
			if (toolBoxItems.hasOwnProperty(key)) {
				if (CONFIG.TOOLBOX_NAME.hasOwnProperty(toolBoxItems[key]['name'])) {
					this.surveyCreator.toolbox.replaceItem(toolBoxItems[key]);
					toolBoxItems[key]['title'] = CONFIG.TOOLBOX_NAME[toolBoxItems[key]['name']];
					toolBoxItems[key]['tooltip'] = CONFIG.TOOLBOX_NAME[toolBoxItems[key]['name']];
				}
			}
		}
		SurveyKo.JsonObject.metaData.addProperty("question", "id");
		SurveyKo.JsonObject.metaData.addProperty("question", "question_type");
		this.surveyCreator.onElementDoubleClick.add(function (sender, options) {
			sender.showQuestionEditor(options.element);
		});
		this.surveyCreator.onQuestionAdded.add(function (sender, options) {
			if (options.question.getType() === "rating") {
				options.question.rateMin = 0;
				options.question.rateMax = 10;
				options.question.minRateDescription = "Most unlikely";
				options.question.maxRateDescription = "Most likely";
			}
			options.question.id = uniqueGenerator()
			options.question.question_type = CONFIG.QUESTION_TYPES[options.question.getType()] || options.question.getType()
		});
		this.surveyCreator.saveSurveyFunc = this.saveMySurvey;
		this.surveyCreator.onActiveTabChanged.add((sender, options) => {
			if (options.tabName == "translation") {
				if (!this.isTranTabInitialised) {
					sender.translation.setSelectedLocales(this.state.survey_locales);
					this.isTranTabInitialised = true;
				}
			}
		});
		SurveyKo.Serializer.addProperty("matrix", {
			name: "Types",
			title: "Types",
			choices: Object.keys(CONFIG.LIKERT_CHOICES),
		});
		SurveyKo.Serializer.removeProperty("focusFirstQuestionAutomatic ", "visibleIf");
		SurveyKo.Serializer.addProperty("matrix", {
			name: "Options",
			title: "Options",
			dependsOn: "Types",
			choices: function (obj) {
				var entity = !!obj ? obj.Types : null;
				if (entity != null) {
					return Object.keys(CONFIG.LIKERT_CHOICES[entity]);
				}
				else {
					return [];
				}
			}
		});

		SurveyKo.JsonObject.metaData.addProperty("html", { name: "isStartPage:switch", default: false });
		SurveyJSCreator.SurveyQuestionEditorDefinition.definition.question.properties.push("isStartPage");

		SurveyKo.JsonObject.metaData.addProperty("personalinfo", { name: "isUnique:switch", default: false });
		SurveyJSCreator.SurveyQuestionEditorDefinition.definition.question.properties.push("isUnique");
		SurveyJSCreator.SurveyQuestionEditorDefinition.definition.question.properties.push("Types");
		SurveyJSCreator.SurveyQuestionEditorDefinition.definition.question.properties.push("Options");
		this.surveyCreator.onPropertyValueChanging.add(function (sender, options) {
			if (options.propertyName === "Options") {
				options.obj.columns = CONFIG.LIKERT_CHOICES[options.obj.Types][options.newValue];
			}
		});

		this.surveyCreator.toolbarItems.push({
			id: "thememapping",
			visible: true,
			title: "Theme Mapping",
			action: () => {
				if (surveyStatus === 'draft' || surveyStatus === 'inactive') {
					this.props.dispatchShowThemeMap()
				} else {
					toast.warn(`Survey status is ${surveyStatus}!`)
				}
			}
		});
		this.surveyCreator.toolbarItems().reverse();
		this.surveyCreator.toolbox.items.forEach((item, i) => {
			let toolNode = item;
			let nodeIconName = toolNode.iconName;
			toolNode.iconName = `${nodeIconName}_custom`;
			this.surveyCreator.toolbox.replaceItem(toolNode);
		});
		//for hide property tab
		function makeTabsInvisible(tabs, names) {
			for (let i = 0; i < tabs.length; i++) {
				if (names.indexOf(tabs[i].name) > -1) {
					tabs[i].visible = false;
				}
			}
		}
		makeTabsInvisible(SurveyJSCreator.SurveyQuestionEditorDefinition.definition["survey"].tabs, ["logic", "data", "validation"]);
		this.surveyCreator.rightContainerActiveItem("toolbox");
		this.surveyCreator.hideAdvancedSettings = true;
		//tranlation tab new language add for hindi
		SurveyKo.surveyLocalization.locales[CONFIG.tranlationLanguage.hindiText] = CONFIG.mySurveyStringsHindi;
		SurveyKo.surveyLocalization.localeNames[CONFIG.tranlationLanguage.hindiText] = CONFIG.tranlationLanguage.hindiText;
		// tranlation tab new language add for marathi
		SurveyKo.surveyLocalization.locales[CONFIG.tranlationLanguage.marathiText] = CONFIG.mySurveyStringsMarathi;
		SurveyKo.surveyLocalization.localeNames[CONFIG.tranlationLanguage.marathiText] = CONFIG.tranlationLanguage.marathiText;
		SurveyKo.Serializer.findProperty("survey", "mode").visible = false;
		SurveyKo.Serializer.findProperty("question", "question_type").visible = false;
		SurveyKo.Serializer.findProperty("question", "id").visible = false;
		SurveyKo.Serializer.findProperty("question", "name").visible = false;
		SurveyKo.Serializer.findProperty("page", "name").visible = false;
		SurveyKo.Serializer.findProperty("survey", "cookieName").visible = false;
		SurveyKo.Serializer.findProperty("survey", "focusFirstQuestionAutomatic").visible = false;
		/** -----Init Base Languages--- */
		Object.keys(SURVEY_LANG_STRINGS).forEach(el => {
			SurveyKo.surveyLocalization.locales[el] = SURVEY_LANG_STRINGS[el]
			SurveyKo.surveyLocalization.localeNames[el] = el;
		})
		//tab name changes navigation section	
		let locales = SurveyJSCreator.defaultStrings;
		locales.pe.firstPageIsStarted = "First page as welcome page";
		SurveyJSCreator.StylesManager.ThemeColors["default"] = CONFIG.BUILDER_THEME
		SurveyJSCreator.StylesManager.applyTheme("default");
		let isReadOnly = (surveyStatus === 'active' || surveyStatus === 'close' || !this.state.canEditQuestion)
		this.surveyCreator.readOnly = isReadOnly
		this.surveyCreator.text = (this.state.survey_json) ? JSON.stringify(this.state.survey_json) : null;
		this.context.EziLoader.hide()
	}

	fetchCategoryData = () => {
		let formData = new FormData();
		formData.append("category_id", this.props.match.params.category_id);
		Axios.post(configURL.categoryViewData, formData).then(res => {
			if (res.data.success !== undefined && res.data.success) {
				this.props.dispatchCategory({ categoryName: res.data.category_name })
			}
		})
	}

	changeSurveyLocale = (lang) => {
		surveyPreviewModel.locale = lang
		this.props.dispatchsSurveyLocale(lang)
	}

	previewSurvey = () => {
		let question_json = JSON.parse(this.surveyCreator.text);
		let questionsData = surveyQuestionModifier(question_json)
		let previewQuestions = JSON.stringify(questionsData)
		surveyPreviewModel = new SurveyKo.Model(previewQuestions);
		let locales = this.isTranTabInitialised ? this.surveyCreator.translation.getSelectedLocales() : this.state.survey_locales
		this.setSelectedLocales(locales)
		surveyPreviewModel.locale = this.props.surveyState.selectedLocale
		surveyPreviewModel.onComplete.add((result) => {
			console.log(result.data)
		});
		surveyPreviewModel.render("surveyPreview");
	}

	handleSaveBuilderData = () => {
		let locales = this.isTranTabInitialised ? this.surveyCreator.translation.getSelectedLocales() : this.state.survey_locales
		let formData = new FormData();
		let identifier_questions = getIdentifierQuestion(this.state.survey_json)
		let personal_questions = getPersonalQuestion(this.state.survey_json)
		formData.append("survey_id", this.props.match.params.survey_id);
		formData.append("survey_json", JSON.stringify(this.state.survey_json));
		formData.append("survey_name", this.props.surveyState.surveyName);
		formData.append("identifier_questions", JSON.stringify(identifier_questions));
		formData.append("personal_questions", JSON.stringify(personal_questions));
		formData.append("survey_locales", JSON.stringify(locales));
		Axios.post(configURL.saveSurveyQustions, formData)
	}

	setLaunchable = (questionsData = null) => {
		let canLaunch = canSaveBuilderQuestions(questionsData)
		this.props.setLaunchable(canLaunch)
	}

	setSelectedLocales = (locales = []) => {
		let usedLocales = []
		usedLocales = locales.filter(item => item !== "").map(el => {
			return {
				id: el,
				name: SurveyKo.surveyLocalization.localeNames[el] || "Default"
			}
		})
		usedLocales.push({ id: "", name: "English" })
		this.props.dispatchsSurveyLocales(usedLocales)
	}

	saveMySurvey = () => {
		if (!this.state.canSaveQuestion) {
			this.setState({ canSaveQuestion: true })
			return;
		}
		let question_json = JSON.parse(this.surveyCreator.text);
		let questionsData = surveyQuestionModifier(question_json)
		this.setLaunchable(question_json)
		this.setState({ survey_json: questionsData })
		if (this.state.canSaveQuestion) {
			clearTimeout(this.inputSearchTimer);
			this.inputSearchTimer = setTimeout(this.handleSaveBuilderData, 500);
		}
	}

	render() {
		return (
			<ErrorBoundary>
				<ChecklistModal />
				<section className="Page-SurveyCreator">
					<ThemeMappingModal id={this.props.match.params.survey_id} questionType="survey" />
					<SurveyCreatorTab previewSurvey={this.previewSurvey} changeLocale={this.changeSurveyLocale} />
				</section>
			</ErrorBoundary>
		);
	}
}
const mapStateToProps = state => {
	return {
		surveyState: state.survey
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		dispatchCategory: (data) => dispatch(AppActions.setSurveyCategory(data)),
		dispatchSurveyName: (data) => dispatch(AppActions.setSurveyName(data)),
		dispatchMeta: (data) => dispatch(AppActions.setSurveyMeta(data)),
		dispatchShowThemeMap: () => dispatch(AppActions.viewThemeMap({ view: true })),
		setLaunchable: (flag) => dispatch(AppActions.launchableSurvey({ launchable: flag })),
		dispatchResetSurveyState: () => dispatch(AppActions.resetSurveyState()),
		dispatchsSurveyLocales: (locales) => dispatch(AppActions.setSurveyLocales({ locales: locales })),
		dispatchsSurveyLocale: (locale) => dispatch(AppActions.setSelectedLocale({ locale }))
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(SurveyCreator);