
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import appReducer from "store/reducers/appReducer";
import surveyReducer from "store/reducers/surveyReducer";
import participantReducer from "store/reducers/participantReducer";
import templateReducer from "store/reducers/templateReducer";

const rootReducer = combineReducers({
    app: appReducer,
    survey: surveyReducer,
    participant: participantReducer,
    template: templateReducer,
})
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers());

export default store;