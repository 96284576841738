import React, { useState, useEffect, useContext } from "react";
import './Profiles.scss';
import { Breadcrumb } from 'react-bootstrap';
import Axios from 'utility/Axios';
import AppContext from 'store/AppContext';
import Skeleton from 'components/Skeleton';
import configURL from "config/config";
import { toast } from "react-toastify";
import NoResult from "components/NoResult";
import { APP_ID } from "constants/constants";
import { confirmAlert } from "react-confirm-alert";

const Profiles = (props) => {

    const { languageObj = {}, accesFeature = {}, handleUnAuthWarn } = useContext(AppContext)
    const [profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(false);

    const getProfiles = (page = 1) => {
        setLoading(true)
        let formData = new FormData()
        formData.append("page", page)
        formData.append("application_id", APP_ID)
        Axios.post(configURL.get_company_profiles, formData).then(response => {
            setLoading(false)
            if (response.data.success === true) {
                setProfiles(response.data.results.data)
            } else {
                toast.warn(response.data.message);
            }
        }).catch(() => {
            setLoading(false)
        })
    }

    const handleDeleteProfile = (id = null) => {
        let formData = new FormData();
        formData.append("profile_id", id)
        confirmAlert({
            title: 'Delete Profile',
            message: "Are you sure you want to delete this ? ",
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        Axios.post(configURL.profile_delete, formData).then(response => {
                            if (response.data.success === true) {
                                toast.success(response.data.message)
                                const updatedProfiles = [...profiles].filter(item => item.id !== id);
                                setProfiles(updatedProfiles)
                            } else {
                                toast.success(response.data.message)
                            }
                        })
                    },
                },
                {
                    label: 'Cancel',

                }
            ]
        });
    }

    useEffect(() => {
        getProfiles();
    }, []);

    return (
        <React.Fragment>
            <section className="Page-Profiles" >
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item>{languageObj.translate('Settings.1')}</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => props.history.push("/users-roles")}>User Management</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="column-header">
                        <h1 className="page-heading">{languageObj.translate('Profiles.1')}</h1>
                        <div className="column-header-btn">
                            <button type="button" className={`btn-ripple add-new ${accesFeature.add_profile || "access_lock"}`} onClick={() => {
                                if (accesFeature.add_profile) {
                                    props.history.push('/profile/create')
                                } else {
                                    handleUnAuthWarn()
                                }
                            }}>
                                {languageObj.translate('Addnew.1')}
                                <span className="add_ic"></span>
                            </button>
                        </div>
                    </div>
                </div>
                {loading ?
                    <Skeleton value={8} />
                    :
                    <div className="profile-card-wrap">
                        {profiles.length > 0 ? profiles.map((item, index) => (
                            <div className="profile-card" key={item.id} onClick={() => props.history.push('/profile/edit/' + item.id)}>
                                {!item.is_default && <span className={`profile-card-delte_ic ${accesFeature.delete_profile || 'access_lock'}`} onClick={(e) => {
                                    e.stopPropagation()
                                    if (accesFeature.delete_profile) {
                                        handleDeleteProfile(item.id)
                                    } else {
                                        handleUnAuthWarn()
                                    }
                                }}></span>}
                                <label className="profile-card-label">{item.name || ""}</label>
                                <p className="profile-card-des">{(item.description) && item.description.substr(0, 120) || ""}</p>
                            </div>
                        )) : <NoResult />}
                    </div>
                }
            </section>
        </React.Fragment>
    )
}

export default Profiles;