import React, { useState, useEffect, useContext } from "react";
import Axios from "utility/Axios";
import configURL from 'config/config';
import useForm from 'react-hook-form';
import { Spinner, Breadcrumb } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from 'react-select';
import { outlineRemove } from 'utility/helper';
import AppContext from 'store/AppContext';

const TemplateAdd = (props) => {
    const { languageObj = {}, handleUnAuthWarn = {}, accesFeature = {}, EziLoader } = useContext(AppContext)
    const [templateData, setTemplateData] = useState({ name: '', description: '', iseditable: false });
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, errors } = useForm();
    const [reportType, setReportType] = useState(null);
    const [reportTypeErr, setReportTypeErr] = useState(false);
    const [reportTypes, setReportTypes] = useState([]);
    const actionType = props.location.state.action || "create-template";
    const templateId = props.match.params.template_id || null;
    const categoryId = props.match.params.category_id || null;
    const categoryName = props.location.state.category_name || null;

    /**
     * Save Template Data.
     * 
     * @param {object} data 
     */
    const handleFormSubmit = data => {
        if (!reportType) {
            setReportTypeErr(true)
            return;
        }
        setLoading(true);
        let formData = new FormData();
        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("iseditable", data.iseditable);
        formData.append("category_id", categoryId);
        formData.append("report_type_id", reportType.value);
        formData.append("report_type_name", reportType.label);
        if (actionType === 'clone-template' && templateId) {
            formData.append("reference_template_id", templateId);
        }
        if (actionType === 'edit-template' && templateId !== null && templateId !== undefined && templateId !== "") {
            formData.append("template_id", templateId);
        }
        Axios.post(configURL.partner_save_template_url, formData).then(res => {
            setLoading(false);
            if (res.data.success !== undefined && res.data.success) {
                toast.success(res.data.message);
                let template_id;
                switch (actionType) {
                    case 'edit-template':
                        template_id = templateId
                        break;
                    case 'clone-template':
                        template_id = res.data.results.template_id || ""
                        break;
                    case 'create-template':
                        template_id = res.data.results.template_id || ""
                        break;
                }
                props.history.push("/partner-template-dashboard/template-questions/" + categoryId + "/" + template_id, {
                    action: actionType,
                });
            } else {
                toast.warn(res.data.message);
            }
        })
    };

    /**
     * Change Template state.
     * 
     * @param {object} event Object Event 
     */
    const inputChangeHandler = ({ target }) => {
        setTemplateData({ ...templateData, [target.name]: target.value });
    }

    const getReportTypes = async () => {
        let formData = new FormData()
        formData.append("report_type", "partner")
        let response = await Axios.post(configURL.getReportTypes, formData)
        if (response.data.success) {
            let typesData = [];
            response.data.results.forEach(element => {
                typesData.push({
                    value: element.id,
                    label: element.name
                })
            });
            setReportTypes(typesData)
        }
    }
    useEffect(() => {
        getReportTypes();
        if (templateId !== null && (actionType === "edit-template" || actionType === 'clone-template')) {
            EziLoader.show()
            let formData = new FormData();
            formData.append("template_id", templateId)
            Axios.post(configURL.partner_get_template_details_url, formData).then(response => {
                EziLoader.hide()
                if (response.data.success !== undefined && response.data.success == true) {
                    setTemplateData(
                        {
                            name: response.data.results.name || null,
                            description: response.data.results.description || null,
                            iseditable: response.data.results.iseditable
                        }
                    );
                    if (response.data.results.report_type_id && response.data.results.report_type_name) {
                        setReportType({
                            value: response.data.results.report_type_id || null,
                            label: response.data.results.report_type_name || null
                        })
                    }
                } else {
                    toast.warn(response.data.message);
                }
            }).catch(err => {
                console.log(err);
                EziLoader.hide()
            })
        }
    }, [])

    return (
        <React.Fragment>
            <section className="Page-AddCategory">
                <div className="breadcrumb_ezi template-add-beadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => props.history.push("/template-dashboard")}>
                            {languageObj.translate('Template.1')}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => props.history.goBack()}>{categoryName}</Breadcrumb.Item>
                        <Breadcrumb.Item>{(actionType === "edit-template" || actionType === "clone-template") ? templateData.name : "Create New"}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="survey-category-wrap">
                        <h1 className="category_label">
                            {(actionType === "create-template") && "Create New Survey Template"}
                            {(actionType === "edit-template") && "Edit Template"}
                            {(actionType === "clone-template") && "Copy Template"}
                        </h1>
                        <div className="category-field-control">
                            <input type="text" name="name" defaultValue={templateData.name} ref={register({ required: true })} placeholder="Template Name" className="create-category-input" onChange={(e) => inputChangeHandler(e)} />
                            {errors.name && <span className="error-message">{languageObj.translate('templateNameErr.1')}</span>}
                        </div>
                        <div className="category-field-control">
                            <Select
                                ref={register}
                                className="ezi-select-plugin"
                                options={reportTypes}
                                value={reportType}
                                styles={outlineRemove}
                                placeholder="Report Type"
                                onChange={(selectedOption) => {
                                    setReportType(selectedOption)
                                    setReportTypeErr(false)
                                }}
                            />
                            {reportTypeErr && <span className="error-message">Please select report type.</span>}
                        </div>
                        <div className="category-field-control">
                            <textarea defaultValue={templateData.description} rows="4" name="description" ref={register} className="create-category-input" placeholder="Template Description" onChange={(e) => inputChangeHandler(e)}></textarea>
                            {errors.description && <span className="error-message">{languageObj.translate('templateDescErr')}</span>}
                        </div>
                        <div className="category-field-control">
                            <label className="global-checkbox">Can Modify
                            <input type="checkbox" ref={register} name="iseditable" checked={templateData.iseditable} onChange={({ target }) => {
                                    setTemplateData({
                                        ...templateData,
                                        iseditable: target.checked ? true : false
                                    })
                                }} />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="category_add_btn_wrap">
                            <button type="submit" className="btn-ripple category_add_btn" disabled={loading}>
                                {(actionType === "create-template") && "Create Template & Continue"}
                                {(actionType === "edit-template") && "Save Template & Continue"}
                                {(actionType === "clone-template") && "Create As New Template"}
                                {loading && <Spinner animation="grow" variant="success" />}
                            </button>
                        </div>
                    </div>
                </form>
            </section>
        </React.Fragment>
    )
}

export default TemplateAdd;