import React , { useState } from 'react';
import { Bar , Doughnut } from 'react-chartjs-2';
import Rating from 'react-rating';

const StarRatingMatrix = () => {

    const barChartData = {
        labels: ["Quality of Product", "Speed" , "Cleanliness" ],
        datasets: [
            {
                label: "first",
                backgroundColor: '#5A4E63',
                borderColor: '#5A4E63',
                data: [40, 25, 15 ],
                offsetGridLines : false,
            },
            {
                label: "Second",
                backgroundColor: '#CE2E6C',
                borderColor: '#CE2E6C',
                data: [40, 25, 15 ],
                offsetGridLines : false,
            }
         
        ],
    };

    
    const barChartOptions = {
        responsive: true,   
        maintainAspectRatio: false,
        legend: {
            display: false,
            align:'start',
            position:'top',
            labels: {
                fontColor: '#5A4E63',
                boxWidth: 6,
                fontSize:12,
                fontFamily :"NunitoSansSemiBold",
                padding	:20,
                usePointStyle:true,
            }
        },
        scales: {
            xAxes: [{
                stacked: true, 
                barPercentage: 0.25,
                categoryPercentage: 0.4,
                ticks: { fontColor: "#373737",fontFamily: "NunitoSans", fontSize: 12, stepSize: 6, beginAtZero: true,},
                gridLines: { color: "transparent",zeroLineColor: 'transparent',}, 
            }],

            yAxes: [{
                stacked: true, 
                ticks: { fontColor: "#5B5B5B",fontFamily: "NunitoSans", fontSize: 12, stepSize: 10, beginAtZero: true , padding: 15},
                 gridLines: { color: '#EDEDED',  zeroLineColor: '#EDEDED',borderColor:"transparent",   drawBorder: false, } }]
             }
        
    };

    const doughnutData = {
        labels: ["Positive Response", "Negative Response" ],
        datasets: [{
            data: [70 ,30],
            backgroundColor: [ '#CE2E6C', '#5A4E63'],
        }],
        text: 'Cleanliness'
    } 

    const doughnutOptions = {
        segmentShowStroke: false,
        responsive: true, 
        maintainAspectRatio: false,  
        rotation: 1 * Math.PI,
        circumference: 1 * Math.PI,
        legend: {
            display: false,
        },
        cutoutPercentage: 80,
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        tooltips: {
            displayColors: false,
            backgroundColor: "#5A4E63",
            enabled: true,
            mode: "single",
            bodyFontSize: 13,
            bodyFontFamily: "NunitoSansSemiBold",
            bodyFontColor: "#FFFFFF",
            yPadding: 8,
            xPadding: 15,
            cornerRadius: 5,
          },
    }

    const responseDoughnutData = {
        labels: ["1 Star", "2 Star", "3 Star", "4 Star", "5 Star"],
        datasets: [{
            data: [25,20,25,20,20],
            backgroundColor: [ '#CE2E6C', '#5A4E63', '#32CBBB' , '#32A6CB' , '#FFBD24'],
        }],
        text: 'Cleanliness'
    } 

    const responseDoughnutOptions = {
        segmentShowStroke: false,
        responsive: false, 
        maintainAspectRatio: false,  
        legend: {
            display: false,
        },
        cutoutPercentage: 75,
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        tooltips: {
            displayColors: false,
            backgroundColor: "#5A4E63",
            enabled: true,
            mode: "single",
            bodyFontSize: 13,
            bodyFontFamily: "NunitoSansSemiBold",
            bodyFontColor: "#FFFFFF",
            yPadding: 8,
            xPadding: 15,
            cornerRadius: 5,
          },
    }


    const resSmallDoughnutData = {
        labels: ["1 Star", "2 Star"],
        datasets: [{
            data: [70 ,30],
            backgroundColor: [ '#32A6CB', '#5A4E63'],
        }],
        text: 'Cleanliness'
    } 

    const resSmallDoughnutOptions = {
        segmentShowStroke: false,
        responsive: false, 
        maintainAspectRatio: false,  
        legend: {
            display: false,
        },
        cutoutPercentage: 45,
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        tooltips: {
            displayColors: false,
            backgroundColor: "#5A4E63",
            enabled: true,
            mode: "single",
            bodyFontSize: 13,
            bodyFontFamily: "NunitoSansSemiBold",
            bodyFontColor: "#FFFFFF",
            yPadding: 8,
            xPadding: 15,
            cornerRadius: 5,
          },
    }


    const themeChartData = {
        labels: ["1 Star", "2 Star", "3 Star", "4 Star", "5 Star"],
        datasets: [
            {
                label: "",
                backgroundColor: [ '#CE2E6C', '#5A4E63', '#32CBBB' , '#32A6CB' , '#FFBD24'],
                borderColor: '#CE2E6C',
                data: [40, 25, 15 ,30,40],
                offsetGridLines : false,
            }
        ]
    };

    
    const themeChartOptions = {
        responsive: true,   
        maintainAspectRatio: false,
        legend: {
            display: false,
            align:'start',
            position:'top',
            labels: {
                fontColor: '#5A4E63',
                boxWidth: 6,
                fontSize:12,
                fontFamily :"NunitoSansSemiBold",
                padding	:20,
                usePointStyle:true,
            }
        },
        scales: {
            xAxes: [{
                barPercentage: 0.8,
                categoryPercentage: 0.4,
                ticks: { fontColor: "#373737",fontFamily: "NunitoSans", fontSize: 12, stepSize: 6, beginAtZero: true,},
                gridLines: { color: "transparent",zeroLineColor: 'transparent',}, 
            }],

            yAxes: [{
                ticks: { fontColor: "#5B5B5B",fontFamily: "NunitoSans", fontSize: 12, stepSize: 10, beginAtZero: true , padding: 15},
                 gridLines: { color: '#EDEDED',  zeroLineColor: '#EDEDED',borderColor:"transparent",   drawBorder: false, } }]
             }
        
    };

    return(           
        <React.Fragment>
            <section className="Common-QuestionComponent Page-StarRatingMatrix">
                <div className="question-type-wrap">
                    <label className="question"><span className="question-no">Q</span><span>[5]</span>How would you rate your recent hotel experience?</label>
                    <div className="question-type star-rating">Star Rating Simple Matrix</div>
                </div>

                <div className="checkbox-multi-card-wrap">

                  
                    <div className="overall-star-rating">
                        <div className="overall-star-rating-text">
                            <span className="star-rate">3.5/5</span>
                            <span className="star-rate-response">155 response</span>
                        </div>
                        <Rating
                          readonly
                        placeholderRating={3.5}
                        emptySymbol={  <img alt="" src={require(`../../../../assets/images/report/rating-blank-star.svg`)}  className="rating-icon" />}
                        placeholderSymbol={<img alt="" src={require(`../../../../assets/images/report/rating-fill-star.svg`)}  className="rating-icon" />}
                        fullSymbol={<img alt="" src={require(`../../../../assets/images/report/rating-fill-star.svg`)}  className="rating-icon" />}
                        />
                    </div>

                    <div className="total-responses">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Total Responses</span>
                            <span className="subtitle">189</span>
                        </div>
                        <span className="checkbox-multi-card-icon"></span>
                    </div>

                    <div className="total-comments">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Total Comments</span>
                            <span className="subtitle">98</span>
                        </div>
                        <span className="checkbox-multi-card-icon"></span>
                    </div>

                </div>


                <div className="star-rating-matrix-box">


                    <div className="checkbox-multi-chart-wrap star-rating-matrix-left-box">  


                        <div className="checkbox-multi-chart-donut-wrap">
                            <div className="response-statistics-header category_statistics">
                                <p>Cleanliness</p>
                                <button type="button" className="header-download-graph"></button>
                            </div>                            
                            <div className="checkbox-multi-chart-donut">
                                <Doughnut data={doughnutData} options={doughnutOptions} height={250} width={250}/>                           
                            </div>
                            <div className="res-top-text">
                                <span className="res-negative-txt">Most Negative</span>
                                <span className="res-positive-txt">Most Positive</span>
                            </div>
                            <div className="response-percent-text-wrap">
                                <div className="negative-percent-wrap">
                                    <span className="title">33.55%</span>
                                    <span className="subtitle">Negative response</span>
                                </div>
                                <div className="positive-percent-wrap">
                                    <span className="title">66.45%</span>
                                    <span className="subtitle">Positive response</span>
                                </div>
                            </div>
                        </div>

                        <div className="response-statistics-chart">
                            <div className="response-statistics-header">
                            <div className="postive-negative-text-wrap">
                                    <span className="negative-text">Negative responses</span>
                                    <span className="positive-text">Positive responses</span>
                            </div>
                                <div className="select-dowload-wrap">
                                    <button type="button" className="header-download-graph"></button>
                                </div>
                            </div>
                            <div className="response-statistics-body">
                                <Bar data={barChartData} options={barChartOptions} height={250}/>
                            </div>
                        </div> 

                    </div>

                    <div className="star-rating-matrix-right-box">
                        <button type="button" className="header-download-graph"></button>
                        <div className="star-matrix-full-donut-wrap">
                                <div className="star-matrix-full-donut">
                                    <Doughnut data={responseDoughnutData} options={responseDoughnutOptions} height={150} width={150}/>
                                    <span className="title">Cleanliness</span>
                                    <span className="subtitle">329 response</span>
                                </div>
                                <div className="star-matrix-small-donut">                                      
                                    <Doughnut data={resSmallDoughnutData} options={resSmallDoughnutOptions} height={60} width={60}/>
                                    <span className="res-small-title">Quality of product</span>
                                    <Doughnut data={resSmallDoughnutData} options={resSmallDoughnutOptions} height={60} width={60}/>
                                    <span className="res-small-title">Cleanliness</span>
                                    <Doughnut data={resSmallDoughnutData} options={resSmallDoughnutOptions} height={60} width={60}/>
                                    <span className="res-small-title">Speed</span>
                                </div>
                        </div>

                        <div className="star-matrix-theme-chart">
                            <Bar data={themeChartData} options={themeChartOptions} height={250}/>
                        </div>

                    </div>

                </div>

               

            </section>
        </React.Fragment>   
    )


}

export default StarRatingMatrix;