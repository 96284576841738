import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Axios from "utility/Axios";
import configURL from 'config/config';
import useForm from 'react-hook-form';
import { Spinner } from 'react-bootstrap';
import { Breadcrumb } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from 'react-select';
import { outlineRemove } from 'utility/helper';
import AppContext from 'store/AppContext';


const SurveyAdd = (props) => {
    let { history } = props;
    let { category_id } = useParams();
    const [loading, setLoading] = useState(false);
    const [reportType, setReportType] = useState(null);
    const [reportTypeErr, setReportTypeErr] = useState(false);
    const [surveyData, setSurveyData] = useState({ survey_name: "", survey_description: "" });
    const [reportTypes, setReportTypes] = useState([]);
    const [isCloned, setIsCloned] = useState(false);
    const { register, handleSubmit, errors } = useForm();
    const { languageObj = {}, EziLoader } = useContext(AppContext)

    const createSurvey = () => {
        if (!reportType) {
            setReportTypeErr(true)
            return;
        }
        let apiUrl = configURL.createSurvey;
        let formData = new FormData();
        formData.append("name", surveyData.survey_name);
        formData.append("description", surveyData.survey_description);
        formData.append("report_type_id", reportType.value);
        formData.append("report_type_name", reportType.label);
        formData.append("category_id", category_id);
        if (props.location.state.template_id !== undefined && props.location.state.template_id !== "") {
            formData.append("template_id", props.location.state.template_id);
        }
        if (props.location.state.survey_id !== undefined && props.location.state.survey_id !== "") {
            apiUrl = configURL.copy_survey;
            formData.append("survey_id", props.location.state.survey_id);
        }
        setLoading(true);
        Axios.post(apiUrl, formData).then(res => {
            setLoading(false);
            if (res.data.success !== undefined && res.data.success === true) {
                toast.success(res.data.message || "Survey Saved");
                history.push(`/survey-dashboard/survey-builder/${category_id}/${res.data.data.id}`, {
                    surveyName: res.data.data.name,
                    action: "create",
                    status: "draft",
                    template_id: props.location.state.template_id || null
                });
            } else {
                toast.warn(res.data.message)
            }
        })
    }

    const getReportTypes = async () => {
        let formData = new FormData()
        formData.append("report_type", "company")
        let response = await Axios.post(configURL.getReportTypes, formData)
        if (response.data.success) {
            let typesData = [];
            response.data.results.forEach(element => {
                typesData.push({ value: element.id, label: element.name })
            });
            setReportTypes(typesData)
        }
    }

    const handleFormChange = ({ target }) => {
        let name = target.name;
        let value = target.value;
        setSurveyData({
            ...surveyData,
            [name]: value
        })
    }

    const isTypeDisabled = () => {
        if (props.location.state.template_id !== undefined && props.location.state.template_id !== "") {
            return true
        }
        if (props.location.state.survey_id !== undefined && props.location.state.survey_id !== "") {
            return true
        }
        return false
    }

    const getSurveyDetail = () => {
        EziLoader.show();
        let formData = new FormData();
        formData.append("survey_id", props.location.state.survey_id);
        Axios.post(configURL.get_survey_detail, formData).then(res => {
            EziLoader.hide();
            if (res.data.success !== undefined && res.data.success) {
                let { result = {} } = res.data
                if (result.report_type_id && result.report_type_name) {
                    setReportType({ value: result.report_type_id, label: result.report_type_name })
                }
                setSurveyData({ survey_name: result.name, survey_description: result.description });
            } else {
                toast.warn(res.data.message)
            }
        }).catch(err => {
            console.log(err)
            EziLoader.hide();
        })
    }

    const getTemplateDetails = () => {
        EziLoader.show();
        let formData = new FormData();
        formData.append("template_id", props.location.state.template_id);
        Axios.post(configURL.get_template_details_url, formData).then(response => {
            EziLoader.hide();
            if (response.data.success !== undefined && response.data.success == true) {
                if (response.data.results.report_type_id && response.data.results.report_type_name) {
                    setReportType({ value: response.data.results.report_type_id, label: response.data.results.report_type_name })
                }
            }
        }).catch(err => {
            console.log(err)
            EziLoader.hide();
        })
    }

    useEffect(() => {
        getReportTypes();
        if (props.location.state.survey_id !== undefined && props.location.state.survey_id !== "" &&
            props.location.state.action !== undefined && props.location.state.action === "clone") {
            getSurveyDetail()
            setIsCloned(true)
        }
        if (props.location.state.template_id !== undefined && props.location.state.template_id !== "") {
            getTemplateDetails()
        }
    }, [])

    const BreadcrumbItem = () => {
        let action = props.location.state.action || null;
        let text = "Add new survey";
        switch (action) {
            case "add-survey":
                text = "Add new survey"
                break;

            case "clone":
                text = "Copy Survey"
                break;
        }
        return <Breadcrumb.Item>{text}</Breadcrumb.Item>
    }

    return (
        <React.Fragment>
            <section className="Page-SurveyAdd">
                <div className="breadcrumb_ezi survey-add-beadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => props.history.goBack()}>{props.location.state.category_name}</Breadcrumb.Item>
                        <BreadcrumbItem />
                    </Breadcrumb>
                </div>

                <form onSubmit={handleSubmit(createSurvey)}>
                    <div className="survey-category-wrap">
                        <h1 className="category_label">{languageObj.translate('CreateNewSurvey.1')}</h1>
                        <div className="category-field-control">
                            <input type="text" placeholder={languageObj.translate('Surveyname.1')} name="survey_name" ref={register({ required: true })} className="create-category-input" onChange={handleFormChange} defaultValue={surveyData.survey_name} />
                            {errors.survey_name && <span className="error-message">{languageObj.translate('Surveynameisrequired.1')}</span>}
                        </div>
                        <div className="category-field-control">
                            <Select
                                ref={register}
                                className="ezi-select-plugin"
                                options={reportTypes}
                                value={reportType}
                                styles={outlineRemove}
                                placeholder="Report Type"
                                isDisabled={isTypeDisabled()}
                                onChange={(selectedOption) => {
                                    setReportType(selectedOption)
                                    setReportTypeErr(false)
                                }}
                            />
                            {reportTypeErr && <span className="error-message">Please select report type.</span>}
                        </div>
                        <div className="category-field-control">
                            <textarea maxLength="250" rows="4" name="survey_description" ref={register} className="create-category-input" placeholder={languageObj.translate('SurveyDescription.1')} onChange={handleFormChange} defaultValue={surveyData.survey_description}></textarea>
                            <span className="des-count">{surveyData.survey_description ? surveyData.survey_description.length : 0}/250</span>
                        </div>
                        <div className="category_add_btn_wrap">
                            <button type="submit" className="btn-ripple category_add_btn" disabled={loading}>
                                {isCloned ? 'Create as new survey' : languageObj.translate('Create.1')} &nbsp; &nbsp;
                                {loading && <Spinner animation="grow" size="sm" />}
                            </button>
                        </div>
                    </div>
                </form>
            </section>
        </React.Fragment>
    )
}

export default SurveyAdd;