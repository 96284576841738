import * as ActionType from "./actionTypes"

export const addParticipant = (payload = {}) => {
    return {
        type: ActionType.SET_ADDED_PARTICIPANTS,
        payload: payload
    }
}

export const setParticipantSource = (payload = {}) => {
    return {
        type: ActionType.SET_PARTICIPANT_SOURCE,
        payload: payload
    }
}
export const reloadChannels = (payload = {}) => {
    return {
        type: ActionType.RELOAD_CHANNEL_DATA,
    }
}
export const setDynamicParticipantSourceType = (payload = {}) => {
    return {
        type: ActionType.DYNAMIC_PARTICIPANT_SOURCE_TYPE,
        payload: payload
    }
}

export const setSelectedOnEdit = (payload = {}) => {
    return {
        type: ActionType.SET_PARTICIPANTS_ONEDIT,
        payload: payload
    }
}
export const updateSelectedCount = (payload = {}) => {
    return {
        type: ActionType.SET_PARTICIPANTS_COUNT,
        payload: payload
    }
}
export const updateChannelData = (payload = {}) => {
    return {
        type: ActionType.SET_CHANNEL_DATA,
        payload: payload
    }
}
export const setDynamicSourceFile = (payload = {}) => {
    return {
        type: ActionType.SET_DYNAMIC_SOURCE_DATA,
        payload: payload
    }
}