import React, { useState, useEffect, useRef, useContext } from "react";
import { CircularProgressbar } from 'react-circular-progressbar';
import { Breadcrumb } from 'react-bootstrap';
import Axios from "utility/Axios";
import configURL from 'config/config';
import { getFirstWord } from 'utility/helper'
import './Templates.scss';
import { toast } from "react-toastify";
import CategoryFilter from "components/SearchFilter";
import SweetSearch from "components/SweetSearch";
import Pagination from "react-js-pagination";
import AppContext from 'store/AppContext';

const AllocateTemplate = (props) => {
    const { languageObj = {}, EziLoader } = useContext(AppContext)
    const [defaultCategory, setDefaultCategory] = useState({ ...props.location.state, description: "" });
    const [categoryMetaData, setCategoryMetaData] = useState({})
    const [categories, setCategories] = useState([]);
    const [categoryTemplates, setCategoryTemplates] = useState([]);
    const [pagination, setPagination] = useState({});
    const [filterType, setFilterType] = useState("all");
    const perPage = 10;
    const [searchLoading, setSearchLoading] = useState(false);
    const inputSearch = useRef(null);
    var searchTimer = null;
    const companyId = props.match.params.account_id;
    const [selectedTemplates, setSelectedTemplates] = useState([]);

    /**
     * Get Selected/Default category MetaData.
     */
    const getCategoryMetaData = async () => {
        EziLoader.show()
        let formData = new FormData();
        formData.append("category_id", defaultCategory.category_id);
        let response = await Axios.post(configURL.partner_category_templates_metadata_url, formData);
        EziLoader.hide()
        if (response.data.success !== undefined && response.data.success === true) {
            setCategoryMetaData(response.data.results, getCategoryTemplates());
        } else {
            toast.warn(response.data.message);
        }
    }

    /**
     * Get Searched category.
     * 
     * @param {string} search Search string.
     */
    const getCategoriesListing = (search = "") => {
        let formData = new FormData();
        formData.append("search", search);
        formData.append("type", "template");
        Axios.post(configURL.partner_template_category, formData).then(response => {
            if (response.data.success !== undefined && response.data.success === true) {
                let result = response.data.results;
                const categoryData = [];
                result.forEach(item => {
                    categoryData.push({ id: item.id, name: item.category_name, description: item.description });
                });
                setCategories(categoryData);
            } else {
                toast.warn(response.data.message);
            }
        }).catch(err => {
            console.log(err.toString())
        })
    }

    /**
     * Get Category templates.
     * 
     * @param {object} obj category object
     */
    const changeTemplatesCategory = (obj = {}) => {
        setSelectedTemplates([])
        setDefaultCategory({
            category_id: obj.id || null,
            category_name: obj.name || null,
            description: obj.description || ""
        })
        props.history.replace(`/partner/companies-template-dashboard/${companyId}`, {
            category_id: obj.id,
            category_name: obj.name
        })
    }

    /**
     * Get Perticular template types.
     * 
     * @param {string} type template types
     * @param {number} page Page number
     */
    const getCategoryTemplates = (page = 1, type = "all") => {
        let searchVal = inputSearch.current.value;
        let formData = new FormData();
        formData.append("category_id", defaultCategory.category_id);
        formData.append("account_id", companyId);
        formData.append("per_page", perPage);
        formData.append("page", page);
        formData.append("type", type);
        formData.append("search", searchVal);
        Axios.post(configURL.partner_company_templates, formData).then(response => {
            if (response.data.success !== undefined && response.data.success === true) {
                setCategoryTemplates(response.data.results);
                setPagination(response.data.pagination);
            } else {
                toast.warn(response.data.message);
            }
            EziLoader.hide()
            setSearchLoading(false)
        }).catch(err => {
            EziLoader.hide()
            console.log(err.toString())
        })
    }

    /**
     * Filter Data based on search.
     * 
     * @param {string} type Input value
     */
    const handleFilterSearch = ({ target }) => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setSearchLoading(true);
            getCategoryTemplates(1, filterType);
        }, 800);
    }

    /**
     * Filter Data based on Click.
     * 
     * @param {string} type Filter Type
     */
    const handleFilterChange = (type = "all") => {
        EziLoader.show()
        setFilterType(type);
        getCategoryTemplates(1, type);
    }

    /**
     * Handle Pagination
     * 
     * @param {string} type Filter Type
     */
    const handlePagination = (page = 1) => {
        EziLoader.show()
        getCategoryTemplates(page, filterType);
    }

    const handleCreateTemplate = () => {
        if (defaultCategory.category_id && defaultCategory.category_id !== "") {
            props.history.push(`/partner-template-dashboard/template-add/${defaultCategory.category_id}`, {
                action: "create-template",
                category_name: defaultCategory.category_name
            })
        } else {
            props.history.push('/partner-template-dashboard/template-category', {
                action: "create-new"
            });
        }
    }

    const allocateTemplateToCompanies = () => {
        if (selectedTemplates.length > 0) {
            EziLoader.show()
            let formData = new FormData();
            formData.append("account_id", companyId);
            let addData = {
                'data': selectedTemplates
            }
            formData.append("insertdata", JSON.stringify(addData));
            Axios.post(configURL.partner_allocate_template, formData).then(response => {
                EziLoader.hide()
                if (response.data.success !== undefined && response.data.success) {
                    toast.success("Template assigned successfully");
                    setSelectedTemplates([])
                    props.history.push(`/partner/companies`);
                } else {
                    toast.warn(response.data.message)
                }
            }).catch(err => {
                EziLoader.hide()
                console.log(err.toString())
            })
        } else {
            toast.warn("please select template");
        }
    }

    const handleCheckAllTemplate = ({ target }) => {
        let isChecked = target.checked;
        if (isChecked) {
            let allTemplates = categoryTemplates.filter(item => item.ispublished).map(item => ({ id: item.id, category_id: item.category_id }))
            let combined = [...selectedTemplates, ...allTemplates];
            let updatedOptUnique = [...combined.reduce((map, val) => {
                if (!map.has(val.id)) {
                    map.set(val.id, val);
                }
                return map;
            }, new Map()).values()]
            setSelectedTemplates(updatedOptUnique)
        } else {

            let templates = categoryTemplates.map(item => item.id)
            let newTemplates = selectedTemplates.filter(item => !templates.includes(item.id))
            setSelectedTemplates([...newTemplates])
        }
    }

    const handleCheckTemplate = (target, data) => {
        let isChecked = target.checked;
        if (isChecked) {
            let combined = [...selectedTemplates, { id: data.id, category_id: data.category_id }];
            let updatedOptUnique = [...combined.reduce((map, val) => {
                if (!map.has(val.id)) {
                    map.set(val.id, val);
                }
                return map;
            }, new Map()).values()]
            setSelectedTemplates(updatedOptUnique)
        } else {
            let newTemplates = selectedTemplates.filter(item => item.id !== data.id)
            setSelectedTemplates(newTemplates)
        }
    }
    const isSelected = (id) => {
        return selectedTemplates.length > 0 && selectedTemplates.map(item => item.id).includes(id)
    }

    const isCheckedAll = () => {
        let allData = categoryTemplates.map(item => item.id)
        let selectedTemp = selectedTemplates.filter(item => allData.includes(item.id))
        return selectedTemp.length === allData.length
    }
    useEffect(() => {
        EziLoader.show()
        getCategoryMetaData()
    }, [defaultCategory]);
    useEffect(() => {
        getCategoriesListing()
    }, []);

    return (
        <React.Fragment>
            <section className="Page-CompaniesTemplateDashboard">
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => props.history.push("/partner/companies")}>
                            Companies
                        </Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => props.history.goBack()}>{defaultCategory.category_name}</Breadcrumb.Item>
                        <Breadcrumb.Item>Templates</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="template-category-dropdown" >
                        <CategoryFilter handleSelect={changeTemplatesCategory} data={categories} defaultSelected={defaultCategory.category_name} />
                    </div>
                    <span className="template-category-subtitle"> {(defaultCategory.description !== undefined) ? defaultCategory.description : ''} </span>
                </div>

                {/* <div className="category-dashboard-card-wrap">
                    <div className={`category-dashboard-card total_template ${(filterType === "all") && "active"}`} onClick={() => handleFilterChange("all")}>
                        <div className="category-dashboard-card_text_wrap">
                            <p className="category-dashboard-card-heading">{categoryMetaData.total_templates || 0}</p>
                            <span className="category-dashboard-card-subheading">{languageObj.translate('TotalTemplates.1')}</span>
                        </div>
                        <div className="category_progress_ring_wrap">
                            <div className="category_progress_ring">
                                <CircularProgressbar className="category_progress_circle" value={categoryMetaData.published_template_percentage || 0} text={`${categoryMetaData.published_template_percentage || 0}%`}
                                />
                            </div>
                            <span className="publish-templates-text">{languageObj.translate('PublishedTemplates.1')}</span>
                        </div>
                    </div>
                    <div className={`category-dashboard-card active_template ${(filterType === "active") && "active"}`} onClick={() => handleFilterChange("active")}>
                        <div className="category-dashboard-card_text_wrap">
                            <p className="category-dashboard-card-heading">{categoryMetaData.active_templates || 0}</p>
                            <span className="category-dashboard-card-subheading">Active Templates</span>
                        </div>
                    </div>
                    <div className={`category-dashboard-card unpublish_template ${(filterType === "unpublished") && "active"}`} onClick={() => handleFilterChange("unpublished")}>
                        <div className="category-dashboard-card_text_wrap">
                            <p className="category-dashboard-card-heading">{categoryMetaData.unpublished_templates || 0}</p>
                            <span className="category-dashboard-card-subheading">Unpublished</span>
                        </div>
                    </div>

                    <div className={`category-dashboard-card create-survey-template `} onClick={handleCreateTemplate}>
                        <span className="create-survey-template-ic"></span>
                        <p className="create-survey-template-name">Create {(defaultCategory.category_id && defaultCategory.category_id !== "") ? defaultCategory.category_name : ""} Template</p>
                    </div>
                </div> */}

                <div className="cat-search-count-wrap">
                    <div className="cat-temp-count-wrap">
                        Selected Template
                        <span className="cat-temp-count">{selectedTemplates.length || 0}</span>
                    </div>
                    <SweetSearch loading={searchLoading} change={handleFilterSearch} ref={inputSearch} />
                </div>

                <div className="category-dashboard-table">

                    <div className="category-dashboard-table-row category-table-heading">
                        <div className="category-dashboard-table-cell">
                            <label className="ezi-checkbox">
                                <input type="checkbox" onChange={handleCheckAllTemplate} checked={isCheckedAll()} />
                                <span className="ezi-checkbox-mark"></span>
                            </label>
                        </div>
                        <div className="category-dashboard-table-cell"> Template Name </div>
                        <div className="category-dashboard-table-cell"> Owner </div>
                        <div className="category-dashboard-table-cell"> Status </div>
                        <div className="category-dashboard-table-cell"> Active </div>
                        <div className="category-dashboard-table-cell"> Template Allocated </div>
                        <div className="category-dashboard-table-cell">
                            <buton type="button" className="ezi-btn btn-ripple ezi-pink-btn allocate-save" onClick={() => { allocateTemplateToCompanies() }}>Save</buton>
                        </div>
                    </div>
                    {
                        categoryTemplates.length > 0 ? categoryTemplates.map((item, index) => {
                            return (
                                <div key={index} className="category-dashboard-table-row">
                                    <div className="category-dashboard-table-cell" data-title="Checkbox">
                                        <label className="ezi-checkbox">
                                            <input type="checkbox" onChange={({ target }) => { if (!item.ispublished) { toast.error("Please Published The Template") } else { handleCheckTemplate(target, item) } }} checked={isSelected(item.id)} title={`${!item.ispublished ? 'Template is Unpublished' : ''}`} />
                                            <span className="ezi-checkbox-mark"></span>
                                        </label>
                                    </div>
                                    <div className="category-dashboard-table-cell" data-title="Template Name">
                                        <div className={`category-table-template-wrap `} onClick={() => {

                                            props.history.push(`/partner-template-dashboard/edit-template/${(defaultCategory.category_id !== "") ? defaultCategory.category_id : item.category_id}/${item.id}`, {
                                                action: "edit-template",
                                                category_name: defaultCategory.category_name
                                            })

                                        }}>
                                            <div className="category-table-template-text-wrap">
                                                <span className="category-table-template-name">{item.name}</span>
                                                <span className="category-table-create">Last Modified: {getFirstWord(item.updated_at)}   |   Created on: {getFirstWord(item.created_at)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="category-dashboard-table-cell" data-title="Owner"> {item.owner_name || "Dummy"} </div>
                                    <div className="category-dashboard-table-cell table-status" data-title="Status"> {(item.ispublished) ? "Published" : "Unpublished"} </div>
                                    <div className="category-dashboard-table-cell table-status" data-title="Is Active"><span className={`isactive ${item.status}`}>{getFirstWord(item.status)}</span></div>

                                    <div className="category-dashboard-table-cell table-status" data-title="Template Allocated">
                                        <span>{item.is_template_allocated}</span>
                                    </div>
                                </div>
                            )
                        }) : <h4 style={{ textAlign: "center" }}>No result Found</h4>
                    }
                </div>
                <div className="pagination-plugin-wrap category-pagination-formatter">
                    <Pagination
                        activePage={pagination.current_page}
                        itemsCountPerPage={perPage}
                        totalItemsCount={pagination.total}
                        onChange={handlePagination}
                        hideDisabled={true}
                    />
                </div>
            </section>
        </React.Fragment>
    )
}

export default AllocateTemplate;