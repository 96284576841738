import React, { useEffect, useState, useContext } from 'react';
import './TemplateDashboard.scss';
import { TEMPLATE_DASHBOARD_CARDS } from "constants/constants"
import Axios from "utility/Axios";
import configURL from 'config/config';
import { toast } from 'react-toastify';
import AppContext from 'store/AppContext';
import RecentActivity from 'modules/company/Dashboard/RecentActivity';

const TemplateDashboard = (props) => {
    const { languageObj = {}, handleUnAuthWarn = {}, accesFeature = {}, EziLoader } = useContext(AppContext)
    const [widgetCount, setWidgetCount] = useState({});
    const [recentTemplates, setRecentTemplates] = useState([]);

    const handleCardClick = (status) => {
        if(status) {
            props.history.push('/template-dashboard/category-templates', {
                category_id: "",
                category_name: "All Categories",
                template_status: status ,
            });
        }       
    }

    /**
     * Get Survey widget counts
     */
    const getDashboardInitials = () => {
        EziLoader.show()
        Axios.post(configURL.template_dashboard_initials, {}).then(response => {
            EziLoader.hide()
            if (response.data.success !== undefined && response.data.success) {
                setWidgetCount(response.data.results.dashboard_counts ? response.data.results.dashboard_counts : {});
                setRecentTemplates(response.data.results.recent_templates ? response.data.results.recent_templates : []);
            } else {
                toast.warn(response.data.message)
            }
        }).catch(err => {
            console.log(err);
            EziLoader.hide()
        })
    }

    useEffect(() => {
        getDashboardInitials();
    }, [])

    const handleCreateSurveyCat = (template_id = null) => {
        if (accesFeature.create_survey && template_id) {
            let formData = new FormData();
            formData.append("template_id", template_id)
            Axios.post(configURL.create_survey_cat_by_template, formData).then(response => {
                if (response.data.success !== undefined && response.data.success) {
                    let result = response.data.result
                    props.history.push(`/survey-dashboard/add-survey/${result.category_id}`, {
                        template_id: template_id,
                        action: "add-survey",
                        category_name: result.category_name
                    });
                } else {
                    toast.warn(response.data.message)
                }
            })
        } else {
            handleUnAuthWarn()
        }
    }

    return (
        <React.Fragment>
            <section className="Page-TemplateDashboard">
                <div className="st_heading">{languageObj.translate('SurveyTemplateDashboard.1')}</div>
                <div className="st_card-wrap">
                    {
                        TEMPLATE_DASHBOARD_CARDS.map((item) =>
                            <div key={item.id} className="st_card" onClick={() => handleCardClick(item.status)}>
                                <div className="st_card-icon-wrap">
                                    <span className={`st_card-icon ${item.card_icon}`}></span>
                                </div>
                                <div className="st_card-text-wrap">
                                    <span className="st_card-text">{item.card_name}</span>
                                    <span className="st_count">{item.card_count}</span>
                                    <span className="st_count">{Object.keys(widgetCount).length > 0 && widgetCount[item.data_label]}</span>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="action-hightlight-card-wrap">
                    <div className="st_create-exsting-wrap">
                        <div className="st_action-card-wrap">
                            <div className={`st_action-card create-card ${accesFeature.create_template || " access_lock"}`} onClick={() => {
                                if (accesFeature.create_template) {
                                    props.history.push('/template-dashboard/template-category', {
                                        action: "create-new"
                                    });
                                } else {
                                    handleUnAuthWarn()
                                }
                            }}>
                                <div className="st_action-icon-wrap">
                                    <span className="st_action-icon"></span>
                                </div>
                                <label>{languageObj.translate('CreateNewTemplate.1')}</label>
                            </div>
                            <div className={`st_action-card existing-card`} onClick={() => {
                                props.history.push('/template-dashboard/category-templates', {
                                    category_id: "",
                                    category_name: "All Categories"
                                });
                            }}>
                                <div className="st_action-icon-wrap">
                                    <span className="st_action-icon"></span>
                                </div>
                                <label>{languageObj.translate('ViewExistingTemplate.1')}</label>
                            </div>
                        </div>
                        <div className="recent-st_card">
                            <div className="recent-st_card-header">{languageObj.translate('Recenttemplates.1')}</div>
                            <div className="recent-st_card-body">
                                <ul>
                                    {
                                        recentTemplates.map((item, index) =>
                                            <li key={`recent-template${index}`} className={((item.ispublished === true) ? "Published" : "Unpublished").toLowerCase()}>
                                                <span className="recent-st_index">{index + 1}</span>
                                                <span className="recent-st_name">{item.name}</span>
                                                <span className={`recent-st_status ${((item.ispublished === true) ? "Published" : "Unpublished").toLowerCase()}`}>{(item.ispublished === true) ? "Published" : "Unpublished"}</span>
                                                <button type="button" className={`recent-st_report ${accesFeature.create_survey || " access_lock"}`} onClick={() => handleCreateSurveyCat(item.id)}>{languageObj.translate('CreateSurvey.1')}</button>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <RecentActivity type="template" />
                </div>
            </section>
        </React.Fragment>
    )
}

export default TemplateDashboard;