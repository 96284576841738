import React , { useState } from 'react';
import { Bar , Doughnut } from 'react-chartjs-2';
import BootstrapTable from "react-bootstrap-table-next";

const PersonalInfo = () => {

    const data = [
        { id: 1,  fname: "samir",  lname:"Munjewar" ,email:"abc@gmail.com" ,  phone:"9845675647",dob:"20/10/2019",city:"Nagpur"},
        { id: 2,  fname: "akshay", lname:"Rampal" ,email:"abc@gmail.com" ,  phone:"9845675647",dob:"20/10/2019",city:"Nagpur" },
        { id: 3,  fname: "pranay", lname:"Chaohan" ,email:"abc@gmail.com" ,  phone:"9845675647",dob:"20/10/2019",city:"Nagpur" },
        { id: 4,  fname: "Sushil", lname:"Bansod" , email:"abc@gmail.com" , phone:"9845675647",dob:"20/10/2019",city:"Nagpur" }
    ];
    const columns = [
        { dataField: "id", text:"Sr No" }, 
        { dataField: "fname", text: "First Name" }, 
        { dataField: "lname", text: "Last Name" }, 
        { dataField: "email", text: "Email" },
        { dataField: "phone", text: "Phone" },
        { dataField: "dob", text: "Dob" },
        { dataField: "city", text: "City" },
    ]; 
  

    return(           
        <React.Fragment>
            <section className="Common-QuestionComponent Page-PersonalInfo">
                <div className="question-type-wrap">
                    <label className="question"><span className="question-no">Q</span><span>[11]</span>Enter your personal details?</label>
                    <div className="question-type personal-info">Personal Information</div>
                </div>

                <div className="checkbox-multi-card-wrap">

                    <div className="total-responses">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Total Responses</span>
                            <span className="subtitle">189</span>
                        </div>
                        <span className="checkbox-multi-card-icon"></span>
                    </div>

                    <div className="total-comments">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Total Comments</span>
                            <span className="subtitle">98</span>
                        </div>
                        <span className="checkbox-multi-card-icon"></span>
                    </div>

                    <div className="skipped">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Skipped</span>
                            <span className="subtitle">50</span>
                        </div>
                        <span className="checkbox-multi-card-icon"></span>
                    </div>

                    <div className="total-comments">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Total Comments</span>
                            <span className="subtitle">98</span>
                        </div>
                        <span className="checkbox-multi-card-icon"></span>
                    </div>

                </div>

                <div className="personal-info-table">
                    <div className="sweet-search-table-header">
                        <div className="ezi-sweet-search-wrap">
                            <input type="text" className="sweet-search-input" placeholder="Search" />
                        </div>
                    </div>
                    <BootstrapTable
                        keyField="id"
                        data={data}
                        columns={columns}
                    />
                </div>
               

            </section>
        </React.Fragment>   
    )


}

export default PersonalInfo;