import React, { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap';

const InputPrompt = (props) => {
    const [inputValue, setInputValue] = useState("")

    useEffect(() => {
        if (!props.show) {
            setInputValue("")
        }
    }, [props.show])

    return (
        <Modal centered show={props.show} onHide={props.onhide} aria-labelledby="contained-modal-title-vcenter" size="sm" className="ezi-alert-modal" backdropClassName="ezi-alert-backdrop" >
            <Modal.Body className="ezi-alert-body">
                <div className="ezi-alert-inner prompt-alert">
                    <button type="button" className="close-alert-icon" onClick={props.onhide}>Cancel</button>
                    <span className="ezi-alert-text">{props.alerttext}</span>
                    <input className="ezi-prompt-input" type="text" value={inputValue} onChange={({ target }) => setInputValue(target.value)} />
                    <div className="ezi-alert-footer">
                        <button type="button" className="confirm-alert-btn" onClick={() => props.confirm(inputValue)}>Continue</button>&nbsp;
                        <button type="button" className="close-alert-btn" onClick={props.onhide}>Cancel</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default InputPrompt;