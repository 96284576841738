import React from 'react';
import { Switch } from "react-router-dom";
import RootRouter from "./RootRouter";
import PrivateRoute from './PrivateRoute';
import { connect } from "react-redux";

/**
 * Lazy Load page
 */
const PageNotFound = React.lazy(() => import('components/PageNotFound'));

const InnerRoutes = ({ appState }) => {

    const appAccesses = appState.access_matrix || {};
    const rootPages = Object.keys(appAccesses);

    return (
        <Switch>
            {
                RootRouter.map((route, index) => {
                    if (route.skip_access === false && rootPages.includes(route.access_page) && appAccesses[route.access_page] !== undefined && appAccesses[route.access_page].includes(route.min_permission) && route.component) {
                        return <PrivateRoute key={index} path={route.path} exact={route.exact} component={route.component} />
                    }
                    if (route.skip_access === true && route.component) {
                        return <PrivateRoute key={index} path={route.path} exact={route.exact} component={route.component} />
                    }
                    return null
                })
            }
            <PrivateRoute component={PageNotFound} />
        </Switch>
    )
}

const mapStateToProps = state => {
    return {
        appState: state.app.appState,
    }
}

export default connect(mapStateToProps)(InnerRoutes);