import React, { useState, useRef, Fragment } from 'react';
import useOutsideClick from '../hooks/useOutsideClick';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const SearchFilter = (props) => {

    const [loading, setLoading] = useState(false);
    const [serching, setSerching] = useState(false);
    const [data, setData] = useState([...props.data]);
    const [filteredData, setFilteredData] = useState(props.data);
    const [searchTerm, setSearchTerm] = useState("");
    const [translate] = useTranslation();
    const ref = useRef();
    let searchTimer = null;

    /**
     * Search Catrgories.
     * 
     * @param {object} target Javascript Event target. 
     */
    const searchCategory = ({ target }) => {
        setLoading(true)
        let inputValue = target.value;
        clearTimeout(searchTimer);
        setSearchTerm(inputValue);
        searchTimer = setTimeout(() => {
            if (inputValue.length > 0) {
                const filtedData = data.filter(item => item.name.toLowerCase().includes(inputValue.toLowerCase()))
                setFilteredData(filtedData);
            } else {
                setFilteredData(data);
            }
            setLoading(false);
        }, 1000);
    }

    const handleSelect = (data = {}) => {
        props.handleSelect(data);
        setSearchTerm("");
        setSerching(false)
    }

    useOutsideClick(ref, () => {
        serching && setSerching(false);
    });

    useEffect(() => {
        let filters = [...props.data];
        setData(filters);
        setFilteredData(filters);
    }, [props.data])

    return (
        <Fragment>
            <button type="button" onClick={() => setSerching(!serching)} className={`template-category-dropdown-btn ${props.defaultSelected ? "selected" : ""}`}> {props.defaultSelected} </button>
            <div className={`template-category-inner-dropdown ${serching ? 'active' : ""}`} ref={ref}>
                <div className="template-category-dropdown-header">
                    <input type="text" value={searchTerm} className="template-category-dropdown-search" onChange={(e) => searchCategory(e)} placeholder={translate('Search.1')} />
                    <label>{props.defaultSelected}</label>
                </div>
                <ul>
                    {
                        filteredData.map(item =>
                            <li onClick={() => handleSelect(item)} key={item.id}>
                                {item.name}
                            </li>
                        )
                    }
                </ul>
                {loading && 
                <div className="search-loader-position">
                    <div className="serach-loader-wrapper_c">
                        <div className="search-loader-inner_c"></div>
                    </div>
                </div>
                }
            </div>

        </Fragment>
    )

}

export default SearchFilter;