import React, { useEffect, useState } from "react";
import { Modal, Spinner } from 'react-bootstrap';
import configURL from "config/config";
import { useParams, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import Axios from "utility/Axios";
import { connect } from "react-redux";
import * as AppActions from "store/actions";

const ChecklistModal = ({ dispatchHideChecklist, showCheckList = false }) => {
    const urlParam = useParams();
    const urlHistory = useHistory();
    const [surveyCheckList, setSurveyCheckList] = useState({})
    const [loading, setLoading] = useState(false);

    const checklistLabel = {
        webEmail: "Web Email",
        webSms: "Web Sms",
        emailEmbed: "Embeded Email",
        webEmbed: "Web Embeded",
    }
    const handleLaunchSurvey = () => {
        setLoading(true)
        let formData = new FormData();
        formData.append("survey_id", urlParam.survey_id);
        Axios.post(configURL.surveyLaunch, formData).then(res => {
            setLoading(false)
            if (res.data.success !== undefined && res.data.success) {
                urlHistory.replace("/survey-dashboard");
                dispatchHideChecklist()
                toast.success(res.data.message || "Survey Launched successfully.");
            } else {
                toast.warn(res.data.message || "Something went wrong.");
            }
        }).catch(err => {
            setLoading(false)
            toast.warn("Something went wrong.");
        })
    }
    useEffect(() => {
        if (showCheckList) {
            let formData = new FormData();
            formData.append("survey_id", urlParam.survey_id);
            Axios.post(configURL.surveyCheckList, formData).then(res => {
                if (res.data.success !== undefined && res.data.success) {
                    setSurveyCheckList(res.data.result)
                } else {
                    dispatchHideChecklist()
                    toast.warn(res.data.message);
                }
            })
        }
        return () => toast.dismiss()
    }, [showCheckList])

    return (
        <Modal show={showCheckList} size="md" centered className="theme-modal-wrapper checklist-modal" >
            <Modal.Header className="ezi-modal-header">
                <Modal.Title className="theme-modal-title ezi-modal-header-title" >
                    <span className="theme-modal-title-text">Survey Checklist</span>
                    <span className="ezi-modal-close" onClick={dispatchHideChecklist}></span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="checklist-label-wrap">
                    <label className="checklist-label">Survey Name</label>
                    <span className="checklist-info survey-name">{surveyCheckList.survey_name || "NA"}</span>
                </div>
                <div className="checklist-label-wrap">
                    <label className="checklist-label">No of Question</label>
                    <span className="checklist-info">{surveyCheckList.no_of_question || 0}</span>
                </div>
                <div className="checklist-label-wrap">
                    <label className="checklist-label">Survey Type</label>
                    <span className="checklist-info">{surveyCheckList.survey_type || "NA"}</span>
                </div>
                <div className="checklist-label-wrap">
                    <label className="checklist-label">Participant Source</label>
                    <span className="checklist-info">{surveyCheckList.participant_source || "NA"}</span>
                </div>
                <div className="checklist-label-wrap">
                    <label className="checklist-label">Total Count</label>
                    <span className="checklist-info">{surveyCheckList.participant_count || "NA"}</span>
                </div>
                <div className="checklist-label-wrap">
                    <label className="checklist-label">Channel Detail</label>
                    <div className="channel-name-wrap">
                        {(surveyCheckList.channel_detail && surveyCheckList.channel_detail.length > 0) ?
                            surveyCheckList.channel_detail.map(item => (<span className="checklist-info channel-name">{checklistLabel[item.name]}, <span className="checklist-info">Triggers On : {item.scheduled_time}</span> </span>))
                            : <span>Communication Channel Not St</span>
                        }
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="checklist-modal-close" onClick={dispatchHideChecklist}> Cancel </button>
                <button type="button" className="btn-ripple ezi-pink-btn checklist-modal-submit" onClick={handleLaunchSurvey} disabled={loading}> Launch {loading && <Spinner size="sm" animation="border" />}</button>
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = state => {
    return {
        showCheckList: state.survey.showCheckList
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchHideChecklist: () => dispatch(AppActions.viewChecklist({ view: false })),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChecklistModal)