import React from 'react';

const EziLoader = (props) => {

    return (
        <React.Fragment>
            <div className="loading-wrapper">
                <div className="main-ezi-loader" id="ezi-loader">
                    <span className="ezi-loader-check"></span>
                </div>
                <div className="ezi-loader-text">{props.children}</div>
            </div>
        </React.Fragment>
    )

}

export default EziLoader;