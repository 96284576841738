import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import configURL from 'config/config';
import Axios from 'utility/Axios';
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import AppContext from 'store/AppContext';


const ThemeMappingModal = ({ questionType, id, ...rest }) => {
    const unique_id = id
    const { languageObj = {} } = useContext(AppContext)
    let urlParams = useParams();
    const [themeQue, setThemeQue] = useState([]);
    const [surveyThemeDs, setSurveyThemeDs] = useState([]);
    const [surveySubthemeDs, setSurveySubthemeDs] = useState({});
    const [loading, setLoading] = useState(false);
    const [mappedData, setMappedData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const quePerPage = 10;
    const pageNumbers = [];
    const [themeQuestions, setThemeQuestions] = useState([]);

    for (let i = 1; i <= Math.ceil(themeQuestions.length / quePerPage); i++) {
        pageNumbers.push(i);
    }

    const themeDs = () => {
        let formData = new FormData();
        formData.append("category_id", urlParams.category_id);
        formData.append("type", questionType);
        Axios.post(configURL.partner_surveythemeDs, formData).then(res => {
            setLoading(false);
            if (res.data.success !== undefined && res.data.success) {
                setSurveyThemeDs(res.data.data);
                setEmptySubthemeObj(res.data.data);
            } else {
                setSurveyThemeDs([]);
            }
        })
    }

    const subthemeDs = (themeId) => {
        let formData = new FormData();
        formData.append("theme_id", themeId);
        Axios.post(configURL.partner_surveySubThemeDs, formData).then(res => {
            if (res.data.success !== undefined && res.data.success) {
                setSurveySubthemeDs({ ...surveySubthemeDs, [themeId]: res.data.data });
            } else {
                setSurveySubthemeDs({ ...surveySubthemeDs, [themeId]: [] });
            }
        })
    }

    const setEmptySubthemeObj = (data) => {
        let tempSubthemeObj = {};
        data.forEach(item => {
            tempSubthemeObj[item.id] = []
        })
        setSurveySubthemeDs(tempSubthemeObj);
    }

    const setEmptyMappingObj = (data) => {
        let tempMappingobj = {};
        Object.keys(data).map(key => {
            tempMappingobj[key] = {
                'theme_id': (data[key]["mapping"] && data[key]["mapping"]["theme_id"]) ? data[key]["mapping"]["theme_id"] : "",
                'subtheme_id': (data[key]["mapping"] && data[key]["mapping"]["subtheme_id"]) ? data[key]["mapping"]["subtheme_id"] : "",
            }
        }
        )
        setMappedData(tempMappingobj);
    }

    const resetMapping = () => {
        let tempMappingobj = {};
        let oldQuestions = {};
        Object.keys(themeQuestions).map(key => {
            tempMappingobj[key] = {
                'theme_id': "",
                'subtheme_id': "",
            }
        })
        Object.keys(themeQuestions).map(key => {
            oldQuestions[key] = {
                name: themeQuestions[key].name,
                mapping: { theme_id: "", subtheme_id: "" }
            }
        })
        setMappedData(tempMappingobj);
        setThemeQuestions(oldQuestions)
    }
    const loadInitialSubThemes = (data = []) => {
        let formData = new FormData();
        formData.append("theme_id", JSON.stringify(data));
        Axios.post(configURL.partner_subThemesData, formData).then(res => {
            if (res.status == 201 && res.data.success) {
                let result = res.data.data;
                setSurveySubthemeDs((prevState) => {
                    return { ...prevState, ...result }
                });
            }
        })
    }

    const questionListing = () => {
        let formData = new FormData();
        formData.append("id", unique_id);
        formData.append("type", questionType);
        Axios.post(configURL.partner_themeQuestionListing, formData).then(res => {
            if (res.data.success !== undefined && res.data.success) {
                let Response = res.data.data;
                setEmptyMappingObj(Response);
                setThemeQuestions(Response);
                let themes = [];
                Object.keys(Response).forEach((item) => {
                    if (Response[item]["mapping"] && Response[item]["mapping"]["theme_id"]) {
                        themes.push(Response[item]["mapping"]["theme_id"]);
                    }
                });
                loadInitialSubThemes(themes);
            } else {
                setThemeQuestions([]);
            }
        })
    }

    const onThemeChange = ({ target }) => {
        let queId = target[target.selectedIndex].getAttribute('data-queid');
        setMappedData({
            ...mappedData, [queId]: {
                ...mappedData[queId], 'theme_id': target.value, 'subtheme_id': ""
            }
        });
        setThemeQuestions((prevState) => {
            return {
                ...prevState, [queId]: {
                    ...prevState[queId], mapping: {
                        ...prevState[queId]["mapping"], ["theme_id"]: target.value, 'subtheme_id': ""
                    }
                }
            }
        });
        if (target.value !== '') {
            subthemeDs(target.value);
        } else {
            setSurveySubthemeDs({ ...surveySubthemeDs, [target.value]: [] });
        }
    }

    const onSubthemeChange = ({ target }) => {
        let queId = target[target.selectedIndex].getAttribute('data-queid');
        setMappedData((prevState) => {
            return {
                ...prevState, [queId]: {
                    ...prevState[queId], 'subtheme_id': target.value
                }
            }
        });

        setThemeQuestions((prevState) => {
            return {
                ...prevState, [queId]: {
                    ...prevState[queId], mapping: {
                        ...prevState[queId]["mapping"], ["subtheme_id"]: target.value
                    }
                }
            }
        });

    }

    const saveColumnMapping = () => {
        let formData = new FormData();
        formData.append("id", unique_id);
        formData.append("type", questionType);
        formData.append("mapping", JSON.stringify(mappedData));
        Axios.post(configURL.partner_surveyQuestionMapping, formData).then(res => {
            if (res.status === 201 && res.data.success) {
                rest.onHide();
                toast.success(res.data.message || "Theme Mapping updated !");
            } else {
                toast.warn(res.data.message || "Questions are not mapped with themes.");
            }
        })
    }

    useEffect(() => {
        setLoading(true);
        questionListing();
        themeDs();
        return () => toast.dismiss();
    }, []);
    const indexOfLastQuestion = currentPage * quePerPage;
    const indexOfFirstQuestion = indexOfLastQuestion - quePerPage;
    const currentQuestion = themeQue.slice(indexOfFirstQuestion, indexOfLastQuestion);
    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
    }

    return (
        <Modal {...rest} animation={true} className="ezi-right-animated-modal theme-mapping-right-modal" backdropClassName="ezi-right-modal-backdrop" >
            <Modal.Body>
                <section className="Page-ThemeMappingModal">
                    <div className="question-wrapper-section">
                        <div className="questions-header">
                            <span className="question-heading">{languageObj.translate('ThemeMapping.1')}</span>
                            <div className="question-save-wrap">
                                <button type="button" className="btn-ripple ezi-pink-btn question-save" onClick={saveColumnMapping}>{languageObj.translate('Save.1')}</button>
                                <button type="button" className="question-reset" onClick={resetMapping}>{languageObj.translate('Reset.1')}</button>
                            </div>
                        </div>
                        <div className="que-mapping-body">
                            {
                                Object.keys(themeQuestions).map(key =>
                                    <div className="question-wrapper" key={key}>

                                        <p className="question-list">{themeQuestions[key]["name"]} </p>
                                        <div className="question-theme-select-wrap">
                                            <select name="theme" className="question-theme-select" onChange={(e) => onThemeChange(e)} value={(themeQuestions[key]["mapping"] && themeQuestions[key]["mapping"]["theme_id"]) ? themeQuestions[key]["mapping"]["theme_id"] : ""}>
                                                <option data-queid={key} value="">Theme</option>
                                                {
                                                    surveyThemeDs.map((theme) =>
                                                        <option data-queid={key} key={theme.id} value={theme.id}>{theme.name}</option>
                                                    )
                                                }
                                            </select>
                                            <select name="subtheme" className="question-subtheme-select" onChange={(e) => onSubthemeChange(e)} value={(themeQuestions[key]["mapping"] && themeQuestions[key]["mapping"]["subtheme_id"]) ? themeQuestions[key]["mapping"]["subtheme_id"] : ""}>
                                                <option value="" data-queid={key}>Subtheme</option>
                                                {
                                                    surveySubthemeDs[mappedData[key]['theme_id']] && surveySubthemeDs[mappedData[key]['theme_id']].map((subtheme) =>
                                                        <option data-queid={key} key={subtheme.id} value={subtheme.id}>{subtheme.name}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className="question-pagination-wrap">
                            <ul className='question-pagination'>
                                {pageNumbers.map(number => (
                                    <li key={number} className={`question-page-item ${(currentPage == number) ? "active" : ""}`}>
                                        <div onClick={() => paginate(number)} className='question-page-link'>
                                            {number}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {loading && <h3>Loading Data....</h3>}
                </section>
            </Modal.Body>
        </Modal>
    );
}

export default ThemeMappingModal;