import React, { useState } from 'react';
import DatePicker from 'react-date-picker';


const AddSingleFields = (props) => {
    const activeTab = props.activeTab;
    const { register } = props;
    const demographicData = props.demographicData;
    const [date, setDate] = useState(new Date());
    const handleDateChange = date => {
        setDate(date);
        props.dateChange(date, activeTab);
    }

    if (demographicData !== undefined) {
        const tabData = demographicData[activeTab];

        return (
            tabData.length && tabData.length > 0 ? tabData.map((data, index) =>
                <label key={index}>
                    {data.label}
                    {(() => {
                        switch (data.type) {
                            case "dropdown": return (
                                <select name={data.name} onChange={(e) => props.selectChangeHandler(e, activeTab)} ref={register}>
                                    <option key={'select'} value=''>{`Select ${data.name}`}</option>
                                    {
                                        data.options.map((optionItem, index) =>
                                            <option key={index} value={optionItem}>{optionItem}</option>
                                        )
                                    }
                                </select>
                            );

                            case "radio": return (
                                <div className="radio-wrap">
                                    {data.options.map((optionItem, optionIndex) =>
                                        <div key={optionIndex} className="ezi-radio radio-inner-wrap"> {optionItem}
                                            <input name={data.name} type="radio" value={optionItem} onChange={(e) => props.selectChangeHandler(e, activeTab)} ref={register} />
                                            <span className="ezi-checkmark"></span>
                                        </div>
                                    )}
                                </div>
                            );

                            case "datetime": return (
                                <div className="datepicker-wrap" key={index}>
                                    <DatePicker selected={date} value={date} name={data.name} onChange={handleDateChange} ref={register} />
                                </div>
                            );

                            case "text": return (
                                <input type="text" placeholder={`Enter ${data.label}`} name={data.name} onChange={(e) => props.selectChangeHandler(e, activeTab)} ref={register} />
                            );
                        }
                    })()}
                </label>
            ) : <p>Identifirs missing</p>)
    } else {
        return (
            <p>Record does not exist..</p>
        );
    }
}

export default AddSingleFields;