import React, { useContext, useEffect, useState, useRef } from "react";
import './Users.scss';
import { Breadcrumb } from 'react-bootstrap';
import AppContext from 'store/AppContext';
import EziLoader from "components/EziLoader";
import Axios from "utility/Axios";
import configURL from "config/config";
import { toast } from "react-toastify";
import DataTable from "components/DataTable";
import SweetSearch from "components/SweetSearch";
import { confirmAlert } from "react-confirm-alert";

const Users = (props) => {

    const { languageObj = {}, accesFeature = {}, handleUnAuthWarn, appState } = useContext(AppContext)
    const [pagination, setPagination] = useState({})
    const [loading, setLoading] = useState(false)
    const [searchLoading, setSearchLoading] = useState(false)
    const [usersData, setUsersData] = useState([])
    const [dataColumn, setDataColumn] = useState([{}]);
    const per_page = 10;
    const inputSearch = useRef(null);
    var searchTimer = null;

    const statusFormatter = (cell, row) => {
        return (
            <span className={`user-status_c ${row.active ? "active" : "offline"}`}>{row.active ? "active" : "offline"}</span>
        );
    }

    const handleDeleteUser = (row = {}) => {
        let formData = new FormData();
        formData.append("person_id", row.id)
        confirmAlert({
            title: 'Delete User',
            message: "Are you sure you want to delete this ? ",
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        Axios.post(configURL.delete_person, formData).then(response => {
                            if (response.data.success === true) {
                                toast.success("User Deleted!")
                                getUsersList()
                            } else {
                                toast.warn(response.data.message)
                            }
                        })
                    }
                },
                {
                    label: 'Cancel',

                }
            ]
        });
    }

    const handleTableChange = (type, props) => {
        setLoading(true)
        switch (type) {
            case "pagination":
                getUsersList(props.page);
                break;
            case "sort":
                //getUsersList();
                break;
        }

    }

    const getUsersList = (page = 1) => {
        let userSearch = inputSearch.current.value;
        let formData = new FormData()
        formData.append("page", page)
        formData.append("per_page", per_page)
        formData.append("search", userSearch)
        Axios.post(configURL.get_company_users, formData).then(response => {
            if (response.data.success === true) {
                const columnData = response.data.results.columns.map(item => {
                    if (item.dataField === "active") {
                        return {
                            ...item,
                            formatter: statusFormatter,
                        }
                    } else if (item.dataField === "id") {
                        return {
                            ...item,
                            hidden: true,
                        }
                    }
                    else {
                        return item;
                    }
                });
                columnData.push({
                    dataField: 'action', text: 'Action', headerFormatter: headingFormatter, formatter: actionFormatter
                });
                setDataColumn(columnData)
                setUsersData(response.data.results.data)
                setPagination(response.data.pagination)
                setLoading(false)
                setSearchLoading(false)
            } else {
                toast.warn(response.data.message);
            }
        })
    }

    const handleTableSearch = () => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setSearchLoading(true);
            getUsersList();
        }, 500);
    }

    const headingFormatter = (column, colIndex) => {
        return (
            <div className="action-heading-name">Action</div>
        );
    }

    const actionFormatter = (cell, row) => {
        return (
            <div className="dt-bootstrap-edit-delte-wrap">
                <button type="button" className="dt-bootstrap-edit_ic" onClick={() => props.history.push(`/user/view/${row.id}`)}>View</button>
                <button type="button" className={`dt-bootstrap-delete_ic ${accesFeature.delete_user || "access_lock"}`} onClick={() => {
                    if (accesFeature.delete_user) {
                        handleDeleteUser(row)
                    } else {
                        handleUnAuthWarn()
                    }
                }} disabled={(appState.user && appState.user.id === row.id)} title={(appState.user && appState.user.id === row.id) ? 'Logged In' : ''}>Delete</button>
            </div>
        );
    }

    useEffect(() => {
        setLoading(true)
        getUsersList();
    }, [])

    return (
        <React.Fragment>
            <section className="Page-Users" >
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item>{languageObj.translate('Settings.1')}</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => props.history.push("/users-roles")}>User Management</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="column-header">
                        <h1 className="page-heading">{languageObj.translate('Users.1')}</h1>
                        <div className="column-header-btn">
                            <button type="button" className={`btn-ripple add-new ${accesFeature.add_user || "access_lock"}`} onClick={() => {
                                if (accesFeature.add_user) {
                                    props.history.push('/user/create', {
                                        action: "add"
                                    })
                                } else {
                                    handleUnAuthWarn()
                                }
                            }}>
                                {languageObj.translate('Addnew.1')}
                                <span className="add_ic"></span>
                            </button>
                            <button type="button" className="btn-ripple ezi-grey-btn" >{languageObj.translate('Export.1')}</button>
                        </div>
                    </div>
                </div>
                <div className="users-table-content">
                    <SweetSearch ref={inputSearch} change={handleTableSearch} loading={searchLoading} />
                    {(usersData.length > 0 && dataColumn.length > 0) &&
                        <div className="saved_participants_table">
                            <DataTable
                                data={usersData}
                                columns={dataColumn}
                                hideSelectAll
                                page={pagination.current_page}
                                sizePerPage={per_page}
                                onTableChange={handleTableChange}
                                keyField="id"
                                totalSize={pagination.total}
                                hideSelect
                            />
                        </div>
                    }
                    {(usersData.length === 0) &&
                        <div className="saved_participants_table">
                            <DataTable data={[]} columns={[{}]} keyField="id" hideSelect />
                        </div>
                    }
                </div>
            </section>
            {loading && <EziLoader />}
        </React.Fragment>
    )
}

export default Users;