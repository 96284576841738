import React from 'react';
import { Button, Modal } from 'react-bootstrap';

function DeleteModal(props) {
    return(
        <Modal show={props.small} onClick={props.popModal}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure ? You want to delete this entry !
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.popModal}>Cancel</Button>
                <Button variant="primary" onClick={props.confirmDelete}>Confirm</Button>
            </Modal.Footer>
      </Modal>
    )
}

export default DeleteModal;