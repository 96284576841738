/**
 * Get Feature Access All Application.
 * 
 * @param {Object} accessControl All Accesses
 * @returns {Object} All Features Access.
 */
export const appAccess = (accessControl = null) => {
    return {
        /** Peoples Directory */
        view_peoples: (accessControl && accessControl["peoples"] !== undefined && accessControl["peoples"].includes("READ")),

        view_employee: (accessControl && accessControl["employee"] !== undefined && accessControl["employee"].includes("READ")),
        view_customer: (accessControl && accessControl["customer"] !== undefined && accessControl["customer"].includes("READ")),
        view_others: (accessControl && accessControl["others"] !== undefined && accessControl["others"].includes("READ")),

        delete_employee: (accessControl && accessControl["employee"] !== undefined && accessControl["employee"].includes("DELETE")),
        delete_customer: (accessControl && accessControl["customer"] !== undefined && accessControl["customer"].includes("DELETE")),
        delete_others: (accessControl && accessControl["others"] !== undefined && accessControl["others"].includes("DELETE")),

        edit_employee: (accessControl && accessControl["employee"] !== undefined && accessControl["employee"].includes("UPDATE")),
        edit_customer: (accessControl && accessControl["customer"] !== undefined && accessControl["customer"].includes("UPDATE")),
        edit_others: (accessControl && accessControl["others"] !== undefined && accessControl["others"].includes("UPDATE")),

        create_employee: (accessControl && accessControl["employee"] !== undefined && accessControl["employee"].includes("CREATE")),
        create_customer: (accessControl && accessControl["customer"] !== undefined && accessControl["customer"].includes("CREATE")),
        create_others: (accessControl && accessControl["others"] !== undefined && accessControl["others"].includes("CREATE")),

        /** Theme and Subthemes */
        create_theme: (accessControl && accessControl["themes"] !== undefined && accessControl["themes"].includes("CREATE")),
        update_theme: (accessControl && accessControl["themes"] !== undefined && accessControl["themes"].includes("UPDATE")),
        delete_theme: (accessControl && accessControl["themes"] !== undefined && accessControl["themes"].includes("DELETE")),
        /** Templates */
        create_template: (accessControl && accessControl["templates"] !== undefined && accessControl["templates"].includes("CREATE")),
        update_template: (accessControl && accessControl["templates"] !== undefined && accessControl["templates"].includes("UPDATE")),
        delete_template: (accessControl && accessControl["templates"] !== undefined && accessControl["templates"].includes("DELETE")),
        /** Survey */
        create_survey: (accessControl && accessControl["survey"] !== undefined && accessControl["survey"].includes("CREATE")),
        edit_survey: (accessControl && accessControl["survey"] !== undefined && accessControl["survey"].includes("UPDATE")),
        delete_survey: (accessControl && accessControl["survey"] !== undefined && accessControl["survey"].includes("DELETE")),
        /** User & Roles */
        users: (accessControl && accessControl["users"] !== undefined && accessControl["users"].includes("READ")),
        roles: (accessControl && accessControl["roles"] !== undefined && accessControl["roles"].includes("READ")),
        profiles: (accessControl && accessControl["profiles"] !== undefined && accessControl["profiles"].includes("READ")),

        /** Root Dashboard */
        analyze_survey: (accessControl && accessControl["survey"] !== undefined && accessControl["survey"].includes("READ")),
        user_manage: (accessControl && accessControl["account_management"] !== undefined && accessControl["account_management"].includes("READ")),

        /** Users */
        add_user: (accessControl && accessControl["users"] !== undefined && accessControl["users"].includes("CREATE")),
        edit_user: (accessControl && accessControl["users"] !== undefined && accessControl["users"].includes("UPDATE")),
        delete_user: (accessControl && accessControl["users"] !== undefined && accessControl["users"].includes("DELETE")),

        /** Roles */
        add_role: (accessControl && accessControl["roles"] !== undefined && accessControl["roles"].includes("CREATE")),
        edit_role: (accessControl && accessControl["roles"] !== undefined && accessControl["roles"].includes("UPDATE")),
        delete_role: (accessControl && accessControl["roles"] !== undefined && accessControl["roles"].includes("DELETE")),

        /** Profile */
        add_profile: (accessControl && accessControl["profiles"] !== undefined && accessControl["profiles"].includes("CREATE")),
        edit_profile: (accessControl && accessControl["profiles"] !== undefined && accessControl["profiles"].includes("UPDATE")),
        delete_profile: (accessControl && accessControl["profiles"] !== undefined && accessControl["profiles"].includes("DELETE")),

        /** Admin */
        add_admin: (accessControl && accessControl["admin"] !== undefined && accessControl["admin"].includes("CREATE")),
        edit_admin: (accessControl && accessControl["admin"] !== undefined && accessControl["admin"].includes("UPDATE")),
        /** Partner */
        add_partner: (accessControl && accessControl["partner"] !== undefined && accessControl["partner"].includes("CREATE")),
        edit_partner: (accessControl && accessControl["partner"] !== undefined && accessControl["partner"].includes("UPDATE")),
        delete_partner: (accessControl && accessControl["partner"] !== undefined && accessControl["partner"].includes("DELETE")),
        /** Partner Template */
        partner_create_template: (accessControl && accessControl["partner_templates"] !== undefined && accessControl["partner_templates"].includes("CREATE")),
        partner_update_template: (accessControl && accessControl["partner_templates"] !== undefined && accessControl["partner_templates"].includes("UPDATE")),
        partner_delete_template: (accessControl && accessControl["partner_templates"] !== undefined && accessControl["partner_templates"].includes("DELETE")),
    }
}