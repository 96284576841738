import React from 'react';
import { Bar } from 'react-chartjs-2';

const ChannelEffectiveness = () =>{

    const barChartData = {
        labels: ["SMS", "Email", "Email embed"],
        datasets: [
            {
                label: "Total Invitees",
                backgroundColor: '#FF5757',
                borderColor: '#FF5757',
                data: [100, 50, 150],
                offsetGridLines : false,
            },
            {
                
                label: "Responded Invitees",
                backgroundColor: '#5A4E63',
                borderColor: '#5A4E63',
                data: [50, 150, 100],
            },
            {
                label: "Dropouts",
                backgroundColor: '#FBCD49',
                borderColor: '#FBCD49',
                data: [150, 100, 50],
            }
        ]
    };

    
    const barChartOptions = {
        responsive: true,   
        maintainAspectRatio: false,
        legend: {
            display: true,
            align:'start',
            position:'top',
            labels: {
                fontColor: '#5A4E63',
                boxWidth: 6,
                fontSize:12,
                fontFamily :"NunitoSansSemiBold",
                padding	:20,
                usePointStyle:true,
            }
        },
        scales: {
            xAxes: [{
                barPercentage: 0.5,
                categoryPercentage: 0.4,
                ticks: { fontColor: "#C3CBD4",fontFamily: "NunitoSans", fontSize: 12, stepSize: 6, beginAtZero: true,},
                gridLines: { color: "transparent",zeroLineColor: 'transparent',}, 
            }],

            yAxes: [{
                ticks: { fontColor: "#C3CBD4",fontFamily: "NunitoSans", fontSize: 12, stepSize: 50, beginAtZero: true , padding: 15},
                 gridLines: { color: '#EDEDED',  zeroLineColor: '#EDEDED',borderColor:"transparent",   drawBorder: false, } }]
             }
        
    };
    

    return(

        <div className="channel-effectiveness-card">

            <div className="channel-effectiveness-card-header">
                <span className="header-tit">Channel Effectiveness</span>
            </div>

            <div className="report-card-body">

                <Bar data={barChartData} options={barChartOptions}/>
                

            </div>
            
        </div>
        

    )


}

export default ChannelEffectiveness;