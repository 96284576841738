import React , { useState , useEffect , useRef,useContext ,useCallback} from 'react';
import { Doughnut , HorizontalBar , Bar , Chart} from 'react-chartjs-2';
import CommentFilter from './ReportCommentFilter'
import SweetSearch from "components/SweetSearch";
import { Tab , Nav } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import Axios from "utility/Axios";
import configURL from 'config/config';
import { toast } from "react-toastify";
import AppContext from 'store/AppContext';
import ReactWordcloud from 'react-wordcloud';

const CommentType = (props) => {  
    const urlParams = useParams();
    const  CHECKBOX_VALUE = [ 'All','Passive','Promoter','Detractors' ]
    const SMILY_CLASSES = ['smile', 'sad', 'very-poor', 'poor'];
    const [ questionData, setQuestionData] = useState(props.question);
    const [ showFilter, setShowFilter] = useState(false);
    const [ key, setKey] = useState( !(props.question && props.question.question_type === "MULTIPLELINETEXT") ? 'distribution' : 'comments'  );    
    const [ surveyId , setSurveyId ] = useState(urlParams.survey_id);
    const [ questionBarData , setQuestionBarData ] = useState([]);   
    const [ commentCountLabel , setCommentCountLabel ] = useState([]);  
    const [ commentCountData , setCommentCountData ] = useState([]);    
    const [ keyPhrase , setKeyPhrase ] = useState([]);
    const [ keyPhraseLabel , setKeyPhraseLabel ] = useState([]);
    const [ distributionData , setDistributionData ] = useState([]);
    const [ commentList , setCommentList ] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);  
    const { EziLoader } = useContext(AppContext)
    let searchTimer = null;
    let showCommentTab =false;
    let inputSearch = useRef(null);
    let distributionName = useRef();
    let distributionTime = useRef();

    const  wordsData =  [
        { text: 'told', value: 64, }, { text: 'mistake', value: 11, }, { text: 'thought', value: 16, },
        { text: 'bad', value: 17, }, { text: 'correct', value: 10, }, { text: 'day', value: 54, },
        { text: 'prescription', value: 12, }, { text: 'time', value: 77, }, { text: 'thing', value: 45, },
        { text: 'left', value: 19, }, { text: 'pay', value: 13, }, { text: 'people', value: 32, },
        { text: 'month', value: 22, }, { text: 'again', value: 35, }, { text: 'review', value: 24, },
        { text: 'call', value: 38, }, { text: 'doctor', value: 70, }, { text: 'asked', value: 26, },
        { text: 'finally', value: 14, }, { text: 'insurance', value: 29, }, { text: 'week', value: 41, },
        { text: 'called', value: 49, }, { text: 'problem', value: 20, }, { text: 'going', value: 59, },
        { text: 'help', value: 49, }, { text: 'felt', value: 45, }, { text: 'discomfort', value: 11, },
        { text: 'lower', value: 22, }, { text: 'severe', value: 12, }, { text: 'free', value: 38, },
        { text: 'better', value: 54, }, { text: 'muscle', value: 14, }, { text: 'neck', value: 41, },
        { text: 'root', value: 24, }, { text: 'adjustment', value: 16, }, { text: 'therapy', value: 29, },
        { text: 'injury', value: 20, }, { text: 'excruciating', value: 10, }, { text: 'chronic', value: 13, },
        { text: 'chiropractor', value: 35, }, { text: 'treatment', value: 59, }, { text: 'tooth', value: 32, },
        { text: 'chiropractic', value: 17, }, { text: 'dr', value: 77, }, { text: 'relief', value: 19, },
        { text: 'shoulder', value: 26, }, { text: 'nurse', value: 17, }, { text: 'room', value: 22, },
        { text: 'hour', value: 35, }, { text: 'wait', value: 38, }, { text: 'hospital', value: 11, },
        { text: 'eye', value: 13, }, { text: 'test', value: 10, }, { text: 'appointment', value: 49, },
        { text: 'medical', value: 19, }, { text: 'question', value: 20, }, { text: 'office', value: 64, },
        { text: 'care', value: 54, }, { text: 'minute', value: 29, }, { text: 'waiting', value: 16, },
        { text: 'patient', value: 59, }, { text: 'health', value: 49, }, { text: 'alternative', value: 24, },
        { text: 'holistic', value: 19, }, { text: 'traditional', value: 20, }, { text: 'symptom', value: 29, },
        { text: 'internal', value: 17, }, { text: 'prescribed', value: 26, }, { text: 'acupuncturist', value: 16, },
        { text: 'pain', value: 64, }, { text: 'integrative', value: 10, }, { text: 'herb', value: 13, },
        { text: 'sport', value: 22, }, { text: 'physician', value: 41, }, { text: 'herbal', value: 11, },
        { text: 'eastern', value: 12, }, { text: 'chinese', value: 32, }, { text: 'acupuncture', value: 45, },
        { text: 'prescribe', value: 14, }, { text: 'medication', value: 38, }, { text: 'western', value: 35, },
        { text: 'sure', value: 38, }, { text: 'work', value: 64, }, { text: 'smile', value: 17, },
        { text: 'teeth', value: 26, }, { text: 'pair', value: 11, }, { text: 'wanted', value: 20, },
        { text: 'frame', value: 13, }, { text: 'lasik', value: 10, }, { text: 'amazing', value: 41, },
        { text: 'fit', value: 14, }, { text: 'happy', value: 22, }, { text: 'feel', value: 49, },
        { text: 'glasse', value: 19, }, { text: 'vision', value: 12, }, { text: 'pressure', value: 16, },
        { text: 'find', value: 29, }, { text: 'experience', value: 59, }, { text: 'year', value: 70, },
        { text: 'massage', value: 35, }, { text: 'best', value: 54, }, { text: 'mouth', value: 20, },
        { text: 'staff', value: 64, }, { text: 'gum', value: 10, }, { text: 'chair', value: 12, },
        { text: 'ray', value: 22, }, { text: 'dentistry', value: 11, }, { text: 'canal', value: 13, },
        { text: 'procedure', value: 32, }, { text: 'filling', value: 26, }, { text: 'gentle', value: 19, }, 
        { text: 'cavity', value: 17, }, { text: 'crown', value: 14, }, { text: 'cleaning', value: 38, },
        { text: 'hygienist', value: 24, }, { text: 'dental', value: 59, }, { text: 'charge', value: 24, },
        { text: 'cost', value: 29, }, { text: 'charged', value: 13, }, { text: 'spent', value: 17, },
        { text: 'paying', value: 14, }, { text: 'pocket', value: 12, }, { text: 'dollar', value: 11, },
        { text: 'business', value: 32, }, { text: 'refund', value: 10, },
      ];
      const [ wordCount , setWordCount ] = useState(20);
      const wordOptions = {
          colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
          enableTooltip: true,
          deterministic: false,
          fontFamily: 'NunitoSansSemiBold',
          fontSizes: [5,50],
          rotations:0,
   
        };

    const handleFilterSearch = () => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            commentListing();
        }, 1000);
    }
   
    const setFilter = (filter) => {
        distributionListing();
    }
  
    const questionTypeListing = () => {
        EziLoader.show();
        let formData = new FormData();
        formData.append("survey_id", surveyId);
        formData.append("question_type", props.question.question_type);
        formData.append("question_id", props.question.id);  
        formData.append("filters",JSON.stringify(props.filterData));  
        formData.append("date",JSON.stringify(props.dateFilter));  
        Axios.post(`${configURL.ReportAnalysisBarChart}`, formData).then(response => {
            EziLoader.hide(); 
            if (response.data.success !== undefined && response.data.success) {                             
                setQuestionBarData(response.data.result);
            } else {
                toast.warn(response.data.message);
            }
        })
    } 
    const commentCount = () => {
        let formData = new FormData();
        EziLoader.show();
        formData.append("survey_id", surveyId);
        formData.append("question_type", props.question.question_type);
        formData.append("question_id", props.question.id);
        formData.append("filters",JSON.stringify(props.filterData));
        formData.append("date",JSON.stringify(props.dateFilter));
        Axios.post(`${configURL.ReportAnalysisCommentCount}`, formData).then(response => {
            EziLoader.hide(); 
            if (response.data.success !== undefined && response.data.success) {                
                const commnetValue = [];
                if(response.data.result.lables !== undefined && response.data.result.lables){
                     setCommentCountLabel(response.data.result.lables);
                }
                 var count=response.data.result.commentcount;
                 if(response.data.result.count !== undefined && response.data.result.count){
                for (var index in count) {                   
                        if (count.hasOwnProperty(index)) 
                        {
                              commnetValue.push(count[index]);
                            
                        }
                } 
            }
                setCommentCountData(commnetValue);
                console.log("Comment count data",commentCountData)
                showCommentTab=response.data.success;
                keyPhraseListing();
                commentListing();
            }
            //  else {
            //     showCommentTab=(response.data.success);
            // }
        })
    }
    const keyPhraseListing = () => {
        EziLoader.show();
        let formData = new FormData();
        formData.append("survey_id", surveyId);        
        formData.append("question_type", props.question.question_type);
        formData.append("question_id", props.question.id);
        formData.append("filters",JSON.stringify(props.filterData));
        formData.append("date",JSON.stringify(props.dateFilter));
        Axios.post(`${configURL.ReportAnalysisSentiment}`, formData).then(response => {          
            EziLoader.hide();
            if (response.data.success !== undefined && response.data.success) {  
                const sentimentKey = [];
                const sentimentValue = [];              
                response.data.result.forEach((k, v) => {                   
                    sentimentKey.push(k.key);
                    sentimentValue.push(k.value); 
                });
                setKeyPhraseLabel(sentimentKey);
                setKeyPhrase(sentimentValue);
                                  
            } else {
                toast.warn(response.data.message);
            }
        })
    }
    const commentListing = ( filters = []) => {
        EziLoader.show();
        let formData = new FormData();
        formData.append("survey_id", surveyId);
        let search = (inputSearch.current?inputSearch.current.value:"");
        formData.append("search", search);
        formData.append("question_type", props.question.question_type);
        formData.append("question_id", props.question.id);
        formData.append("comment_filters",JSON.stringify(filters)); 
        formData.append("filters",JSON.stringify(props.filterData));
        formData.append("date",JSON.stringify(props.dateFilter));
        Axios.post(`${configURL.ReportAnalysisComment}`, formData).then(response => {
            EziLoader.hide();
            if (response.data.success !== undefined && response.data.success) {                
                setCommentList(response.data.result.data);
                                  
            } else {
                toast.warn(response.data.message);
            }
        })
    }
    const distributionListing = () => {
        let formData = new FormData();
        EziLoader.show();
        let value = (distributionName.current?distributionName.current.value:"");
        let distributionFilter = (distributionTime.current?distributionTime.current.value:"hourly");
        formData.append("survey_id", surveyId);
        formData.append("filter",distributionFilter);
        formData.append("question_type", props.question.question_type);
        formData.append("question_id", props.question.id);
        formData.append("keyword",value)
        formData.append("filters",JSON.stringify(props.filterData));
        formData.append("date",JSON.stringify(props.dateFilter));
        Axios.post(`${configURL.ReportAnalysisDistribution}`, formData).then(response => {
            EziLoader.hide();
            if (response.data.success !== undefined && response.data.success) {                
                setDistributionData(response.data.result);              
            } else {
                toast.warn(response.data.message);
            }
        })
    }

    const responseRateBarData = {
        labels: questionBarData.lables,
        datasets: [ 
            {
                label: "Count",
                backgroundColor: '#CE2E6C' ,
                borderColor:'#CE2E6C',
                data:questionBarData.count ,
                offsetGridLines : false,
            }
        ]
    };

    const responseRateBarOptions = {
        responsive: true,   
        maintainAspectRatio: false,
        animation: {
            duration: 0,
            onComplete: function () {
                // render the value of the chart above the bar
                var ctx = this.chart.ctx;
                ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, 'bold', Chart.defaults.global.defaultFontFamily);
                ctx.fillStyle = "#5A4E63";
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
                this.data.datasets.forEach(function (dataset) {
                    for (var i = 0; i < dataset.data.length; i++) {
                        var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                        ctx.fillText(dataset.data[i], model.x, model.y - 5);
                    }
            });
        }},
        layout: { padding: { top: 20, } },
        legend: {
            display: false,
            align:'start',
            position:'top',
            labels: { fontColor: '#5A4E63', boxWidth: 6, fontSize:12, fontFamily :"NunitoSansSemiBold", padding	:20, usePointStyle:true, }
        },
        scales: {
            xAxes: [{
                // categoryPercentage:0.8,
                // barPercentage: 1.0,
                barThickness: 24,
                ticks: { fontColor: "#373737",fontFamily: "NunitoSans", fontSize: 12, stepSize: 6, beginAtZero: true,},
                gridLines: { color: "transparent",zeroLineColor: 'transparent',}, 
            }],

            yAxes: [{
       
                ticks: { fontColor: "#5B5B5B",fontFamily: "NunitoSans", fontSize: 12, stepSize: 10, beginAtZero: true , padding: 15,},
                 gridLines: { color: '#EDEDED',  zeroLineColor: '#EDEDED',borderColor:"transparent",   drawBorder: false, } }]
             }
        
    };

    const sentimentDoughnutData = {

        labels: commentCountLabel,
        datasets: [{
            data: commentCountData,
            backgroundColor: [ '#F1B343' , '#CE2E6C', '#009245' ],
        }]
    }

    const sentimentDoughnutOptions = {
        segmentShowStroke: false,
        responsive: true, 
        maintainAspectRatio: false,  
        legend: {
            display: true,
            align:'center',
            position:'bottom',
            labels: { fontColor: '#5A4E63', boxWidth: 6, fontSize:12, fontFamily :"NunitoSansSemiBold", padding	:24, usePointStyle:true, }
        },
        cutoutPercentage: 67,
        elements: { arc: { borderWidth: 0 } },
        tooltips: { displayColors: false, backgroundColor: "#5A4E63", enabled: true, mode: "single", bodyFontSize: 13, bodyFontFamily: "NunitoSansSemiBold", bodyFontColor: "#FFFFFF", yPadding: 8, xPadding: 15, cornerRadius: 5, },
    }

    const trendingKeywordData = {
        labels: keyPhraseLabel,
        datasets: [
          {
            label: 'Dataset',
            backgroundColor: '#CE2E6C',
            borderColor: '#CE2E6C',
            hoverBackgroundColor: '#CE2E6C',
            hoverBorderColor: '#CE2E6C',
            borderWidth: 1,
            data: keyPhrase
          }
        ]
      };

    const trendingKeywordOptions = {
        responsive: true,   
        maintainAspectRatio: false,
        legend: { display: false, },
        layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
        scales: {
            xAxes: [{
                ticks: { display:false},
                gridLines: {   color: "rgba(0, 0, 0, 0)",zeroLineColor: '#EBEBEB',}, 
            }],
            yAxes: [{
                barPercentage: 1.8,
                categoryPercentage: 0.3,
                ticks: { fontColor: "#373737",fontFamily: "NunitoSansSemiBold", fontSize: 12, stepSize: 10, padding: 15},
                 gridLines: {  color: "transparent",  zeroLineColor: 'transparent', } }]
            }        
    };
 
    const questionDistributionData = {
        datasets: [{
            
            datalabels: { display: 'auto',color: '#FF7AAD', font:{size:'13',family:'NunitoSansBold'} , anchor: 'end', align: 'top', formatter: Math.round, font: { weight: 'bold' } },
            label: 'Total Responses',
            type:'line',
            data:distributionData.counts,
            fill: false,
            borderColor: '#FFBD24',
            backgroundColor: '#FFBD24',
            pointBorderColor: '#FFBD24',
            pointBackgroundColor: '#FFBD24',
            pointHoverBackgroundColor: '#FFBD24',
            pointHoverBorderColor: '#FFBD24',
            yAxisID: 'y-axis-2',
            borderWidth: 2,
        },
        {
            datalabels: { display: true, color: '#fff'},
            type: 'bar',
            label: distributionData.keyword,
            fill: false,
            backgroundColor: [ '#CE2E6C','#CE2E6C','#CE2E6C','#CE2E6C','#CE2E6C','#CE2E6C','#CE2E6C'],
            borderColor: [ '#CE2E6C','#CE2E6C','#CE2E6C','#CE2E6C','#CE2E6C','#CE2E6C','#CE2E6C'],
            yAxisID: 'y-axis-1',
            data:distributionData.keyword_count,
        }
    ]};
        
    const questionDistributionOptions = {   
        showTooltips: true,  
        responsive: true,
        maintainAspectRatio: false,
        labels: distributionData.lables,
        legend: {
            display: true,
            align:'center',
            position:'bottom',
            labels: { fontColor: '#5A4E63', boxWidth: 6, fontSize:12, fontFamily :"NunitoSansSemiBold", padding	:24, usePointStyle:true, }
        },
        layout: {
            padding: {top: 35,bottom:20}
        },
        elements: { line: { fill: false } },
        scales: {        
            xAxes: [{
                display: true,
                gridLines: { display: false },        
                labels: distributionData.lables,
                barThickness: 28,
                ticks: { fontColor: "#686868",fontFamily: "NunitoSansSemiBold", fontSize: 12, padding: 5,},
            }],
            yAxes: [{
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
                gridLines: { display: false },
                labels: { show: true },
                scaleLabel: {
                    display: true,
                    labelString: "Count",
                    fontColor: "#5B5B5B",fontFamily: "NunitoSansSemiBold", fontSize: 13, 
                    padding:{bottom:15} 
                  },
                ticks: { fontColor: "#686868",fontFamily: "NunitoSansSemiBold", fontSize: 12, padding: 5, precision: 0,beginAtZero: true,},
            },
            {
                type: 'linear',
                display: true,
                position: 'right',
                id: 'y-axis-2',
                scaleLabel: {
                    display: true,
                    labelString: "Total Responses",
                    fontColor: "#5B5B5B",fontFamily: "NunitoSansSemiBold", fontSize: 13, 
                    padding:{bottom:15} 
                  },
                labels: { show: true },
                   ticks: { fontColor: "#686868",fontFamily: "NunitoSansSemiBold", fontSize: 12,  padding: 5, precision: 0,beginAtZero: true},
                   gridLines: { color: '#EBEBEB',  zeroLineColor: '#EDEDED',borderColor:"transparent",   drawBorder: false,  }
            }
        ]}
        
    };

    const plugins = [{
        afterDraw: (chartInstance, easing) => {
        const ctx = chartInstance.chart.ctx;
        ctx.fillText("", 100, 100);
        }
    }];
  
    useEffect(() => {     
        distributionName = (distributionName.current?"":"");
   
        setQuestionData(props.question); 
        setKey( !(props.question && props.question.question_type === "MULTIPLELINETEXT") ? 'distribution' : 'comments'  );   
        commentCount();      
        
        if(!(props.question && props.question.question_type === "MULTIPLELINETEXT")){
            questionTypeListing();
            distributionListing();
        }
    }, [props.question , props.filterData , props.dateFilter]);
  
 

    return(           
        <React.Fragment>
            <section className="CX-QuestionComponent CE-CommentType">
                
                <div className="question-type-wrap">
                    <label className="question">{questionData && questionData.title}</label>
                    {/* <div className="question-type checkbox-multi">{questionData && questionData.type} Type Question</div> */}
                </div>
            
                { !(questionData && questionData.question_type === "MULTIPLELINETEXT") &&              
                    (
                        <div className="ce-chart-box-wrap response-rate-box">
                            <div className="ce-chart-box">
                                <div className="ce-chart-box-header">
                                    <span className="left-text">Analysis</span>
                                </div>
                                <div className="ce-chart-box-body">
                                    <div className="doughnut-center-align">
                                        <Bar data={responseRateBarData} options={responseRateBarOptions} height={250}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className="question-comment-tab">
                    <div className="tablist_ezi">
                        <Tab.Container  activeKey={key} onSelect={(k) => setKey(k)}  >
                            <div className="tab-header-wrap">                                
                                <div className="tab-left-header">
                                    <Nav variant="pills" >
                                            { !(questionData && questionData.question_type === "MULTIPLELINETEXT") &&
                                                <Nav.Item>
                                                    <Nav.Link eventKey="distribution">Distribution</Nav.Link>
                                                </Nav.Item>
                                            }
                                            {(showCommentTab) &&
                                                <Nav.Item>
                                                    <Nav.Link eventKey="comments">Comments</Nav.Link>
                                                </Nav.Item> 
                                            }  
                                    </Nav>       
                                </div>                                
                            </div>
                            <Tab.Content>
                            { !(questionData && questionData.question_type === "MULTIPLELINETEXT") &&
                                <Tab.Pane eventKey="distribution" className="question-tab-body question-distribution-body" mountOnEnter unmountOnExit>
                                    
                                    <div className="question-distribution-chart-wrap">
                                        <div className="question-distribution-chart">
                                            <div className="question-distribution-select-wrap">
                                                <div className="dist-lebel-select">
                                                    <label>Distribution :</label> 
                                                    <select className="distribution-select"  onChange={(e) => setFilter(e.target.value)} ref={distributionName}>
                                                    {/* {questionBarData.lables && setDistributionName(questionBarData.lables[0])} */}

                                                    {questionBarData.lables && questionBarData.lables.map((key)=>  
                                                          <option value={key}>{key}</option>
                                                    )}
                                                    </select>                                              
                                                </div>
                                                <select className="distribution-select"  onChange={(e) => setFilter(e.target.value)} ref={distributionTime} >
                                                    <option value="hourly" selected>Hourly</option>
                                                    <option value="daily" >Daily</option>
                                                    <option value="weekly">Weekly</option>
                                                    <option value="monthly">Monthly</option>
                                                </select>
                                            </div>
                                            <div className="question-distribution-chart-body">                                                
                                                <Bar data={questionDistributionData} options={questionDistributionOptions} plugins={plugins} height={340} />   
                                            </div>
                                        </div>
                                    </div>

                                </Tab.Pane>  
                                }                        
                                <Tab.Pane eventKey="comments" className="question-tab-body question-comments-body" mountOnEnter unmountOnExit>

                                    <div className="comment-chart-component">
                                        <div className="comment-chart-header">
                                            <span className="ce-title">What's the buzz ?</span>
                                            <span className="ce-subtitle">98 Comments</span>
                                        </div>
                                        {/* <label className="share-comment-heading">Please share any other comments to serve you better</label> */}
                                        <div className="ce-chart-content">
                                            <div className="ce-chart-box-wrap sentiment-box">
                                                <div className="ce-chart-box">
                                                    <div className="ce-chart-box-header">
                                                        <span className="left-text">Sentiment</span>
                                                    </div>
                                                    <div className="ce-chart-box-body">
                                                        <div className="doughnut-center-align">
                                                            <Doughnut data={sentimentDoughnutData} options={sentimentDoughnutOptions} height={200} width={200}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="hortalzontal-line-box">
                                                <div className="ce-chart-box">
                                                    <div className="ce-chart-box-header word-count-select-header">
                                                        <span className="left-text">Trending Keywords</span>
                                                        <div className="word-count-select">
                                                            <span className="word-count-label">Word Count :</span>
                                                            <select className="distribution-select" onChange={(e) => setWordCount(e.target.value)} >
                                                                <option value="10">10</option>
                                                                <option value="20" selected>20</option>
                                                                <option value="30">30</option>
                                                                <option value="50">40</option>
                                                                <option value="50">50</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="hortalzontal-line-box-body ">
                                                        <div className="word-cloud-wrapper">
                                                            <ReactWordcloud words={wordsData} options={wordOptions} maxWords={wordCount}/>
                                                        </div>
                                                        {/* <HorizontalBar data={trendingKeywordData} options={trendingKeywordOptions} height={250} /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Tab.Pane>                          
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>

                
                    
                {  (key === "comments") &&  <React.Fragment>

                    <div className="ce-comment-section question-comment-section">
                        <div className="ce-comment-section-header">
                            <label className="commnet-label">Comments</label>
                            <div className="comment-right-header">
                            
                                <div className="comment-filter-btn-wrap">
                                    <button type="button" className="ezi-filter-btn" onClick={() => setShowFilter(!showFilter)}>Filter</button>
                                    <CommentFilter
                                        show={showFilter}
                                        hide={() => setShowFilter(false)}
                                        position="right"
                                        applyFilter={(filters) => commentListing(filters)}
                                    />
                                </div>
                                {/* <button type="button" className="comment-download">Download</button> */}
                                <SweetSearch loading={searchLoading} change={handleFilterSearch} ref={inputSearch} />
                            </div>
                        </div>
                        <label className="ce-comment-title">Please share any other comments to serve you better</label>
                        <div className="ce-comment-body">
                            {
                                commentList && commentList.map((item,index)=>
                                    <div className={`comment-box-emoji-wrap ${item.sentiment_score.toLowerCase()}`}>
                                        <div  className="ce-comment-box">
                                            <div className="comment-box-text-wrap">
                                                <span className="comment-heading">{item.comments} </span>
                                                <div className="comment-badge-wrap">
                                                    {item.key_phrases && item.key_phrases.map((key)=>  
                                                        <span className="comment-badge">{key.Text}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="comment-box-right-text">
                                                <span className="comment-date-title">{(item.respondent_fname)}</span>
                                                <span className="comment-date">{item.sentiment_created_at} </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }    
                        </div>
                    </div>
                </React.Fragment> }

            </section>
        </React.Fragment>   
    )


}

export default CommentType;