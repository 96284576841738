import React, { useState, useContext, useEffect } from 'react';
import { Breadcrumb, Spinner } from 'react-bootstrap';
import AppContext from 'store/AppContext';
import { PERMISSION_DATA } from 'constants/constants';
import Axios from 'utility/Axios';
import configURL from 'config/config';
import useForm from 'react-hook-form';
import { toast } from 'react-toastify';

const ProfileDetails = (props) => {
    const { profile_id = null } = props.match.params
    const { languageObj = {}, EziLoader, accesFeature = {}, handleUnAuthWarn } = useContext(AppContext);
    const [isSaving, setIsSaving] = useState(false)
    const [allAssigned, setAllAssigned] = useState(false)
    const [profileData, setProfileData] = useState({
        name: "",
        description: "",
        access_matrix: {},
        is_default: false
    })
    const { register, handleSubmit, errors } = useForm()

    useEffect(() => {
        if (profileData.is_default) {
            toast.warn("Default profiles changes are not allowed")
        }
    }, [profileData.is_default]);

    useEffect(() => {
        if (profile_id && profile_id !== "") {
            EziLoader.show()
            let formData = new FormData();
            formData.append("profile_id", profile_id)
            Axios.post(configURL.profile_detail, formData).then(res => {
                EziLoader.hide()
                if (res.data.success === true) {
                    setProfileData({
                        name: res.data.data.name,
                        description: res.data.data.description,
                        access_matrix: res.data.data.access_matrix || {},
                        is_default: res.data.data.is_default || false
                    })
                }
            })
        } else {
            const permissions = { ...PERMISSION_DATA }
            const newUserPer = {};
            Object.keys(permissions).forEach(el => newUserPer[el] = ["READ"])
            setProfileData({ ...profileData, access_matrix: newUserPer })
        }
    }, [])

    const handleInputChange = ({ target }) => {
        let { name, value } = target
        setProfileData({
            ...profileData,
            [name]: value
        })
    }

    const handleAssignAll = (event, node) => {
        let isChecked = event.checked
        let access_matrix = profileData.access_matrix
        if (isChecked) {
            if (!access_matrix.hasOwnProperty(node)) {
                access_matrix[node] = []
            }
            access_matrix[node] = ["CREATE", "READ", "UPDATE", "DELETE"]
        } else {
            delete access_matrix[node]
        }
        setProfileData({ ...profileData, access_matrix })
    }

    const isAllAssigned = (node) => {
        let nodeMatrix = profileData.access_matrix[node]
        let access = ["CREATE", "READ", "UPDATE", "DELETE"]
        return nodeMatrix && access.every(el => nodeMatrix.includes(el))
    }

    const saveProfileData = () => {
        setIsSaving(true)
        let formData = new FormData();
        formData.append("name", profileData.name)
        formData.append("description", profileData.description)
        formData.append("access_matrix", JSON.stringify(profileData.access_matrix))
        if (profile_id && profile_id !== "") {
            formData.append("profile_id", profile_id)
        }
        Axios.post(configURL.save_profile, formData).then(res => {
            setIsSaving(false)
            if (res.data.success === true) {
                toast.success("Profile Saved")
                props.history.push("/profiles")
            } else {
                toast.warn(res.data.message)
            }
        })
    }
    const isChecked = (node, permission) => {
        return Object.keys(profileData.access_matrix).length > 0 && profileData.access_matrix.hasOwnProperty(node) && profileData.access_matrix[node].includes(permission)
    }
    const handlePermissionCheck = (target, node) => {
        let isChecked = target.checked;
        let permission = target.value;
        const access_matrix = { ...profileData.access_matrix };
        if (isChecked) {
            if (!access_matrix.hasOwnProperty(node)) {
                access_matrix[node] = []
            }
            let permissions = access_matrix[node]
            permissions.push(permission)
            access_matrix[node] = [...new Set(permissions)];
            setProfileData({ ...profileData, access_matrix })
        } else {
            let permissions = access_matrix[node]
            let index = permissions.indexOf(permission)
            if (index > -1) permissions.splice(index, 1)
            if (permissions.length <= 0) {
                delete access_matrix[node]
            } else {
                access_matrix[node] = [...new Set(permissions)];
            }
            setProfileData({ ...profileData, access_matrix })
        }
    }

    const SaveButton = () => {
        let accessData = (profile_id && profile_id !== "") ? accesFeature.edit_profile : accesFeature.add_profile;
        if (accessData && accessData === true) {
            return (
                <button type="submit" className={`ezi-btn btn-ripple permission-edit active`} disabled={(profileData.is_default)} title={(profileData.is_default) ? 'Default profiles changes are not allowed' : ''}>
                    Save {isSaving && <Spinner animation="grow" color="white" size="sm" />}
                </button>
            )
        } else {
            return (
                <button type="button" className={`ezi-btn btn-ripple permission-edit access_lock`} onClick={handleUnAuthWarn}>
                    Save
                </button>)
        }
    }

    return (
        <React.Fragment>
            <section className="Page-ProfilePermission">
                <form onSubmit={handleSubmit(saveProfileData)}>
                    <div className="breadcrumb_ezi">
                        <Breadcrumb>
                            <Breadcrumb.Item>{languageObj.translate('Settings.1')}</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => props.history.push("/profiles")}>Profiles</Breadcrumb.Item>
                            <Breadcrumb.Item>{profileData.name || "Profile"}</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="column-header">
                            <h1 className="page-heading">{profileData.name || "Profile"} Permissions</h1>
                            <div className="column-header-btn">
                                <SaveButton />
                                <button type="button" className="ezi-grey-btn user-info-action-delete" onClick={() => props.history.goBack()}>
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                    <fieldset disabled={(profileData.is_default)}>
                        <header className="permission-header-wrap">
                            <div className="permission-header-left">
                                <div className="permission-header-field">
                                    <label className="permission-header-form-label">Profile Name</label>
                                    <div className="permission-input-wrap">
                                        <input type="text" className={`permission-header-input active`} name="name" ref={register({ required: true })} defaultValue={profileData.name || ""} onChange={handleInputChange} />
                                        {errors.name && errors.name.type === 'required' && <span className={`error-message`}>* Please Enter Profile Name.</span>}
                                    </div>

                                </div>
                                <div className="permission-header-field">
                                    <label className="permission-header-form-label">Description</label>
                                    <div className="permission-input-wrap">
                                        <textarea className={`permission-header-textarea active`} name="description" ref={register({ required: true })} defaultValue={profileData.description || ""} onChange={handleInputChange} />
                                        {errors.description && errors.description.type === 'required' && <span className={`error-message`}>* Please Enter Profile Description.</span>}
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="privilege-wrap">
                            <h3 className="privilege-heading">Privileges</h3>
                            <div className="privilege-body">
                                <div className="permission-table">
                                    <div className="permission-table-row permission-table-heading">
                                        <div className="permission-table-cell"> Modules</div>
                                        <div className="permission-table-cell"> Assign All</div>
                                        <div className="permission-table-cell"> View </div>
                                        <div className="permission-table-cell"> Create </div>
                                        <div className="permission-table-cell"> Edit </div>
                                        <div className="permission-table-cell"> Delete </div>
                                    </div>
                                    {
                                        Object.keys(PERMISSION_DATA).map((item, index) =>
                                            <div className="permission-table-row" key={index}>
                                                <div className="permission-table-cell" data-title="Modules">
                                                    {PERMISSION_DATA[item]['label']}
                                                </div>
                                                <div className="permission-table-cell" data-title="Assign All">
                                                    <label className="ezi-checkbox" title="Assign All Permissions.">
                                                        <input type="checkbox" onChange={({ target }) => handleAssignAll(target, item)} checked={isAllAssigned(item)} />
                                                        <span className="ezi-checkbox-mark"></span>
                                                    </label>
                                                </div>
                                                {PERMISSION_DATA[item].permissions.map((el, i) => (
                                                    <div key={i} className="permission-table-cell" title={el.toLowerCase()}>
                                                        <label className="ezi-checkbox">
                                                            <input type="checkbox"
                                                                value={el}
                                                                onChange={({ target }) => handlePermissionCheck(target, item)}
                                                                checked={isChecked(item, el)}
                                                            />
                                                            <span className="ezi-checkbox-mark"></span>
                                                        </label>
                                                    </div>)
                                                )}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </section>
        </React.Fragment>
    )
}

export default ProfileDetails;