import React , { useState } from 'react';
import { Bar , Radar } from 'react-chartjs-2';
import { ProgressBar } from 'react-bootstrap';

const Ranking = () => {

    const barChartData = {
        labels: [ 'Rank 1' , 'Rank 2' , 'Rank 3' , 'Rank 4' ],
        datasets: [
            {
                label: "Location",
                backgroundColor: '#FFBD24',
                borderColor: '#FFBD24',
                data: [40, 25, 35 ,30 ,25],
                offsetGridLines : false,
            },
            {
                label: "Comfort",
                backgroundColor: '#FF4E4E',
                borderColor: '#FF4E4E',
                data: [40, 25, 35 ,30 ,25],
                offsetGridLines : false,
            },
            {
                label: "Service",
                backgroundColor: '#5A4E63',
                borderColor: '#5A4E63',
                data: [40, 25, 35 ,30 ,25],
                offsetGridLines : false,
            },
            {
                label: "Value for money",
                backgroundColor: '#CE2E6C',
                borderColor: '#CE2E6C',
                data: [40, 25, 35 ,30 ,25],
                offsetGridLines : false,
            }
        ]
    };

    
    const barChartOptions = {
        responsive: true,   
        maintainAspectRatio: false,
        legend: {
            display: true,
            align:'start',
            position:'top',
            labels: {
                fontColor: '#5A4E63',
                boxWidth: 6,
                fontSize:12,
                fontFamily :"NunitoSansSemiBold",
                padding	:20,
                usePointStyle:true,
            }
        },
        scales: {
            xAxes: [{
                stacked: true, 
                barPercentage: 0.7,
                categoryPercentage: 0.4,
                ticks: { fontColor: "#373737",fontFamily: "NunitoSans", fontSize: 12, stepSize: 10, beginAtZero: true,},
                gridLines: { color: "transparent",zeroLineColor: 'transparent',}, 
            }],

            yAxes: [{
                stacked: true, 
                ticks: { fontColor: "#5B5B5B",fontFamily: "NunitoSans", fontSize: 12, stepSize: 30, beginAtZero: true , padding: 15},
                 gridLines: { color: '#EDEDED',  zeroLineColor: '#EDEDED',borderColor:"transparent",   drawBorder: false, } }]
             }
        
    };

    const radarData = {
        labels: ['Location','Comfort','Value for money','Service'],
        datasets: [
                {
                label: 'Location',
                backgroundColor: 'rgba(206,46,108,0.23)',
                borderColor: '#CE2E6C',
                pointBackgroundColor: '#CE2E6C',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#CE2E6C',
                data: [45,45,45,45],
                borderWidth: 1,
            },
            {
                label: 'Comfort',
                backgroundColor: 'rgba(90,78,98,0.43)',
                borderColor: '#5A4E63',
                pointBackgroundColor: '#5A4E63',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#5A4E63',
                data: [40,40,40,40],
                borderWidth: 1,
            },
            {
                label: 'Value for money',
                backgroundColor: 'rgba(255,189.36,0.62)',
                borderColor: '#FFBD24',
                pointBackgroundColor: '#FFBD24',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#FFBD24',
                data: [35,35,35,35],
                borderWidth: 1,
            },
            {
                label: 'Service',
                backgroundColor: 'rgba(50,203,187,0.35)',
                borderColor: '#32CBBB',
                pointBackgroundColor: '#32CBBB',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#32CBBB',              
                data: [20,20,20,20],
                borderWidth: 1,
            },
           
          
        
          
         
         
        ]
      };

      const radarOptions = {

        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false,
          },
        scale: {
            pointLabels :{
                fontSize: 12,
                fontColor:"#5A4E63",
            },
            ticks: {
                beginAtZero: true,
                min:25,
                max: 50,
            }
        },
        gridLines: {
            color: [ '#E9E9E9' ]
          },
           
        

      }




    return(           
        <React.Fragment>
            <section className="Common-QuestionComponent Page-DropdownSingle">
                <div className="question-type-wrap">
                    <label className="question"><span className="question-no">Q</span><span>[8]</span>Please rank the following features in order of importance?</label>
                    <div className="question-type ranking">Ranking</div>
                </div>

                <div className="checkbox-multi-card-wrap">

                  
                    <div className="majority-card">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Majority assigned Rank 1 </span>
                            <span className="subtitle">Location</span>
                        </div>
                    </div>

                    <div className="least-card">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Majority  assigned Rank 4 </span>
                            <span className="subtitle">Service</span>
                        </div>
                    </div>

                    <div className="total-responses">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Total Responses</span>
                            <span className="subtitle">189</span>
                        </div>
                        <span className="checkbox-multi-card-icon"></span>
                    </div>

                    <div className="total-comments">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Total Comments</span>
                            <span className="subtitle">98</span>
                        </div>
                        <span className="checkbox-multi-card-icon"></span>
                    </div>

                </div>

                <div className="checkbox-multi-chart-wrap">

                    <div className="response-statistics-chart">
                        <div className="response-statistics-header">
                            <p>Response statistics</p>
                            <button type="button" className="header-download-graph"></button>
                        </div>
                        <div className="response-statistics-body">
                             <Bar data={barChartData} options={barChartOptions} height={280}/>
                        </div>
                    </div>

                    <div className="checkbox-multi-chart-donut radar-chart-wrap">
                        <button type="button" className="header-download-graph"></button>
                        <Radar data={radarData} options={radarOptions} />
                    </div>

                </div>

            </section>
        </React.Fragment>   
    )


}

export default Ranking;