import React, { useState , useEffect , useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import './CustomerExperience.scss';
import FilterModal from './FilterModal'; 
import CommentType from './CommentType';
import InsightComponent from './InsightComponent';
import { useParams } from "react-router-dom";
import Axios from "utility/Axios";
import configURL from 'config/config';
import { toast } from "react-toastify";
import AppContext from 'store/AppContext';
import EziLoader from 'components/EziLoader';
import DatePicker from 'react-date-picker';
import ReportDemogrphyFilter from './ReportDemographicFilter';
import { confirmAlert } from 'react-confirm-alert';

const ReportDashboard = () => {

    const { languageObj = {}, handleUnAuthWarn = {}, accesFeature = {}, EziLoader } = useContext(AppContext)
    const urlParams = useParams();   
    const [ surveyId , setSurveyId ] = useState(urlParams.survey_id)
    const [ filterModal , setFilterModal ] = useState(false);
    const [ toggle , setToggle] = useState(false);
    const [ pageLoad , setPageLoad] = useState(false);
    const [ insights , setInsights] = useState(false);
    const [ questionList , setQuestionList] = useState();
    const [ insightData , setInsightData] = useState({});
    const [ surveyName, setSurveyName]=useState();
    const [ date, setDate]=useState();
    const [ questionData , setQuestionData]=useState();
    const [ activeClass , setActiveClass]=useState(null);
    const [ showFilter, setShowFilter] = useState(false);
    const [ filterData, setFilterData] = useState([]);
    const [ datefilter, setDateFilter] = useState([]);
    
    const appStore = JSON.parse(localStorage.getItem("appState"));

    const pageChange = (item,index) => {
        setQuestionData(item)
        setToggle(false)
        setActiveClass(item.id);
    }
    const [reportDate, setReportDate] = useState({
        start_date: new Date(),
        end_date: new Date()
    });
    
   
    const questionListing = () => {
        let formData = new FormData();       
        formData.append("survey_id", surveyId);
        // formData.append("survey_id", "8addb246-34b2-41d2-aea6-aedbfbb983fb");
        Axios.post(configURL.ReportSurveyQuestion, formData).then(response => {
            if (response.data.success !== undefined && response.data.success) {
                EziLoader.hide()
                setSurveyName(response.data.result.name);  
                let start_date = (response.data.result.start_time) ? new Date(response.data.result.start_time) : new Date()             
                let end_date = (response.data.result.end_time) ? new Date(response.data.result.end_time) : new Date()             
                setDate(response.data.result);
                setReportDate({
                    start_date: start_date,
                    end_date: end_date
                });
                setDateFilter({
                    start_date: start_date,
                    end_date: end_date
                });
                if(response.data.result.insight.question_type === ( "HI" || "NPS" || "CSAT" ) ){                      
                    setInsights(true); 
                    setInsightData(response.data.result.insight);              
                }
                setQuestionList(response.data.result.data); 
                setQuestionData(response.data.result.data[0]); 
                setPageLoad(true);                                           
            }
            else {
                toast.warn(response.data.message);
            }
        })
    }

    useEffect(() => {
        EziLoader.show()
        questionListing();
    }, []);
  
    const handleCalendarClose = () => {
        if (reportDate.start_date.getTime() > reportDate.end_date.getTime()) {
            confirmAlert({
                title: 'Date Error!',
                message: 'Start date could not be greater than End Date..',
                buttons: [{ label: 'I Understood' }]
            });
            return;
        }
        console.log(" distribution ",reportDate);
        let date = {
            start_date: new Date(reportDate.start_date.getTime() - (reportDate.start_date.getTimezoneOffset() * 60000)).toJSON(),
            end_date: new Date(reportDate.end_date.getTime() - (reportDate.end_date.getTimezoneOffset() * 60000)).toJSON()
        };
        setDateFilter(date);
    };


    return (

      
        <React.Fragment>
            { pageLoad ?
            <section className="Page-CX-Report Page-CustomerExperience">

                <div className="ce-header">

                    <div className="ce-title-wrap">
                        <span className="ce-title">{surveyName}</span>
                        {/* <span className="ce-subtitle">{surveyName} </span> */}
                    </div>
                    <div className="ce-right-header">
                        <div className="ce-date-range-wrap">
                            <div className="ce-date-wrap">
                                <DatePicker
                                    selected={reportDate.start_date}
                                    value={reportDate.start_date}
                                    disabled={false}
                                    minDate={new Date(date.start_time)}
                                    maxDate ={new Date()}                                             
                                    onChange={(d) => setReportDate({ ...reportDate, start_date: d })}
                                    clearIcon={null}
                                    className="ezi-datepicker-custom"
                                 />
                            </div>
                            <span className="ce-date-devider"></span>
                            <div className="ce-date-wrap">
                            <DatePicker
                                selected={new Date()}
                                value={reportDate.end_date} 
                                minDate={new Date(date.start_time)}
                                maxDate ={new Date()}                                            
                                onChange={(d) => setReportDate({ ...reportDate, end_date: d })}
                                disabled={false}
                                clearIcon={null}
                                className="ezi-datepicker-custom"
                                onCalendarClose={handleCalendarClose}
                            />
                            </div>
                        </div>
                        <div className="ec-action-header">
                            <div className="filter-compare-btn-wrap">
                            <button type="button" className="ezi-filter-btn" onClick={() => setShowFilter(!showFilter)}>Filter</button>
                                {/* <button type="button" className="filter-button" /* onClick={() => setFilterModal(true)} >Filters</button> */}
                                {/* <button type="button" className="compare-button">Compare</button> */}
                                <ReportDemogrphyFilter
                                        show={showFilter}
                                        hide={() => setShowFilter(false)}
                                        position="right"
                                        applyFilter={(filters) => 
                                            setFilterData(filters)
                                        }
                                    />
                            </div>
                            {/* <div className="applied-filter-wrap">
                                <span className="applied-filter-label">Applied Filters :</span>
                                <div className="applied-filter-badge">
                                    <span className="applied-badge">Clear All<button type="button">x</button></span>
                                    <span className="applied-badge">PUNE<button type="button">x</button></span>
                                </div>
                            </div>                          */}
                        </div>
                    </div>
                </div>

                
               
                <div className={`aside-content-wrap ${insights ? 'question-insight-aside' : 'question-ananlysis-aside'}`}>

                { !insights &&
                    <div className="aside-wrap">
                        
                        <div className="menu-dropdown-wrap">
                            {!toggle && <button type="button" className="question-toggle-btn" onClick={ () => setToggle(!toggle)}>Questions</button>}
                            <div className={`question-toggle-overlay ${toggle ? 'active' : ''}`} onClick={ () => setToggle(!toggle)}></div>
                            <div className="analysis-menu-wrap">
                                <Dropdown className="analysis-menu">
                                    <Dropdown.Toggle id="dropdown-basic"> Analysis </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item><div className="analysis-menu-list insight" onClick={() => setInsights(true)}>Insights</div></Dropdown.Item>
                                        <Dropdown.Item><div className="analysis-menu-list qa" onClick={() => setInsights(false)} >Question Analysis</div></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                         
                        <aside className={`report-sidebar ${ toggle ? 'active' : ''}`}>                       
                            <div className="report-sidebar-header">
                                <div className="survey-download-wrap">
                                    <span className="survey-analysis">Question Analysis</span>
                                    {/* <button type="button" className="download_ic"></button> */}
                                </div>
                                <div className="report-sidebar-search-wrap">
                                    {/* <input type="text" className="report-sidebar-search" /> */}
                                    <div className="report-sidebar-select-wrap">
                                        <select className="report-sidebar-select"><option>Themes</option></select>
                                        <select className="report-sidebar-select"><option>Sub Themes</option></select>
                                    </div>
                                </div>
                            </div>
                            <div className="question-list-wrap">
                                {
                                    questionList && questionList.map((item, index) =>
                                        <div className={`question-list ${ ( (index === 0 && activeClass === null) || item.id === activeClass ) ? "active" : ""}`} key={index} onClick={() => pageChange(item,index)}>
                                            {/* <span className="que-number">Question</span> */}
                                            <span className="question">{item.title || item.name } {/* { item.title !== undefined && typeof item.title } */}</span>
                                        </div>

                                    )
                                }
                            </div>
                        </aside>
                    </div>
                }


                { !insights &&
                    <section className="CE-report-content">                    
                        <main>                          
                            <CommentType question={questionData} filterData={filterData} dateFilter={datefilter} />
                           
                        </main>
                    </section>
                }


                { insights &&
                    <section className="CE-report-content insight-main-section"> 

                        <div className="analysis-menu-wrap">
                            <Dropdown className="analysis-menu">
                                <Dropdown.Toggle id="dropdown-basic"> Menu </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item><div className="analysis-menu-list insight" onClick={() => setInsights(true)}>Insights</div></Dropdown.Item>
                                    <Dropdown.Item><div className="analysis-menu-list qa" onClick={() => setInsights(false)} >Question Analysis</div></Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>  
                        <InsightComponent insightData={insightData} filterData={filterData} dateFilter={datefilter} /> 
                                         
                    </section>
                }

                    
    

                </div>
                <FilterModal show={filterModal} onHide={() => setFilterModal(false)}   />
            </section>
            :   <span>Loading...</span>
            }
        </React.Fragment>
        
    )
}

export default ReportDashboard;