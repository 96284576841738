/** App actions type*/
export const LOGOUT = "LOGOUT"
export const LOGIN = "LOGIN"
export const UPDATE_USER = "UPDATE_USER"

/** Survey Actions type */
export const SET_SURVEY_NAME = "SET_SURVEY_NAME"
export const SET_SURVEY_META = "SET_SURVEY_META"
export const SET_LAUNCHABLE = "SET_LAUNCHABLE"
export const SET_SURVEY_CATEGORY = "SET_SURVEY_CATEGORY"
export const VIEW_SURVEY_CHECKLIST = "VIEW_SURVEY_CHECKLIST"
export const VIEW_THEME_MAP = "VIEW_THEME_MAP"
export const RESET_STATE = "RESET_STATE"
export const SET_SELECTED_LOCALES = "SET_SELECTED_LOCALES"
export const SET_SELECTED_LOCALE = "SET_SELECTED_LOCALE"
export const SET_SURVEY_LOGO = "SET_SURVEY_LOGO"


/*------------Participants Actions Types---------------------*/
export const SET_ADDED_PARTICIPANTS = "SET_ADDED_PARTICIPANTS"
export const SET_PARTICIPANT_SOURCE = "SET_PARTICIPANT_SOURCE"
export const SET_PARTICIPANTS_ONEDIT = "SET_PARTICIPANTS_ONEDIT"
export const SET_PARTICIPANTS_COUNT = "SET_PARTICIPANTS_COUNT"
export const SET_CHANNEL_DATA = "SET_CHANNEL_DATA"
export const RELOAD_CHANNEL_DATA = "RELOAD_CHANNEL_DATA"
export const SET_DYNAMIC_SOURCE_DATA = "SET_DYNAMIC_SOURCE_DATA"
export const DYNAMIC_PARTICIPANT_SOURCE_TYPE = "DYNAMIC_PARTICIPANT_SOURCE_TYPE"

/*------------Template Actions---------------------*/
export const SET_TEMPLATE_INITIAL = "SET_TEMPLATE_INITIAL"
export const SET_TEMPLATE_LAUNCHABLE = "SET_TEMPLATE_LAUNCHABLE"
export const RESET_TEMPLATE_STATE = "RESET_TEMPLATE_STATE"
export const SET_TEMPLATE_LOCALES = "SET_TEMPLATE_LOCALES"
export const SET_TEMPLATE_LOCALE = "SET_TEMPLATE_LOCALE"