import React, { useState, useEffect, useContext } from "react";
import './Identifier.scss';
import { Tab, Breadcrumb, Nav } from 'react-bootstrap';
import useForm from 'react-hook-form';
import configURL from 'config/config';
import Axios from "utility/Axios";
import { toast } from 'react-toastify';
import AppContext from 'store/AppContext';
import { confirmAlert } from "react-confirm-alert";
import { PERSONAL_IDENTIFIERS, PERSONAL_INPUT_TYPES } from "constants/constants";

const Identifier = (props) => {
    const listType = props.location.pathname.split('/').splice(-1, 1).toString();
    const { languageObj = {}, handleUnAuthWarn = {}, accesFeature = {} } = useContext(AppContext)
    const { register, handleSubmit, errors } = useForm();
    const [identifierList, setIdentifierList] = useState([]);
    const [createIdenfierToggle, setCreateIdenfierToggle] = useState(false);
    const [optionIdenfierToggle, setOptionIdenfierToggle] = useState(false);
    const [optionValue, setOptionValue] = useState('');
    const [identifierLabelMapping, setidentifierLabelMapping] = useState({});
    const [identifierTab, setIdentifierTab] = useState('');

    const saveIdentifier = (e) => {
        let formData = new FormData();
        formData.append("language", languageObj.curLang);
        formData.append("type", listType);
        formData.append("name", identifierTab);
        formData.append("label", identifierLabelMapping[identifierTab]);
        Axios.post(configURL.updateIdentifier, formData).then(res => {
            if (res.data && res.data.success) {
                toast.success(`${res.data.message} !`);
                identifierListing();
            }
        })
    }

    const getAccesses = () => {
        switch (listType) {
            case 'employee':
                return {
                    add: accesFeature.create_employee,
                    edit: accesFeature.edit_employee,
                    delete: accesFeature.delete_employee
                }
            case 'customer':
                return {
                    add: accesFeature.create_customer,
                    edit: accesFeature.edit_customer,
                    delete: accesFeature.delete_customer
                }
            case 'other':
                return {
                    add: accesFeature.delete_others,
                    edit: accesFeature.edit_others,
                    delete: accesFeature.delete_others
                }
        }
    }

    const deleteIdenfier = (event, label) => {
        let { name = "" } = event
        if (typeAccess.delete) {
            confirmAlert({
                title: 'Delete Identifier',
                message: `Are you sure want to delete "${label}" identifier.`,
                buttons: [{
                    label: 'Confirm',
                    onClick: () => {
                        let formData = new FormData();
                        formData.append("type", listType);
                        formData.append("deleted_name", name);
                        formData.append("language", languageObj.curLang);
                        Axios.post(configURL.deleteIdentifier, formData).then(res => {
                            if (res.data && res.data.success) {
                                identifierListing();
                                toast.success(`${res.data.message} !`);
                            }
                        })
                    }
                }, { label: 'Cancel' }]
            });
        } else {
            handleUnAuthWarn();
        }
    }

    const submitIdenfierForm = (data) => {
        let formData = new FormData();
        formData.append("type", listType);
        formData.append("language", languageObj.curLang);
        data.name = data.label.replace(/ /g, "_");
        formData.append("identifier_json", JSON.stringify(data));
        Axios.post(configURL.createIdentifier, formData).then(res => {
            if (res.data && res.data.success) {
                identifierListing();
                setCreateIdenfierToggle(false);
                toast.success(`${res.data.message} !`);
            } else {
                toast.error(`${res.data.message} !`);
            }
        })
    }

    const addIdentifierOption = data => {
        let formData = new FormData();
        formData.append("type", listType);
        formData.append("language", languageObj.curLang);
        formData.append("option", data.option);
        formData.append("name", identifierTab);

        Axios.post(configURL.addIdentifierOption, formData).then(res => {
            if (res.data && res.data.success) {
                identifierListing();
                setOptionIdenfierToggle(false);
                toast.success(`${res.data.message} !`);
            } else {
                toast.error(`${res.data.message} !`);
            }
        })
    }

    const handleIdentifierLabel = (e) => {
        setidentifierLabelMapping({ ...identifierLabelMapping, [e.target.name]: e.target.value });
    }

    const makeIdentifierObject = (response) => {
        let identifierLabelMap = {};
        response.length && response.length > 0 && response.map(res => {
            identifierLabelMap[res.name] = res.label;
        });
        setidentifierLabelMapping(identifierLabelMap);
    }

    const identifierListing = () => {
        let formData = new FormData();
        formData.append("type", listType);
        Axios.post(configURL.demographicURL, formData).then(response => {
            if (response.data.success !== undefined && response.data.success === true) {
                makeIdentifierObject(response.data.data[languageObj.curLang]['identifier']);
                setIdentifierList(response.data.data[languageObj.curLang]['identifier']);
            } else {
                toast.warn(response.data.message)
            }
        })
    }

    useEffect(() => {
        identifierListing();
    }, []);

    useEffect(() => {

    }, [createIdenfierToggle, identifierLabelMapping])
    const typeAccess = getAccesses();

    return (
        <React.Fragment>
            <section className="Page-Identifier">
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => props.history.push("/people-directory")}>
                            {languageObj.translate('PeopleDirectory.1')}
                        </Breadcrumb.Item>
                        {(() => {
                            switch (listType) {
                                case 'employee':
                                    return <Breadcrumb.Item onClick={() => props.history.push("/people-directory", {
                                        page_key: "employee"
                                    })}>{'Employee'}</Breadcrumb.Item>
                                case 'customer':
                                    return <Breadcrumb.Item onClick={() => props.history.push("/people-directory", {
                                        page_key: "customer"
                                    })}>{'Customer'}</Breadcrumb.Item>
                                case 'other':
                                    return <Breadcrumb.Item onClick={() => props.history.push("/people-directory", {
                                        page_key: "other_contact"
                                    })}>{'Other Contact'}</Breadcrumb.Item>
                            }
                        })()}
                        <Breadcrumb.Item>Identifiers</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="column-header">
                        <h1 className="page-heading">{listType} Identifiers</h1>
                    </div>
                </div>

                <div className="tablist_ezi">
                    <Tab.Container defaultActiveKey="personal">
                        <div className="tab-header-wrap">
                            <div className="tab-left-header">
                                <Nav variant="pills" >
                                    <Nav.Item>
                                        <Nav.Link eventKey="personal">Personal</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="identifier">Identifier</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>

                        <div className="identifier-tab-content">
                            <Tab.Content>

                                <Tab.Pane eventKey="personal">
                                    <Tab.Container defaultActiveKey="name">
                                        <form className="personal-form">
                                            <div className="identifier-col-wrap">
                                                <div className="identifier-left-col">
                                                    <div className="field-tab-header">
                                                        <label className="head">Personal</label>
                                                    </div>
                                                    <Nav variant="pills" >
                                                        {
                                                            PERSONAL_IDENTIFIERS[listType].map(personal =>
                                                                <Nav.Item key={personal.name}>
                                                                    <Nav.Link eventKey={personal.name} onClick={() => setIdentifierTab(personal.name)}>
                                                                        <div className="identifier-label-wrap">
                                                                            <button type="button" className="identifier-label-name" name={personal.name}> {personal.label} </button>
                                                                        </div>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            )
                                                        }
                                                    </Nav>
                                                </div>

                                                <div className="identifier-right-col">
                                                    <div className="field-tab-header">
                                                        <label className="head head_r">Personal settings</label>
                                                    </div>
                                                    <Tab.Content>
                                                        {
                                                            PERSONAL_IDENTIFIERS[listType].map(personal =>
                                                                <Tab.Pane eventKey={personal.name} key={personal.name}>
                                                                    <div className="identifier-field-wrap">
                                                                        <label>Personal Name</label>
                                                                        <p>{personal.label}</p>
                                                                    </div>
                                                                </Tab.Pane>
                                                            )
                                                        }
                                                    </Tab.Content>
                                                </div>
                                            </div>
                                        </form>
                                    </Tab.Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="identifier">
                                    <Tab.Container defaultActiveKey="name">
                                        <form className="personal-form">
                                            <div className="identifier-col-wrap">
                                                <div className="identifier-left-col">
                                                    <div className="field-tab-header">
                                                        <label className="head">Identifiers</label>
                                                        <button type="button" className={`create-btn ${typeAccess.add || "access_lock"}`} onClick={() => {
                                                            if (typeAccess.add) {
                                                                setCreateIdenfierToggle(!createIdenfierToggle);
                                                            } else {
                                                                handleUnAuthWarn()
                                                            }
                                                        }}><span className={(!createIdenfierToggle) ? 'plus' : ""}></span>{(createIdenfierToggle) ? 'Cancel' : 'Create new'} </button>
                                                    </div>
                                                    {
                                                        createIdenfierToggle &&
                                                        <div className="create-identifier-main-wrap">
                                                            <form onSubmit={handleSubmit(submitIdenfierForm)}>
                                                                <div className="create-new-identifier-wrap">
                                                                    <input type="text" className="add-identifier-input" name="label" ref={register({ required: true })} placeholder="Identifier name" />
                                                                    {/* {validations && <span className="error_cu">{validations}</span>  } */}
                                                                    <select className="map-select" name="type" ref={register({ required: true })}>
                                                                        <option key={0} value="">Select identifier type</option>
                                                                        {PERSONAL_INPUT_TYPES.map((type) => (
                                                                            <option key={type.id} value={type.value}>{type.label}</option>
                                                                        ))}
                                                                    </select>
                                                                    <button type="submit" className="btn-ripple add-identifier">Add</button>
                                                                </div>
                                                                <span className="error_cu">{errors.label && '* Idenfier name Id required.'}</span>
                                                                <span className="error_cu">{errors.type && '* Idenfier type is required.'}</span>
                                                            </form>
                                                        </div>
                                                    }
                                                    <Nav variant="pills" >
                                                        {
                                                            identifierList.length && identifierList.length > 0 ? identifierList.map(identifier => <Nav.Item key={identifier.name}>
                                                                <Nav.Link eventKey={typeAccess.edit ? identifier.name : identifier.name + "null"} onClick={() => {
                                                                    if (typeAccess.edit) {
                                                                        setIdentifierTab(identifier.name)
                                                                    } else {
                                                                        handleUnAuthWarn();
                                                                    }
                                                                }} className={`${typeAccess.edit || "access_lock"}`}>
                                                                    <div className="identifier-label-wrap">
                                                                        <button type="button" className="identifier-label-name" name={identifier.name}> {identifier.label} </button>

                                                                    </div>
                                                                </Nav.Link>
                                                                <button type="button" className={`identifier-delete-icon ${typeAccess.delete || "access_lock"}`} name={identifier.name} onClick={({ target }) => deleteIdenfier(target, identifier.label)}> Del </button>
                                                            </Nav.Item>
                                                            ) : <p>Indentifier missing</p>
                                                        }
                                                    </Nav>
                                                </div>


                                                <div className="identifier-right-col">
                                                    <Tab.Content>
                                                        {
                                                            identifierList.length && identifierList.length > 0 && identifierList.map(identifier =>
                                                                <Tab.Pane eventKey={identifier.name} key={identifier.name}>
                                                                    <div className="field-tab-header">
                                                                        <label className="head head_r">Identifier settings</label>
                                                                        <button type="button" onClick={saveIdentifier} className="btn-ripple ezi-pink-btn save-identifier">Save</button>
                                                                    </div>
                                                                    <div className="identifier-field-wrap">
                                                                        <label>Identifier Name &nbsp;(<em>{identifier.type || ""}</em>)</label>
                                                                        <input type="text" className="identifier-setting-input" placeholder="Name" name={identifier.name} value={identifierLabelMapping[identifier.name]} onChange={handleIdentifierLabel} />
                                                                    </div>
                                                                    {
                                                                        (identifier.type === 'dropdown') &&
                                                                        <div className="identifier-field-wrap">
                                                                            <label>Identifier Option</label>
                                                                            <div className="identifier-select-wrap">
                                                                                <select className="identifier-setting-select">
                                                                                    {identifier.options.map((option, index) => (
                                                                                        <option key={index} value={option}>{option}</option>
                                                                                    ))}
                                                                                </select>
                                                                                <button className={`btn-ripple ezi-pink-btn ${optionIdenfierToggle ? 'cancel' : 'add-option'}`} type="button" name={identifier.name} onClick={() => setOptionIdenfierToggle(!optionIdenfierToggle)}> {optionIdenfierToggle ? 'Cancel' : 'Add Option'}  </button>
                                                                            </div>
                                                                            {
                                                                                optionIdenfierToggle &&
                                                                                <form onSubmit={handleSubmit(addIdentifierOption)}>
                                                                                    <div className="identifier-select-wrap">
                                                                                        <input className="identifier-setting-input" type="text" name="option" ref={register({ required: true })} value={optionValue} onChange={(e) => setOptionValue(e.target.value)} placeholder="Identifier Option" />
                                                                                        <button type="submit" className="btn-ripple ezi-pink-btn add-identifier"> Add </button>
                                                                                    </div>
                                                                                    <span className="error_cu">{errors.option && '* Option is required.'}</span>
                                                                                </form>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </Tab.Pane>
                                                            )
                                                        }
                                                    </Tab.Content>
                                                </div>
                                            </div>
                                        </form>
                                    </Tab.Container>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Identifier;