import React from 'react';

export default class EditableContent extends React.Component {
    constructor(props) {
        super(props);
    }
    shouldComponentUpdate(nextProps) {
        return nextProps.html !== this.nodeSelect.innerHTML;
    }

    componentDidUpdate() {
        if (this.props.html !== this.nodeSelect.innerHTML) {
            this.nodeSelect.innerHTML = this.props.html;
        }

    }

    emitChange = () => {
        var html = this.nodeSelect.innerHTML;
        if (this.props.onChange && html !== this.lastHtml) {
            this.props.onChange({
                target: {
                    value: html
                }
            });
        }
        this.lastHtml = html;
    }

    render() {
        return (
            <p className={this.props.styles} ref={(ref) => { this.nodeSelect = ref }}
                onInput={this.emitChange}
                onBlur={this.emitChange}
                contentEditable={this.props.editable}
                suppressContentEditableWarning={true}
                dangerouslySetInnerHTML={{ __html: this.props.html }}>{this.props.value}</p>
        )
    }
}

