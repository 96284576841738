export const LIKERT_CHOICES = {
    "Likert Scale": {
        "Agreement": ["Strongly Disagree", "Disagree", "Undecided", "Agree", "Strongly Agree"],
        "Frequency": ["Never", "Rarely", "Sometimes", "Often", "Always"],
        "Importance": ["Unimportant", "Slightly Important", "Moderately Important", "Important", "Very Important"],
        "Quality": ["Very Poor", "Poor", "Fair", "Good", "Excellent"],
        "Likelihood": ["Almost Never True", "Usually Not True", "Occasionally True", "Usually True", "Almost Always True"],
        "Likelihood(2)": ["Definitely Not", "Probably Not", "Possibly", "Probably", "Definitely"]
    },
    "Star Rating Scale": {
        '5 star Rating': [1, 2, 3, 4, 5],
        '8 Star Rating': [1, 2, 3, 4, 5, 6, 7, 8]
    }
}
export const TOOLBOX_NAME = {
    "text": "Single Line Text",
    "comment": "Multiple Line Text",
    "checkbox": "Checkbox(Multiple Select)",
    "radiogroup": "Radio Button ",
    "dropdown": "Dropdown (Single Select)",
    "rating": "NPS",
    "boolean": "Yes No ",
    "imagepicker": "Image Type",
    "barrating": "Star Rating Scale",
    "rating_csat": "CSAT",
    "datepicker": "Date",
    "signaturepad": "Signature",
    "sortablelist": "Sorting/Ranking",
    "tagbox": "Dropdown (Multi Select)",
    "matrix": "Radio Matrix ",
    "bootstrapslider": "Slider Scale",
    "file": "File Upload",
}

export const QUESTION_TYPES = {
    "rating": "NPS",
    "rating_csat": "CSAT",
    "smily": "HI",
    "barrating": "STARRATING",
    "text": "SINGLELINETEXT",
    "comment": "MULTIPLELINETEXT",
    "radiogroup": "RADIOBUTTON",
    "dropdown": "DROPDOWNSINGLESELECT",
    "tagbox": "DROPDOWNMULTISELECT",
    "checkbox": "CHECKBOXMULTISELECT",
    "yesno": "THUMBUPDOWN",
    "boolean": "YESNO",
    "imagepicker": "IMAGETYPE",
    "matrix": "RADIOMATRIX",
    "sortablelist": "RANKING",
    "bootstrapslider": "SLIDERSCALE",
    "html": "HTML",
    "datepicker": "DATEPICKER",
    "file": "FILE",
    "personalinfo": "PERSONALINFO"
}

export const BUILDER_OPTIONS = {
    showEmbededSurveyTab: false,
    hideAdvancedSettings: false,
    haveCommercialLicense: true,
    showJSONEditorTab: false,
    showTestSurveyTab: true,
    showPropertyGrid: true,
    isAutoSave: true,
    showLogicTab: true,
    showApplyButtonInEditors: false,
    showTranslationTab: true,
    questionTypes: [
        "yesno",
        "checkbox",
        "text",
        "comment",
        "radiogroup",
        "matrix",
        "tagbox",
        "dropdown",
        "rating",
        "boolean",
        "imagepicker",
        "sortablelist",
        "barrating",
        "datepicker",
        "file",
        "bootstrapslider",
        "html",
        "rating_csat"
    ]
}
export const TOOLBOX_ORDERS = [
    "rating",
    "rating_csat",
    "smily",
    "barrating",
    "text",
    "comment",
    "radiogroup",
    "dropdown",
    "tagbox",
    "checkbox",
    "yesno",
    "boolean",
    "imagepicker",
    "matrix",
    "sortablelist",
    //  "aspects",
    "bootstrapslider",
    "html",
    "datepicker",
    "file",
]

export const TOOLBAR_NAMES = {
    "undo": "",
    "redo": "",
    "toolbox": "Statement types",
    "settings": "Settings"
}
const MAIN_COLOR = "#504658";
const MAIN_HOVER_COLOR = "black";
const TEXT_COLOR = "#504658";
const HEADER_COLOR = "#7ff07f";
const HEADER_BK_COLOR = "#504658";
const BODY_BK_COLOR = "#f8f8f8";

export const BUILDER_THEME = {
    "$primary-color": MAIN_COLOR,
    "$secondary-color": MAIN_COLOR,
    "$primary-text-color": TEXT_COLOR,
    "$primary-hover-color": MAIN_HOVER_COLOR,
    "$selection-border-color": MAIN_COLOR
}
export const mySurveyStringsHindi = {
    pagePrevText: "पीछे",
    pageNextText: "आगे",
    completeText: "पूर्ण"
    //other localized strings
}
export const mySurveyStringsMarathi = {
    pagePrevText: "आधी",
    pageNextText: "पुढे",
    completeText: "पूर्ण"
    //other localized strings
}

export const tranlationLanguage = {
    hindiText: "हिन्दी",
    marathiText: "मराठी",
    //other localized strings
}