import React, { useState, useEffect } from 'react';
import './SurveyPreview.scss'
import { useParams } from "react-router-dom";
import Axios from "utility/Axios";
import configURL from 'config/config';
import { Dropdown } from 'react-bootstrap';
import { connect } from "react-redux";

const SurveyPreview = ({ selectedLocale, selectedLocales, changeLocale, surveyLogos }) => {
    const [companyData, setCompanyData] = useState();
    const [partnerData, setPartnerData] = useState();
    let urlParams = useParams();

    const fetchPreviewData = () => {
        let formData = new FormData();
        formData.append("survey_id", urlParams.survey_id);
        Axios.post(configURL.companyLogo, formData).then(res => {
            if (res.data.success !== undefined && res.data.success) {
                setCompanyData(res.data.company);
                setPartnerData(res.data.partner);
            }

        })
    }

    useEffect(() => {
        fetchPreviewData();
    }, []);

    useEffect(() => {
        if (surveyLogos.partner_logo) {
            setPartnerData({
                ...partnerData,
                partner_logo_path: surveyLogos.partner_logo
            })
        }
        if (surveyLogos.company_logo) {
            setCompanyData({
                ...companyData,
                company_logo_path: surveyLogos.company_logo
            });
        }
    }, [surveyLogos.partner_logo, surveyLogos.company_logo]);

    return (
        <React.Fragment>
            <div className="preview-survey-header">
                <div className="preview-survey-header-left">
                    <div className="preview-survey-logo-wrap">
                        {(companyData && companyData.company_logo_path !== null) ?
                            <img alt="" src={(companyData && companyData.company_logo_path !== null) ? companyData.company_logo_path : require(`../../../../assets/images/company-logo/company-logo.png`)} className="preview-survey-logo" />
                            :
                            <span className="initial-letter-block">{companyData && companyData.company_name.charAt(0).toUpperCase()}</span>
                        }
                    </div>
                    <div className="preview-text-wrap">
                        <span className="preview-survey-company-name">{companyData && companyData.company_name}</span>
                        <span className="preview-survey-name">{companyData && companyData.survey_name}</span>
                    </div>
                </div>

                {/* <div className="preview-survey-header-right">
                    <div className="preview-survey-logo-wrap">
                        {(partnerData && partnerData.partner_logo_path !== null) ?
                            <img alt="" src={(partnerData && partnerData.partner_logo_path !== null) ? partnerData.partner_logo_path : require(`src/assets/images/company-logo/company-logo.png`)} className="preview-survey-logo" />
                            :
                            <span className="initial-letter-block">{partnerData && partnerData.company_name.charAt(0).toUpperCase()}</span>
                        }
                    </div>
                    <div className="preview-text-wrap">
                        <span className="preview-survey-company-name">In partner with</span>
                        <span className="preview-survey-name">{partnerData && partnerData.company_name}</span>
                    </div>
                </div> */}
            </div>

            {/* <div className="preview-survey-feedback-wrap">
                <div className="preview-survey-text-wrap">
                    <span className="preview-survey-feedback-heading">Your Feedback is valuable to us!</span>                              
                </div>                               
            </div> */}
            <div className="languageDropdown">
                <label className="lang-text">Language :</label>
                <Dropdown alignRight>
                    <Dropdown.Toggle id="dropdown-basic">
                        <div className="flag-avtar-wrap">
                            <label className="country-flag-label">{selectedLocale || "En"}</label>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {selectedLocales.map((item, index) => (
                            <Dropdown.Item key={index}>
                                <div className="flag-img-wrap" onClick={() => changeLocale(item.id)}>
                                    <label className="country-flag-label">{item.name}</label>
                                </div>
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="preview-survey-wrap">
                <div id="surveyPreview"></div>
            </div>

            {/* <div className="preview-survey-thank-wrap">
                <span className="preview-survey-complete-ic"></span>
                <span className="preview-survey-feedback-para">Thank you for your feedback!</span>
            </div> */}

            <div className="preview-survey-poweredby-wrap">
                <span className="preview-survey-poweredby-heading">Powered by</span>
                <img alt="" src={require(`../../../../assets/images/logo.png`)} className="preview-survey-poweredby-logo" />
                {/* <span className="preview-survey-poweredby-text">© {new Date().getFullYear()} Great Place IT Services ™.<br />All Rights Reserved.</span> */}
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        selectedLocale: state.survey.selectedLocale,
        selectedLocales: state.survey.selectedLocales,
        surveyLogos: state.survey.surveyLogos,
    }
}
export default connect(mapStateToProps)(SurveyPreview);