import React from 'react';
import { Line as LineChart } from 'react-chartjs-2';

const QuestionStatistics = () =>{

    const lineChartData = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July"],
        datasets: [
            {
                label: "My First dataset",
                borderColor: '#CE2E6C' ,
                backgroundColor: '#F5D5E1',
                data: [9,20,4,25,8,20,5],
                borderWidth: 1,
                pointBorderColor:'#CE2E6C',
                pointBackgroundColor:'#fff'
                
            },
            {
                label: "My Second dataset",
                borderColor: '#657988' ,
                backgroundColor: 'transparent',
                data: [5,15,10,20,25,8,15],
                borderWidth: 1,
                borderDash: [8,3],
                radius: 0
            }
        ]
    };

    const lineChartOptions = {
        responsive: true,   
        maintainAspectRatio: false,
        // elements: { point: { radius: 0 } },
        legend: { display: false },
        layout: { padding: { top:10 }, },
        scales: {            
                xAxes: [{ display: false }],
                yAxes: [{ display: false }]
            },
    }

    return(

        <div className="question-statistics-card">

            <div className="question-statistics-card-header">
                <span className="header-tit">Question Level Statistics</span>
            </div>

            <div className="question-statistics-card-body">
                <div className="question-statistics-inner-header">
                    <div className="question-text-wrap">
                        <span className="title">4.5</span>
                        <span className="subtitle">Average score</span>
                    </div>
                    <div className="question-text-wrap">
                        <span className="title">6min</span>
                        <span className="subtitle">Average time</span>
                    </div>
                    <div className="question-text-wrap">
                        <span className="title">5.2</span>
                        <span className="subtitle">Average Global score</span>
                    </div>
                    <div className="question-text-wrap">
                        <span className="title">13min</span>
                        <span className="subtitle">Average Global time</span>
                    </div>
                </div>
                <div className="question-line-chart-wrap">                     
                    <LineChart data = {lineChartData} options={lineChartOptions} height={200} />
                </div>
            </div>
            
        </div>
        

    )


}

export default QuestionStatistics;