import React, { useState, useEffect, useRef, useContext } from 'react';
import Axios from "utility/Axios";
import configURL from 'config/config';
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import SweetSearch from 'components/SweetSearch';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DemographicFilter from 'components/DemographicFilter';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import AppContext from 'store/AppContext';
import * as AppAction from "store/actions";
import { getTablePagesSizes } from 'utility/helper';

const SavedParticipants = ({ surveyStatus, surveyAction, selectedOnEdit, dispatchSelectedOnEdit, dispatchSelectedCount }) => {
    const [participantFilter, setParticipantFilter] = useState(false);
    const { EziLoader } = useContext(AppContext)
    const [searchLoading, setSearchLoading] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [dataColumn, setDataColumn] = useState([{}]);
    const [pagination, setPagination] = useState({});
    const [uniqueKeyField, setUniqueKeyField] = useState("");
    const i18Lang = localStorage.getItem("i18nextLng");
    const [filters, setFilters] = useState([]);
    let inputSearchTimer = null;
    let tableRef = useRef(null);
    const inputSerch = useRef(null);
    let pageRef = useRef(25)
    const urlParams = useParams();

    const buttonFormatter = (cell, row, rowIndex) => {
        if (surveyStatus === 'draft') {
            return (
                <div className="participant_table_ic-wrap">
                    <button type="button" className="participant_table_ic tab_delete_ic" onClick={() => handleDeleteParticipant(row.id)}>Delete</button>
                    <button type="button" className="participant_table_ic tab_reset_ic" disabled title='Added Candidate'>Reset</button>
                </div>
            );
        } else if (row.status && row.status === 'non-appeared' && (surveyStatus === 'active' || surveyStatus === 'inactive')) {
            return (
                <div className="participant_table_ic-wrap">
                    <button type="button" className="participant_table_ic tab_delete_ic" onClick={() => handleDeleteParticipant(row.id)}>Delete</button>
                    <button type="button" className="participant_table_ic tab_reset_ic" disabled title='Non Appeared'>Reset</button>
                </div>
            );
        } else if (row.status && row.status !== 'non-appeared' && (surveyStatus === 'active' || surveyStatus === 'inactive')) {
            return (
                <div className="participant_table_ic-wrap">
                    <button type="button" className="participant_table_ic tab_delete_ic" disabled title="Appeared Candidate">Delete</button>
                    <button type="button" className="participant_table_ic tab_reset_ic" onClick={() => handleResetParticipantData(row)}>Reset</button>
                </div>
            );
        } else {
            return (
                <div className="participant_table_ic-wrap">
                    <button type="button" className="participant_table_ic tab_delete_ic" disabled title="Survey is closed">Delete</button>
                    <button type="button" className="participant_table_ic tab_reset_ic" onClick={() => handleResetParticipantData(row)}>Reset</button>
                </div>
            );
        }
    }

    const handleDeleteParticipant = (rowId) => {
        confirmAlert({
            title: 'Delete Participant',
            message: 'Are you sure you want to delete ?',
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        let formData = new FormData();
                        formData.append("participant_id", rowId);
                        formData.append("survey_id", urlParams.survey_id);
                        formData.append("isedit", (surveyAction === "edit") ? true : false);
                        Axios.post(configURL.deleteSelectedParticipants, formData).then(res => {
                            if (res.data && res.data.success) {
                                toast.success(res.data.message || 'Data Deleted');
                                let current_page = tableRef.current.paginationContext.currPage
                                if ((tableRef.current.table.props.data.length - 1) === 0) {
                                    current_page = tableRef.current.paginationContext.currPage - 1
                                }
                                current_page = (current_page > 0) ? current_page : 1
                                getParticipantData(current_page, "")
                            } else {
                                toast.warn(res.data.message || 'Something went wrong');
                            }
                        }).catch(err => {
                            toast.error(err.toString());
                        })
                    }
                },
                {
                    label: 'Cancel'
                }
            ]
        });
    }

    /**
     * Reset Participant Data
     * @param {Object} row 
     */
    const handleResetParticipantData = (row) => {
        let participant_id = row.id
        confirmAlert({
            title: 'Reset Participant Response',
            message: 'Are you sure you want to reset this response ?',
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        let formData = new FormData();
                        formData.append("participant_id", participant_id);
                        formData.append("survey_id", urlParams.survey_id);
                        Axios.post(configURL.resetParticipantResponse, formData).then(res => {
                            if (res.data && res.data.success) {
                                toast.success(res.data.message || 'Data Deleted');
                                let current_page = tableRef.current.paginationContext.currPage
                                getParticipantData(current_page, "")
                            } else {
                                toast.warn(res.data.message || 'Something went wrong');
                            }
                        }).catch(err => {
                            toast.error(err.toString());
                        })
                    }
                },
                {
                    label: 'Cancel'
                }
            ]
        });
    }

    /**
     * Load data.
     */
    const getParticipantData = (pageNum = 1, search = "") => {
        let formData = new FormData();
        formData.append("page", pageNum);
        formData.append("search", search);
        formData.append("survey_id", urlParams.survey_id);
        formData.append("language", i18Lang);
        formData.append("isedit", (surveyAction === "edit") ? true : false);
        formData.append("filters", JSON.stringify(filters));
        formData.append("per_page", pageRef.current);
        Axios.post(configURL.selected_participants, formData).then(response => {
            EziLoader.hide()
            if (response.status === 201 && response.data.success === true) {
                const columnData = response.data.column.map(item => {
                    if (item.dataField === "status") {
                        return {
                            ...item,
                            formatter: statusFormatter,
                        }
                    } else {
                        return item;
                    }
                });
                columnData.push({
                    headerFormatter: () => {
                        return <span className="participant_action">Action</span>
                    },
                    formatter: buttonFormatter
                });
                setParticipants(response.data.data);
                setDataColumn(columnData);
                setPagination(response.data.pagination);
                setUniqueKeyField('id')
                setSearchLoading(false);
                setDataLoaded(true);
                if (surveyAction === "create" || surveyStatus === 'draft') {
                    let selectedIds = response.data.data.length > 0 ? response.data.data.reduce((arr, cur) => {
                        return arr.concat(cur.id)
                    }, []) : []
                    dispatchSelectedOnEdit(selectedIds);
                }
            } else {
                toast.warn("Something went wrong!!")
            }
        });
    }

    /**
     * Load initial data.
     */
    useEffect(() => {
        EziLoader.show()
        getParticipantData();
    }, [filters])

    const statusFormatter = (cell, row) => {
        return (
            <span className={`themes-status_c ${cell.toLowerCase()}`}>{cell}</span>
        );
    }

    const handleTableChange = (type, { page, sizePerPage }) => {
        switch (type) {
            case "pagination":
                EziLoader.show()
                pageRef.current = sizePerPage
                getParticipantData(page);
                break;
            case "sort":
                getParticipantData();
                break;
        }

    }
    const handleCheckData = (row, isSelect) => {
        if (surveyAction === "create" || surveyStatus === 'draft') {
            return false;
        }
        const selected = [...selectedOnEdit];
        if (isSelect) {
            selected.push(row[uniqueKeyField]);

        } else {
            selected.indexOf(row[uniqueKeyField]) !== -1 && selected.splice(selected.indexOf(row[uniqueKeyField]), 1)
        }
        dispatchSelectedOnEdit(selected)
        dispatchSelectedCount(selected.length)
    }

    const handleTableSearch = () => {
        let inputValue = inputSerch.current.value;
        clearTimeout(inputSearchTimer);
        inputSearchTimer = setTimeout(() => {
            setSearchLoading(true);
            getParticipantData(1, inputValue)
        }, 1000);
    }

    const handleApplyFilter = (filters) => {
        setFilters(filters)
        setParticipantFilter(false);
    }
    const handleClearFilter = () => {
        setFilters([])
    }
    const handleOnSelectAll = (isSelected, rows) => {
        if (isSelected) {
            let selectedData = rows.map(item => item[uniqueKeyField])
            let newValues = [...new Set([...selectedOnEdit, ...selectedData])]
            dispatchSelectedOnEdit(newValues)
            dispatchSelectedCount(newValues.length)
        }
        if (!isSelected) {
            let unSelectedData = rows.map(item => item[uniqueKeyField])
            let oldValues = [...selectedOnEdit];
            let newValues = oldValues.filter(item => !unSelectedData.includes(item));
            dispatchSelectedOnEdit(newValues)
            dispatchSelectedCount(newValues.length)
        }
    }

    /**
     * Build Data Table
     * @param {Object} param0 
     */
    const RemotePagination = ({ data, page = 1, sizePerPage, onTableChange, totalSize }) => (
        <div>
            <BootstrapTable
                ref={tableRef}
                remote
                keyField={uniqueKeyField}
                data={data}
                noDataIndication="No Data Available."
                columns={dataColumn}
                pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList: getTablePagesSizes(totalSize) })}
                onTableChange={onTableChange}
                selectRow={{ mode: 'checkbox', onSelect: handleCheckData, selected: selectedOnEdit, onSelectAll: handleOnSelectAll, hideSelectAll: (surveyAction === "create" || surveyStatus === 'draft') }}
            />
        </div>
    )

    /**
     * Download Survey Response Sheet
     */
    const downLoadResponseSheet = () => {
        let formData = new FormData();
        formData.append("survey_id", urlParams.survey_id);
        formData.append("isedit", (surveyAction === "edit") ? true : false);

        Axios.post(configURL.download_response_sheet, formData).then(response => {
            if (response.data.success === true) {
                window.open(response.data.download_link, '_blank').focus();
            } else {
                toast.warn("Something went wrong!!")
            }
        });
    }

    return (
        <React.Fragment >
            <div className="saved-demographic-filter-wrap">

                <div className="participant-fiter-wrap">
                    {(surveyAction === 'edit' && surveyStatus !== 'draft') &&
                        <button className="response-participant-download" onClick={downLoadResponseSheet} title="Download Response Sheet"></button>}
                    <button type="button" className="ezi-filter-btn" onClick={() => setParticipantFilter(!participantFilter)} >Filter</button>
                    <DemographicFilter
                        applyFilter={handleApplyFilter}
                        clearFilter={handleClearFilter}
                        searchable={true}
                        show={participantFilter}
                        dataSource={{
                            filters: configURL.participantFilters,
                            filterOptions: configURL.participantFiltersOptions
                        }}
                        additional={{ survey_id: urlParams.survey_id }}
                        hide={() => setParticipantFilter(false)}
                    />
                </div>
                <SweetSearch ref={inputSerch} change={handleTableSearch} loading={searchLoading} />
            </div>
            {dataLoaded &&
                <div className="saved_participants_table">
                    <RemotePagination
                        data={participants}
                        page={pagination.current_page || 0}
                        sizePerPage={pageRef.current}
                        totalSize={pagination.total || 0}
                        onTableChange={handleTableChange}
                    />
                </div>
            }
        </React.Fragment>
    )

}
const mapStateToProps = state => {
    return {
        surveyAction: state.survey.action,
        surveyStatus: state.survey.status,
        selectedOnEdit: state.participant.selectedOnEdit
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatchSelectedOnEdit: (data = []) => dispatch(AppAction.setSelectedOnEdit({ participants: data })),
        dispatchSelectedCount: (count = 0) => dispatch(AppAction.updateSelectedCount({ count })),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedParticipants)