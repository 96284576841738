import React from 'react';

const PageNotFound = (props) => {
    return (
        <div className="page-not-found-component">
            <div className="no-result-svg-wrap">
                <img src={require("../assets/images/no-result-found.svg")} />
                <span className="unauthorised-result-text">You are not authorized to access this feature</span>
            </div>
        </div>
    )
}
export default PageNotFound;