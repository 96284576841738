export const SURVEY_LANG_STRINGS = {
    "मराठी": {
        pagePrevText: "आधी",
        pageNextText: "पुढे",
        completeText: "पूर्ण"
    },
    "हिन्दी": {
        pagePrevText: "पीछे",
        pageNextText: "आगे",
        completeText: "पूर्ण"
    }
}