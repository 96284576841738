import React, { useState, useEffect, useContext, useRef } from "react";
import './AddCompany.scss';
import { Breadcrumb, Tab, Nav } from 'react-bootstrap';
import useForm from 'react-hook-form';
import configURL from 'config/config';
import Axios from "utility/Axios";
import { toast } from 'react-toastify';
import AppContext from 'store/AppContext';

const SaveCompany = ({ history, match }) => {
    const { EziLoader } = useContext(AppContext)
    const [companyTab, setCompanyTab] = useState('general');
    const { register, handleSubmit, errors, reset } = useForm();
    const [submitClicked, setSubmitClicked] = useState(false);
    const [companyData, setCompanyData] = useState({});
    const company_id = match.params.account_id
    const imageRef = useRef(null)

    /**
     * Save Company
     * @param {Object} data 
     */
    const submitFormData = (data) => {
        EziLoader.show()
        const sendData = new FormData();
        let logo = data.company_logo.length > 0 ? data.company_logo[0] : null
        sendData.append("company_logo", logo);
        sendData.append("insertdata", JSON.stringify({ 'data': data }));
        if (company_id) {
            sendData.append("account_id", company_id)
        }
        Axios.post(configURL.save_compnay_details, sendData).then(response => {
            EziLoader.hide()
            if (response.data.success === true) {
                toast.success(response.data.message || "Company Saved");
                history.push("/partner/companies")
            } else {
                toast.warn(response.data.message || "Something went wrong");
            }
        }).catch(err => {
            console.log(err.toString())
            EziLoader.hide()
        })
    }

    /**
     * Handle Form Error Tab
     */
    useEffect(() => {
        let errorsKey = Object.keys(errors)
        if (submitClicked && errorsKey.length > 0) {
            let general = ["companyname", "companywebsite", "companyaddress", "city", "zipcode"]
            let additional = ["first_name", "password", "phone", "email"]
            let errorTab = "";
            if (errorsKey.length > 0) {
                if (general.some(item => errorsKey.includes(item)) && errorTab === "") {
                    errorTab = "general";
                }
                if (additional.some(item => errorsKey.includes(item)) && errorTab === "") {
                    errorTab = "additional";
                }
                if (errorTab !== "") {
                    setCompanyTab(errorTab)
                }
            }
            setSubmitClicked(false)
        }
    }, [submitClicked, errors]);

    useEffect(() => {
        if (company_id) {
            EziLoader.show()
            let formData = new FormData()
            formData.append("account_id", company_id)
            Axios.post(configURL.get_compnay_details, formData).then(response => {
                EziLoader.hide()
                if (response.data.success) {
                    setCompanyData(response.data.result)
                }
            }).catch(err => {
                EziLoader.hide()
            })
        }
    }, []);

    /**
     * Handle Form Reset
     */
    const clearForm = () => {
        reset({
            companyname: "",
            companywebsite: "",
            companyaddress: "",
            city: "",
            zipcode: "",
            first_name: "",
            password: "",
            phone: "",
            email: "",
            billingemailaddress: "",
            subscriptionplan: "",
            subscriptionstartdate: "",
            subscriptionenddate: "",
            paymentmode: ""
        })
    }

    /**
     * Handle Image Upload
     * @param {Event} e 
     */
    const previewFile = () => {
        const file = document.querySelector('input[type=file]').files[0];
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            imageRef.current.src = reader.result
        }, false);
        reader.readAsDataURL(file);
    }
    return (
        <React.Fragment>
            <section className="Page-AddCompany" >
                <form onSubmit={handleSubmit(submitFormData)} className="company-form">
                    <div className="breadcrumb_ezi">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => history.push("/partner-dashboard")}>Dashboard</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => history.push("/partner/companies")}>Companies</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => history.push("/partner/add-company")}>{company_id ? 'update ' : 'add'}</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="column-header">
                            <h1 className="page-heading">{company_id ? 'Update Company' : 'Add new company'}</h1>
                            <div className="column-header-btn">
                                <button type="button" className="btn-ripple clear_all" onClick={clearForm} >Clear all</button>
                                <button type="submit" className="btn-ripple add-new" onClickCapture={() => setSubmitClicked(true)}>Save</button>
                            </div>
                        </div>
                    </div>
                    <div className="add-company-form-wrap">
                        <div className="company-info-header">
                            <div className="conpany-name-id-wrap">
                                <span className="title">{companyData.companyname || 'Company name'}</span>
                                {/* <span className="subtitle">Account ID</span> */}
                            </div>
                            <div className="company-add-logo-wrap">
                                <img ref={imageRef} alt="" src={companyData.company_logo || require(`../../../assets/images/partner-default-logo.svg`)} className="company-default-logo" />
                                <div className="company-logo-upload-position">
                                    <div className="company-logo-upload-wrapper">
                                        <span className="company-logo-upload-btn"></span>
                                        <input type="file" name="company_logo" ref={register} className="company-logo-upload-input" onChange={previewFile} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tablist_ezi">
                            <Tab.Container activeKey={companyTab} onSelect={k => setCompanyTab(k)}>
                                <div className="tab-header-wrap">
                                    <div className="tab-left-header">
                                        <Nav variant="pills" >
                                            <Nav.Item>
                                                <Nav.Link eventKey="general">General</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="additional">Additional</Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="billing">Billing</Nav.Link>
                                            </Nav.Item> */}
                                        </Nav>
                                    </div>
                                </div>
                                <Tab.Content>
                                    <Tab.Pane eventKey="general">
                                        <label>
                                            <input type="text"
                                                placeholder="Company Name"
                                                name="companyname"
                                                ref={register({ required: true })}
                                                defaultValue={companyData.companyname || ""} />
                                            {errors.companyname && errors.companyname.type === 'required' &&
                                                <span className="error_cu">Company Name is required</span>}
                                        </label>
                                        <label>
                                            <input type="text"
                                                placeholder="Company Website"
                                                name="companywebsite"
                                                ref={register({ required: true })}
                                                defaultValue={companyData.companywebsite || ""} />
                                            {errors.companywebsite && errors.companywebsite.type === 'required' &&
                                                <span className="error_cu">Company Website Name is required</span>}
                                        </label>
                                        <label>
                                            <input type="text"
                                                placeholder="Company Address"
                                                name="companyaddress"
                                                ref={register({ required: true })}
                                                defaultValue={companyData.companyaddress || ""} />
                                            {errors.companyaddress && errors.companyaddress.type === 'required' &&
                                                <span className="error_cu">Company Address is required</span>}
                                        </label>
                                        <label>
                                            <select name="industry" value={companyData.industry || ""} ref={register}
                                                onChange={({ target }) => {
                                                    setCompanyData({ ...companyData, industry: target.value })
                                                }}>
                                                <option >Select Industry Vertical</option>
                                                <option value="IT">IT</option>
                                                <option value="Software">Software</option>
                                            </select>
                                        </label>

                                        <label>
                                            <input type="text"
                                                placeholder="City"
                                                name="city"
                                                ref={register({ required: true })}
                                                defaultValue={companyData.city || ""} />
                                            {errors.city && errors.city.type === 'required' &&
                                                <span className="error_cu">City is required</span>}
                                        </label>
                                        <label>
                                            <input type="text"
                                                placeholder="Zipcode"
                                                name="zipcode"
                                                ref={register({ maxLength: 7, required: true, pattern: /^[0-9]*$/ })}
                                                defaultValue={companyData.zipcode || ""} />
                                            {errors.zipcode && errors.zipcode.type === 'required' &&
                                                <span className="error_cu">Zipcode is required</span>}
                                            {errors.zipcode && errors.zipcode.type === 'pattern' &&
                                                <span className="error_cu">Zipcode is not valid</span>}
                                            {errors.zipcode && errors.zipcode.type === 'maxLength' &&
                                                <span className="error_cu">Max zipcode length is 7</span>}
                                        </label>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="additional">
                                        <label>
                                            <input type="text"
                                                placeholder="Name"
                                                name="first_name"
                                                ref={register({ required: true })}
                                                defaultValue={companyData.first_name || ""} />
                                            {errors.first_name && errors.first_name.type === 'required' &&
                                                <span className="error_cu">Name is required</span>}
                                        </label>
                                        {!company_id &&
                                            <label>
                                                <input type="password"
                                                    placeholder="Enter Password"
                                                    name="password" ref={register({ required: true })}
                                                    defaultValue={companyData.password || ""} />
                                                {errors.password && errors.password.type === 'required' &&
                                                    <span className="error_cu">Password is required</span>}
                                            </label>}
                                        <label>
                                            <input type="text"
                                                placeholder="Contact Number"
                                                name="phone" ref={register({ required: true })}
                                                defaultValue={companyData.phone || ""} />
                                            {errors.phone && errors.phone.type === 'required' &&
                                                <span className="error_cu">Phone Number is required</span>}
                                        </label>

                                        <label>

                                            <input type="text"
                                                placeholder="Login email id"
                                                name="email"
                                                ref={register({ required: true, pattern: /^[a-zA-Z0-9.a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]+$/i })} defaultValue={companyData.email || ""} disabled={(company_id)} />
                                            {errors.email && errors.email.type === 'required' &&
                                                <span className="error_cu">Email is required</span>}
                                            {errors.email && errors.email.type === 'pattern' &&
                                                <span className="error_cu">Please Enter Valid Email id</span>}
                                        </label>
                                    </Tab.Pane>
                                    {/* <Tab.Pane eventKey="billing">
                                        <label>
                                            Billing email address
                                            <input type="text"
                                                placeholder="Enter Billing email address"
                                                name="billingemailaddress"
                                                ref={register({ minLength: 0, maxLength: 256 })}
                                                defaultValue={companyData.billingemailaddress || ""} />
                                        </label>
                                        <label>
                                            Subscription Plan
                                            <input type="text"
                                                placeholder="Enter Subscription Plan"
                                                name="subscriptionplan"
                                                ref={register({ minLength: 0, maxLength: 256 })}
                                                defaultValue={companyData.subscriptionplan || ""} />
                                        </label>
                                        <label>
                                            Subscription Start Date
                                            <input type="text"
                                                placeholder="Enter Subscription Start Date"
                                                name="subscriptionstartdate"
                                                ref={register({ minLength: 0, maxLength: 256 })}
                                                defaultValue={companyData.subscriptionstartdate || ""} />
                                        </label>
                                        <label>
                                            Subscription End Date
                                            <input type="text"
                                                placeholder="Enter Subscription End Date"
                                                name="subscriptionenddate"
                                                ref={register({ minLength: 0, maxLength: 256 })}
                                                defaultValue={companyData.subscriptionenddate || ""} />
                                        </label>
                                        <label>
                                            Payment mode
                                            <input type="text"
                                                placeholder="Payment Mode"
                                                name="paymentmode"
                                                ref={register({ minLength: 0, maxLength: 256 })}
                                                defaultValue={companyData.paymentmode || ""} />
                                        </label>
                                    </Tab.Pane> */}
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </form>
            </section>
        </React.Fragment>
    )
}

export default SaveCompany;