import React, { useState, useRef, useContext } from "react";
import './UserProfile.scss';
import ViewUserProfile from "./ViewUserProfile";
import useForm from 'react-hook-form';
import Axios from "utility/Axios";
import configURL from "config/config";
import { toast } from "react-toastify";
import AppContext from 'store/AppContext';
import * as AppActions from "store/actions";
import { connect } from "react-redux";

const UserProfile = ({ updateUser }) => {
    const { EziLoader } = useContext(AppContext)
    let localStore = localStorage.getItem("appState");
    const userDetail = localStore ? JSON.parse(localStore).user : {};
    const { register, handleSubmit, errors, getValues } = useForm()
    const [editProfile, setEditProfile] = useState(false);
    const [viewUserData, setViewUserData] = useState(userDetail);
    const [userData, setUserData] = useState(userDetail);
    const [editPwd, setEditPwd] = useState(false);
    const imageRef = useRef(null)
    /**
     * Save User Data
     * 
     * @param {Object} data 
     */
    const handleSaveUser = (data) => {
        EziLoader.show()
        let formData = new FormData();
        if (data.first_name) {
            formData.append("first_name", data.first_name)
        }
        if (data.last_name) {
            formData.append("last_name", data.last_name)
        }
        if (data.cell_number) {
            formData.append("cell_number", data.cell_number)
        }
        if (data.email) {
            formData.append("email", data.email)
        }

        if (data.current_password) {
            formData.append("current_password", data.current_password)
        }
        if (data.new_password) {
            formData.append("new_password", data.new_password)
        }
        if (data.profile_pic && data.profile_pic.length > 0) {
            formData.append("profile_pic_url", data.profile_pic[0])
        }

        Axios.post(configURL.update_company_user, formData).then(res => {
            EziLoader.hide()
            if (res.data.success === true) {
                toast.success(res.data.message || "Profile Updated")
                let profile_pic = res.data.data.profile_pic_url || null;
                let localStore = JSON.parse(localStorage.getItem("appState"));
                setUserData({ ...userData, profile_pic_url: profile_pic })
                setViewUserData({ ...userData, profile_pic_url: profile_pic })
                setEditProfile(false)
                setEditPwd(false)
                const newUserData = { ...localStore, user: { ...localStore.user, ...userData, profile_pic_url: profile_pic } }
                localStorage.setItem("appState", JSON.stringify(newUserData))
                updateUser()
            } else {
                toast.warn(res.data.message.toString() || "Something went wrong.")
            }
        }).catch(err => {
            EziLoader.hide()
        })
    }

    const handleChangeInput = ({ target }) => {
        if (target.name === 'first_name') {
            setUserData({
                ...userData,
                name: target.value,
                first_name: target.value
            })
            return;
        }
        setUserData({
            ...userData,
            [target.name]: target.value,
        })

    }
    const previewFile = () => {
        const file = document.querySelector('input[type=file]').files[0];
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            imageRef.current.src = reader.result
        }, false);
        reader.readAsDataURL(file);
    }
    return (
        <React.Fragment>
            <section className="Page-UserProfile" >
                <form onSubmit={handleSubmit(handleSaveUser)}>
                    <div className="profile-wrap">
                        <h1>Profile</h1>
                        {!editProfile && <ViewUserProfile editProfile={() => setEditProfile(!editProfile)} userDetail={viewUserData} />}
                        {editProfile &&
                            <div className="user-info-card">
                                <div className="user-info-header">
                                    <div className="user-info-img-wrap">
                                        <div className="ul-logo-wrap">
                                            <img ref={imageRef} alt="" src={userDetail.profile_pic_url || require(`../../../assets/images/partner-default-logo.svg`)} className="ul-default-logo" />
                                            <div className="ul-logo-upload-position">
                                                <div className="ul-logo-upload-wrapper">
                                                    <span className="ul-logo-upload-btn"></span>
                                                    <input type="file" name="profile_pic" ref={register} className="ul-logo-upload-input" onChange={previewFile} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-text-wrap">
                                            <span className="title">{userData.first_name || 'NA'} {userData.last_name || 'NA'}</span>
                                            <span className="subtitle">{userDetail.assigned_roles && userDetail.assigned_roles.join(', ') || "NA"}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-info-body">
                                    <div className="user-info-col">
                                        <div className="lbl-control">
                                            <label>First Name</label>
                                            <div className="val-control">
                                                <input className="fld-value-input" name="first_name" ref={
                                                    register({
                                                        required: true
                                                    })
                                                } defaultValue={userData.first_name} onChange={handleChangeInput} />
                                                {errors.first_name && errors.first_name.type === 'required' && <span className={`error-message`}>Please Enter First Name</span>}
                                            </div>
                                        </div>
                                        <div className="lbl-control">
                                            <label>Last Name</label>
                                            <div className="val-control">
                                                <input className="fld-value-input" name="last_name" ref={
                                                    register({
                                                        required: true
                                                    })
                                                } defaultValue={userData.last_name} onChange={handleChangeInput} />
                                                {errors.last_name && errors.last_name.type === 'required' && <span className={`error-message`}>Please Enter Last Name</span>}
                                            </div>
                                        </div>
                                        <div className="lbl-control">
                                            <label>Primary Email</label>
                                            <div className="val-control">
                                                <input className="fld-value-input" name="email" ref={register({
                                                    required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
                                                })} defaultValue={userData.email} onChange={handleChangeInput} />
                                                {errors.email && errors.email.type === 'required' && <span className={`error-message`}>Please Enter Email Id.</span>}
                                                {errors.email && errors.email.type === "pattern" && <span className={`error-message`}>Please Enter Valid Email</span>}
                                            </div>
                                        </div>
                                        <div className="lbl-control">
                                            <label>Mobile Number</label>
                                            <div className="val-control">
                                                <input className="fld-value-input" name="cell_number" ref={register({
                                                    pattern: /^((\+)?(\d{2}[-]))?(\d{10}){1}?$/
                                                })} defaultValue={userData.cell_number} onChange={handleChangeInput} />
                                                {errors.cell_number && errors.cell_number.type === "pattern" && <span className={`error-message`}>Please Enter Valid Cell Number</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="security-wrap">
                        <h1>Security</h1>
                        {!editPwd &&
                            <div className="security-card">
                                <div className="change-pass-wrap">
                                    <div className="pass-fld-wrap">
                                        <label>Password</label>
                                        <div className="pass-val-control">
                                            <span className="pass-val">*****</span>
                                        </div>
                                    </div>
                                    <button type="button" className="ezi-btn btn-ripple change-pas" onClick={() => setEditPwd(!editPwd)}>Change Password</button>
                                </div>
                            </div>}
                        {editPwd &&
                            <div className="security-card">
                                <div className="pass-fld-wrap">
                                    <label>Current Password</label>
                                    <div className="pass-val-control">
                                        <input type="password" className="fld-pass-input" name="current_password" ref={register({ required: true })} />
                                        {errors.current_password && errors.current_password.type === 'required' && <span className={`error-message`}>You must specify a Old Password</span>}
                                    </div>
                                </div>
                                <div className="pass-fld-wrap">
                                    <label>New Password</label>
                                    <div className="pass-val-control">
                                        <input type="password" className="fld-pass-input" name="new_password" ref={register({ required: true, minLength: 8 })} />
                                        {errors.new_password && errors.new_password.type === 'required' && <span className={`error-message`}>You must specify a new password</span>}
                                        {errors.new_password && errors.new_password.type === 'minLength' && <span className={`error-message`}>New Password should be atleast 8 character long</span>}
                                    </div>
                                </div>
                                <div className="pass-fld-wrap">
                                    <label>Confirm New Password</label>
                                    <div className="pass-val-control">
                                        <input type="text" className="fld-pass-input" name="password_match" ref={register({
                                            required: true,
                                            validate: value => (value === getValues()["new_password"])
                                        })} />
                                        {errors.password_match && errors.password_match.type === 'required' && <span className={`error-message`}>You must specify a confirmation password</span>}
                                        {errors.password_match && errors.password_match.type === 'validate' && <span className={`error-message`}>The passwords do not match</span>}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {(editProfile || editPwd) && <div className="profile-save-wrap">
                        <button className="ezi-btn btn-ripple  ezi-pink-btn profile-save" type="submit">Save</button>
                        <button className="ezi-btn profile-cancel" onClick={() => {
                            setEditProfile(false)
                            setEditPwd(false)
                        }}>Cancel</button>
                    </div>}
                </form>
            </section>
        </React.Fragment>
    )
}
const mapDispatchToProps = dispatch => {
    return {
        updateUser: () => dispatch(AppActions.updateUser())
    }
}
export default connect(null, mapDispatchToProps)(UserProfile);