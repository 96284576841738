import React from 'react';
import { Doughnut } from 'react-chartjs-2';


const Statistics = (props) =>{

    const doughnutData = {

        labels: [ 'Email', 'Website', 'SMS' ],
        datasets: [{
            data: [280, 100, 50],
            backgroundColor: [ '#7467F0', '#FFA550', '#EA6061' ],
        }]


    }       
        // { value: 280, color:"#7467F0", label: "Email" },
        // { value: 100, color: "#FFA550", label: "Website" },
        // { value: 50, color: "#EA6061", label: "SMS" }

     
       
    

    // const doughnutOptions = {
    //     segmentShowStroke: false,
    //     responsive: true, 
    //     maintainAspectRatio: false,  
    // }

    const doughnutOptions = {
        segmentShowStroke: false,
        responsive: true, 
        maintainAspectRatio: false,  
        legend: { display: false, },
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        tooltips: {
            displayColors: false,
            backgroundColor: "#5A4E63",
            enabled: true,
            mode: "single",
            bodyFontSize: 13,
            bodyFontFamily: "NunitoSansSemiBold",
            bodyFontColor: "#FFFFFF",
            yPadding: 8,
            xPadding: 15,
            cornerRadius: 5,
          },
    }



    return(

        <div className="statistic-card">
            <div className="statistic-card-header">
                <span className="header-tit">Statistics</span>
                <button type="button" className="view-trend-btn" onClick={props.showTrend}>{props.label}</button>
            </div>

            <div className="report-card-body">
                <div className="statistic-card-body">
                    <div className="statistic-text-wrap">
                        <div className="invited-respondant-text-wrap">
                            <div className="invited-text-wrap">
                                <span className="title">Invited</span>
                                <span className="subtitle">5,000</span>
                                {/* <span className="rate-count">80% Response rate</span> */}
                            </div>
                            <div className="respondant-text-wrap">
                                <span className="title">Respondents</span>
                                <span className="subtitle">4,000</span>
                                {/* <span className="rate-count">10% Dropout rate</span> */}
                            </div>
                        </div>
                        <div className="nonrespondant-dropout-text-wrap">
                            <div className="dropout-text-wrap">
                                <span className="title">Non-respondents</span>
                                <span className="subtitle">5,000</span>
                            </div>
                            <div className="dropout-text-wrap">
                                <span className="title">Dropouts</span>
                                <span className="subtitle">4,000</span>
                            </div>
                        </div>
                    </div>
                    <div className="statistic-donut-wrap">
                        <Doughnut data={doughnutData} options={doughnutOptions}/>
                    </div>
                </div>
            </div>
        </div>
        

    )


}

export default Statistics;