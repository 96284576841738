export const demographicFilter = [
    {
        name: "location",
        label: "Location",
        options: [
            "nagpur"
        ]
    },
    {
        name: "department",
        label: "Department",
        options: [
            "it",
            "sales",
            "admin",
            "hr"
        ]
    },
    {
        name: "gender",
        label: "Gender",
        options: [
            "male",
            "female",
            "other"
        ]
    },
    {
        name: "dob",
        label: "DOB",
        options: [
            "2020-01-08t18:30:00.000z"
        ]
    },
    {
        name: "active",
        label: "Active",
        options: [
            "yes",
            "no"
        ]
    }
];

export const surveyFilter = [
    {
        name: "survey_created_by",
        label: "Created By",
        options: [{ id: "12fsd", name: "Puneet Pandey" }, { id: "dfsf32", name: "Samir Munjewar" }]
    },
    {
        name: "survey_type",
        label: "Survey Type",
        options: [{ id: "12fsdd", name: "Survey Designer" }, { id: "dxfsfs32", name: "Customer Experience" }]
    },
    {
        name: "survey_status",
        label: "Survey Status",
        options: [{ id: "active", name: "Active" }, { id: "inactive", name: "Inactive" }, { id: "close", name: "Closed" }]
    }
];