import React, { useEffect, useState, Fragment } from "react";
import { Modal, Spinner } from 'react-bootstrap';
import useForm from 'react-hook-form';
import Axios from "utility/Axios";
import configURL from 'config/config';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as AppAction from "store/actions";

function DynamicParticipantModal({ dispatchParticipantSourceType, surveyAction, ...modelProps }) {
    const { register, handleSubmit, errors } = useForm();
    const [participantIdentifier, setParticipantIdentifier] = useState([])
    const [loading, setLoading] = useState(false)
    const [savingMsg, setSavingMsg] = useState(null)
    const [saving, setSaving] = useState(false)
    const urlParams = useParams()
    const requiredData = ['fname', 'lname', 'email']
    const onSubmit = data => {
        setSaving(true)
        let formData = new FormData()
        formData.append('survey_id', urlParams.survey_id)
        formData.append("isedit", (surveyAction === "edit") ? true : false);
        formData.append("participant_data", JSON.stringify(data));

        Axios.post(configURL.savedynamicParticipant, formData).then(response => {
            setSaving(false)
            if (response.data.success) {
                dispatchParticipantSourceType('single')
                modelProps.onHide()
                toast.success(response.data.message || 'Participant data saved.')
            } else {
                setSavingMsg(response.data.message || 'Something went wrong')
                setTimeout(() => setSavingMsg(null), 8000);
            }
        }).catch(err => {
            console.log(err.response);
            setSavingMsg('Something went wrong')
            setSaving(false)
        })
    };
    useEffect(() => {
        if (modelProps.show) {
            setSavingMsg(null)
            setLoading(true)
            let formData = new FormData()
            formData.append('survey_id', urlParams.survey_id)
            Axios.post(configURL.dynamicParticipantIdentifier, formData).then(response => {
                setLoading(false)
                if (response.data.success) {
                    setParticipantIdentifier(response.data.results)
                }
            }).catch(err => {
                console.log(err.response);
                setLoading(false)
            })
        }
    }, [modelProps.show])
    return (

        <Modal {...modelProps} size="md" aria-labelledby="contained-modal-title-vcenter" centered className="theme-modal-wrapper" >
            <Modal.Header className="ezi-modal-header">
                <Modal.Title id="contained-modal-title-vcenter" className="theme-modal-title ezi-modal-header-title" >
                    <span className="theme-modal-title-text">Add New Dynamic Participant</span>
                    <span className="ezi-modal-close" onClick={modelProps.onHide}></span>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {!loading ? <form onSubmit={handleSubmit(onSubmit)} className="add-theme-form">
                    {participantIdentifier.length > 0 && participantIdentifier.map((item, i) => (
                        <div key={i} className="theme-field-wrapper">
                            <div className="theme-field-50">
                                <div className="theme-field">
                                    <label>{item.label}</label>
                                    {(() => {
                                        switch (item.type) {
                                            case "text": return (
                                                <Fragment>
                                                    <input type="text" className="theme-field-control" name={item.name} ref={register({ required: requiredData.includes(item.name) })} placeholder={`Please enter ${item.label}`} />
                                                    {errors[item.name] && <span className="theme-error_cu">{item.label} is required.</span>}
                                                </Fragment>
                                            );
                                            case "email": return (
                                                <Fragment>
                                                    <input type="text" className="theme-field-control" name={item.name} ref={register({ required: true, pattern: /^[a-zA-Z0-9.a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]+$/i })} placeholder={`Please enter ${item.label}`} />
                                                    {(errors[item.name] && errors[item.name].type === "required") && <span className="theme-error_cu">{item.label} is required.</span>}
                                                    {(errors[item.name] && errors[item.name].type === "pattern") && <span className="theme-error_cu">{item.label} is not valid.</span>}
                                                </Fragment>
                                            );
                                            case "contact_number": return (
                                                <Fragment>
                                                    <input type="text" className="theme-field-control" name="contact_number" ref={register({ pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/ })} placeholder={`Please enter ${item.label}`} />
                                                    {(errors.contact_number && errors.contact_number.type === "pattern") && <span className="theme-error_cu">{item.label} is not valid.</span>}
                                                </Fragment>
                                            );
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="theme-modal-footer">
                        {savingMsg && <p className="dynamic_participant_msg">{savingMsg}</p>}
                        <button type="button" className="close-theme-btn" onClick={modelProps.onHide}>Close</button>
                        <button type="submit" className="btn-ripple ezi-pink-btn add-theme-btn" disabled={saving}>
                            Save Participant {saving && <Spinner animation="border" size="sm" />}
                        </button>
                    </div>
                </form> : <p className="dynamic_participant_loading">Loading Information...</p>}
            </Modal.Body>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        surveyAction: state.survey.action
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchParticipantSourceType: (type) => dispatch(AppAction.setDynamicParticipantSourceType({ type }))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DynamicParticipantModal)