import React, { useState, useEffect, useContext } from "react";
import './ColumnMapping.scss';
import { Breadcrumb } from 'react-bootstrap';
import useColumnMapping from './useColumnMapping';
import ColumnTodo from "./ColumnTodo";
import Axios from "utility/Axios";
import { useHistory } from "react-router-dom";
import ColumnTodoForm from "./ColumnTodoForm";
import configURL from 'config/config';
import { toast } from 'react-toastify';
import AppContext from 'store/AppContext';
import EziLoader from 'components/EziLoader';

const ColumnMapping = (headers) => {
	const { languageObj = {} } = useContext(AppContext)
	let history = useHistory();
	const mapData = useColumnMapping();
	const [loading, setLoading] = useState(false);
	const [activeTab] = useState(history.location.pathname.split('/')[2]);
	let currentPath = headers.recentpath;
	var previewData = headers.data;

	const [demography, setDemography] = useState(false);
	const [defaultMapping, setdefaultMapping] = useState({});
	const [defaultDemoData, setdefaultDemoData] = useState({});
	let mappingText = 'Employee';
	switch (activeTab) {
		case 'employee':
			mappingText = 'Employee';
			break;

		case 'customer':
			mappingText = 'Customer'
			break;

		case 'other':
			mappingText = 'Others'
			break;
	}
	const [todos, setColumnTodos] = useState([
		{ label: mappingText + ' ID', text: 'id', isCompleted: false, isDeleteButtonShow: false, isSelected: true },
		{ label: mappingText + ' Email', text: 'email', isCompleted: false, isDeleteButtonShow: false, isSelected: true },
		{ label: mappingText + ' First Name', text: 'fname', isCompleted: false, isDeleteButtonShow: false, isSelected: false },
		{ label: mappingText + ' Last Name', text: 'lname', isCompleted: false, isDeleteButtonShow: false, isSelected: false },
		{ label: mappingText + ' Contact No', text: 'contact_no', isCompleted: false, isDeleteButtonShow: false, isSelected: false }
	]);
	const getEmployeeDefaultData = (todos) => {
		var local = JSON.parse(localStorage.getItem('appState'));
		let defaultData = {};
		if (todos !== undefined && todos.length > 0) {
			todos.map((todo) => {
				defaultData[todo.text] = '';
			});
		}
		defaultData['attributes'] = {};
		defaultData['token'] = local["user"]["auth_token"];
		defaultData['company_id'] = local["user"]["company_id"];
		return defaultData;
	}

	const handleOptionSelect = (e) => {
		let demographicName = e.target[e.target.selectedIndex].getAttribute('data-demographic');
		if (defaultDemoData.hasOwnProperty(demographicName)) {
			setdefaultMapping({ ...defaultMapping, [demographicName]: e.target.value });
		} else {
			setAdditionalAttributes(demographicName, e.target.value);
		}
	}

	const setAdditionalAttributes = (demographicName, demographicValue = '') => {
		let attributes = defaultMapping.attributes;
		setdefaultMapping({
			...defaultMapping, 'attributes': {
				...attributes,
				[demographicName]: demographicValue,
			}
		});
	}

	const deleteAdditionalAttributes = (demographicName) => {
		if (defaultDemoData.hasOwnProperty(demographicName)) {
			// if default demographics name also need to delete then write code here
		} else {
			let attributes = defaultMapping.attributes;
			delete attributes[demographicName];
			setdefaultMapping({
				...defaultMapping, 'attributes': {
					...attributes,
				}
			});
		}
	}

	const handleDemoCheck = (e) => {
		let checked = e.target.checked;
		let demographicName = e.target.getAttribute('data-demographic');
		let objectIndex = todos.findIndex(obj => Object.values(obj).includes(demographicName));
		if (objectIndex !== -1) {
			let prevTodo = todos;
			prevTodo[objectIndex]['isSelected'] = checked;
			setColumnTodos([...prevTodo]);
		}
	}

	const handleAddTodo = text => {
		const newToDos = [...todos, { text, label: text, isDeleteButtonShow: true }];
		setColumnTodos(newToDos);
		setAdditionalAttributes(text);
	};

	const handleComplete = index => {
		const newToDos = [...todos];
		newToDos[index].isCompleted = true;
		setColumnTodos(newToDos);
		newToDos[index].isDeleteButtonShow = true;
	};

	const handleUnfinished = index => {
		const newToDos = [...todos];
		newToDos[index].isCompleted = false;
		setColumnTodos(newToDos);
	};

	const handleDeleteTodo = (index, demographicName) => {
		const newToDos = [...todos];
		newToDos.splice(index, 1);
		setColumnTodos(newToDos);
		deleteAdditionalAttributes(demographicName);
	};

	const previewHeaderData = previewData && previewData.header.map((item, index) => {
		if (index <= 3) {
			return <div className="preview-header-th" key={index}>{item}</div>;
		}
	})

	var previewBodyData = [];

	for (var i = 1; i < 4; i++) {
		const data = <div className="preview-body-tr" key={`preData${i}`}>
			{
				(previewData && previewData.excel_preview[i] !== undefined) && previewData.excel_preview[i].map((item, index) => {
					if (index <= 3) {
						return <div className="preview-body-td" key={index}>{item}</div>;
					}
				})
			}
		</div>
		// const newData = {data}</div>
		previewBodyData.push(data);
	}

	const pick = (obj, keys) =>
		Object.keys(obj).filter(i => keys.includes(i)).reduce((acc, key) => {
			acc[key] = obj[key];
			return acc;
		}, {})

	const sendBulkUploadData = () => {
		let todosdata = todos.filter(todo => { return todo.isSelected });
		if (todosdata.length === 0) {
			toast.error(`Please check atleast one Checkbox !`);
			return false;
		}
		setLoading(true);
		let allText = todosdata.map(todo => todo.text);
		let finalObject = pick(defaultMapping, allText);
		let finalAttributes = pick(defaultMapping.attributes, allText);
		finalObject.attributes = finalAttributes;
		const formdata = new FormData();
		formdata.append("map", JSON.stringify(finalObject));
		formdata.append("file", headers.fileobject);
		formdata.append("app_id", "0e37df36-f698-11e6-8dd4-cb9ced3df976");
		formdata.append("account_id", "0e37df36-f698-11e6-8dd4-cb9ced3df976");
		formdata.append("partner_id", "0e37df36-f698-11e6-8dd4-cb9ced3df976");

		setLoading(true);
		Axios.post(configURL.excelValidation + '/' + currentPath, formdata).then(response => {
			setLoading(false);
			if (response.data.success) {
				setLoading(false);
				history.push(`/validated-data/${currentPath}`, {
					value: response.data
				});
			}
		})
	}

	useEffect(() => {
		let defaultData = getEmployeeDefaultData(todos);
		setdefaultMapping(defaultData);
		setdefaultDemoData(defaultData);
	}, []);

	useEffect(() => {
	}, [defaultMapping]);

	return (
		<React.Fragment>

			<section className="Page-ColumnMapping">

				<div className="breadcrumb_ezi">
					<Breadcrumb>
						<Breadcrumb.Item onClick={() => history.push("/people-directory")}>
							{languageObj.translate('PeopleDirectory.1')}
						</Breadcrumb.Item>
						{(() => {
							switch (activeTab) {
								case 'employee':
									return <Breadcrumb.Item onClick={() => history.push("/people-directory", {
										page_key: "employee"
									})}>{'Employee'}</Breadcrumb.Item>
								case 'customer':
									return <Breadcrumb.Item onClick={() => history.push("/people-directory", {
										page_key: "customer"
									})}>{'Customer'}</Breadcrumb.Item>
								case 'other':
									return <Breadcrumb.Item onClick={() => history.push("/people-directory", {
										page_key: "other_contact"
									})}>{'Other Contact'}</Breadcrumb.Item>
							}
						})()}
						<Breadcrumb.Item>{languageObj.translate('BulkUpload.1')}</Breadcrumb.Item>
						<Breadcrumb.Item >{languageObj.translate('ColumnMapping.1')}</Breadcrumb.Item>
					</Breadcrumb>
					<div className="column-header">
						<h1 className="page-heading">{languageObj.translate('ColumnMapping.1')}</h1>
						<div className="column-header-btn">
							{/* <button type="button" className="btn-ripple add-new">{translate('Addnew.1')}<span className="add_ic"></span></button> */}
							<button type="button" className="btn-ripple next" onClick={() => sendBulkUploadData()}>{languageObj.translate('Next.1')}<span className="next_ic"></span></button>
						</div>
					</div>

				</div>


				<div className="mapping-card">
					<div className="map-card">
						<img alt="" src={require(`../../../assets/images/employees.svg`)} className="map-card-img" />

						<div className="map-card-content">
							<h1>{previewData && previewData.number_of_employees}</h1>
							<label>{mappingText} entries</label>
						</div>
					</div>
					<div className="map-card">
						<img alt="" src={require(`../../../assets/images/column.svg`)} className="map-card-img" />
						<div className="map-card-content">
							<h1>{previewData && previewData.number_of_columns}</h1>
							<label>Number of Column</label>
						</div>
					</div>
					<div className="map-card">
						<img alt="" src={require(`../../../assets/images/employees.svg`)} className="map-card-img" />
						<div className="map-card-content">
							<h1>250</h1>
							<label>{mappingText} entries</label>
						</div>
					</div>
				</div>




				<div className="mapping-table">

					<div className="mapping-column">
						<div className="col-heading-wrap">
							<div className="col-heading"> Demographic </div>
							<div className="col-heading"> Excel Column </div>
						</div>
						<div className="demography-excel-column">

							{todos.map((todo, index) => (
								<ColumnTodo
									key={index}
									index={index}
									todo={todo}
									onComplete={handleComplete}
									onUnfinished={handleUnfinished}
									onDeleteTodo={handleDeleteTodo}
									selectHandler={handleOptionSelect}
									checkHandler={handleDemoCheck}
								/>
							))}
							{demography && <ColumnTodoForm onAddColumn={handleAddTodo} closeDemography={() => setDemography(!demography)} />}


							{!demography &&
								<div className="new-demography-wrap">
									<button type="button" className="new-demography" onClick={() => setDemography(true)}>
										Create new demographic
									</button>
								</div>
							}	

						</div>
					</div>

					<div className="preview-column">
						<div className="preview-heading"> Preview </div>
						<div className="preview-table">
							<div className="preview-header">
								{previewHeaderData}
							</div>
							<div className="preview-body">
								{previewBodyData}
							</div>
						</div>
					</div>
				</div>

				{loading && <EziLoader />}
			</section>
		</React.Fragment>
	)
}

export default ColumnMapping;
