import React, { useState, useCallback, useContext, useEffect, useRef } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import Dropzone from "hooks/Dropzone";
import Axios from "utility/Axios";
import configURL from 'config/config';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppContext from 'store/AppContext';
import DemographicFilter from 'components/DemographicFilter';
import { connect } from 'react-redux';
import * as AppAction from "store/actions";
import SweetSearch from 'components/SweetSearch';
import { getTablePagesSizes } from 'utility/helper';
import DynamicParticipantModal from './DynamicParticipantModal';

const ParticipantSourceTab = ({ dispatchParticipant, dispatchParticipantSource, selectedParticipant, dispatchDynamicSourceFile, dispatchParticipantSourceType, participantSource, surveyStatus }) => {

    const [participantTab, setParticipantTab] = useState(participantSource);
    const [participantListTab, setParticipantListTab] = useState('all');
    const [selectedUniqueIds, setSelectedUniqueIds] = useState([]);
    const [dropdownFlag, setDropdownFlag] = useState(false);
    const [peopleType, setPeopleType] = useState('');
    const [peoples, setPeoples] = useState([]);
    const [tableMeta, setTableMeta] = useState({ pagination: {}, columns: [{}], unique_key: "id" })
    const [sampleTabData, setSampleTabData] = useState({ sample_type: "random", participant_count: "" });
    const [dynamicSourceLink, setDynamicSourceLink] = useState(null);
    const [dynamicSourceType, setDynamicSourceType] = useState("");
    const [dynamicSourceModel, setDynamicSourceModel] = useState(false);
    const urlParams = useParams()
    const pathLocation = useLocation();
    const [searchLoading, setSearchLoading] = useState(false);
    const { languageObj = {}, EziLoader } = useContext(AppContext)
    let pageRef = useRef(25)
    let file;
    let inputSearchTimer = null;
    const inputSerch = useRef(null);

    useEffect(() => {
        getDynamicSourceSampleLink()
    }, []);

    /**
     * Handle People Search
     */
    const handleTableSearch = () => {
        clearTimeout(inputSearchTimer);
        inputSearchTimer = setTimeout(() => {
            setSearchLoading(true);
            changePeopleListing(peopleType, 1);
        }, 1000);
    }

    /**
     * Dynamic Source Sample Data link
     */
    const getDynamicSourceSampleLink = async () => {
        let dynamicSource = await Axios.post(configURL.dynamicPeopleSourceLink, {})
        if (dynamicSource.data.success) {
            setDynamicSourceLink(dynamicSource.data.url)
        }
    }
    /**
     * Handle Dynamic source exel load
     */
    const handleDynamicSource = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            dispatchDynamicSourceFile(acceptedFiles[0])
        }
    }, [])

    /**
     * Change People Type
     * @param {Event} event 
     */
    const changePeopleType = (event) => {
        EziLoader.show()
        setPeopleType(event.target.value);
        changePeopleListing(event.target.value);
    }

    /**
     * Handle People Listing Load Data
     * @param {String} type 
     * @param {String} page 
     * @param {String} activeTab
     * @param {Array} filters 
     */
    const changePeopleListing = (type = peopleType, page = 1, activeTab, filters = []) => {
        let searchValue = inputSerch.current ? inputSerch.current.value : null;
        let formData = new FormData();
        formData.append("type", type);
        formData.append("language", 'En');
        formData.append("page", page);
        formData.append("per_page", pageRef.current);
        if (searchValue && searchValue !== "") formData.append("search", searchValue);
        formData.append("is_random", (activeTab === 'sampled').toString());
        formData.append("filters", JSON.stringify(filters));
        Axios.post(configURL.peopleByType, formData).then(res => {
            EziLoader.hide()
            setSearchLoading(false);
            if (res.data.success !== undefined && res.data.success == true) {
                setTableMeta({
                    pagination: res.data.pagination || {},
                    columns: res.data.column || [],
                    unique_key: res.data.unique_key || 'id'
                })
                setPeoples(res.data.data);
            } else {
                setPeoples([]);
            }
        }).catch(err => {
            EziLoader.hide()
        })
    }

    /**
     * Handle Row Selection
     * @param {Object} row 
     * @param {Boolean} isSelect 
     */
    const handleSelect = (row, isSelect) => {
        let selectedOldUniqueIds = [...selectedUniqueIds]
        if (isSelect) {
            let newData = { ...selectedParticipant, [peopleType]: [...selectedParticipant[peopleType]].concat(row) }
            setSelectedUniqueIds([...selectedOldUniqueIds, row[tableMeta.unique_key]]);
            dispatchParticipant(newData)
        } else {
            let tempSendCheckData = { ...selectedParticipant };
            let objIndex = tempSendCheckData[peopleType].findIndex(item => item["id"] === row["id"]);
            tempSendCheckData[peopleType].splice(objIndex, 1)
            let idIndex = selectedOldUniqueIds.indexOf(row[tableMeta.unique_key]);
            selectedOldUniqueIds.splice(idIndex, 1)
            setSelectedUniqueIds([...selectedOldUniqueIds]);
            dispatchParticipant(tempSendCheckData)
        }
    }

    /**
     * Build Data Table
     * @param {Object} param0 
     */
    const RemotePagination = ({ data, page = 1, sizePerPage, onTableChange, totalSize }) => (
        <div>
            <BootstrapTable
                remote
                keyField={tableMeta.unique_key}
                data={data}
                noDataIndication="No Data Available."
                columns={tableMeta.columns || [{}]}
                pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList: getTablePagesSizes(totalSize) })}
                onTableChange={onTableChange}
                selectRow={{ mode: 'checkbox', onSelect: handleSelect, selected: selectedUniqueIds, onSelectAll: handleOnSelectAll }}
            />
        </div>
    );

    /**
     * Handle Table All Checkbox selection 
     * @param {*} isSelected 
     * @param {*} rows 
     */
    const handleOnSelectAll = (isSelected, rows) => {
        let selectedOldUniqueIds = [...selectedUniqueIds]
        let tempSelectedPeoples = { ...selectedParticipant };
        let unique_key = tableMeta.unique_key

        if (isSelected) {
            let selectedData = rows.map(item => item[unique_key])
            let newValues = [...selectedOldUniqueIds, ...selectedData];
            setSelectedUniqueIds([...new Set(newValues)])
            let newData = { ...tempSelectedPeoples, [peopleType]: [...tempSelectedPeoples[peopleType]].concat(rows) }
            dispatchParticipant(newData)

        }
        if (!isSelected) {
            let unSelectedData = rows.map(item => item[unique_key])
            let oldValues = [...selectedOldUniqueIds];
            let newValues = oldValues.filter(item => !unSelectedData.includes(item));
            setSelectedUniqueIds(newValues)
            let typesPeople = tempSelectedPeoples[peopleType]
            let filtered = typesPeople.filter(item => !unSelectedData.includes(item[unique_key]))
            tempSelectedPeoples[peopleType] = filtered
            dispatchParticipant(tempSelectedPeoples)
        }
    }

    /**
     * Handle Table Change
     * @param {String} type 
     * @param {Object} param1 
     */
    const handleAllEntriesChange = (type, { page, sizePerPage }) => {
        pageRef.current = sizePerPage
        if (type === 'pagination') {
            EziLoader.show()
            changePeopleListing(peopleType, page);
        }
    }

    /**
     * Sample Data
     */
    const handleApplySampling = () => {
        let formData = new FormData();
        formData.append("people_type", peopleType);
        formData.append("sampled_number", sampleTabData.participant_count);
        formData.append("survey_id", urlParams.survey_id);
        formData.append("sampled_type", sampleTabData.sample_type);
        formData.append("isedit", (pathLocation.state.action === "edit") ? true : false);
        Axios.post(configURL.save_sample_api, formData).then(res => {
            if (res.data.success !== undefined && res.data.success === true) {
                toast.success(res.data.message)
                setSampleTabData({ sample_type: "random", participant_count: "" });
            } else {
                toast.warn(res.data.message)
            }
        })
    }

    /**
     * Handle Listing Selection
     * @param {String} tab 
     */
    const handleListingSelection = (tab) => {
        switch (tab) {
            case 'all':
                setSampleTabData({ sample_type: "random", participant_count: "" })
                break;
            case 'sampled':
                setSelectedUniqueIds([])
                dispatchParticipant({ customer: [], employee: [], other: [] })
                break;
        }
        setParticipantListTab(tab)
    }

    /**
     * Handle Sample input change
     * @param {Event} param0 
     */
    const handleSampleTabDataChange = ({ target }) => {
        let name = target.name;
        let value = target.value;
        setSampleTabData({
            ...sampleTabData,
            [name]: value
        })
    }

    /**
     * Handle People Source Change
     * @param {String} tab 
     */
    const handleSourceSwitch = (tab) => {
        if (tab !== 'people') {
            setSelectedUniqueIds([])
            dispatchParticipant({ customer: [], employee: [], other: [] })
        }
        setParticipantTab(tab)
    }

    /**
     * Apply Filter
     * @param {Array} filters 
     */
    const handleApplyFilter = (filters = []) => {
        setDropdownFlag(false)
        EziLoader.show()
        changePeopleListing(peopleType, 1, "", filters)
    }

    /**
     * Clear Filter
     * @param {Array} filters 
     */
    const handleClearFilter = () => {
        setDropdownFlag(false)
        EziLoader.show()
        changePeopleListing(peopleType, 1)
    }

    /**
     * Handle Dynamic Source Type
     * @param {Object} param0 
     */
    const handleDynamicSourceType = (participantType) => {
        setDynamicSourceType(participantType)
        if (participantType === 'single') {
            setDynamicSourceModel(true)
        }
        let type = (participantType === 'single') ? '' : participantType
        dispatchParticipantSourceType(type)
    }

    return (
        <React.Fragment>
            <div className="participant-source-wrap">
                <h1 className="participant-source-heading">{languageObj.translate('ParticipantSource.1')}</h1>
                <div className="participant-source-container">
                    <Tab.Container activeKey={participantTab} onSelect={k => handleSourceSwitch(k)}>
                        <div className="survey-button-tab-header">
                            <div className="tab-left-header">
                                <Nav variant="pills" >
                                    <Nav.Item>
                                        <Nav.Link eventKey="people" onClick={() => dispatchParticipantSource('people')} >
                                            {languageObj.translate('People.1')}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="dynamic_participant" onClick={() => dispatchParticipantSource('dynamic_participant')}>
                                            Dynamic Participants
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="nosource" onClick={() => dispatchParticipantSource('nosource')}>
                                            <OverlayTrigger overlay={<Tooltip>Added Participants and Communication History will be disabled.</Tooltip>}>
                                                <span>{languageObj.translate('NoSource.1')}</span>
                                            </OverlayTrigger>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                        <Tab.Content className="survey-button-tab-content">
                            <Tab.Pane eventKey="people">
                                <select name="people" className="survey-button-tab-select" onChange={(e) => changePeopleType(e)} value={peopleType}>
                                    <option value={''}>{languageObj.translate('SelectPeopleType.1')}</option>
                                    <option value={'employee'}>{languageObj.translate('employee.1')}</option>
                                    <option value={'customer'}>{languageObj.translate('Customer.1')}</option>
                                    <option value={'other'}>{languageObj.translate('other.1')}</option>
                                </select>
                            </Tab.Pane>
                            <Tab.Pane eventKey="dynamic_participant">
                                <div className="survey-button-tab-header">
                                    <br></br>
                                    <div className="tab-left-header">
                                        <Nav variant="pills" >
                                            <Nav.Item>
                                                <Nav.Link eventKey="upload" onClick={() => handleDynamicSourceType('upload')}>
                                                    Upload
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="single" onClick={() => handleDynamicSourceType('single')}>
                                                    Add Single
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                                {dynamicSourceType === 'upload' && <div className="dropzone-wrap survey-dropzone-wrap">
                                    <Dropzone sampleLink={dynamicSourceLink} onDrop={handleDynamicSource} accept={".xlsx, .xls, .csv"} />
                                </div>}
                                <DynamicParticipantModal show={dynamicSourceModel} onHide={() => {
                                    setDynamicSourceModel(false)
                                }} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="nosource">
                                {/* <h6>No source</h6> */}
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
            {/* participant source tab end */}
            {/* participant list tab Start */}
            {
                participantTab == "people" && (peopleType !== '') && <div className="participant-list-wrap">
                    <h1 className="participant-list-heading">{languageObj.translate('ParticipantList.1')}</h1>
                    <div className="participant-list-container">
                        <Tab.Container activeKey={participantListTab} onSelect={k => handleListingSelection(k)}>
                            <div className="survey-button-tab-header">
                                <div className="tab-left-header">
                                    <Nav variant="pills" >
                                        <Nav.Item>
                                            <Nav.Link eventKey="all">{languageObj.translate('All.1')}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="sampled">{languageObj.translate('Sampled.1')}</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                            <Tab.Content className="survey-button-tab-content">
                                <Tab.Pane eventKey="all">
                                    <div className="custom-demography-select-wrap">
                                        <button type="button" onClick={() => setDropdownFlag(!dropdownFlag)} className="custom-demography-select"> {languageObj.translate('Selectdemography.1')} </button>

                                        <div className="participant-count-search-wrap">
                                            <button type="button" className="invitees-length-btn" >{languageObj.translate('ParticipantAdded.1')} <span className="invitees-length-badge"> {selectedUniqueIds.length || 0}</span> </button>
                                            <SweetSearch ref={inputSerch} change={handleTableSearch} loading={searchLoading} />
                                        </div>
                                        <DemographicFilter
                                            applyFilter={handleApplyFilter}
                                            clearFilter={handleClearFilter}
                                            searchable={true}
                                            dataSource={{
                                                filters: `${configURL.surveyDemographicfilter}/${peopleType}`,
                                                filterOptions: `${configURL.surveyDemographicfilterSearch}/${peopleType}`
                                            }}
                                            show={dropdownFlag}
                                            hide={() => setDropdownFlag(false)}
                                        />
                                    </div>
                                    <div className="participant-source-datatable">
                                        <RemotePagination
                                            data={peoples}
                                            page={tableMeta.pagination.current_page || 0}
                                            sizePerPage={pageRef.current}
                                            totalSize={tableMeta.pagination.total || 0}
                                            onTableChange={handleAllEntriesChange}
                                        />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="sampled">
                                    <div className="sampled-select-wrap">
                                        <select name="sample_type" value={sampleTabData.sample_type} className="survey-button-tab-select" onChange={handleSampleTabDataChange}>
                                            <option value="">Select Sample</option>
                                            <option value="random">Random</option>
                                        </select>
                                        {sampleTabData.sample_type === 'random' && <input name="participant_count" className="survey-button-tab-input" placeholder="No of Participant" value={sampleTabData.participant_count} onChange={handleSampleTabDataChange} />}
                                        <button className="ezi-pink-btn btn-ripple sampling-apply-btn" type="button" onClick={handleApplySampling}>Apply</button>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            }
        </React.Fragment >
    )

}
const mapStateToProps = state => {
    return {
        surveyStatus: state.survey.status,
        selectedParticipant: state.participant.addedParticipant,
        participantSource: state.participant.participantSource
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatchParticipant: (data = {}) => dispatch(AppAction.addParticipant({ participants: data })),
        dispatchParticipantSource: (source) => dispatch(AppAction.setParticipantSource({ source })),
        dispatchParticipantSourceType: (type) => dispatch(AppAction.setDynamicParticipantSourceType({ type })),
        dispatchDynamicSourceFile: (file) => dispatch(AppAction.setDynamicSourceFile({ file }))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantSourceTab)