import React, { useState, useEffect, useRef, useContext } from "react";
import { CircularProgressbar } from 'react-circular-progressbar';
import { Breadcrumb, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Axios from "utility/Axios";
import configURL from 'config/config';
import { getFirstWord, dateConvert } from 'utility/helper';
import 'utility/i18next.js'
import './CategorySurveyDashboard.scss';
import EziAlert from 'components/Alert';
import { toast } from "react-toastify";
import CategoryFilter from "components/SearchFilter";
import SweetSearch from "components/SweetSearch";
import Pagination from "react-js-pagination";
import { confirmAlert } from 'react-confirm-alert';
import AppContext from 'store/AppContext';
import DateTimePicker from 'react-datetime-picker';
import SurveyFilter from './SurveyFilter';
import ActiveSurvey from "components/ActiveSurvey";

const CategorySurveyDashboard = (props) => {
    const { languageObj = {}, handleUnAuthWarn = {}, accesFeature = {}, EziLoader } = useContext(AppContext)
    const [defaultCategory, setDefaultCategory] = useState({ ...props.location.state, description: "" });
    const [categoryMetaData, setCategoryMetaData] = useState({})
    const [categories, setCategories] = useState([]);
    const [categorySurveys, setCategorySurveys] = useState([]);
    const [pagination, setPagination] = useState({});
    const [searchLoading, setSearchLoading] = useState(false);
    const [filterType, setFilterType] = useState(props.location.state.survey_status || "total");
    const [listingFilter, setListingFilter] = useState({});
    const [surveyUpdatedata, setSurveyUpdatedata] = useState({
        show: false,
        id: "",
        name: "",
        closeDate: "",
        startDate: ""
    });

    const [activateSurveyData, setActivateSurveyData] = useState({
        show: false,
        closeDate: "",
        survey_id: null
    });

    const perPage = 10;
    const [loading] = useState(false);
    const [modal, setModel] = useState({
        show: false,
        text: "",
        seletedId: "",
        newStatus: ""
    });
    var searchTimer = null;
    const inputSearch = useRef(null);

    const [showFilter, setShowFilter] = useState(false);


    /**
     * Get Selected/Default category MetaData.
     */
    const getCategoryMetaData = async () => {
        let formData = new FormData();
        formData.append("category_id", defaultCategory.category_id);
        let response = await Axios.post(configURL.survey_dashboard_details, formData);
        if (response.data.success !== undefined && response.data.success === true) {
            setCategoryMetaData(response.data.results, getCategorySurveys());
        } else {
            toast.warn(response.data.message);
        }
    }

    /**
     * Change status
     * 
     * @param {number} id data id
     */
    const handleStatusChange = () => {
        let selectedId = modal.seletedId;
        let newStatus = modal.newStatus;
        const data = [...categorySurveys];
        const modifiedData = data.map(item => {
            if (item.id === selectedId) {
                item.status = newStatus
            }
            return item;
        })
        setCategorySurveys(modifiedData);
        setModel({ show: false, newStatus: "", text: "", seletedId: "" })
        let formData = new FormData();
        formData.append("survey_id", selectedId);
        formData.append("status", newStatus);
        Axios.post(configURL.survey_status_update, formData).then(response => {
            if (response.data.success !== undefined && response.data.success === true) {
                toast.success(response.data.message);
            } else {
                toast.warn(response.data.message)
            }
        })
    }

    /**
     * Get Searched category.
     * 
     * @param {string} search Search string.
     */
    const getCategoriesListing = (search = "") => {
        let formData = new FormData();
        formData.append("search", search);
        formData.append("type", "survey");
        Axios.post(configURL.categoryDs, formData).then(response => {
            if (response.data.success !== undefined && response.data.success === true) {
                let result = response.data.results;
                const categoryData = [];
                result.forEach(item => {
                    categoryData.push({ id: item.id, name: item.category_name, description: item.description });
                });
                setCategories(categoryData);
            } else {
                toast.warn(response.data.message);
            }
        })
    }

    /**
     * Get Category templates.
     * 
     * @param {object} obj category object
     */
    const changeSurveyCategory = (obj = {}) => {
        setDefaultCategory({
            category_id: obj.id || null,
            category_name: obj.name || null,
            description: obj.description || ""
        });
        props.history.replace("/survey-dashboard/category-survey-dashboard", {
            category_id: obj.id,
            category_name: obj.name
        })
        setFilterType("total")
    }

    /**
     * Get Perticular template types.
     * 
     * @param {string} type template types
     * @param {number} page Page number
     */
    const getCategorySurveys = (page = 1, type = filterType, filters = listingFilter) => {
        let searchVal = inputSearch.current.value;
        let formData = new FormData();
        formData.append("category_id", defaultCategory.category_id);
        formData.append("per_page", perPage);
        formData.append("page", page);
        formData.append("type", type);
        formData.append("search", searchVal);
        formData.append("filters", JSON.stringify(filters));
        Axios.post(configURL.category_wise_surveys, formData).then(response => {
            EziLoader.hide()
            setSearchLoading(false)
            if (response.data.success !== undefined && response.data.success === true) {
                setCategorySurveys(response.data.results);
                setPagination(response.data.pagination);
            } else {
                toast.warn(response.data.message);
            }
        }).catch(err => {
            console.log(err)
            EziLoader.hide()
        })
    }

    /**
     * Filter Data based on Click.
     * 
     * @param {string} type Filter Type
     */
    const handleFilterChange = (type = "total") => {
        EziLoader.show()
        setFilterType(type);
        getCategorySurveys(1, type);
    }

    /**
     * Filter Data based on search.
     * 
     * @param {string} type Input value
     */
    const handleFilterSearch = () => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setSearchLoading(true)
            getCategorySurveys(1, filterType);
        }, 800);
    }

    /**
     * Handle Pagination
     * 
     * @param {string} type Filter Type
     */
    const handlePagination = (page = 1) => {
        EziLoader.show()
        getCategorySurveys(page, filterType);
    }

    /**
     * Handle Survey Delete
     * 
     * @param {number} id Survey Id
     */
    const handleSurveyDelete = (id) => {
        confirmAlert({
            title: 'Delete Survey',
            message: 'Are you sure you want to delete ?',
            buttons: [
                {
                    label: 'Confirm',
                    className: 'test',
                    onClick: () => {
                        let filteredData = categorySurveys.filter(item => item.id !== id);
                        setCategorySurveys(filteredData);
                        let formData = new FormData();
                        formData.append("survey_id", id);
                        Axios.post(configURL.rollback_survey, formData).then(response => {
                            if (response.data.success !== undefined && response.data.success === true) {
                                toast.success(response.data.message);
                            } else {
                                toast.warn(response.data.message);
                            }
                        })
                    }
                },
                {
                    label: 'Cancel'
                }
            ]
        });
    }

    /**
     * Create Survey
     */
    const handleCreateSurvey = () => {
        if (defaultCategory.category_id && defaultCategory.category_id !== "") {
            props.history.push(`/survey-dashboard/add-survey/${defaultCategory.category_id}`, {
                action: "add-survey",
                category_name: defaultCategory.category_name
            })
        } else {
            props.history.push('/survey-dashboard/categories', {
                action: "add-new"
            });
        }
    }

    useEffect(() => {
        EziLoader.show()
        getCategoryMetaData()
    }, [defaultCategory]);
    useEffect(() => {
        getCategoriesListing()
    }, []);

    /**
     * Change Survey Date
     */
    const handleUpdateSurveyDate = () => {
        let start_date = (surveyUpdatedata.startDate !== null) ? dateConvert(new Date(surveyUpdatedata.startDate)) : null
        let end_date = (surveyUpdatedata.closeDate !== null) ? dateConvert(new Date(surveyUpdatedata.closeDate)) : null
        let formData = new FormData();
        formData.append("survey_id", surveyUpdatedata.id);
        formData.append("start_date", start_date);
        formData.append("end_date", end_date);
        Axios.post(configURL.update_survey_date, formData).then(response => {
            if (response.data.success !== undefined && response.data.success === true) {
                setSurveyUpdatedata({ show: false, name: "", id: "", closeDate: null, startDate: null })
            } else {
                toast.warn(response.data.message);
            }
        })
    }

    /**
     * Survey Date Check
     * @param {Object} item 
     */
    const checkDateChange = (item) => {
        if (item.endtime !== null && (new Date(new Date(item.endtime).toDateString()).getTime() < new Date(new Date().toDateString()).getTime())) {
            toast.warn("Survey Ended")
            return
        }
        setSurveyUpdatedata({
            show: true,
            name: item.name,
            id: item.id,
            closeDate: item.endtime,
            startDate: (item.starttime !== 'started') ? item.starttime : null
        })
    }

    /**
     * Handle Apply filter
     * @param {Object} filter 
     */
    const handleApplyFilter = (filter = []) => {
        EziLoader.show()
        setListingFilter(filter)
        setShowFilter(false)
        getCategorySurveys(1, filterType, filter);
    }

    /**
     * Handle Filter Clear
     */
    const handleClearFilter = () => {
        EziLoader.show()
        setListingFilter({})
        setShowFilter(false)
        getCategorySurveys(1, filterType, {});
    }

    /**
     * Clone Survey
     * @param {Object} data 
     */
    const handleCloneSurvey = (data = null) => {
        let survey_id = data.id
        let category_id = data.category_id;
        let categoryData = categories.find(item => item.id === category_id)
        let category_name = categoryData && categoryData.name ? categoryData.name : "Category";

        props.history.push(`/survey-dashboard/add-survey/${category_id}`, {
            action: "add-survey",
            category_name: category_name,
            survey_id: survey_id,
            action: "clone"
        });
    }

    /**
     * Handle Survey Status Change
     * @param {Object} item 
     */
    const handleSurveyStatus = (item) => {
        if (item.status.toLowerCase() === "active" || item.status.toLowerCase() === "inactive") {
            setModel({
                show: true,
                text: `Are you sure you want to change survey status from ${item.status} to ${(item.status.toLowerCase() === "inactive") ? "Active" : "Inactive"}`,
                seletedId: item.id,
                newStatus: (item.status.toLowerCase() === "inactive") ? "active" : "inactive"
            })
        }
        if (item.status.toLowerCase() === "close") {

            confirmAlert({
                title: 'Activate Survey',
                message: 'Do you really want to reopen the survey?',
                buttons: [
                    {
                        label: 'Confirm',
                        onClick: () => {
                            if (new Date(new Date(item.endtime).toDateString()).getTime() < new Date(new Date().toDateString()).getTime()) {
                                setActivateSurveyData({ show: true, closeDate: item.endtime, survey_id: item.id })
                            } else {
                                handleActivateSurvey(null, item.id)
                            }
                        }
                    },
                    {
                        label: 'Cancel'
                    }
                ]
            });
        }
    }

    /**
     * Activate Survey
     * @param {Date} date 
     * @param {String} survey_id 
     */
    const handleActivateSurvey = (date, survey_id) => {
        let formData = new FormData()
        formData.append('survey_id', survey_id)
        if (date) {
            formData.append('enddate', dateConvert(date))
        }
        formData.append('status', 'active')
        Axios.post(configURL.survey_status_update, formData).then(response => {
            if (response.data.success !== undefined && response.data.success === true) {
                toast.success(response.data.message);
                setActivateSurveyData({ show: false, closeDate: null, survey_id: null })
                getCategorySurveys(pagination.current_page || 1)
            } else {
                toast.warn(response.data.message)
            }
        })
    }

    const handleSurveyAnalyze = (surveyId) => {
        props.history.push(`/customer-experience/${surveyId}`, {
            survey_id: surveyId,
        });
    }

    return (
        <React.Fragment>
            <section className="Page-CategorySurveyDashboard">
                <EziAlert
                    show={modal.show}
                    alerttext={modal.text}
                    showcancel
                    showClose
                    confirm={handleStatusChange}
                    onhide={() => setModel({ show: false, text: "", seletedId: "", curStatus: "" })}
                />
                <ActiveSurvey
                    show={activateSurveyData.show}
                    closeDate={activateSurveyData.closeDate}
                    confirm={handleActivateSurvey}
                    survey_id={activateSurveyData.survey_id}
                    onhide={() => setActivateSurveyData({ show: false, closeDate: null, survey_id: null })}
                />
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => props.history.goBack()}>{defaultCategory.category_name}</Breadcrumb.Item>
                        <Breadcrumb.Item >{languageObj.translate('Surveys.1')}</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="template-category-dropdown" >
                        <CategoryFilter handleSelect={changeSurveyCategory} data={categories} defaultSelected={defaultCategory.category_name} />
                    </div>
                    <span className="template-category-subtitle"> {(defaultCategory.description !== undefined) ? defaultCategory.description : ''} </span>
                </div>

                <div className="category-dashboard-card-wrap">
                    <div className={`category-dashboard-card total_survey ${(filterType == "total") && "active"}`} onClick={() => handleFilterChange("total")}>
                        <div className="category-dashboard-card_text_wrap">
                            <p className="category-dashboard-card-heading">{categoryMetaData.total_surveys || 0}</p>
                            <span className="category-dashboard-card-subheading">{languageObj.translate('TotalSurveys.1')}</span>
                        </div>
                        <div className="category_progress_ring_wrap">
                            <div className="category_progress_ring">
                                <CircularProgressbar className="category_progress_circle" value={categoryMetaData.active_survey_percentage || 0} text={`${categoryMetaData.active_survey_percentage || 0}%`}
                                />
                            </div>
                            <span className="circular-survey-text active--text">{languageObj.translate('ActiveSurveys.1')}</span>
                            <span className="circular-survey-text inactive--text">{languageObj.translate('InactivSurveys.1')}</span>
                        </div>
                    </div>
                    <div className={`category-dashboard-card active_survey ${(filterType == "active") && "active"}`} onClick={() => handleFilterChange("active")}>
                        <div className="category-dashboard-card_text_wrap">
                            <p className="category-dashboard-card-heading">{categoryMetaData.active_surveys || 0}</p>
                            <span className="category-dashboard-card-subheading">{languageObj.translate('ActiveSurveys.1')}</span>
                        </div>
                    </div>
                    <div className={`category-dashboard-card unpublish_survey ${(filterType == "inactive") && "active"}`} onClick={() => handleFilterChange("inactive")}>
                        <div className="category-dashboard-card_text_wrap">
                            <p className="category-dashboard-card-heading">{categoryMetaData.inactive_surveys || 0}</p>
                            <span className="category-dashboard-card-subheading">{languageObj.translate('InactivSurveys.1')}</span>
                        </div>
                    </div>
                    <div className={`category-dashboard-card closed_survey ${(filterType == "close") && "active"}`} onClick={() => handleFilterChange("close")}>
                        <div className="category-dashboard-card_text_wrap">
                            <p className="category-dashboard-card-heading">{categoryMetaData.closed_surveys || 0}</p>
                            <span className="category-dashboard-card-subheading">{languageObj.translate('ClosedSurveys.1')}</span>
                        </div>
                    </div>

                    <div className={`category-dashboard-card create-survey--c ${accesFeature.create_survey || "access_lock"}`} onClick={() => {
                        if (accesFeature.create_survey) {
                            handleCreateSurvey()
                        } else {
                            handleUnAuthWarn()
                        }

                    }}>
                        <span className="create-survey--ic"></span>
                        <p className="create-survey--name"> {languageObj.translate('Create.1')}{defaultCategory.category_id !== "" ? defaultCategory.category_name : ""} {languageObj.translate('Survey.1')}</p>
                    </div>
                </div>

                <div className="filter-search-wrap">
                    <div className="custom-demography-button-wrap">
                        <button type="button" className="ezi-filter-btn" onClick={() => setShowFilter(!showFilter)}>Filter</button>
                        <SurveyFilter
                            show={showFilter}
                            hide={() => setShowFilter(false)}
                            position="right"
                            applyFilter={handleApplyFilter}
                            clearFilter={handleClearFilter}
                        />
                    </div>
                    <SweetSearch loading={searchLoading} change={handleFilterSearch} ref={inputSearch} />
                </div>

                <div className="category-dashboard-table">

                    <div className="category-dashboard-table-row category-table-heading">
                        <div className="category-dashboard-table-cell">  {languageObj.translate('Survey.1')}</div>
                        <div className="category-dashboard-table-cell">  Created By</div>
                        <div className="category-dashboard-table-cell">  Report Type</div>
                        <div className="category-dashboard-table-cell"> {languageObj.translate('Status.1')} </div>
                        <div className="category-dashboard-table-cell">
                            <span className="appeared-heading">Appeared / </span>
                            <span className="appeared-heading">Total / </span>
                            <span className="appeared-heading">Response Rate</span>
                        </div>
                        <div className="category-dashboard-table-cell"> {languageObj.translate('Analysis.1')} </div>
                        <div className="category-dashboard-table-cell">  {languageObj.translate('Action.1')}</div>
                    </div>
                    {
                        categorySurveys.length > 0 ? categorySurveys.map((item, index) => {
                            return (
                                <div key={index} className="category-dashboard-table-row">
                                    <div className="category-dashboard-table-cell" data-title="Survey">
                                        <div className={`category-table-survey-wrap ${accesFeature.edit_survey || "access_lock"}`} onClick={() => {
                                            if (accesFeature.edit_survey) {
                                                props.history.push(`/survey-dashboard/edit-survey/${(defaultCategory.category_id !== "") ? defaultCategory.category_id : item.category_id}/${item.id}`, {
                                                    surveyName: item.name,
                                                    action: "edit",
                                                    status: (item.status && item.status.toLowerCase()) || 'draft'
                                                })
                                            } else {
                                                handleUnAuthWarn()
                                            }

                                        }}>
                                            <div className="category-table-survey-text-wrap">
                                                <span className="category-table-survey-name">{item.name}</span>
                                                <span className="category-table-create">
                                                    Last Modified : {getFirstWord(item.updated_at)}   |   Created on : {getFirstWord(item.created_at)}
                                                </span>
                                                <span className="category-table-create">
                                                    Started on : {getFirstWord(item.starttime) || "---"}   |  Closed on : {getFirstWord(item.endtime) || "---"}
                                                </span>
                                                <span className="category-table-create">Description : {item.description}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="category-dashboard-table-cell" data-title="Owner"><span className="owner-name">{item.owner || "---"}</span></div>
                                    <div className="category-dashboard-table-cell" data-title="Type"><span className="survey-type">{item.report_type_name || '---'}</span></div>
                                    <div onClick={() => accesFeature.edit_survey ? handleSurveyStatus(item) : handleUnAuthWarn()} className={`category-dashboard-table-cell table-status ${accesFeature.edit_survey || "access_lock"}`} data-title="Status"> <span className={`dt-status-formatter ${item.status || 'draft'}`}>{item.status || 'draft'}</span></div>
                                    <div className="category-dashboard-table-cell " data-title="Responses/Dropout">
                                        <span className="dt-dropouts-formatter">{item.appeared || 0}</span>
                                        <span className="response-devider">/</span>
                                        <span className="dt-dropouts-formatter">{item.total}</span>
                                        <span className="response-devider">/</span>
                                        <span className="dt-dropouts-formatter">{item.response_rate} {!isNaN(item.response_rate) && '%'}</span>
                                    </div>

                                    <div className="category-dashboard-table-cell " data-title="Analysis">
                                        <button type="button" className="dt-analyze-formatter" disabled={(item.status.toLowerCase() === 'active' || item.status.toLowerCase() === 'close') ? false : true} onClick={() => handleSurveyAnalyze(item.id)}>
                                            {languageObj.translate('Analyze.1')}
                                        </button>
                                    </div>
                                    <div className={`category-dashboard-table-cell`} data-title="Action">
                                        <div className="action-wrap">
                                            {(item.status && (item.status.toLowerCase() === 'draft' || item.status.toLowerCase() === 'inactive')) ?
                                                <OverlayTrigger overlay={<Tooltip>{(item.endtime !== null && new Date(new Date(item.endtime).toDateString()).getTime() < new Date(new Date().toDateString()).getTime()) ? 'Survey Ended' : 'Change Survey Dates'}</Tooltip>}>
                                                    <button type="button" className="edit_data" onClick={() => {
                                                        if (accesFeature.edit_survey) {
                                                            checkDateChange(item)
                                                        } else {
                                                            handleUnAuthWarn()
                                                        }
                                                    }}>
                                                    </button>
                                                </OverlayTrigger> :
                                                <OverlayTrigger overlay={<Tooltip>Survey is Active or Finished</Tooltip>}>
                                                    <span className="edit_data"></span>
                                                </OverlayTrigger>
                                            }
                                            <OverlayTrigger overlay={<Tooltip>Copy Survey</Tooltip>}>
                                                <button type="button" className={`clone_survey ${accesFeature.create_survey || " access_lock"}`} onClick={() => {
                                                    if (accesFeature.create_survey) {
                                                        handleCloneSurvey(item)
                                                    } else {
                                                        handleUnAuthWarn()
                                                    }
                                                }}>
                                                </button>
                                            </OverlayTrigger>


                                            {(item.status.toLowerCase() === 'active' || item.status.toLowerCase() === 'close') ?
                                                <OverlayTrigger overlay={<Tooltip>Survey is Active or Finished</Tooltip>}>
                                                    <span className="delete_data"></span>
                                                </OverlayTrigger> :
                                                <OverlayTrigger overlay={<Tooltip>Delete Survey</Tooltip>}>
                                                    <button type="button" className="delete_data" /* disabled={(item.status.toLowerCase() === 'active' || item.status.toLowerCase() === 'close')} */ onClick={() => {
                                                        if (accesFeature.delete_survey) {
                                                            handleSurveyDelete(item.id)
                                                        } else {
                                                            handleUnAuthWarn()
                                                        }
                                                    }}></button>
                                                </OverlayTrigger>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : null
                    }
                </div>
                {categorySurveys.length <= 0 && <div className="category-table-no-result">No result Found</div>}

                <div className="pagination-plugin-wrap category-pagination-formatter">
                    <Pagination
                        activePage={pagination.current_page}
                        itemsCountPerPage="10"
                        totalItemsCount={pagination.total}
                        onChange={handlePagination}
                        hideDisabled={true}
                    />
                </div>
            </section>
            <Modal show={surveyUpdatedata.show} onHide={() => setSurveyUpdatedata({ show: false, name: "", id: "", closeDate: null, startDate: null })} size="md" aria-labelledby="ezi-modal-for" centered className="ezi-modal-wrapper" >
                <Modal.Header className="ezi-modal-header">
                    <Modal.Title id="ezi-modal-for" className="ezi-modal-title-wrap" >
                        <span className="ezi-modal-header-title">Update {surveyUpdatedata.name}</span>
                        <span className="ezi-modal-close" onClick={() => setSurveyUpdatedata({ show: false, name: "", id: "", closeDate: null, startDate: null })}></span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="survey-date-change-wrapper">
                        <div className="survey-date-change-field">
                            <label>Survey Started At :</label>
                            <DateTimePicker
                                onChange={(date) => setSurveyUpdatedata({
                                    ...surveyUpdatedata,
                                    startDate: date
                                })}
                                className="ezi-datepicker-custom"
                                value={new Date(surveyUpdatedata.startDate)}
                                clearIcon={null}
                                calendarIcon={null}
                                disabled={(surveyUpdatedata.startDate !== null && new Date(surveyUpdatedata.startDate).getTime() < new Date().getTime()) ? true : false}
                            />
                        </div>
                        <div className="survey-date-change-field">
                            <label>Survey Ending At :</label>
                            <DateTimePicker
                                onChange={(date) => setSurveyUpdatedata({
                                    ...surveyUpdatedata,
                                    closeDate: date
                                })}
                                className="ezi-datepicker-custom"
                                value={new Date(surveyUpdatedata.closeDate)}
                                clearIcon={null}
                                calendarIcon={null}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <div className="ezi-modal-footer">
                    <button type="button" className="ezi-modal-close-btn" onClick={() => setSurveyUpdatedata({ show: false, name: "", id: "", closeDate: null, startDate: null })}>Close</button>
                    <button type="button" className="btn-ripple ezi-pink-btn ezi-modal-save-btn" onClick={handleUpdateSurveyDate}>Save</button>
                </div>
            </Modal>
            {loading && <EziLoader />}
        </React.Fragment>
    )
}

export default CategorySurveyDashboard;