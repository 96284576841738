import React, { useState, useContext, useEffect } from "react";
import './Users.scss';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Tab, Breadcrumb, Nav } from 'react-bootstrap';
import AppContext from 'store/AppContext';
import configURL from "config/config";
import { toast } from "react-toastify";
import Axios from "utility/Axios";
import { confirmAlert } from "react-confirm-alert";

const UsersView = (props) => {
    const user_id = props.match.params.user_id || null;
    const { languageObj = {}, handleUnAuthWarn = {}, accesFeature = {}, EziLoader, appState } = useContext(AppContext)
    const [key, setKey] = useState('primaryInfo');
    const [userData, setUserData] = useState({});

    const getUserDetail = () => {
        EziLoader.show()
        let formData = new FormData();
        formData.append("person_id", user_id)
        Axios.post(configURL.get_user_detail, formData).then(response => {
            EziLoader.hide()
            if (response.data.success === true) {
                setUserData(response.data.result);
            } else {
                toast.warn(response.data.message);
            }
        }).catch(() => {
            EziLoader.hide()
        })
    }

    const handleRemoveUser = () => {
        let formData = new FormData();
        formData.append("person_id", user_id)
        confirmAlert({
            title: 'Delete User',
            message: "Are you sure you want to delete this ? ",
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        Axios.post(configURL.delete_person, formData).then(response => {
                            if (response.data.success === true) {
                                toast.success("User Deleted!")
                            } else {
                                toast.success(response.data.message)
                            }
                        })
                        props.history.replace("/users");
                    },
                },
                {
                    label: 'Cancel',

                }
            ]
        });
    }
    useEffect(() => {
        getUserDetail();
    }, [])
    useEffect(() => {
        if (appState.user && appState.user.id === user_id) {
            toast.warn("Loggedin User edit/delete operations are not allowed.")
        }
    }, [])
    return (
        <React.Fragment>
            <section className="Page-UsersView" >
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item>{languageObj.translate('Settings.1')}</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => props.history.push("/users")}>{languageObj.translate('Users.1')}</Breadcrumb.Item>
                        <Breadcrumb.Item>detail</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <header className="user-info-header">
                    <div className="user-header-col user-info-text">
                        <span className="title_c">{userData.first_name || ""} {userData.last_name || ""}</span>
                        <span className="subtitle_c">{userData.email || ""}</span>
                    </div>
                    <div className="user-header-col user-info-role">
                        <span className="user-info-role_ic"></span>
                        <div className="user-info-role-text">
                            <span className="user-role-title">Role</span>
                            <span className="user-role-subtitle">{(userData.roles && userData.roles.reduce((arr, cur) => arr.concat(cur.label), []).join()) || ""}</span>
                        </div>
                    </div>
                    <div className="user-header-col user-info-profile-status">
                        <div className="profile-status-progress-wrap">
                            <CircularProgressbar className="profile-status-progress" value={80} text="80%" />
                        </div>
                        <div className="user-info-role-text">
                            <span className="user-role-title">Profile Status</span>
                            <span className="user-role-subtitle">Partially completed</span>
                        </div>
                    </div>
                    <div className="user-header-col user-info-action">
                        <button type="button" className={`ezi-btn btn-ripple user-info-action-edit ${accesFeature.edit_user || "access_lock"}`} onClick={() => {
                            if (accesFeature.edit_user) {
                                props.history.push('/user/edit/' + user_id, {
                                    action: "edit"
                                })
                            } else {
                                handleUnAuthWarn()
                            }
                        }} disabled={(appState.user && appState.user.id === user_id)} title={(appState.user && appState.user.id === user_id) ? 'Logged In' : ''}>Edit</button>
                        <button type="button" className={`user-info-action-delete ${accesFeature.delete_user || "access_lock"}`} onClick={() => {
                            if (accesFeature.delete_user) {
                                handleRemoveUser()
                            } else {
                                handleUnAuthWarn()
                            }
                        }} disabled={(appState.user && appState.user.id === user_id)} title={(appState.user && appState.user.id === user_id) ? 'Logged In' : ''}>Delete</button>
                    </div>
                </header>
                <main className="user-info-content">
                    <Tab.Container activeKey={key} onSelect={k => setKey(k)}>
                        <div className="user-view-side-tab">
                            <Nav variant="pills" className="user-side-tab-nav" >
                                <Nav.Item>
                                    <Nav.Link eventKey="primaryInfo">User login & Role</Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item>
                                    <Nav.Link eventKey="calenderInfo">Calendar settings</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="currencyInfo">Currency and Number Field</Nav.Link>
                                </Nav.Item> */}
                            </Nav>
                        </div>
                        <Tab.Content className="user-view-tab-content">
                            <Tab.Pane eventKey="primaryInfo">
                                <div className="info-tab-header">
                                    <span className="info-tab-header-title">Primary Information</span>
                                    <span className="info-tab-header-subtitle">Login Details and assigned roles</span>
                                </div>
                                <div className="info-inner-content">
                                    <div className="form-view-data">
                                        <label className="form-view-field-wrap">
                                            <span className="form-view-label">First Name</span>
                                            <span className="form-view-value">{userData.first_name || ""}</span>
                                        </label>
                                        <label className="form-view-field-wrap">
                                            <span className="form-view-label">Last Name</span>
                                            <span className="form-view-value">{userData.last_name || ""}</span>
                                        </label>
                                        <label className="form-view-field-wrap">
                                            <span className="form-view-label">Primary Email</span>
                                            <span className="form-view-value">{userData.email || ""}</span>
                                        </label>
                                        <label className="form-view-field-wrap">
                                            <span className="form-view-label">Mobile Number</span>
                                            <span className="form-view-value">{userData.cell_number || ""}</span>
                                        </label>
                                        <label className="form-view-field-wrap">
                                            <span className="form-view-label">Role</span>
                                            <span className="form-view-value">{(userData.roles && userData.roles.reduce((arr, cur) => arr.concat(cur.label), []).join()) || ""}</span>
                                        </label>
                                        <label className="form-view-field-wrap">
                                            <span className="form-view-label">Status</span>
                                            <span className="form-view-value">{(userData.active) ? "Active" : "Offline"}</span>
                                        </label>
                                    </div>
                                </div>
                            </Tab.Pane>
                            {/* <Tab.Pane eventKey="calenderInfo">
                                <div className="info-tab-header">
                                    <span className="info-tab-header-title">Calendar </span>
                                    <span className="info-tab-header-subtitle">Calendar and time information</span>
                                </div>
                                <div className="info-inner-content">
                                    <div className="form-view-data">
                                        {
                                            CALENDER_DATA.map((item, index) =>
                                                <label key={item.id} className="form-view-field-wrap">
                                                    <span className="form-view-label">{item.label}</span>
                                                    <span className="form-view-value">{item.value}</span>
                                                </label>
                                            )
                                        }
                                    </div>
                                </div>

                            </Tab.Pane> */}
                            {/* <Tab.Pane eventKey="currencyInfo">
                                <div className="info-tab-header">
                                    <span className="info-tab-header-title">Currency</span>
                                    <span className="info-tab-header-subtitle">Currency and number field information</span>
                                </div>
                                <div className="info-inner-content">
                                    <div className="form-view-data">
                                        {
                                            CURRENCY_DATA.map((item, index) =>
                                                <label key={item.id} className="form-view-field-wrap">
                                                    <span className="form-view-label">{item.label}</span>
                                                    <span className="form-view-value">{item.value}</span>
                                                </label>
                                            )
                                        }
                                    </div>
                                </div>
                            </Tab.Pane> */}
                        </Tab.Content>
                    </Tab.Container>
                </main>
            </section>
        </React.Fragment>
    )
}

export default UsersView;