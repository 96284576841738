import React , { useState } from 'react';
import { Bar , Doughnut } from 'react-chartjs-2';
import { CircularProgressbar } from 'react-circular-progressbar';
import { ProgressBar } from 'react-bootstrap';

const ImageTypeMulti = () => {

    const barChartData = {
        labels: [ 'Chinese', 'Mexican', 'Italian' , 'Thai' , 'American'],
        datasets: [
            {
                label: "Total Invitees",
                backgroundColor: ['#CE2E6C', '#504658' , '#32CBBB', '#FBCD49', '#33A6CB'],
                borderColor: ['#CE2E6C', '#504658' , '#32CBBB', '#FBCD49', '#33A6CB'],
                data: [40, 25, 35 ,30 ,25],
                offsetGridLines : false,
            }
        ]
    };

    
    const barChartOptions = {
        responsive: true,   
        maintainAspectRatio: false,
        legend: {
            display: false,
            align:'start',
            position:'top',
            labels: {
                fontColor: '#5A4E63',
                boxWidth: 6,
                fontSize:12,
                fontFamily :"NunitoSansSemiBold",
                padding	:20,
                usePointStyle:true,
            }
        },
        scales: {
            xAxes: [{
                barPercentage: 0.7,
                categoryPercentage: 0.4,
                ticks: { fontColor: "#373737",fontFamily: "NunitoSans", fontSize: 12, stepSize: 6, beginAtZero: true,},
                gridLines: { color: "transparent",zeroLineColor: 'transparent',}, 
            }],

            yAxes: [{
                ticks: { fontColor: "#5B5B5B",fontFamily: "NunitoSans", fontSize: 12, stepSize: 10, beginAtZero: true , padding: 15},
                 gridLines: { color: '#EDEDED',  zeroLineColor: '#EDEDED',borderColor:"transparent",   drawBorder: false, } }]
             }
        
    };

    const doughnutData = {

        labels: [ 'Chinese', 'Mexican', 'Italian' , 'Thai' , 'American'],
        datasets: [{
            data: [30, 30, 20 , 20 ,20],
            backgroundColor: [ '#CE2E6C', '#5A4E63', '#32CBBB' , '#32A6CB' , '#FFBD24' ],
        }]


    } 

    const doughnutOptions = {
        segmentShowStroke: false,
        responsive: true, 
        maintainAspectRatio: false,  
        legend: {
            display: true,
            align:'start',
            position:'bottom',
            labels: {
                fontColor: '#5A4E63',
                boxWidth: 6,
                fontSize:12,
                fontFamily :"NunitoSansSemiBold",
                padding	:20,
                usePointStyle:true,
            }
        },
        cutoutPercentage: 75,
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        tooltips: {
            displayColors: false,
            backgroundColor: "#5A4E63",
            enabled: true,
            mode: "single",
            bodyFontSize: 13,
            bodyFontFamily: "NunitoSansSemiBold",
            bodyFontColor: "#FFFFFF",
            yPadding: 8,
            xPadding: 15,
            cornerRadius: 5,
          },
    }




    return(           
        <React.Fragment>
            <section className="Common-QuestionComponent Page-ImageType">
                <div className="question-type-wrap">
                    <label className="question"><span className="question-no">Q</span><span>[7]</span>What's your favourite type of food?</label>
                    <div className="question-type image-type">Image type - Multi Select</div>
                </div>

                <div className="checkbox-multi-card-wrap">

                  
                    <div className="total-responses">                         
                        <div className="image-type-ring">
                            <CircularProgressbar className="image-type-ring_progress least" value={30} text={`30%`} />
                        </div>
                        <div className="least-selected-wrap"> 
                            <span className="title">Least selected</span>
                            <span className="subtitle">Mexican</span>
                        </div>
                    </div>

                    <div className="total-comments">    
                        <div className="image-type-ring">
                            <CircularProgressbar className="image-type-ring_progress majority" value={30} text={`70%`} />
                        </div>                     
                        <div className="majority-selected-wrap"> 
                            <span className="title">Majority selected</span>
                            <span className="subtitle">Chinese</span>
                        </div>
                    </div>

                    <div className="total-responses">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Total Responses</span>
                            <span className="subtitle">189</span>
                        </div>
                        <span className="checkbox-multi-card-icon"></span>
                    </div>

                    <div className="total-comments">                         
                        <div className="checkbox-text-wrap"> 
                            <span className="title">Total Comments</span>
                            <span className="subtitle">98</span>
                        </div>
                        <span className="checkbox-multi-card-icon"></span>
                    </div>

                </div>

                <div className="checkbox-multi-chart-wrap">

                    <div className="response-statistics-chart">
                        <div className="response-statistics-header">
                            <p>Response statistics</p>
                            <button type="button" className="header-download-graph"></button>
                        </div>
                        <div className="response-statistics-body">
                             <Bar data={barChartData} options={barChartOptions} height={250}/>
                        </div>
                    </div>

                    <div className="checkbox-multi-chart-donut">
                        <button type="button" className="header-download-graph"></button>
                         <Doughnut data={doughnutData} options={doughnutOptions} height={150} width={150}/>
                    </div>

                </div>

                <div className="multi-progressbar-wrap">                               

                    <div className="multi-progressbar-inner"> 
                        <div className="multi-progress-card-wrap">
                        {
                            [...Array(5)].map((item,index) =>
                                <div className="multi-progress-img-card" key={index}>
                                    <div className="multi-progress-img-text">
                                        <span className="title">Mexican</span>
                                        <span className="subtitle">15.5%</span>
                                    </div>
                                    <div className="multi-progress-img-wrap">
                                        <img alt="" src={require(`../../../../assets/images/report/food/food1.svg`)} />
                                    </div>
                                </div>
                            )
                        }      
                        </div>

                        <ProgressBar>
                            <ProgressBar  className="multi-progress-color" now={25} key={1} />
                            <ProgressBar  className="multi-progress-color" now={20} key={2} />
                            <ProgressBar  className="multi-progress-color" now={10} key={3} />
                            <ProgressBar  className="multi-progress-color" now={20} key={4} />
                            <ProgressBar  className="multi-progress-color" now={25} key={5} />
                        </ProgressBar>
                    </div>
                </div>

            </section>
        </React.Fragment>   
    )


}

export default ImageTypeMulti;