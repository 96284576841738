import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import './EmployeeExperience.scss';
import FilterModal from './FilterModal'; 
import CheckboxMulti from './CheckboxMulti';
import DropdownSingle from './DropdownSingle';
import StarRating from './StarRating';
import StarRatingCustom from './StarRatingCustom';
import StarRatingMatrix from './StarRatingMatrix';
import ImageType from './ImageType';
import ImageTypeMulti from './ImageTypeMulti';
import Ranking from './Ranking';
import DateType from './DateType';
import CityType from './CityType';
import PersonalInfo from './PersonalInfo';
import RadioButton from './RadioButton';
import SmileyRating from './SmileyRating';
import SmileyRatingMatrix from './SmileyRatingMatrix';
import SmileyCustomRating from './SmileyCustomRating';
import YesNoMaybe from './YesNoMaybe';
import YesNoMaybeMatrix from './YesNoMaybeMatrix';

const ReportDashboard = () => {

    const [pageData, setPageData] = useState(1)
    const [ filterModal , setFilterModal ] = useState(false);
    const [ toggle , setToggle] = useState(false);


    const QUESTION_DATA = [
        { id: 1, question: "What factors are important to you when buying a product?" },
        { id: 2, question: "How long have you been purchasing our products?" },
        { id: 3, question: "How would you rate our services today?", },
        { id: 4, question: "How do you rate car maintenance service for these companies?" },
        { id: 5, question: "How would you rate your recent hotel experience?" },
        { id: 6, question: "What's your favourite type of food?" },
        { id: 7, question: "What's your favourite type of food?" },
        { id: 8, question: "Please rank the following features in order of importance?" },
        { id: 9, question: "What time can you make the appointment?" },
        { id: 10, question: "Which City are you from?" },
        { id: 11, question: "Enter your personal details?" },
        { id: 12, question: "How would you rate our services today?" },
        { id: 13, question: "How was your experience at our hotel?" },
        { id: 14, question: "How would you rate our services today?" },
        { id: 15, question: "Do you plan to visit our store again in the next 2 weeks?" },
        { id: 16, question: "Do you plan to visit our store again in the next 2 weeks?" },
        { id: 17, question: "How long have you been purchasing our products?" },

    ]

    const pageChange = (e) => {
        setPageData(e);
        setToggle(false)
    }

    return (


        <React.Fragment>
            <section className="Page-CE-Report">

                <button type="button" className="question-toggle-btn" onClick={ () => setToggle(!toggle)}>Questions</button>
                <div className={`question-toggle-overlay ${toggle ? 'active' : ''}`} onClick={ () => setToggle(!toggle)}></div>
                
                <aside className={`report-sidebar ${ toggle ? 'active' : ''}`}>
                    <div className="report-sidebar-header">
                        <span className="back-dashboard">Back to report dashboard</span>
                        <div className="survey-download-wrap">
                            <span className="survey-analysis">Survey Analysis</span>
                            <button type="button" className="download_ic"></button>
                        </div>
                        <div className="report-sidebar-search-wrap">
                            <input type="text" className="report-sidebar-search" />
                            <div className="report-sidebar-select-wrap">
                                <select className="report-sidebar-select"><option>Themes</option></select>
                                <select className="report-sidebar-select"><option>Sub Themes</option></select>
                            </div>
                        </div>
                    </div>

                    <div className="question-list-wrap">

                        {
                            QUESTION_DATA.map((item, index) =>
                                <div className={`question-list ${(pageData == item.id) ? "active" : ""}`} key={item.id} onClick={() => pageChange(item.id)}>
                                    <span className="que-number">Question <span className="sr-no">[{index + 1}]</span></span>
                                    <span className="question">{item.question}</span>
                                </div>

                            )
                        }

                    </div>

                </aside>
                <section className="report-content">
                    <header>

                        <div className="report-header-text-wrap">
                            <span className="title">Awareness Survey</span>
                            <span className="subtitle">3rd Nov 2019 - 7th Dec 2019</span>
                        </div>

                        <div className="report-header-right">
                            <button type="button" className="ezi-filter-btn" onClick={() => setFilterModal(true)}>Filter</button>

                            <button type="button" className="btn-ripple download-report">Basic Analysis</button>
                            <div className="menu-dropdown common-dropdown">
                                <Dropdown>
                                    <Dropdown.Toggle>
                                        <span>Advance Analysis</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#">Basic Analysis</Dropdown.Item>
                                        <Dropdown.Item href="#">Advance Analysis</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                        </div>
                    </header>
                    <main>
                        {(() => {
                            switch (pageData) {
                                case 1: return (<CheckboxMulti />);

                                case 2: return (<DropdownSingle />);

                                case 3: return (<StarRating />);

                                case 4: return (<StarRatingCustom />);

                                case 5: return (<StarRatingMatrix />);

                                case 6: return (<ImageType />);

                                case 7: return (<ImageTypeMulti />);

                                case 8: return (<Ranking />);

                                case 9: return (<DateType />);

                                case 10: return (<CityType />);

                                case 11: return (<PersonalInfo />);

                                case 12: return (<SmileyRating />);

                                case 13: return (<SmileyRatingMatrix />);

                                case 14: return (<SmileyCustomRating />);

                                case 15: return (<YesNoMaybe />);

                                case 16: return (<YesNoMaybeMatrix />);

                                case 17: return (<RadioButton />);

                            }
                        })()}
                    </main>
                </section>
                <FilterModal show={filterModal} onHide={() => setFilterModal(false)}   />
            </section>
        </React.Fragment>
    )
}

export default ReportDashboard;