import React, { useCallback, useEffect, useState, useContext } from 'react';
import './BulkUpdate.scss';
import Dropzone from "hooks/Dropzone";
import configURL from 'config/config';
import Axios from "utility/Axios";
import { toast } from 'react-toastify';
import AppContext from 'store/AppContext';
import { Breadcrumb } from 'react-bootstrap';

var file;
const BulkUpdate = (props) => {
    let currentPath = props.match.path.split('/')[2];
    const { languageObj = {} } = useContext(AppContext)
    const [upload, setUpload] = useState(false);
    const [message, SetMessage] = useState();
    const [excelDownloadLink, setExcelDownloadLink] = useState('');
    const onDrop = useCallback(acceptedFiles => {
        file = acceptedFiles[0];
    }, []);
    const peoplesAlias = { employee: "employees", customer: "customers", other: "other contacts" }
    const downloadExcel = () => {
        if (excelDownloadLink !== '') {
            window.open(excelDownloadLink, '_blank').focus();
        } else {
            toast.error(`Invalid Download Link !`);
        }
    }

    const getExcelDownloadLink = () => {
        const formdata = new FormData();
        let localStore = localStorage.getItem("bulkUpdateData");
        let exelData = localStore ? JSON.parse(localStore) : {}
        formdata.append("selected_people", JSON.stringify(exelData.selected_people || []));
        formdata.append("selected_column", JSON.stringify(exelData.selected_columns || []));
        formdata.append("selected_column_name", JSON.stringify(exelData.selected_column_name || []));
        Axios.post(`${configURL.bulkExcelDownload}/${currentPath}`, formdata).then(res => {
            if (res.data.success !== undefined && res.data.success) {
                setExcelDownloadLink(res.data.excel_link);
            } else {
                toast.warn(res.data.message);
            }
        })
    }

    useEffect(() => {
        if (upload) {
            if (typeof (file) === "undefined") {
                toast.warn("Please attach file in drop area before upload.");
            }
            else if (file.size >= 50000) {
                toast.warn("Please do not upload file size greater than 50MB");
            }
            else if (file.name.split('.').pop() !== "xlsx" && "xls") {
                toast.warn("Please upload file in xlsx or xls format");
            }
            else {
                const fileupload = new FormData();
                fileupload.append("language", languageObj.curLang);
                fileupload.append("file", file);
                Axios.post(`${configURL.bulkUpdate}/${currentPath}`, fileupload).then(response => {
                    if (response.data.success) {
                        toast.success(response.data.message.toString(), {
                        });
                    }
                    else {
                        SetMessage(response.data.message);
                        toast.warn(response.data.message.toString());
                    }
                })
            }

        }
        setUpload(false);
    }, [upload]);

    useEffect(() => {
        getExcelDownloadLink();
    }, [])

    return (
        <React.Fragment>
            <section className="Page-BulkUpdate">
                <div className="breadcrumb_ezi people-directory-breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => props.history.push("/people-directory")}>
                            {languageObj.translate('PeopleDirectory.1')}
                        </Breadcrumb.Item>
                        {(() => {
                            switch (currentPath) {
                                case 'employee':
                                    return <Breadcrumb.Item onClick={() => props.history.push("/people-directory", {
                                        page_key: "employee"
                                    })}>{'Employee'}</Breadcrumb.Item>
                                case 'customer':
                                    return <Breadcrumb.Item onClick={() => props.history.push("/people-directory", {
                                        page_key: "customer"
                                    })}>{'Customer'}</Breadcrumb.Item>
                                case 'other':
                                    return <Breadcrumb.Item onClick={() => props.history.push("/people-directory", {
                                        page_key: "other_contact"
                                    })}>{'Other Contact'}</Breadcrumb.Item>
                            }
                        })()}
                        <Breadcrumb.Item>{languageObj.translate('BulkUpdate.1')}</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="column-header">
                        <h1 className="page-heading">{languageObj.translate('BulkUpdate.1')}</h1>
                        <button className="btn-ripple  download-template" onClick={downloadExcel}>
                            Download Template of selected {peoplesAlias[currentPath]}
                        </button>
                    </div>
                </div>
                <div className="dropzone-wrap">
                    <Dropzone onDrop={onDrop} accept={"application/vnd.ms-excel/*"} />
                </div>
                <div className="dropzone-btn">
                    <input type="button" className="btn-ripple upload-btn" onClick={() => { setUpload(true) }} value="Upload Selected File" />
                </div>
                <span className="dropzone-supported-file"> {languageObj.translate('Filessupported.1')}, Max File Size is 50MB</span>
            </section>
        </React.Fragment>
    )
}
export default BulkUpdate;