
export const dateConvert = (date = null) => {
    let currentdate = date || new Date()
    return currentdate.getFullYear() + "-"
        + (currentdate.getMonth() + 1) + "-"
        + currentdate.getDate() + " "
        + currentdate.getHours() + ":"
        + currentdate.getMinutes() + ":"
        + currentdate.getSeconds();
}

export const getFirstWord = (str) => {
    if (str && str.length !== null) {
        let spaceIndex = str.replace(/ .*/, '');
        return spaceIndex;
    }
};

export const toTitleCase = (str) => {
    if (str && str.length !== null) {
        return str.replace(/\w\S*/g, (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
}

export const outlineRemove = {
    control: base => ({
        ...base,
        border: 0,
        boxShadow: "none"
    })
};

export const getNameInitials = string => {
    const names = string.split(' ');
    const initials = names.map(name => name.charAt(0).toUpperCase())
    if (initials.length > 1) {
        return `${initials[0]}${initials[initials.length - 1]}`;
    } else {
        return initials[0];
    }
}

export const compareObject = (obj1, obj2) => {
    for (var p in obj1) {
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

        switch (typeof (obj1[p])) {
            case 'object':
                if (!compareObject(obj1[p], obj2[p])) return false;
                break;
            case 'function':
                if (typeof (obj2[p]) == 'undefined' || (p != 'compareObject' && obj1[p].toString() != obj2[p].toString())) return false;
                break;
            default:
                if (obj1[p] != obj2[p]) return false;
        }
    }
    for (var p in obj2) {
        if (typeof (obj1[p]) == 'undefined') return false;
    }
    return true;
};

export const uniqueGenerator = () => {
    let date = Date.now();
    if (date <= uniqueGenerator.previous) {
        date = ++uniqueGenerator.previous;
    } else {
        uniqueGenerator.previous = date;
    }

    return 'unq' + date.toString(20) + Math
        .floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

export const surveyQuestionModifier = (questions = {}) => {
    let questionsData = questions.pages || [];
    questionsData.forEach((page, index) => {
        if (questionsData[index] && questionsData[index].hasOwnProperty("elements") && questionsData[index]["elements"].length > 0) {
            questionsData[index]["elements"].forEach((item, i) => {
                if (questionsData[index]["elements"][i]["type"] === 'matrix') {
                    if (questionsData[index]["elements"][i].hasOwnProperty("rows")) {
                        questionsData[index]["elements"][i]["rows"].forEach((mtItem, inum) => {
                            let mtOptions = {}
                            if (typeof mtItem === 'string') {
                                mtOptions['text'] = mtItem
                                mtOptions['value'] = uniqueGenerator()
                            }
                            if (typeof mtItem === 'object') {
                                let rowVal = mtItem['value']
                                mtOptions['text'] = mtItem['text']
                                if (rowVal && !rowVal.startsWith('unq')) {
                                    rowVal = uniqueGenerator()
                                }
                                if (rowVal.length <= 3 || !rowVal) {
                                    rowVal = uniqueGenerator()
                                }
                                mtOptions['value'] = rowVal
                            }
                            questionsData[index]["elements"][i]["rows"][inum] = mtOptions
                        })
                    }
                }
            })
        }
    })
    questions.pages = questionsData
    return questions
}

export const canSaveBuilderQuestions = (questionsData = null) => {
    if (questionsData && questionsData.hasOwnProperty('pages') && questionsData.pages.length > 0) {
        const Questions = questionsData.pages.reduce((arr, el) => {
            let newEl = (el.hasOwnProperty('elements') && el['elements'].length > 0) ? el['elements'] : [];
            return arr.concat(newEl);
        }, []);

        return Questions.length > 0
    } else {
        return false
    }
}
export const getIdentifierQuestion = (questions = {}) => {
    let questionsData = questions.pages || [];
    let identifierQuestions = []
    questionsData.forEach((page, index) => {
        if (questionsData[index] && questionsData[index].hasOwnProperty("elements") && questionsData[index]["elements"].length > 0) {
            questionsData[index]["elements"].forEach((item, i) => {
                if (item.hasOwnProperty('isIdentifier') && item['isIdentifier']) {
                    identifierQuestions.push(item)
                }
            })
        }
    })
    return identifierQuestions
}
export const getPersonalQuestion = (questions = {}) => {
    let questionsData = questions.pages || [];
    let personalQuestion = []
    questionsData.forEach((page, index) => {
        if (questionsData[index] && questionsData[index].hasOwnProperty("elements") && questionsData[index]["elements"].length > 0) {
            questionsData[index]["elements"].forEach((item, i) => {
                if (item.hasOwnProperty('question_type') && item['question_type'] == 'PERSONALINFO') {
                    personalQuestion.push(item)
                }
            })
        }
    })
    return personalQuestion
}

export const getTablePagesSizes = (total) => {
    return [
        {
            text: "25",
            value: 25
        },
        {
            text: "50",
            value: 50
        },
        {
            text: "100",
            value: 100
        },
        {
            text: "200",
            value: 200
        }
    ]
}